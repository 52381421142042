import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'tear_off_flyer_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="tear_off_flyer_outline_56"><g fill="currentColor"><path d="M15 19.5a1.5 1.5 0 011.5-1.5h23a1.5 1.5 0 010 3h-23a1.5 1.5 0 01-1.5-1.5zM15 29.5a1.5 1.5 0 011.5-1.5h15a1.5 1.5 0 010 3h-15a1.5 1.5 0 01-1.5-1.5z" /><path clip-rule="evenodd" d="M12.139 7.603C14.266 6.465 16.364 6 21.383 6h13.234c5.019 0 7.117.465 9.244 1.603a10.924 10.924 0 014.536 4.536C49.535 14.266 50 16.364 50 21.383v13.234c0 5.019-.465 7.117-1.603 9.244a10.924 10.924 0 01-4.536 4.536C41.734 49.535 39.636 50 34.617 50H21.383c-5.019 0-7.117-.465-9.244-1.603a10.924 10.924 0 01-4.536-4.536C6.465 41.734 6 39.636 6 34.617V21.383c0-5.019.465-7.117 1.603-9.244a10.924 10.924 0 014.536-4.536zM21.383 9c-4.83 0-6.344.454-7.83 1.248a7.925 7.925 0 00-3.305 3.306c-.794 1.485-1.248 3-1.248 7.83v13.233c0 4.83.454 6.344 1.248 7.83a7.925 7.925 0 003.306 3.305c1.485.794 3 1.248 7.83 1.248h13.233c4.83 0 6.344-.454 7.83-1.248a7.925 7.925 0 003.305-3.306c.794-1.485 1.248-3 1.248-7.83V21.384c0-4.83-.454-6.344-1.248-7.83a7.925 7.925 0 00-3.306-3.305c-1.485-.794-3-1.248-7.83-1.248z" fill-rule="evenodd" /><path d="M20 39.5a1.5 1.5 0 013 0v9a1.5 1.5 0 01-3 0zM33 39.5a1.5 1.5 0 013 0v9a1.5 1.5 0 01-3 0z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56TearOffFlyerOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56TearOffFlyerOutline: FC<Icon56TearOffFlyerOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56TearOffFlyerOutline as any).mountIcon = mountIcon;

export default Icon56TearOffFlyerOutline;
