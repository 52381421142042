import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'warning_triangle_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="warning_triangle_outline_28"><path d="M10.702 7.212c.821-1.422 1.232-2.133 1.7-2.473a2.72 2.72 0 013.197 0c.468.34.878 1.051 1.699 2.473l6.395 11.076c.82 1.422 1.23 2.133 1.291 2.708a2.72 2.72 0 01-1.599 2.769c-.528.235-1.349.235-2.99.235H7.605c-1.641 0-2.462 0-2.99-.235a2.72 2.72 0 01-1.6-2.77c.061-.574.472-1.285 1.292-2.707l6.395-11.076z" stroke="currentColor" stroke-width="2" /><path fill-rule="evenodd" clip-rule="evenodd" d="M14 9.5a1 1 0 00-1 1v5a1 1 0 102 0v-5a1 1 0 00-1-1zm0 8.8a1.2 1.2 0 100 2.4 1.2 1.2 0 000-2.4z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28WarningTriangleOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28WarningTriangleOutline: FC<Icon28WarningTriangleOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28WarningTriangleOutline as any).mountIcon = mountIcon;

export default Icon28WarningTriangleOutline;
