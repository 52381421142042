import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'music_mic_outline_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="music_mic_outline_36"><g fill="currentColor"><path clip-rule="evenodd" d="M24 20a8 8 0 100-16 8 8 0 000 16zm0-2.5a5.5 5.5 0 100-11 5.5 5.5 0 000 11z" fill-rule="evenodd" /><path d="M18.443 21.563a1.28 1.28 0 011.695 1.921l-8.336 7.656a4.008 4.008 0 01-5.657-5.656l6.377-8.336a1.28 1.28 0 011.92 1.694L8.067 27.18a1.446 1.446 0 002.041 2.04z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36MusicMicOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36MusicMicOutline: FC<Icon36MusicMicOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36MusicMicOutline as any).mountIcon = mountIcon;

export default Icon36MusicMicOutline;
