import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'rss_circle_fill_black_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="rss_circle_fill_black_28"><linearGradient id="rss_circle_fill_black_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#626466" /><stop offset="1" stop-color="#444647" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#rss_circle_fill_black_28_a)" /><path d="M8.681 8.001c.214.01.39.023.527.036 5.64.563 10.133 5.023 10.745 10.649.018.162.033.373.046.632a.65.65 0 01-.65.682h-1.106a.65.65 0 01-.649-.614c-.012-.22-.027-.4-.043-.54a9.622 9.622 0 00-8.633-8.42 7.45 7.45 0 00-.302-.022.65.65 0 01-.616-.65V8.65a.65.65 0 01.681-.649zm.016 4.033c.298.021.463.034.494.039a8.022 8.022 0 016.698 6.498c.033.183.061.426.085.728a.65.65 0 01-.648.701h-.988a.65.65 0 01-.647-.594 4.564 4.564 0 00-.037-.317 5.73 5.73 0 00-4.74-4.741l-.315-.026A.65.65 0 018 13.674v-.992a.65.65 0 01.697-.648zM11.292 20H8.65a.65.65 0 01-.65-.65v-2.645a.65.65 0 01.797-.633 4.013 4.013 0 013.097 2.976c.014.057.027.123.04.199a.65.65 0 01-.642.753z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28RssCircleFillBlackProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28RssCircleFillBlack: FC<Icon28RssCircleFillBlackProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28RssCircleFillBlack as any).mountIcon = mountIcon;

export default Icon28RssCircleFillBlack;
