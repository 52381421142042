import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'privacy_circle_fill_purple_32';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="privacy_circle_fill_purple_32"><path d="M16 31.75c8.698 0 15.75-7.052 15.75-15.75S24.698.25 16 .25.25 7.302.25 16 7.302 31.75 16 31.75z" fill="url(#privacy_circle_fill_purple_32_paint0_linear)" stroke="currentColor" stroke-opacity=".08" stroke-width=".5" /><path d="M24.95 14.335c.44.66.504 1.57.165 2.283l-2.296 4.821c-1.303 2.737-4.643 4.847-7.678 4.847h-.855a6.856 6.856 0 01-6.857-6.865v-6.74a2.353 2.353 0 013-2.264v-.628a2.357 2.357 0 013.06-2.253 2.356 2.356 0 014.65.41 2.356 2.356 0 013.003 2.272v3.655c1.114-1.096 2.879-.932 3.809.462zM9.787 12.042a.64.64 0 00-.643.639v6.74a5.142 5.142 0 005.143 5.15h.855c2.377 0 5.113-1.732 6.13-3.869l2.296-4.82a.674.674 0 00-.043-.596c-.367-.55-.963-.556-1.328-.009l-1.867 2.8c-.494.741-.9.623-.9-.276v-7.583a.641.641 0 00-.643-.647.64.64 0 00-.643.64v4.935a.856.856 0 01-.857.854.853.853 0 01-.857-.853V8.073a.642.642 0 10-1.286 0v7.074a.86.86 0 01-.857.853.854.854 0 01-.857-.855V9.788a.643.643 0 10-1.286.001v5.775a.864.864 0 01-.857.865.855.855 0 01-.857-.856V12.69a.644.644 0 00-.643-.648z" fill="#fff" /><defs><linearGradient id="privacy_circle_fill_purple_32_paint0_linear" x1=".25" y1=".25" x2="31.75" y2="31.75" gradientUnits="userSpaceOnUse"><stop stop-color="#FF5263" /><stop offset="1" stop-color="#FF3347" /></linearGradient></defs></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32PrivacyCircleFillPurpleProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32PrivacyCircleFillPurple: FC<Icon32PrivacyCircleFillPurpleProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32PrivacyCircleFillPurple as any).mountIcon = mountIcon;

export default Icon32PrivacyCircleFillPurple;
