import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'headphones_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="headphones_outline_28"><g fill-rule="nonzero" fill="none"><path opacity=".216" d="M0 0h28v28H0z" /><path d="M5 21.059v-3.231a1.941 1.941 0 013.882 0v3.23a1.941 1.941 0 11-3.882 0zm0-1.628v-5.034a3.94 3.94 0 00-2 3.43v3.232a3.941 3.941 0 107.882 0v-3.231A3.941 3.941 0 005 14.397V14a9 9 0 1118 0v.397a3.941 3.941 0 00-5.882 3.43v3.232a3.941 3.941 0 107.882 0v-3.231a3.94 3.94 0 00-2-3.431v4.9a1 1 0 002 0V14c0-6.075-4.925-11-11-11S3 7.925 3 14v5.43a1 1 0 002 0zm18-1.603v3.23a1.941 1.941 0 11-3.882 0v-3.23a1.941 1.941 0 013.882 0z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28HeadphonesOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28HeadphonesOutline: FC<Icon28HeadphonesOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28HeadphonesOutline as any).mountIcon = mountIcon;

export default Icon28HeadphonesOutline;
