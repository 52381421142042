import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'flip_horizontal_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="flip_horizontal_20"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.465 7.531A.75.75 0 019 8.25v8a.75.75 0 01-.75.75H3a.75.75 0 01-.627-1.162l5.25-8a.75.75 0 01.842-.307zM4.389 15.5H7.5v-4.74L4.39 15.5zM6.53 1.47a.75.75 0 010 1.06l-.72.72h8.38l-.72-.72a.75.75 0 011.06-1.06l2 2a.75.75 0 010 1.06l-2 2a.75.75 0 11-1.06-1.06l.72-.72H5.81l.72.72a.75.75 0 01-1.06 1.06l-2-2a.75.75 0 010-1.06l2-2a.75.75 0 011.06 0zm5.005 6.061a.75.75 0 01.842.308l5.25 8A.75.75 0 0117 17h-5.25a.75.75 0 01-.75-.75v-8a.75.75 0 01.535-.719zm.965 3.229v4.74h3.11l-3.11-4.74z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20FlipHorizontalProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20FlipHorizontal: FC<Icon20FlipHorizontalProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20FlipHorizontal as any).mountIcon = mountIcon;

export default Icon20FlipHorizontal;
