import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'book_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="book_outline_20"><path clip-rule="evenodd" d="M6.92 1.5h6.16c.535 0 .98 0 1.345.03.38.03.736.098 1.074.27A2.75 2.75 0 0116.7 3.002c.172.337.24.693.27 1.073.03.365.03.81.03 1.345v9.33a.75.75 0 01-.75.75h-.189c-.033.187-.061.437-.061.75s.028.563.061.75h.189a.75.75 0 010 1.5h-11A2.25 2.25 0 013 16.25V5.42c0-.535 0-.98.03-1.345.03-.38.098-.736.27-1.073A2.75 2.75 0 014.502 1.8c.337-.172.693-.24 1.073-.27.365-.03.81-.03 1.345-.03zM14.545 17c-.027-.218-.045-.468-.045-.75s.018-.532.045-.75H5.25a.75.75 0 000 1.5zM15.5 5.45V14H5.25c-.263 0-.515.045-.75.128V5.45c0-.572 0-.957.025-1.253.023-.287.065-.424.111-.514a1.25 1.25 0 01.547-.547c.09-.046.227-.088.514-.111C5.993 3 6.377 3 6.95 3h6.1c.572 0 .957 0 1.252.025.288.023.425.065.515.111.236.12.427.311.547.547.046.09.088.227.111.514.024.296.025.68.025 1.253z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20BookOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20BookOutline: FC<Icon20BookOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20BookOutline as any).mountIcon = mountIcon;

export default Icon20BookOutline;
