import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'gallery_32';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="gallery_32"><g fill="none" fill-rule="evenodd"><path d="M0 0h32v32H0z" /><path d="M24.5 4h-17A3.5 3.5 0 004 7.5v17A3.5 3.5 0 007.5 28h17a3.5 3.5 0 003.5-3.5v-17A3.5 3.5 0 0024.5 4zm-9.833 18.013l4.265-5.495a.5.5 0 01.795.006l5.006 6.676a.5.5 0 01-.4.8H7.69a.5.5 0 01-.395-.807l3.657-4.702a.5.5 0 01.78-.012l2.936 3.534z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32GalleryProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32Gallery: FC<Icon32GalleryProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32Gallery as any).mountIcon = mountIcon;

export default Icon32Gallery;
