import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'diamond_outline_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="diamond_outline_36"><path d="M4 14.965v-.012a1 1 0 01.006-.06l.002-.023a.817.817 0 01.023-.116l.004-.016a1.043 1.043 0 01.046-.132l-.033.086a1.003 1.003 0 01.137-.272l.01-.014.013-.016 4.484-5.981a.981.981 0 01.158-.17l.03-.024a1 1 0 01.124-.083l-.08.05a.993.993 0 01.067-.043l.013-.007 3.5-2A1 1 0 0113 6h10a1 1 0 01.496.132l3.5 2a.811.811 0 01.12.08l.044.036c.057.05.106.103.148.16L31.8 14.4l.011.015a.95.95 0 01.108.191l-.045-.093a.988.988 0 01.033.065l.016.037a1.152 1.152 0 01.036.1l.004.014.003.01a.857.857 0 01.02.088v.01a.931.931 0 01.012.102l.001.014a1.219 1.219 0 01-.007.172.808.808 0 01-.005.035l-.003.02a1.184 1.184 0 01-.016.07l-.004.017-.007.022a1.416 1.416 0 01-.022.067l-.006.015-.012.028a1.017 1.017 0 01-.03.065l-.02.035-.018.03a1.023 1.023 0 01-.093.126l.07-.09a.901.901 0 01-.08.101l-12.97 14.967a.975.975 0 01-.29.243l-.024.011a.7.7 0 01-.105.048l-.02.007a.786.786 0 01-.091.028l-.023.005a.902.902 0 01-.092.017c-.01 0-.019.002-.028.003a.978.978 0 01-.326-.02l-.023-.005a.858.858 0 01-.087-.026l-.028-.01a.964.964 0 01-.41-.295L4.245 15.655a.434.434 0 01-.045-.056 1.014 1.014 0 01-.028-.04l-.003-.004-.009-.013a.987.987 0 01-.026-.043l-.015-.028a1.296 1.296 0 01-.008-.015l-.004-.007a1.111 1.111 0 01-.048-.111l-.004-.014a1.018 1.018 0 01-.033-.118l-.003-.015a.94.94 0 01-.012-.085l-.002-.017A1.097 1.097 0 014 15.01v-.023zM22.38 19h-8.762L18 27.764zm5.014-1.367l-2.664 1.142-2.284 4.568zm-18.79 0l4.948 5.708-2.283-4.567zM22.133 12h-8.266l-.714 5h9.694zM9.77 10.306l-3.206 4.276 4.63 1.985.72-5.036zm16.46 0l-2.144 1.225.719 5.035 4.63-1.985zM22.734 8h-9.468l-1.75 1 1.75 1h9.468l1.75-1z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36DiamondOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36DiamondOutline: FC<Icon36DiamondOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36DiamondOutline as any).mountIcon = mountIcon;

export default Icon36DiamondOutline;
