import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'memory_card_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="memory_card_24"><path clip-rule="evenodd" d="M8.701 2.435c.258-.161.521-.27.818-.339.297-.068.565-.096 1.34-.096h5.295c1.337 0 1.822.14 2.311.4s.873.646 1.134 1.135.401.974.401 2.31v11.31c0 1.336-.14 1.821-.4 2.31s-.646.873-1.135 1.134-.974.401-2.31.401h-9.31c-1.336 0-1.821-.14-2.31-.4s-.873-.646-1.134-1.135S3 18.491 3 17.155V9.858c0-.775.028-1.043.096-1.34.069-.297.178-.56.34-.818.161-.258.33-.468.878-1.015l3.372-3.372c.547-.547.757-.717 1.015-.879zM10 5a1 1 0 00-1 1v2a1 1 0 002 0V6a1 1 0 00-1-1zm2 1a1 1 0 112 0v2a1 1 0 11-2 0zm4-1a1 1 0 00-1 1v2a1 1 0 102 0V6a1 1 0 00-1-1z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24MemoryCardProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24MemoryCard: FC<Icon24MemoryCardProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24MemoryCard as any).mountIcon = mountIcon;

export default Icon24MemoryCard;
