import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'achievement_circle_fill_blue_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="achievement_circle_fill_blue_28"><linearGradient id="achievement_circle_fill_blue_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#70b2ff" /><stop offset="1" stop-color="#5c9ce6" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#achievement_circle_fill_blue_28_a)" /><path clip-rule="evenodd" d="M13.064 16.54l.5-.266a.93.93 0 01.872 0l.5.266a.93.93 0 001.299-.472l.22-.544a.93.93 0 01.661-.559l.568-.125a.93.93 0 00.687-1.188l-.184-.58a.93.93 0 01.148-.845l.369-.483a.93.93 0 00-.238-1.348l-.503-.321a.93.93 0 01-.428-.747l-.024-.6a.93.93 0 00-1.056-.885l-.563.077a.93.93 0 01-.818-.3l-.382-.425a.929.929 0 00-1.384 0l-.382.425a.93.93 0 01-.818.3l-.564-.077a.93.93 0 00-1.055.884l-.024.6a.93.93 0 01-.428.748l-.503.32a.93.93 0 00-.238 1.348l.369.484a.93.93 0 01.148.844l-.184.581a.93.93 0 00.687 1.188l.568.125a.93.93 0 01.662.56l.22.543a.93.93 0 001.299.472zm-2.225 1.027c.316.315.705.536 1.123.65.067.017.16.036.283.056.067.01.122.057.145.12l.653 1.816a.186.186 0 010 .126l-.601 1.673a.3.3 0 01-.524.076l-.788-1.085c-.062-.085-.09-.112-.126-.136s-.075-.038-.117-.043a.466.466 0 00-.183.022l-1.293.319a.3.3 0 01-.362-.223.307.307 0 01.01-.176l1.15-3.2.08-.221a.186.186 0 01.325-.047c.087.118.162.209.225.273zm6.877-.213l1.225 3.408a.305.305 0 01-.179.389.297.297 0 01-.174.01l-1.292-.319a.466.466 0 00-.183-.022c-.042.005-.081.02-.117.043s-.064.05-.126.136l-.788 1.085a.298.298 0 01-.524-.076l-1.309-3.64a.186.186 0 01.252-.232l.037.016a2.503 2.503 0 002.85-.848.186.186 0 01.326.047z" fill="#fff" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28AchievementCircleFillBlueProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28AchievementCircleFillBlue: FC<Icon28AchievementCircleFillBlueProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28AchievementCircleFillBlue as any).mountIcon = mountIcon;

export default Icon28AchievementCircleFillBlue;
