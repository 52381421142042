import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'crop_32';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="crop_32"><path d="M22.75 26c.69 0 1.25.56 1.25 1.25v1.5a1.25 1.25 0 11-2.5 0v-1.5c0-.69.56-1.25 1.25-1.25zM9.25 2c.647 0 1.18.492 1.244 1.122l.006.128v17.288c0 .335.035.456.1.578a.681.681 0 00.284.284c.104.056.209.09.447.098l.13.002H21.5V11.461c0-.334-.035-.455-.1-.577a.681.681 0 00-.284-.284c-.104-.056-.209-.09-.447-.098l-.13-.002H13.75a1.25 1.25 0 01-.128-2.494L13.75 8h6.404c1.337 0 1.822.14 2.311.4s.873.646 1.134 1.135l.093.185c.201.438.308.956.308 2.126V21.5h4.75a1.25 1.25 0 01.128 2.493L28.75 24l-.75-.001V24H11.846c-1.337 0-1.822-.14-2.311-.4A2.726 2.726 0 018.4 22.464l-.093-.185c-.187-.407-.292-.882-.306-1.885L8 18.021 8 18l-.001-7.5H3.25a1.25 1.25 0 01-1.244-1.122L2 9.25C2 8.56 2.56 8 3.25 8h4.749L8 3.25C8 2.56 8.56 2 9.25 2z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32CropProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32Crop: FC<Icon32CropProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32Crop as any).mountIcon = mountIcon;

export default Icon32Crop;
