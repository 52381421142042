import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'rouble_circle_fill_blue_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="rouble_circle_fill_blue_28"><linearGradient id="rouble_circle_fill_blue_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#70b2ff" /><stop offset="1" stop-color="#5c9ce6" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#rouble_circle_fill_blue_28_a)" /><path clip-rule="evenodd" d="M14.75 8c.083.003.166 0 .25 0a4 4 0 01.2 7.995L15 16h-2.25v1.25h1.906c.193 0 .338.035.46.1a.681.681 0 01.284.284c.065.122.1.267.1.46v.062a.944.944 0 01-.1.46.681.681 0 01-.284.284.944.944 0 01-.46.1H12.75v.656a.944.944 0 01-.1.46.681.681 0 01-.284.284.944.944 0 01-.46.1h-.062a.944.944 0 01-.46-.1.681.681 0 01-.284-.284.944.944 0 01-.1-.46V19h-.656a.944.944 0 01-.46-.1.681.681 0 01-.284-.284.944.944 0 01-.1-.46v-.062c0-.193.035-.338.1-.46a.681.681 0 01.284-.284.944.944 0 01.46-.1H11V16h-.656a.944.944 0 01-.46-.1.681.681 0 01-.284-.284.944.944 0 01-.1-.46v-.062c0-.193.035-.338.1-.46a.681.681 0 01.284-.284.944.944 0 01.46-.1H11V8.756c0-.082.008-.116.023-.146a.698.698 0 01.077-.226.681.681 0 01.284-.284c.122-.065.243-.1.578-.1zM15 9.75h-2.25v4.5H15a2.25 2.25 0 000-4.5z" fill="#fff" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28RoubleCircleFillBlueProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28RoubleCircleFillBlue: FC<Icon28RoubleCircleFillBlueProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28RoubleCircleFillBlue as any).mountIcon = mountIcon;

export default Icon28RoubleCircleFillBlue;
