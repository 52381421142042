import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'song_outline_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="song_outline_36"><path d="M27.493 4.12l.007.13v2.691a4.604 4.604 0 01-3.246 4.4l-.208.058-4.546 1.173V22.65h.004v3.139l-.004.242v.32c0 .106-.013.21-.038.308-.53 5.434-5.956 8.075-8.984 5.047-1.411-1.41-1.587-3.793-.411-5.706 1.218-1.983 3.629-3.198 6.933-3.336V9.321c-.034-2.463 1.163-4.21 3.435-4.966l.24-.076c.92-.28 2.676-.691 5.316-1.251a1.25 1.25 0 011.502 1.092zM17 25.165l-.298.02c-2.291.169-3.797.97-4.505 2.123-.592.964-.51 2.071.049 2.63 1.475 1.476 4.657-.172 4.755-3.967L17 25.165zm8-19.368l-1.59.36c-1.056.246-1.787.437-2.184.57-1.162.386-1.691 1.093-1.724 2.37l-.002.892 3.922-1.01a2.104 2.104 0 001.571-1.87L25 6.941V5.797z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36SongOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36SongOutline: FC<Icon36SongOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36SongOutline as any).mountIcon = mountIcon;

export default Icon36SongOutline;
