import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'cube_box_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="cube_box_outline_20"><path clip-rule="evenodd" d="M9.407 1.547a3.749 3.749 0 011.186 0c.445.071.866.248 1.474.503l.08.034 4.421 1.855.093.039c.44.183.826.345 1.121.62.26.241.458.54.58.872.14.38.139.798.138 1.274V12.74c0 .654.001 1.168-.168 1.63-.15.406-.392.771-.709 1.066-.36.335-.834.533-1.438.786l-.107.045-3.931 1.65c-.027.01-.054.022-.08.033-.608.255-1.03.432-1.474.503a3.752 3.752 0 01-1.186 0c-.445-.071-.865-.248-1.473-.503l-.08-.034-3.931-1.65-.108-.044c-.604-.253-1.077-.45-1.438-.786a2.75 2.75 0 01-.708-1.066c-.17-.462-.17-.976-.169-1.63V6.845v-.1c0-.477-.001-.896.138-1.275.122-.332.32-.631.58-.872.296-.275.682-.437 1.121-.62l.093-.04 4.422-1.854.08-.034c.608-.255 1.028-.432 1.473-.503zm1.343 15.315c.186-.067.435-.17.816-.33l3.932-1.649c.763-.32.964-.416 1.104-.546.143-.134.254-.3.322-.484.065-.18.076-.402.076-1.23v-5.75l-4.854 2.035-.08.034c-.538.226-.93.39-1.32.474a.767.767 0 01.004.084zM9.255 9.416a.76.76 0 00-.005.084v7.362c-.186-.067-.434-.17-.816-.33l-3.931-1.649c-.764-.32-.965-.416-1.104-.546a1.25 1.25 0 01-.322-.484C3.01 13.673 3 13.45 3 12.623v-5.75l4.854 2.035.08.034c.538.226.93.39 1.32.474zM3.603 5.498l4.83 2.026c.72.302.966.4 1.211.44.236.037.476.037.712 0 .245-.04.492-.138 1.21-.44l.726-.304L6.26 4.69a.747.747 0 01-.273-.196l-1.975.829c-.174.073-.307.13-.409.175zM7.83 3.72l6.401 2.686 2.166-.91a22.989 22.989 0 00-.409-.174l-4.422-1.855c-.718-.302-.965-.4-1.21-.44a2.25 2.25 0 00-.712 0c-.245.04-.492.138-1.21.44z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20CubeBoxOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20CubeBoxOutline: FC<Icon20CubeBoxOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20CubeBoxOutline as any).mountIcon = mountIcon;

export default Icon20CubeBoxOutline;
