import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 12 12';
const id = 'flash_circle_12';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" id="flash_circle_12"><path d="M6 0a6 6 0 110 12A6 6 0 016 0zm.3 2.62c-.807.868-1.421 1.524-1.841 1.967a50.41 50.41 0 01-.708.723c-.712.753-.337 1.3.487 1.436l1.17.2a.125.125 0 01.102.144l-.002.01-.482 1.865c-.224.86.113 1.038.715.41l2.53-2.769c.586-.68.44-1.227-.404-1.364l-1.224-.216a.125.125 0 01-.099-.154l.469-1.843c.225-.865-.15-1.022-.712-.409z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon12FlashCircleProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon12FlashCircle: FC<Icon12FlashCircleProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 12,
    height: !isNaN(props.height) ? +props.height : 12,
  }));
};

(Icon12FlashCircle as any).mountIcon = mountIcon;

export default Icon12FlashCircle;
