import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'videocam_slash_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="videocam_slash_outline_28"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M14.872 22H7.128c-1.783 0-2.43-.186-3.082-.534s-1.163-.86-1.512-1.512C2.186 19.302 2 18.655 2 16.872v-5.744c0-1.783.186-2.43.534-3.082.127-.237.275-.455.444-.654L1.293 5.707a1 1 0 011.414-1.414l18 18a1 1 0 01-1.414 1.414L17.586 22zM4.001 10.89c.01-1.136.09-1.515.297-1.9.032-.061.067-.119.105-.173L15.586 20H7.128c-1.324 0-1.727-.078-2.138-.298a1.635 1.635 0 01-.692-.692C4.078 18.6 4 18.197 4 16.873zM14.872 6c1.783 0 2.43.186 3.082.534.651.349 1.163.86 1.512 1.512.232.435.392.866.473 1.633l2.006-1.253A2.65 2.65 0 0126 10.673v6.654a2.65 2.65 0 01-4.055 2.247l-2.77-1.728c-.215-.13-.484-.296-.69-.534a2 2 0 01-.393-.706c-.094-.3-.093-.616-.092-.867v-4.611c0-1.324-.078-1.727-.298-2.138a1.635 1.635 0 00-.692-.692c-.385-.206-.764-.288-1.9-.297L9.64 8a1 1 0 110-2zM20 12l3.006-1.878a.65.65 0 01.994.55v6.655a.65.65 0 01-.994.551L20 16z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28VideocamSlashOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28VideocamSlashOutline: FC<Icon28VideocamSlashOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28VideocamSlashOutline as any).mountIcon = mountIcon;

export default Icon28VideocamSlashOutline;
