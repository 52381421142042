import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'bug_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="bug_outline_20"><path clip-rule="evenodd" d="M5.22 2.22a.75.75 0 011.06 0l1 .999A5.475 5.475 0 0110 2.5c.924 0 1.795.228 2.559.63l.91-.91a.75.75 0 111.061 1.06l-.737.737A5.509 5.509 0 0115.125 6h1.625a.75.75 0 010 1.5h-1.272c.014.165.022.331.022.5v1.5h2.25a.75.75 0 010 1.5H15.5v1.25c0 .254-.017.505-.05.75h1.3a.75.75 0 010 1.5h-1.73a5.501 5.501 0 01-10.04 0H3.25a.75.75 0 010-1.5h1.3a5.549 5.549 0 01-.05-.75V11H2.25a.75.75 0 010-1.5H4.5V8c0-.169.008-.335.022-.5H3.25a.75.75 0 010-1.5h1.625c.274-.7.687-1.332 1.206-1.859l-.861-.86a.75.75 0 010-1.061zM14 12.25V8a4 4 0 00-8 0v4.25a4 4 0 008 0zm-5.5-4a.75.75 0 01.75-.75h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zm.75 3.25a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20BugOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20BugOutline: FC<Icon20BugOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20BugOutline as any).mountIcon = mountIcon;

export default Icon20BugOutline;
