import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'wallet_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="wallet_outline_28"><g fill="none" fill-rule="evenodd"><path d="M0 0h28v28H0z" /><path d="M17.795 4c1.167 0 1.77.116 2.398.452a3.271 3.271 0 011.355 1.355c.317.594.439 1.166.451 2.213.845.055 1.383.202 1.938.499.663.355 1.19.881 1.544 1.544.385.72.519 1.413.519 2.783v6.308c0 1.37-.134 2.063-.519 2.783a3.726 3.726 0 01-1.544 1.544c-.72.385-1.413.519-2.783.519H6.846c-1.37 0-2.063-.134-2.783-.519a3.726 3.726 0 01-1.544-1.544C2.134 21.217 2 20.524 2 19.154V8h.002v-.055c.017-1 .14-1.558.45-2.138a3.271 3.271 0 011.355-1.355C4.435 4.116 5.037 4 6.205 4h11.59zm3.36 6H4v9.154c0 1.068.073 1.449.283 1.84.168.314.409.555.723.723.391.21.772.283 1.84.283h14.308c1.068 0 1.449-.073 1.84-.283.314-.168.555-.409.723-.723.21-.391.283-.772.283-1.84v-6.308c0-1.068-.073-1.449-.283-1.84a1.726 1.726 0 00-.723-.723c-.391-.21-.772-.283-1.84-.283zM20 15a1 1 0 010 2h-2a1 1 0 010-2h2zm-2.205-9H6.205c-.865 0-1.156.056-1.454.216-.234.124-.41.301-.535.535C4.069 7.024 4.01 7.29 4 8H20c-.01-.709-.068-.976-.215-1.25a1.272 1.272 0 00-.535-.534C18.951 6.056 18.66 6 17.795 6z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28WalletOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28WalletOutline: FC<Icon28WalletOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28WalletOutline as any).mountIcon = mountIcon;

export default Icon28WalletOutline;
