import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'chevrons_2_left_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="chevrons_2_left_outline_28"><g fill="currentColor"><path d="M8.414 14l5.793 5.793a1 1 0 01-1.414 1.414l-6.5-6.5a1 1 0 010-1.414l6.5-6.5a1 1 0 111.414 1.414z" /><path d="M16.414 14l5.793 5.793a1 1 0 01-1.414 1.414l-6.5-6.5a1 1 0 010-1.414l6.5-6.5a1 1 0 111.414 1.414z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28Chevrons2LeftOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28Chevrons2LeftOutline: FC<Icon28Chevrons2LeftOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28Chevrons2LeftOutline as any).mountIcon = mountIcon;

export default Icon28Chevrons2LeftOutline;
