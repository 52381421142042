import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'tag_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="tag_outline_28"><g fill="none" fill-rule="evenodd"><path d="M0 0h28v28H0z" /><path d="M23.353 19.01l-4.661 4.6a4.856 4.856 0 01-6.822 0l-6.915-6.825A6.57 6.57 0 013 12.11V7.856A4.856 4.856 0 017.856 3h4.407a6.57 6.57 0 014.615 1.894l6.475 6.391a5.427 5.427 0 01.05 7.675l-.05.05zm-1.405-1.423l.032-.032a3.427 3.427 0 00-.032-4.846l-6.475-6.392A4.57 4.57 0 0012.263 5H7.856A2.856 2.856 0 005 7.856v4.254a4.57 4.57 0 001.36 3.252l6.915 6.825a2.856 2.856 0 004.012 0l4.661-4.6zM10 12a2 2 0 110-4 2 2 0 010 4z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28TagOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28TagOutline: FC<Icon28TagOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28TagOutline as any).mountIcon = mountIcon;

export default Icon28TagOutline;
