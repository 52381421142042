import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'backspace_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="backspace_28"><g fill="none" fill-rule="evenodd"><path d="M0 0h28v28H0z" /><path d="M9 5c-.69 0-1.23.35-1.59.88L2 14l5.41 8.11c.36.53.9.89 1.59.89h13.985A3.015 3.015 0 0026 19.985V8.015A3.015 3.015 0 0022.985 5H9zm9.885 13.295L16 15.41l-2.885 2.885a.997.997 0 01-1.41-1.41L14.59 14l-2.885-2.885a.997.997 0 111.41-1.41L16 12.59l2.885-2.885a.997.997 0 011.41 1.41L17.41 14l2.885 2.885a.997.997 0 01-1.41 1.41z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28BackspaceProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28Backspace: FC<Icon28BackspaceProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28Backspace as any).mountIcon = mountIcon;

export default Icon28Backspace;
