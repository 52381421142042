import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'vinyl_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="vinyl_outline_24"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 15a3 3 0 100-6 3 3 0 000 6zm0-1.8a1.2 1.2 0 100-2.4 1.2 1.2 0 000 2.4z" fill="currentColor" /><path d="M7.4 12A4.6 4.6 0 0112 7.4a.9.9 0 000-1.8A6.4 6.4 0 005.6 12a.9.9 0 001.8 0zm11 0a.9.9 0 00-1.8 0 4.6 4.6 0 01-4.6 4.6.9.9 0 100 1.8 6.4 6.4 0 006.4-6.4z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M5 5a9.872 9.872 0 017-2.9c2.733 0 5.21 1.11 7 2.9a9.872 9.872 0 012.9 7c0 2.733-1.11 5.21-2.9 7a9.872 9.872 0 01-7 2.9A9.872 9.872 0 015 19a9.872 9.872 0 01-2.9-7c0-2.734 1.11-5.21 2.9-7zm7-1.1c-2.237 0-4.26.906-5.728 2.372A8.072 8.072 0 003.9 12c0 2.237.906 4.26 2.372 5.727A8.072 8.072 0 0012 20.1a8.07 8.07 0 005.727-2.373A8.072 8.072 0 0020.1 12c0-2.237-.906-4.26-2.373-5.728A8.072 8.072 0 0012 3.9z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24VinylOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24VinylOutline: FC<Icon24VinylOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24VinylOutline as any).mountIcon = mountIcon;

export default Icon24VinylOutline;
