import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'snowflake_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="snowflake_outline_28"><path clip-rule="evenodd" d="M10.293 2.793a1 1 0 011.414 0L14 5.086l2.293-2.293a1 1 0 111.414 1.414L15 6.914v5.354l4.636-2.677.991-3.698a1 1 0 011.932.518l-.84 3.132 3.133.84a1 1 0 01-.517 1.931l-3.698-.99L16 14l4.636 2.677 3.698-.991a1 1 0 11.518 1.932l-3.132.84.84 3.131a1 1 0 11-1.933.518l-.99-3.698L15 15.732v5.354l2.707 2.707a1 1 0 01-1.414 1.414L14 22.914l-2.293 2.293a1 1 0 01-1.414-1.414L13 21.086v-5.354l-4.636 2.677-.991 3.698a1 1 0 11-1.932-.518l.84-3.132-3.133-.84a1 1 0 11.518-1.931l3.698.99L12 14l-4.636-2.677-3.698.991a1 1 0 01-.518-1.932l3.132-.84-.84-3.131a1 1 0 011.933-.518l.99 3.698L13 12.268V6.914l-2.707-2.707a1 1 0 010-1.414z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28SnowflakeOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28SnowflakeOutline: FC<Icon28SnowflakeOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28SnowflakeOutline as any).mountIcon = mountIcon;

export default Icon28SnowflakeOutline;
