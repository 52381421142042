import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'text_live_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="text_live_outline_24"><g fill="currentColor"><path d="M5.255 5.892a.9.9 0 10-1.334-1.21A10.863 10.863 0 001.1 12c0 2.816 1.069 5.383 2.82 7.317a.9.9 0 001.335-1.209A9.063 9.063 0 012.9 12c0-2.351.89-4.493 2.355-6.108zM20.08 4.683a.9.9 0 10-1.335 1.209A9.063 9.063 0 0121.1 12c0 2.352-.89 4.493-2.355 6.108a.9.9 0 101.334 1.21A10.863 10.863 0 0022.9 12c0-2.815-1.069-5.383-2.82-7.317z" /><path d="M8.102 8.71A.9.9 0 006.727 7.55C5.713 8.75 5.1 10.305 5.1 12s.613 3.25 1.627 4.451a.9.9 0 101.375-1.162C7.352 14.401 6.9 13.254 6.9 12s.452-2.4 1.202-3.29zM17.273 7.549a.9.9 0 10-1.375 1.162c.75.888 1.202 2.035 1.202 3.289s-.452 2.4-1.202 3.29a.9.9 0 101.375 1.161C18.287 15.25 18.9 13.695 18.9 12s-.613-3.25-1.627-4.451zM12.9 10.9H14a.9.9 0 100-1.8h-4a.9.9 0 000 1.8h1.1v3.6a.9.9 0 101.8 0z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24TextLiveOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24TextLiveOutline: FC<Icon24TextLiveOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24TextLiveOutline as any).mountIcon = mountIcon;

export default Icon24TextLiveOutline;
