import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'similar_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="similar_24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" /><path d="M4.052 20.312A11.468 11.468 0 01.5 12c0-3.163 1.284-6.124 3.516-8.276l.026-.025.035-.034a1 1 0 011.38 1.447l-.032.03-.02.02A9.467 9.467 0 002.5 12a9.468 9.468 0 002.935 6.866 1 1 0 01-1.383 1.446zm3.372-3.35A6.733 6.733 0 015.25 12c0-1.91.799-3.694 2.175-4.963a1 1 0 011.356 1.47A4.734 4.734 0 007.25 12c0 1.345.56 2.598 1.53 3.492a1 1 0 01-1.356 1.47zm12.524 3.35a1 1 0 11-1.383-1.446A9.468 9.468 0 0021.5 12a9.467 9.467 0 00-2.904-6.837l-.021-.02-.033-.031a1 1 0 111.416-1.413l.026.025A11.467 11.467 0 0123.5 12c0 3.18-1.299 6.157-3.552 8.312zm-3.372-3.35a1 1 0 11-1.356-1.47A4.734 4.734 0 0016.75 12c0-1.345-.56-2.598-1.53-3.493a1 1 0 011.355-1.47A6.733 6.733 0 0118.75 12c0 1.91-.798 3.693-2.174 4.962zM12 14.25a2.25 2.25 0 110-4.5 2.25 2.25 0 010 4.5z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24SimilarProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Similar: FC<Icon24SimilarProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Similar as any).mountIcon = mountIcon;

export default Icon24Similar;
