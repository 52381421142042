import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'market_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="market_outline_24"><path d="M12 1.1a5 5 0 015 5h.054c1.338 0 1.822.14 2.311.4.49.262.873.646 1.134 1.135.262.489.401.974.401 2.31v4.89c0 2.088-.22 2.86-.634 3.635a4.316 4.316 0 01-1.796 1.796c-.774.414-1.547.634-3.636.634H9.166c-2.09 0-2.862-.22-3.636-.634a4.316 4.316 0 01-1.796-1.796c-.414-.774-.634-1.547-.634-3.636V9.946c0-1.337.14-1.822.4-2.311A2.726 2.726 0 014.636 6.5c.489-.262.974-.401 2.31-.401H7a5 5 0 015-5zm5.247 6.8H6.946c-.948 0-1.203.05-1.462.188a.927.927 0 00-.396.396c-.139.259-.188.514-.188 1.462v4.888c0 1.635.11 2.205.422 2.787.246.46.596.811 1.057 1.057.582.311 1.152.422 2.787.422h5.668c1.635 0 2.205-.11 2.787-.422.46-.246.811-.597 1.057-1.057.311-.582.422-1.152.422-2.787V9.946c0-.948-.05-1.203-.188-1.462a.927.927 0 00-.396-.396c-.24-.129-.478-.18-1.27-.187zM15.946 9c.205 0 .348.035.47.1a.681.681 0 01.284.284c.065.122.1.265.1.47v.092a.953.953 0 01-.1.47.681.681 0 01-.284.284.953.953 0 01-.47.1h-.092a.953.953 0 01-.47-.1.681.681 0 01-.284-.284.953.953 0 01-.1-.47v-.092c0-.205.035-.348.1-.47a.681.681 0 01.284-.284.953.953 0 01.47-.1h.092zm-8 0c.205 0 .348.035.47.1a.681.681 0 01.284.284c.065.122.1.265.1.47v.092a.953.953 0 01-.1.47.681.681 0 01-.284.284.953.953 0 01-.47.1h-.092a.953.953 0 01-.47-.1.681.681 0 01-.284-.284.953.953 0 01-.1-.47v-.092c0-.205.035-.348.1-.47a.682.682 0 01.284-.284.954.954 0 01.47-.1h.092zM12 2.9a3.2 3.2 0 00-3.2 3.2h6.4A3.2 3.2 0 0012 2.9z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24MarketOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24MarketOutline: FC<Icon24MarketOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24MarketOutline as any).mountIcon = mountIcon;

export default Icon24MarketOutline;
