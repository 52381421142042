import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 12 12';
const id = 'ghost_12';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" id="ghost_12"><path d="M6.034 1c1.193 0 2.165.41 3.087 1.247.86.781 1.353 1.836 1.378 2.942l.001.087-.005 4.055c-.014.507-.386.908-.918.908h-.06c-.121-.004-.206-.024-.4-.1l-.099-.04-.294-.122c-.277-.112-.454-.16-.629-.16-.09 0-.16.025-.372.164l-.102.069-.317.22c-.454.312-.793.465-1.207.479l-.063.001-.037-.002-.03.002c-.382 0-.695-.115-1.086-.363l-.176-.116-.329-.228-.125-.083c-.148-.095-.222-.13-.29-.14l-.026-.002-.026-.001c-.176 0-.353.048-.63.16l-.255.106-.139.056c-.164.064-.25.088-.346.096l-.05.003-.065.001c-.513 0-.88-.373-.917-.863l-.002-.045L1.5 5.276c0-1.137.498-2.227 1.382-3.03.9-.815 1.84-1.224 2.99-1.245L6.033 1zM4.782 7.063a.538.538 0 00-.682.152.509.509 0 00.12.721c.11.078.293.183.535.285.973.41 2.036.41 3.025-.285a.509.509 0 00.12-.721.538.538 0 00-.739-.118c-.645.454-1.325.454-1.986.175a1.98 1.98 0 01-.336-.175l-.057-.034zM4.25 4.5a.75.75 0 100 1.5.75.75 0 000-1.5zm3.5 0a.75.75 0 100 1.5.75.75 0 000-1.5z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon12GhostProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon12Ghost: FC<Icon12GhostProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 12,
    height: !isNaN(props.height) ? +props.height : 12,
  }));
};

(Icon12Ghost as any).mountIcon = mountIcon;

export default Icon12Ghost;
