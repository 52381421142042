import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 26 24';
const id = 'switch_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 24" id="switch_24"><path clip-rule="evenodd" d="M8.012 3.267C9.465 2.44 11.167 1.9 13 1.9c2.789 0 5.315 1.132 7.142 2.958A10.073 10.073 0 0123.052 11h1.45a.5.5 0 01.406.791l-2.503 3.495a.5.5 0 01-.813 0l-2.504-3.495a.5.5 0 01.407-.791h1.544a8.073 8.073 0 00-2.311-4.728A8.071 8.071 0 0013 3.9c-1.436 0-2.8.423-4 1.105a1 1 0 01-.988-1.738zM4.962 13h1.543a.5.5 0 00.407-.791L4.408 8.714a.5.5 0 00-.813 0l-2.503 3.495A.5.5 0 001.5 13h1.45a10.072 10.072 0 002.91 6.142A10.071 10.071 0 0013 22.1c1.833 0 3.535-.54 4.988-1.367A1 1 0 0017 18.995C15.801 19.677 14.436 20.1 13 20.1a8.072 8.072 0 01-5.728-2.372A8.073 8.073 0 014.962 13z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24SwitchProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Switch: FC<Icon24SwitchProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 26,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Switch as any).mountIcon = mountIcon;

export default Icon24Switch;
