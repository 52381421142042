import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'screencast_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="screencast_outline_28"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M3.857 18c-.236 0-.467.02-.692.057a1 1 0 11-.33-1.972 6.143 6.143 0 017.08 7.08 1 1 0 01-1.972-.33A4.143 4.143 0 003.857 18z" /><path d="M4 24a2 2 0 100-4 2 2 0 000 4zM3.833 14c-.25 0-.499.011-.743.033a1 1 0 11-.18-1.992c.305-.027.612-.041.923-.041C9.448 12 14 16.552 14 22.167c0 .31-.014.618-.041.923a1 1 0 01-1.992-.18A8.167 8.167 0 003.834 14z" /><path d="M10.956 4h6.088c1.363 0 2.447 0 3.321.071.896.074 1.66.227 2.359.583a6 6 0 012.622 2.622c.356.7.51 1.463.583 2.359.071.874.071 1.958.071 3.321v2.088c0 1.363 0 2.447-.071 3.321-.074.896-.227 1.66-.583 2.359a6 6 0 01-2.622 2.622c-.7.356-1.463.51-2.359.583-.874.071-1.958.071-3.321.071H17a1 1 0 110-2c1.417 0 2.419 0 3.203-.065.772-.063 1.243-.182 1.613-.371a4 4 0 001.748-1.748c.189-.37.308-.842.371-1.613C24 17.419 24 16.417 24 15v-2c0-1.417 0-2.419-.065-3.203-.063-.771-.182-1.243-.371-1.613a4 4 0 00-1.748-1.748c-.37-.189-.841-.308-1.613-.371C19.419 6 18.416 6 17 6h-6c-1.417 0-2.419 0-3.203.065-1.775.145-3.35 1.222-3.657 3.097a1 1 0 11-1.974-.324c.296-1.805 1.479-3.353 3.11-4.184.7-.356 1.463-.51 2.359-.583C8.509 4 9.593 4 10.956 4z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28ScreencastOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28ScreencastOutline: FC<Icon28ScreencastOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28ScreencastOutline as any).mountIcon = mountIcon;

export default Icon28ScreencastOutline;
