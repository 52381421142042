import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'share_external_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="share_external_24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" /><path d="M17 16.08c-.76 0-1.44.3-1.96.77L7.91 12.7c.05-.23.09-.46.09-.7 0-.24-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L7.04 9.81C6.5 9.31 5.79 9 5 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92 0-1.61-1.31-2.92-2.92-2.92z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24ShareExternalProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24ShareExternal: FC<Icon24ShareExternalProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24ShareExternal as any).mountIcon = mountIcon;

export default Icon24ShareExternal;
