import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'artist_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="artist_24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" /><path d="M15.133 14.124c-2.056.516-3.383 2.23-3.383 4.251 0 .59.088 1.134.248 1.625H4a1 1 0 01-1-1v-1c0-3.5 5.333-4.5 8.004-4.5 1.109 0 2.675.172 4.13.624zM15.006 8a4 4 0 01-4.002 4 4 4 0 110-8 4 4 0 014.002 4zm4.498 8.643c0 2.788-.746 3.458-2.835 3.82-1.11.193-2.419-.355-2.419-2.088 0-.846.535-1.65 1.642-1.86.846-.16-.316.04 1.597-.312.464-.086.517-.25.517-.6 0-.176-.004-.319-.01-1.375v-2.851s-.003-1.36 0-2.04c.005-.812.625-1.111 1.949-1.376 0 0 1.514-.247 2.623-.446.245-.044.433.046.433.325v2.557c0 .275-.113.4-.394.449-1.122.197-2.743.477-2.743.477-.25.061-.363.235-.363.485l.003 4.835z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24ArtistProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Artist: FC<Icon24ArtistProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Artist as any).mountIcon = mountIcon;

export default Icon24Artist;
