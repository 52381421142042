import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'wheel_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="wheel_outline_28"><path clip-rule="evenodd" d="M24 14c0 5.523-4.477 10-10 10S4 19.523 4 14 8.477 4 14 4s10 4.477 10 10zm2 0c0 6.627-5.373 12-12 12S2 20.627 2 14 7.373 2 14 2s12 5.373 12 12zm-9.907 5.625C15.44 19.867 14.736 20 14 20s-1.441-.133-2.093-.375l.459-.795a1 1 0 10-1.732-1l-.458.794A5.994 5.994 0 018.083 15H9a1 1 0 100-2h-.917a5.994 5.994 0 012.093-3.624l.458.794a1 1 0 101.732-1l-.459-.795C12.56 8.133 13.264 8 14 8s1.441.133 2.093.375l-.459.795a1 1 0 101.732 1l.458-.794A5.994 5.994 0 0119.917 13H19a1 1 0 100 2h.917a5.994 5.994 0 01-2.093 3.624l-.458-.794a1 1 0 10-1.732 1zM6 14a8 8 0 1016 0 8 8 0 00-16 0zm9.5 0a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28WheelOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28WheelOutline: FC<Icon28WheelOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28WheelOutline as any).mountIcon = mountIcon;

export default Icon28WheelOutline;
