import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'accessibility_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="accessibility_outline_28"><g fill="currentColor"><path d="M14 10.5a2 2 0 100-4 2 2 0 000 4z" /><path d="M15.5 15c0 .224.036.446.107.658l1.342 4.026a1 1 0 01-1.898.632l-.956-2.87a.1.1 0 00-.19 0l-.956 2.87a1 1 0 01-1.898-.632l1.192-3.577a5 5 0 00.257-1.58v-.879a10.935 10.935 0 01-4-1.37 1 1 0 011-1.732c1.324.766 2.859 1.204 4.5 1.204s3.176-.438 4.5-1.204a1 1 0 011 1.732 10.935 10.935 0 01-4 1.37z" /><path clip-rule="evenodd" d="M26 14c0 6.627-5.373 12-12 12S2 20.627 2 14 7.373 2 14 2s12 5.373 12 12zm-2 0c0 5.523-4.477 10-10 10S4 19.523 4 14 8.477 4 14 4s10 4.477 10 10z" fill-rule="evenodd" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28AccessibilityOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28AccessibilityOutline: FC<Icon28AccessibilityOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28AccessibilityOutline as any).mountIcon = mountIcon;

export default Icon28AccessibilityOutline;
