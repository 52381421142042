import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'favorite_outline_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="favorite_outline_36"><path clip-rule="evenodd" d="M18.832 7.04c-.307-.74-1.357-.74-1.664 0l-2.42 5.851a1.35 1.35 0 01-1.161.831l-6.188.4c-.801.051-1.139 1.046-.535 1.575l4.9 4.287c.378.33.541.844.423 1.333l-1.394 5.778c-.186.768.64 1.386 1.324.99l5.208-3.004a1.35 1.35 0 011.35 0l5.208 3.004c.685.395 1.51-.222 1.324-.99l-1.394-5.778a1.35 1.35 0 01.423-1.333l4.9-4.287c.604-.529.266-1.524-.535-1.576l-6.188-.399a1.35 1.35 0 01-1.16-.83zm-4.159-1.032c1.227-2.965 5.427-2.965 6.653 0l2.1 5.074 5.349.345c3.204.207 4.555 4.187 2.139 6.302l-4.285 3.749 1.203 4.983c.742 3.075-2.558 5.544-5.299 3.963L18 27.809l-4.534 2.615c-2.74 1.58-6.04-.888-5.298-3.963l1.203-4.983-4.285-3.75c-2.416-2.114-1.065-6.094 2.14-6.301l5.348-.345 2.1-5.074z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36FavoriteOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36FavoriteOutline: FC<Icon36FavoriteOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36FavoriteOutline as any).mountIcon = mountIcon;

export default Icon36FavoriteOutline;
