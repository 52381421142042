import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'history_forward_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="history_forward_outline_28"><g fill="none" fill-rule="evenodd"><path d="M0 0h28v28H0z" /><path d="M14.5 2c3.25 0 6.283 1.303 8.5 3.529V4l.007-.117A1 1 0 0125 4v4l-.007.117A1 1 0 0124 9h-4l-.117-.007A1 1 0 0120 7h1.643A9.974 9.974 0 0014.5 4c-5.523 0-10 4.477-10 10s4.477 10 10 10a9.964 9.964 0 007.072-2.93 1 1 0 111.415 1.414A11.963 11.963 0 0114.5 26c-6.627 0-12-5.373-12-12s5.373-12 12-12zM14 8a1 1 0 011 1v4.586l2.707 2.707a1 1 0 01-1.414 1.414l-3-3A1 1 0 0113 14V9a1 1 0 011-1z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28HistoryForwardOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28HistoryForwardOutline: FC<Icon28HistoryForwardOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28HistoryForwardOutline as any).mountIcon = mountIcon;

export default Icon28HistoryForwardOutline;
