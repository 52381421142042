import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'bookmark_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="bookmark_outline_20"><path clip-rule="evenodd" d="M8.418 1.5c-.883 0-1.591 0-2.164.047-.588.048-1.099.149-1.57.389a4 4 0 00-1.748 1.748c-.24.471-.341.982-.39 1.57C2.5 5.827 2.5 6.535 2.5 7.418v8.43c0 1.724 1.959 2.717 3.349 1.698l4.008-2.938a.25.25 0 01.296 0l3.997 2.934c1.39 1.02 3.35.027 3.35-1.697V7.418c0-.883 0-1.591-.047-2.164-.048-.588-.149-1.099-.389-1.57a4 4 0 00-1.748-1.748c-.471-.24-.982-.341-1.57-.39-.573-.046-1.281-.046-2.164-.046zM16 7.45c0-.922 0-1.57-.042-2.074-.04-.495-.116-.787-.23-1.011a2.5 2.5 0 00-1.093-1.093c-.224-.114-.516-.19-1.012-.23C13.12 3 12.473 3 11.55 3h-3.1c-.922 0-1.57 0-2.074.042-.495.04-.787.116-1.011.23a2.5 2.5 0 00-1.093 1.093c-.114.224-.19.516-.23 1.011C4 5.881 4 6.528 4 7.45v8.398c0 .495.563.78.962.488l4.008-2.937a1.75 1.75 0 012.07 0l3.998 2.933a.604.604 0 00.962-.487z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20BookmarkOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20BookmarkOutline: FC<Icon20BookmarkOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20BookmarkOutline as any).mountIcon = mountIcon;

export default Icon20BookmarkOutline;
