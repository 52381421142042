import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'forward_15_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="forward_15_28"><g fill="currentColor"><path d="M13.708 6.118v-2.21a.409.409 0 01.622-.347l5.02 3.084a.409.409 0 010 .696l-5.02 3.085a.408.408 0 01-.622-.348v-2.21a7.292 7.292 0 107.166 8.65.875.875 0 011.72.324 9.044 9.044 0 01-8.886 7.36 9.042 9.042 0 110-18.084z" /><path d="M10.942 18.543a.408.408 0 01-.408-.408V13.96l-1.012.398a.327.327 0 01-.447-.304v-.568c0-.164.099-.312.25-.376l2.152-.91a.408.408 0 01.568.376v5.559a.408.408 0 01-.409.408zM13.564 16.74a.703.703 0 00-.167.476c0 .25.098.486.293.709.195.223.462.402.801.538a2.99 2.99 0 001.122.204c.497 0 .934-.096 1.311-.288.377-.192.668-.463.872-.812.205-.35.307-.755.307-1.217 0-.415-.085-.78-.254-1.095s-.407-.56-.712-.735a2.09 2.09 0 00-1.057-.262c-.269 0-.518.045-.747.134-.228.088-.398.206-.508.353h-.078l.101-1.385h2.298c.223 0 .393-.053.51-.157s.177-.254.177-.447c0-.197-.06-.35-.179-.459-.119-.11-.288-.164-.508-.164h-2.6c-.57 0-.873.252-.906.754l-.151 2.274c-.01.153.017.29.08.412.062.122.152.216.27.283a.796.796 0 00.675.047c.088-.036.188-.097.298-.185.137-.1.28-.177.428-.231s.292-.082.432-.082c.19 0 .36.041.51.124s.27.199.353.348c.084.15.126.322.126.515 0 .203-.044.382-.133.538s-.211.278-.368.365a1.078 1.078 0 01-.534.131c-.207 0-.396-.047-.567-.142a1.713 1.713 0 01-.495-.443.878.878 0 00-.263-.224.64.64 0 00-.3-.07.55.55 0 00-.437.194z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28Forward15Props extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28Forward15: FC<Icon28Forward15Props> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28Forward15 as any).mountIcon = mountIcon;

export default Icon28Forward15;
