import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'palette_outline_16';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="palette_outline_16"><path d="M8 1a7.008 7.008 0 016.915 5.908c.323 2.65-1.796 4.507-4.003 4.507h-.59c-.593.005-.825.027-.981.087-.081.031-.23.154-.09.39l.207.358c.115.213.218.446.322.737C10.116 13.933 9.262 15 8 15c-3.72 0-7-3.203-7-7a7 7 0 017-7zm0 1.5A5.5 5.5 0 002.5 8c0 2.96 2.6 5.5 5.5 5.5.27 0 .256-.189.166-.418a3.714 3.714 0 00-.235-.478 1.936 1.936 0 01-.225-.715c-.096-.764.391-1.517 1.1-1.788.308-.118.572-.163 1.092-.179l.387-.006h.627c1.383 0 2.716-1.17 2.518-2.798A5.502 5.502 0 008 2.5zM4.313 6.667a1 1 0 110 2 1 1 0 010-2zm7.334 0a1 1 0 110 2 1 1 0 010-2zM6.313 4a1 1 0 110 2 1 1 0 010-2zm3.334 0a1 1 0 110 2 1 1 0 010-2z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16PaletteOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16PaletteOutline: FC<Icon16PaletteOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16PaletteOutline as any).mountIcon = mountIcon;

export default Icon16PaletteOutline;
