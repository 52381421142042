import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'videocam_add_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="videocam_add_outline_28"><path d="M11.993 10.883A1 1 0 0010 11v2H8l-.117.007A1 1 0 007 14l.007.117A1 1 0 008 15h2v2l.007.117A1 1 0 0012 17v-2h2l.117-.007A1 1 0 0015 14l-.007-.117A1 1 0 0014 13h-2v-2l-.007-.117z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M17.954 6.534C17.302 6.186 16.655 6 14.872 6H7.128c-1.783 0-2.43.186-3.082.534a3.635 3.635 0 00-1.512 1.512C2.186 8.698 2 9.345 2 11.128v5.744c0 1.783.186 2.43.534 3.082.349.651.86 1.163 1.512 1.512.652.348 1.299.534 3.082.534h7.744c1.783 0 2.43-.186 3.082-.534a3.635 3.635 0 001.512-1.512c.232-.434.392-.866.473-1.633l2.006 1.253A2.65 2.65 0 0026 17.327v-6.654a2.65 2.65 0 00-4.055-2.247L19.94 9.679c-.081-.767-.241-1.199-.473-1.633a3.635 3.635 0 00-1.512-1.512zM7.128 8h7.982c1.136.01 1.515.092 1.9.298.304.162.53.388.692.692.22.411.298.814.298 2.138v5.744c0 1.324-.078 1.727-.298 2.139a1.63 1.63 0 01-.692.691c-.411.22-.814.298-2.138.298H7.128c-1.324 0-1.727-.078-2.138-.298a1.635 1.635 0 01-.692-.692C4.078 18.6 4 18.197 4 16.873V10.89c.01-1.136.092-1.515.298-1.9.162-.304.388-.53.692-.692C5.4 8.078 5.804 8 7.128 8zM20 12l3.006-1.878a.65.65 0 01.994.55v6.655a.65.65 0 01-.994.551L20 16v-4z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28VideocamAddOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28VideocamAddOutline: FC<Icon28VideocamAddOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28VideocamAddOutline as any).mountIcon = mountIcon;

export default Icon28VideocamAddOutline;
