import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'pin_dot_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="pin_dot_outline_28"><path d="M10.632 18.824l-5.657 5.657c-.943.943-2.357-.471-1.414-1.414l5.657-5.657-4.55-4.55a1.375 1.375 0 01.867-2.343l5.528-.425a.5.5 0 00.315-.146l3.529-3.529a.5.5 0 00.137-.255l.441-2.206a1 1 0 011.688-.511l7.424 7.424a1 1 0 01-.51 1.688l-2.207.441a.5.5 0 00-.255.137l-3.53 3.529a.5.5 0 00-.145.315l-.425 5.528a1.375 1.375 0 01-2.343.867l-4.55-4.55zm5.324-1.998a2.5 2.5 0 01.725-1.576l3.53-3.53a2.5 2.5 0 011.277-.683l.374-.075L17.08 6.18l-.075.374a2.5 2.5 0 01-.684 1.278l-3.529 3.529a2.5 2.5 0 01-1.576.725l-4.173.32L15.635 21l.321-4.173z" fill="currentColor" /><path d="M12 5.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28PinDotOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28PinDotOutline: FC<Icon28PinDotOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28PinDotOutline as any).mountIcon = mountIcon;

export default Icon28PinDotOutline;
