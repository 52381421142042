import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'notebook_check_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="notebook_check_outline_24"><path clip-rule="evenodd" d="M16 1.75a.9.9 0 01.9.9v.577c.478.075.914.198 1.324.407a4.9 4.9 0 012.141 2.141c.294.576.418 1.202.477 1.924.058.704.058 1.575.058 2.662v4.278c0 1.087 0 1.958-.058 2.662-.059.722-.183 1.348-.476 1.924a4.9 4.9 0 01-2.142 2.14c-.576.294-1.201.418-1.924.478-.704.057-1.574.057-2.661.057H10.36c-1.087 0-1.958 0-2.662-.057-.723-.06-1.348-.184-1.924-.477a4.9 4.9 0 01-2.141-2.141c-.294-.576-.418-1.202-.477-1.925-.057-.703-.057-1.574-.057-2.661V10.36c0-1.087 0-1.958.057-2.662.06-.722.183-1.348.477-1.924a4.9 4.9 0 012.141-2.141c.41-.21.846-.332 1.325-.407V2.65a.9.9 0 111.8 0v.457c.438-.007.923-.007 1.46-.007h.74v-.45a.9.9 0 011.8 0v.45h.739c.538 0 1.023 0 1.46.007V2.65a.9.9 0 01.9-.9zM6.592 5.238a2.2 2.2 0 01.508-.18v.292a.9.9 0 101.8 0v-.443a96.13 96.13 0 011.5-.007h.7v.45a.9.9 0 001.8 0V4.9h.7c.581 0 1.074 0 1.5.007v.443a.9.9 0 001.8 0v-.291a2.2 2.2 0 01.507.179 3.1 3.1 0 011.355 1.355c.142.28.236.642.286 1.253.05.622.052 1.419.052 2.554v4.2c0 1.135-.001 1.932-.052 2.554-.05.611-.144.973-.286 1.253a3.1 3.1 0 01-1.355 1.355c-.28.143-.642.236-1.254.286-.621.051-1.418.052-2.553.052h-3.2c-1.135 0-1.932 0-2.554-.052-.612-.05-.974-.143-1.254-.286a3.1 3.1 0 01-1.355-1.355c-.142-.28-.236-.642-.286-1.253-.05-.622-.051-1.42-.051-2.554v-4.2c0-1.135 0-1.932.051-2.554.05-.611.144-.974.286-1.253a3.1 3.1 0 011.355-1.355zm9.794 5.648a.9.9 0 10-1.273-1.272L11 13.727l-1.864-1.863a.9.9 0 10-1.273 1.272l2.5 2.5a.9.9 0 001.273 0z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24NotebookCheckOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24NotebookCheckOutline: FC<Icon24NotebookCheckOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24NotebookCheckOutline as any).mountIcon = mountIcon;

export default Icon24NotebookCheckOutline;
