import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'market_circle_fill_yellow_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="market_circle_fill_yellow_20"><linearGradient id="market_circle_fill_yellow_20_a" gradientUnits="userSpaceOnUse" x1="-10" x2="10" y1="10" y2="30"><stop offset="0" stop-color="#ffb73d" /><stop offset="1" stop-color="#ffa000" /></linearGradient><path d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z" fill="url(#market_circle_fill_yellow_20_a)" /><path d="M10 3.5A3.5 3.5 0 0113.5 7h.218c.446 0 .607.046.77.134.163.087.291.215.378.378.088.163.134.324.134.77v3.513c0 1.114-.116 1.519-.334 1.926a2.272 2.272 0 01-.945.945c-.407.218-.812.334-1.926.334h-3.59c-1.115 0-1.519-.116-1.926-.334a2.272 2.272 0 01-.945-.945C5.116 13.314 5 12.91 5 11.795V8.282c0-.446.046-.607.134-.77a.909.909 0 01.378-.378c.163-.088.324-.134.77-.134H6.5A3.5 3.5 0 0110 3.5zM7.25 8a.75.75 0 100 1.5.75.75 0 000-1.5zm5.5 0a.75.75 0 100 1.5.75.75 0 000-1.5zM10 5a2 2 0 00-2 2h4a2 2 0 00-2-2z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20MarketCircleFillYellowProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20MarketCircleFillYellow: FC<Icon20MarketCircleFillYellowProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20MarketCircleFillYellow as any).mountIcon = mountIcon;

export default Icon20MarketCircleFillYellow;
