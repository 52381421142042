import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'notification_subtract_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="notification_subtract_outline_24"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.303 4.186a.9.9 0 10-.606-1.695C6.825 3.517 5.1 6.38 5.1 9.63v1.6c0 .103-.041.256-.22.503-.18.249-.426.499-.743.82l-.028.028c-.305.31-.676.688-.964 1.113-.295.436-.545.98-.545 1.632v.02c0 .228-.002.906.464 1.517.511.67 1.393 1.036 2.673 1.036h1.911c.293 1.085.783 1.993 1.53 2.633.775.665 1.74.967 2.822.967 1.081 0 2.047-.302 2.822-.967.747-.64 1.237-1.548 1.53-2.633h1.911c1.293 0 2.172-.387 2.678-1.062.46-.614.46-1.287.459-1.496v-.015c0-.74-.322-1.34-.668-1.804-.275-.368-.622-.72-.91-1.012h-.001l-.176-.179a.9.9 0 10-1.29 1.256l.221.225c.284.287.52.528.714.787.23.308.31.533.31.727 0 .17-.013.316-.1.432-.053.071-.293.341-1.237.341H5.737c-.957 0-1.194-.265-1.242-.328-.08-.104-.095-.24-.095-.445 0-.171.062-.366.236-.623.18-.267.435-.534.755-.858l.063-.064c.283-.287.621-.629.885-.994.29-.401.561-.923.561-1.557v-1.6c0-2.639 1.379-4.722 3.403-5.445zM14.466 17.9H9.534c.218.59.5.996.816 1.267.391.335.917.533 1.65.533.733 0 1.259-.198 1.65-.533.317-.271.598-.678.816-1.267z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24NotificationSubtractOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24NotificationSubtractOutline: FC<Icon24NotificationSubtractOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24NotificationSubtractOutline as any).mountIcon = mountIcon;

export default Icon24NotificationSubtractOutline;
