import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'videocam_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="videocam_16"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 3.75a2 2 0 00-2 2v4.5a2 2 0 002 2h5a2 2 0 002-2v-4.5a2 2 0 00-2-2H4zm11 2.2a.454.454 0 00-.103-.286c-.171-.21-.53-.19-.723-.095-.193.095-1.571.788-1.571.788a.233.233 0 00-.103.191v2.88c0 .075.038.145.103.19 0 0 1.097.548 1.571.789.434.22.826.033.826-.382V5.951z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16VideocamProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16Videocam: FC<Icon16VideocamProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16Videocam as any).mountIcon = mountIcon;

export default Icon16Videocam;
