import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'report_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="report_outline_28"><g fill="none" fill-rule="evenodd"><path d="M0 0h28v28H0z" /><path d="M17.379 2a2.5 2.5 0 011.767.732l6.122 6.122A2.5 2.5 0 0126 10.62v6.758a2.5 2.5 0 01-.732 1.767l-6.122 6.122A2.5 2.5 0 0117.38 26H10.62a2.5 2.5 0 01-1.767-.732l-6.122-6.122A2.5 2.5 0 012 17.38V10.62a2.5 2.5 0 01.732-1.767l6.122-6.122A2.5 2.5 0 0110.62 2h6.758zm0 2H10.62a.5.5 0 00-.353.146l-6.122 6.122A.5.5 0 004 10.62v6.758a.5.5 0 00.146.353l6.122 6.122a.5.5 0 00.353.146h6.758a.5.5 0 00.353-.146l6.122-6.122A.5.5 0 0024 17.38V10.62a.5.5 0 00-.146-.353l-6.122-6.122A.5.5 0 0017.38 4zM14 16.8l.123.006A1.2 1.2 0 0114 19.2l-.123-.006A1.2 1.2 0 0114 16.8zM14 8a1 1 0 011 1v5a1 1 0 01-2 0V9a1 1 0 011-1z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28ReportOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28ReportOutline: FC<Icon28ReportOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28ReportOutline as any).mountIcon = mountIcon;

export default Icon28ReportOutline;
