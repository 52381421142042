import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'delete_outline_android_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="delete_outline_android_24"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M6.9 7.5v8.7c0 .855 0 1.442.038 1.897.036.445.103.683.19.856a2.1 2.1 0 00.919.918c.172.088.411.155.856.191.455.037 1.042.038 1.897.038h2.4c.855 0 1.442 0 1.897-.038.445-.036.684-.103.856-.19a2.1 2.1 0 00.918-.919c.088-.173.155-.411.191-.856.037-.455.038-1.042.038-1.897V7.5h1.8v8.738c0 .808 0 1.469-.044 2.006-.045.556-.142 1.058-.381 1.526a3.9 3.9 0 01-1.704 1.705c-.47.239-.971.336-1.527.381-.537.044-1.198.044-2.006.044h-2.476c-.808 0-1.469 0-2.006-.044-.556-.045-1.058-.142-1.527-.381a3.9 3.9 0 01-1.704-1.705c-.239-.468-.336-.97-.381-1.526-.044-.537-.044-1.198-.044-2.006V7.5z" /><path d="M4.732 8.4H19.268c.1 0 .216 0 .317-.007.115-.008.277-.028.45-.1a1.4 1.4 0 00.758-.757 1.41 1.41 0 00.1-.451c.007-.101.007-.217.007-.317v-.046c0-.326 0-.614-.016-.854a2.45 2.45 0 00-.167-.786 2.4 2.4 0 00-1.299-1.3 2.449 2.449 0 00-.786-.166c-.24-.016-.529-.016-.854-.016h-.652a2.655 2.655 0 00-2.39-1.5H9.264a2.655 2.655 0 00-2.39 1.5h-.653c-.325 0-.614 0-.854.016a2.45 2.45 0 00-.786.167 2.4 2.4 0 00-1.3 1.299 2.45 2.45 0 00-.166.786c-.016.24-.016.529-.016.854v.046c0 .1 0 .216.007.317.008.115.028.277.1.45a1.4 1.4 0 00.757.758c.174.072.336.092.451.1.101.007.217.007.317.007zM4.9 6.6c0-.277.002-.463.012-.61a.76.76 0 01.034-.22.6.6 0 01.324-.324.76.76 0 01.22-.034c.172-.012.398-.012.76-.012h.934c.576 0 1.088-.369 1.27-.915a.855.855 0 01.81-.585h5.471c.368 0 .695.235.811.585.182.546.694.915 1.27.915h.934c.362 0 .588 0 .76.012a.76.76 0 01.22.034.6.6 0 01.324.324c.004.01.023.057.034.22.01.147.012.333.012.61z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24DeleteOutlineAndroidProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24DeleteOutlineAndroid: FC<Icon24DeleteOutlineAndroidProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24DeleteOutlineAndroid as any).mountIcon = mountIcon;

export default Icon24DeleteOutlineAndroid;
