import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'document_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="document_outline_24"><path d="M13.25 3.753H7.47c-1.2 0-2.169.928-2.169 2.065v11.764c0 1.137.968 2.065 2.17 2.065h9.209c1.2 0 2.169-.927 2.169-2.065V10.9h-2.274a3.325 3.325 0 01-3.325-3.325V3.753zm1.775 4.197c0 .649.526 1.175 1.175 1.175h2.317l-3.492-3.97V7.95zM3.5 5.818C3.5 3.706 5.28 2 7.47 2h5.921c.78 0 1.52.33 2.026.904L20.02 8.14c.406.461.629 1.047.629 1.653v7.79c0 2.111-1.78 3.817-3.97 3.817H7.47c-2.19 0-3.97-1.706-3.97-3.818V5.818z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24DocumentOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24DocumentOutline: FC<Icon24DocumentOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24DocumentOutline as any).mountIcon = mountIcon;

export default Icon24DocumentOutline;
