import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'microphone_videocam_slash_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="microphone_videocam_slash_outline_28"><path d="M9.44 5h.06A4.5 4.5 0 0114 9.5v.24a1 1 0 11-2 0V9.5A2.5 2.5 0 009.5 7h-.034a1 1 0 11-.026-2z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M5.088 8.608l-1.795-1.9a1 1 0 011.414-1.415l17 18a1 1 0 11-1.414 1.414l-1.702-1.802c-.4.058-.897.09-1.558.095H17a1 1 0 01-.191-1.982l-1.364-1.444a8.476 8.476 0 01-4.945 2.368V23a1 1 0 11-2 0v-1.058a8.503 8.503 0 01-6.366-4.198 1 1 0 111.732-1A6.497 6.497 0 009.5 20a6.477 6.477 0 004.572-1.88l-1.374-1.454A4.5 4.5 0 015 13.5v-4c0-.305.03-.603.088-.892zm6.236 6.602A2.5 2.5 0 017 13.5v-2.868l4.324 4.578zM17 12a1 1 0 110-2c1.686.009 2.317.194 2.953.534.652.349 1.164.86 1.512 1.512.188.35.328.699.418 1.223l1.057-.66c1.332-.833 3.06.124 3.06 1.695v4.392c0 1.57-1.728 2.528-3.06 1.696l-2.46-1.537a1.027 1.027 0 01-.276-.249.995.995 0 01-.184-.407h-.023c.002-.102.002-.21.002-.327V14.89c-.01-1.136-.091-1.515-.298-1.9a1.636 1.636 0 00-.692-.692c-.398-.213-.788-.293-2.009-.298zm5 5.446l2 1.25v-4.392l-2 1.25v1.892z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28MicrophoneVideocamSlashOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28MicrophoneVideocamSlashOutline: FC<Icon28MicrophoneVideocamSlashOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28MicrophoneVideocamSlashOutline as any).mountIcon = mountIcon;

export default Icon28MicrophoneVideocamSlashOutline;
