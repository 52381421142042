import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'check_box_indetermanate_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="check_box_indetermanate_20"><path clip-rule="evenodd" d="M2 8.4c0-2.24 0-3.36.436-4.216a4 4 0 011.748-1.748C5.04 2 6.16 2 8.4 2h3.2c2.24 0 3.36 0 4.216.436a4 4 0 011.748 1.748C18 5.04 18 6.16 18 8.4v3.2c0 2.24 0 3.36-.436 4.216a4 4 0 01-1.748 1.748C14.96 18 13.84 18 11.6 18H8.4c-2.24 0-3.36 0-4.216-.436a4 4 0 01-1.748-1.748C2 14.96 2 13.84 2 11.6zm4.4.7a.9.9 0 100 1.8h7.2a.9.9 0 100-1.8z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20CheckBoxIndetermanateProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20CheckBoxIndetermanate: FC<Icon20CheckBoxIndetermanateProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20CheckBoxIndetermanate as any).mountIcon = mountIcon;

export default Icon20CheckBoxIndetermanate;
