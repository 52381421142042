import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'articles_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="articles_outline_20"><path d="M17.247 14.5c.42 0 .753.336.753.75l-.007.102a.75.75 0 01-.746.648H4.753a.75.75 0 110-1.5h12.494zm.001-5a.749.749 0 110 1.5H8.752A.748.748 0 018 10.25l.007-.102a.75.75 0 01.745-.648h8.496zm-9-6.5c.408 0 .752.336.752.75l-.007.102a.75.75 0 01-.746.648H5.75v5.75a.75.75 0 01-1.5 0V4.5H1.753a.757.757 0 01-.746-.648L1 3.75A.75.75 0 011.753 3h6.494zm9.003 1.5a.751.751 0 010 1.5h-5.502A.751.751 0 0111 5.25l.007-.102a.748.748 0 01.742-.648h5.502z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20ArticlesOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20ArticlesOutline: FC<Icon20ArticlesOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20ArticlesOutline as any).mountIcon = mountIcon;

export default Icon20ArticlesOutline;
