import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 12 16';
const id = 'arrows_up_down_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 16" id="arrows_up_down_16"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M2.156 11.295a.75.75 0 011.051-.137L6 13.306l2.793-2.148a.75.75 0 11.914 1.189l-3.25 2.5a.75.75 0 01-.914 0l-3.25-2.5a.75.75 0 01-.137-1.052zM9.844 4.71a.75.75 0 01-1.051.137L6 2.699 3.207 4.847a.75.75 0 01-.914-1.19l3.25-2.5a.75.75 0 01.914 0l3.25 2.5a.75.75 0 01.137 1.053z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16ArrowsUpDownProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16ArrowsUpDown: FC<Icon16ArrowsUpDownProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 12,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16ArrowsUpDown as any).mountIcon = mountIcon;

export default Icon16ArrowsUpDown;
