import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'write_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="write_outline_24"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M9.562 4.1H13.1a.9.9 0 110 1.8H9.6c-.995 0-1.687 0-2.225.045-.528.043-.829.123-1.055.238A2.6 2.6 0 005.183 7.32c-.115.226-.195.527-.238 1.055C4.9 8.913 4.9 9.605 4.9 10.6v3.8c0 .995 0 1.687.045 2.226.043.528.123.828.238 1.054a2.6 2.6 0 001.137 1.137c.226.115.527.195 1.055.238.538.044 1.23.045 2.225.045h3.8c.995 0 1.687 0 2.226-.045.528-.043.828-.123 1.054-.238a2.6 2.6 0 001.137-1.137c.115-.226.195-.526.238-1.054.044-.539.045-1.231.045-2.226v-3.5a.9.9 0 011.8 0v3.538c0 .948 0 1.714-.05 2.334-.053.64-.163 1.203-.43 1.726a4.4 4.4 0 01-1.922 1.922c-.523.267-1.087.377-1.726.43-.62.05-1.386.05-2.334.05H9.562c-.948 0-1.714 0-2.334-.05-.64-.053-1.203-.163-1.726-.43a4.4 4.4 0 01-1.922-1.922c-.267-.523-.377-1.087-.43-1.726-.05-.62-.05-1.386-.05-2.334v-3.876c0-.948 0-1.714.05-2.334.053-.64.163-1.203.43-1.726A4.4 4.4 0 015.502 4.58c.523-.267 1.087-.377 1.726-.43.62-.05 1.386-.05 2.334-.05z" /><path d="M19.125 3.326A1.1 1.1 0 1120.68 4.88l-.353.354a.4.4 0 01-.566 0l-.99-.99a.4.4 0 010-.566zM18.524 5.9l-.424-.424c-.437-.437-.72-.411-1.132 0l-5.126 5.127c-1.953 1.952-3.187 3.883-2.758 4.313.43.43 2.36-.805 4.314-2.758l5.126-5.126c.437-.437.411-.72 0-1.132z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24WriteOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24WriteOutline: FC<Icon24WriteOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24WriteOutline as any).mountIcon = mountIcon;

export default Icon24WriteOutline;
