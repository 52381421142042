import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'microphone_slash_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="microphone_slash_28"><path d="M4.792 4.308a1.05 1.05 0 10-1.484 1.484l18.9 18.9a1.05 1.05 0 001.484-1.485l-18.9-18.9z" fill="currentColor" /><path d="M6.663 14.787a1 1 0 00-1.955.422A9.253 9.253 0 0013 22.469V25.5a1 1 0 102 0v-3.03a9.24 9.24 0 005.544-2.44l-1.415-1.416A7.242 7.242 0 0114.25 20.5h-.5a7.253 7.253 0 01-7.087-5.713zm14.171 1.506c-.193.416-.138.918.187 1.243l.038.038c.445.445 1.187.374 1.468-.188.34-.68.599-1.408.765-2.176a1 1 0 00-1.955-.422 7.213 7.213 0 01-.503 1.505zm-1.881-2.605c-.095.691-.926.854-1.42.36l-7.95-7.95c-.253-.253-.359-.626-.225-.958A5.002 5.002 0 0119 7v6c0 .233-.016.463-.047.688z" fill="currentColor" /><path d="M9 8.485l8.285 8.285A5 5 0 019 13V8.485z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28MicrophoneSlashProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28MicrophoneSlash: FC<Icon28MicrophoneSlashProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28MicrophoneSlash as any).mountIcon = mountIcon;

export default Icon28MicrophoneSlash;
