import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 9 13';
const id = 'online_vkmobile_12';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 13" id="online_vkmobile_12"><g fill="none" fill-rule="evenodd"><path d="M0 0h8v12H0z" /><path d="M2.517 10.745c-.342 1.377.171 1.662 1.09.657l3.856-4.431c.893-1.09.67-1.964-.616-2.183l-1.86-.344A.2.2 0 014.83 4.2l.716-2.953C5.888-.138 5.317-.389 4.46.593a525.767 525.767 0 01-2.807 3.146c-.223.248-.583.634-1.078 1.158-1.086 1.205-.514 2.078.741 2.297l1.778.319a.2.2 0 01.159.244l-.735 2.988z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon12OnlineVkmobileProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon12OnlineVkmobile: FC<Icon12OnlineVkmobileProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 9,
    height: !isNaN(props.height) ? +props.height : 13,
  }));
};

(Icon12OnlineVkmobile as any).mountIcon = mountIcon;

export default Icon12OnlineVkmobile;
