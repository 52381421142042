import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'camera_circle_fill_green_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="camera_circle_fill_green_20"><linearGradient id="camera_circle_fill_green_20_a" gradientUnits="userSpaceOnUse" x1="-10" x2="10" y1="10" y2="30"><stop offset="0" stop-color="#50c750" /><stop offset="1" stop-color="#32b332" /></linearGradient><path d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z" fill="url(#camera_circle_fill_green_20_a)" /><path d="M7 6.5c.75 0 .75-1 1.5-1h3c.75 0 .75 1 1.5 1h-.243c.78 0 1.063.081 1.348.234.285.152.509.376.661.661.153.285.234.568.234 1.348v3.014c0 .78-.081 1.063-.234 1.348a1.591 1.591 0 01-.661.661c-.285.153-.568.234-1.348.234H7.243c-.78 0-1.063-.081-1.348-.234a1.59 1.59 0 01-.661-.661C5.08 12.82 5 12.537 5 11.757V8.743c0-.78.081-1.063.234-1.348s.376-.509.661-.661.568-.234 1.348-.234zm3 .75a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5zm0 1.25a1.5 1.5 0 110 3 1.5 1.5 0 010-3z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20CameraCircleFillGreenProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20CameraCircleFillGreen: FC<Icon20CameraCircleFillGreenProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20CameraCircleFillGreen as any).mountIcon = mountIcon;

export default Icon20CameraCircleFillGreen;
