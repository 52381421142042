import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'brush_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="brush_outline_24"><path fill-rule="evenodd" clip-rule="evenodd" d="M20.28 3.562a1.85 1.85 0 00-2.54.09l-6.883 6.796a.9.9 0 001.265 1.281l6.889-6.802.013-.014a.044.044 0 01.032-.013c.014 0 .025.004.033.011a.03.03 0 01.008.01.026.026 0 01.003.01l-.001.01a.027.027 0 01-.006.01l-.013.015-6.113 7.664a.9.9 0 101.407 1.123l6.108-7.657a1.826 1.826 0 00-.201-2.534zM6.733 16.206c.046-1.027.814-1.779 1.74-1.779.96 0 1.738.779 1.738 1.739 0 .937-.784 2.148-2.688 2.743-.86.268-1.538.245-2.043.12.586-.553 1.19-1.426 1.253-2.823zm1.74-3.579c-1.99 0-3.454 1.614-3.538 3.498-.042.93-.45 1.402-.824 1.715-.1.084-.2.158-.303.232l-.047.033c-.083.059-.189.134-.273.203a1.62 1.62 0 00-.195.186 1.11 1.11 0 00-.227.4.997.997 0 00.04.7c.091.208.233.341.331.417.757.587 2.339 1.33 4.623.616 2.383-.744 3.95-2.484 3.95-4.461a3.538 3.538 0 00-3.538-3.539z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24BrushOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24BrushOutline: FC<Icon24BrushOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24BrushOutline as any).mountIcon = mountIcon;

export default Icon24BrushOutline;
