import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'ticket_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="ticket_outline_20"><g clip-path="url(#ticket_outline_20_clip0)"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.401 5.382a.75.75 0 00-.953-.094 1.25 1.25 0 01-1.736-1.736.75.75 0 00-.094-.953c-.694-.687-1.248-1.21-1.93-1.432a3.25 3.25 0 00-2.01 0c-.417.136-.77.375-1.124.675-.342.291-.731.68-1.208 1.157l-.022.022-5.303 5.303-.022.022c-.477.477-.866.866-1.157 1.208-.3.355-.54.707-.675 1.125a3.25 3.25 0 000 2.008c.222.683.745 1.237 1.432 1.931a.75.75 0 00.953.094 1.25 1.25 0 011.736 1.736.75.75 0 00.094.953c.694.687 1.248 1.21 1.93 1.432a3.25 3.25 0 002.01 0c.416-.136.77-.375 1.124-.675.342-.291.731-.68 1.208-1.157l.022-.022 5.303-5.303.022-.022c.476-.476.866-.866 1.156-1.208.301-.355.54-.708.676-1.125a3.25 3.25 0 000-2.008c-.222-.683-.745-1.237-1.432-1.931zm-3.597.814a2.75 2.75 0 002.91.631c.45.478.618.72.692.95.114.35.114.73 0 1.08-.05.155-.151.335-.392.618-.246.29-.592.636-1.095 1.14l-.849.849-.792-.792a.75.75 0 00-1.06 1.06l.791.792-3.394 3.395c-.504.503-.85.849-1.14 1.095-.283.24-.463.342-.617.392a1.75 1.75 0 01-1.082 0c-.229-.074-.471-.242-.949-.693a2.75 2.75 0 00-3.54-3.54c-.45-.478-.619-.72-.693-.95a1.75 1.75 0 010-1.08c.05-.155.151-.335.392-.618.246-.29.592-.636 1.095-1.14L7.476 5.99l.792.792a.75.75 0 101.06-1.061l-.792-.792.849-.848c.504-.504.85-.85 1.14-1.096.283-.24.463-.342.617-.392a1.75 1.75 0 011.082 0c.229.074.471.242.949.693a2.75 2.75 0 00.631 2.909zM11.45 7.843a.75.75 0 00-1.061 1.061l.707.707a.75.75 0 001.06-1.06l-.706-.708z" fill="currentColor" /></g><defs><clipPath id="ticket_outline_20_clip0"><path fill="#fff" d="M0 0h20v20H0z" /></clipPath></defs></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20TicketOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20TicketOutline: FC<Icon20TicketOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20TicketOutline as any).mountIcon = mountIcon;

export default Icon20TicketOutline;
