import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'coins_stack_outline_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="coins_stack_outline_36"><path d="M11.005 23.108C11.15 24.542 14.16 26 18 26c3.937 0 7-1.532 7-3l.002-1.794C23.34 22.33 20.8 23 18 23s-5.339-.67-7-1.794V23zm13.996-6.902C23.34 17.33 20.802 18 18 18c-2.8 0-5.339-.67-7-1.794V18c0 1.468 3.063 3 7 3s7-1.532 7-3zM18 10c-3.937 0-7 1.532-7 3s3.063 3 7 3 7-1.532 7-3-3.063-3-7-3zm-9 3l.005-.176C9.165 9.97 13.198 8 18 8c4.9 0 9 2.05 9 5v10l-.005.176C26.835 26.03 22.802 28 18 28c-4.9 0-9-2.05-9-5z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36CoinsStackOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36CoinsStackOutline: FC<Icon36CoinsStackOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36CoinsStackOutline as any).mountIcon = mountIcon;

export default Icon36CoinsStackOutline;
