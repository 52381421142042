import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'money_circle_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="money_circle_16"><path d="M8 0a8 8 0 110 16A8 8 0 018 0zm1 3H6a.5.5 0 00-.5.5v4h-.373c-.206 0-.29.023-.371.067a.454.454 0 00-.19.189c-.043.081-.066.165-.066.371v.246c0 .206.023.29.067.371a.454.454 0 00.189.19c.081.043.165.066.371.066H5.5v1h-.373c-.206 0-.29.023-.371.067s-.146.107-.19.189c-.043.081-.066.165-.066.371v.246c0 .206.023.29.067.371.043.082.107.146.189.19s.165.066.371.066H5.5v.873c0 .206.023.29.067.371.043.082.107.146.189.19s.165.066.371.066h.246c.206 0 .29-.023.371-.067s.146-.107.19-.189c.043-.081.066-.165.066-.371V11.5h1.373c.206 0 .29-.023.371-.067s.146-.107.19-.189c.043-.081.066-.165.066-.371v-.246c0-.206-.023-.29-.067-.371-.043-.082-.107-.146-.189-.19S8.58 10 8.373 10H7V9h2a3 3 0 100-6zm0 1.5a1.5 1.5 0 110 3H7v-3z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16MoneyCircleProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16MoneyCircle: FC<Icon16MoneyCircleProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16MoneyCircle as any).mountIcon = mountIcon;

export default Icon16MoneyCircle;
