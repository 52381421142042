import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'check_shield_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="check_shield_outline_56"><path fill-rule="evenodd" clip-rule="evenodd" d="M28.538 6.47a3.657 3.657 0 00-1.076 0c-.37.052-.706.126-1.98.55l-13.64 4.547a.5.5 0 00-.342.474V30.6c0 4.125 1.257 7.717 3.776 10.838 2.539 3.145 6.415 5.88 11.746 8.145a2.5 2.5 0 001.956 0c5.33-2.265 9.207-5 11.746-8.145C43.243 38.317 44.5 34.725 44.5 30.6V12.041a.5.5 0 00-.342-.474L30.518 7.02c-1.274-.424-1.61-.498-1.98-.55zm-1.5-2.97a6.655 6.655 0 011.923 0c.653.092 1.203.24 2.506.674l13.64 4.547a3.5 3.5 0 012.393 3.32V30.6c0 4.794-1.482 9.055-4.441 12.722-2.94 3.642-7.281 6.63-12.907 9.022a5.5 5.5 0 01-4.304 0c-5.626-2.391-9.967-5.38-12.906-9.022C9.982 39.655 8.5 35.394 8.5 30.6V12.041a3.5 3.5 0 012.393-3.32l13.64-4.547c1.303-.434 1.853-.582 2.506-.675zm11.423 17.84a1.5 1.5 0 010 2.12l-12 12a1.5 1.5 0 01-2.122 0l-6.5-6.5a1.5 1.5 0 012.122-2.12l5.439 5.439 10.94-10.94a1.5 1.5 0 012.12 0z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56CheckShieldOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56CheckShieldOutline: FC<Icon56CheckShieldOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56CheckShieldOutline as any).mountIcon = mountIcon;

export default Icon56CheckShieldOutline;
