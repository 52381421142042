import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'user_outgoing_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="user_outgoing_outline_28"><g fill="none" fill-rule="evenodd"><path d="M0 0h28v28H0z" /><path d="M10.25 15c4.951 0 9.25 1.763 9.25 5.643 0 2.016-.781 2.857-2.456 2.857H3.456C1.78 23.5 1 22.66 1 20.643 1 16.763 5.299 15 10.25 15zm0 2C6.304 17 3 18.355 3 20.643c0 .89-.071.863.372.858l13.672-.001c.536 0 .456.086.456-.857 0-2.288-3.304-3.643-7.25-3.643zm12.043-9.707a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L24.585 13H18.5a1 1 0 01-.993-.883L17.5 12a1 1 0 011-1h6.085l-2.292-2.293a1 1 0 01-.083-1.32zM10 3c3.039 0 5.5 2.461 5.5 5.5S13.039 14 10 14a5.499 5.499 0 01-5.5-5.5C4.5 5.461 6.961 3 10 3zm0 2a3.499 3.499 0 00-3.5 3.5c0 1.934 1.566 3.5 3.5 3.5s3.5-1.566 3.5-3.5S11.934 5 10 5z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28UserOutgoingOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28UserOutgoingOutline: FC<Icon28UserOutgoingOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28UserOutgoingOutline as any).mountIcon = mountIcon;

export default Icon28UserOutgoingOutline;
