import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'song_circle_fill_violet_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="song_circle_fill_violet_28"><linearGradient id="song_circle_fill_violet_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#c48aff" /><stop offset="1" stop-color="#aa65f0" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#song_circle_fill_violet_28_a)" /><path d="M14.995 17.234c0 3.567-3.8 5.402-5.565 3.507-1.827-1.96-.102-5.175 3.57-5.371v-5.118c-.02-1.462.972-2.502 2.23-2.952l.246-.082c.516-.162 1.397-.393 2.659-.699.406-.099.794.197.856.623l.009.12V8.86c0 1.168-.689 2.198-1.702 2.582l-.193.065-2.11.675z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28SongCircleFillVioletProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28SongCircleFillViolet: FC<Icon28SongCircleFillVioletProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28SongCircleFillViolet as any).mountIcon = mountIcon;

export default Icon28SongCircleFillViolet;
