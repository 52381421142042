import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'calendar_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="calendar_outline_24"><g stroke="currentColor"><rect height="16" rx="3.75" stroke-width="1.8" width="16" x="4" y="4" /><path d="M16 2.4v3.2M8 2.4v3.2" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.8" /><path d="M4 10h16" stroke-width="1.8" /><circle cx="15.5" cy="15.5" r="1" /></g><path clip-rule="evenodd" d="M16 1.5a.9.9 0 01.9.9v.791c.532.067 1.012.187 1.461.416a4.65 4.65 0 012.032 2.032c.28.55.397 1.144.453 1.825.054.662.054 1.48.054 2.497v4.078c0 1.017 0 1.835-.054 2.497-.056.681-.173 1.276-.453 1.825a4.65 4.65 0 01-2.032 2.032c-.549.28-1.144.397-1.825.453-.662.054-1.48.054-2.497.054H9.96c-1.017 0-1.835 0-2.497-.054-.681-.056-1.276-.173-1.825-.453a4.65 4.65 0 01-2.032-2.032c-.28-.549-.397-1.144-.453-1.825-.054-.662-.054-1.48-.054-2.497V9.96c0-1.017 0-1.835.054-2.497.056-.681.173-1.276.453-1.825a4.65 4.65 0 012.032-2.032c.45-.229.929-.35 1.461-.416V2.4a.9.9 0 111.8 0v.703c.327-.003.68-.003 1.061-.003h4.078c.38 0 .734 0 1.061.003V2.4a.9.9 0 01.9-.9zM7.1 5.01v.59a.9.9 0 101.8 0v-.697C9.225 4.9 9.589 4.9 10 4.9h4c.411 0 .775 0 1.1.003V5.6a.9.9 0 101.8 0v-.59c.278.05.478.116.644.2.536.274.972.71 1.245 1.246.13.253.216.585.263 1.154.033.401.043.88.046 1.49H4.902c.003-.61.013-1.089.046-1.49.047-.57.134-.9.263-1.154a2.85 2.85 0 011.245-1.245c.166-.085.366-.151.644-.2zM4.9 10.9h14.2V14c0 1.065 0 1.81-.048 2.39-.047.57-.134.9-.263 1.154a2.85 2.85 0 01-1.245 1.245c-.253.13-.585.216-1.154.263-.58.047-1.325.048-2.39.048h-4c-1.065 0-1.81 0-2.39-.048-.57-.047-.9-.134-1.154-.263a2.85 2.85 0 01-1.245-1.245c-.13-.253-.216-.585-.263-1.154-.047-.58-.048-1.325-.048-2.39zM15.5 17a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24CalendarOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24CalendarOutline: FC<Icon24CalendarOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24CalendarOutline as any).mountIcon = mountIcon;

export default Icon24CalendarOutline;
