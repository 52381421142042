import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'on_off_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="on_off_outline_28"><path clip-rule="evenodd" d="M15 2a1 1 0 10-2 0v11a1 1 0 102 0zM9.834 4.909A1 1 0 109 3.09c-4.13 1.895-7 6.066-7 10.91C2 20.628 7.373 26 14 26s12-5.372 12-11.999c0-4.844-2.87-9.015-7-10.91a1 1 0 00-.834 1.818A10 10 0 0124 14C24 19.523 19.523 24 14 24S4 19.523 4 14.001A10 10 0 019.834 4.91z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28OnOffOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28OnOffOutline: FC<Icon28OnOffOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28OnOffOutline as any).mountIcon = mountIcon;

export default Icon28OnOffOutline;
