import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'pincode_lock_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="pincode_lock_outline_28"><path clip-rule="evenodd" d="M20.5 7a3 3 0 00-3 3v1.065c-.292.043-.581.119-.862.262a3 3 0 00-1.311 1.311c-.193.378-.264.772-.296 1.167-.031.374-.031.83-.031 1.357V17.837c0 .528 0 .982.03 1.357.033.395.104.789.297 1.167a3 3 0 001.311 1.311c.378.193.772.264 1.167.296.375.031.83.031 1.356.031H21.84c.527 0 .982 0 1.356-.03.395-.033.789-.104 1.167-.297a3 3 0 001.311-1.311c.193-.378.264-.772.296-1.167.031-.374.031-.83.031-1.357V15.162c0-.528 0-.983-.03-1.357-.033-.395-.104-.789-.297-1.167a3 3 0 00-1.311-1.311c-.28-.143-.57-.22-.862-.262V10a3 3 0 00-3-3zm-1.3 6c-.577 0-.949 0-1.232.024-.272.022-.372.06-.422.085a1 1 0 00-.437.437c-.025.05-.063.15-.085.422C17 14.25 17 14.623 17 15.2v2.6c0 .577 0 .949.024 1.232.022.272.06.372.085.422a1 1 0 00.437.437c.05.025.15.063.422.085.283.023.655.024 1.232.024h2.6c.577 0 .949 0 1.232-.024.272-.022.372-.06.422-.085a1 1 0 00.437-.437c.025-.05.063-.15.085-.422C24 18.75 24 18.377 24 17.8v-2.6c0-.577 0-.949-.024-1.232-.022-.272-.06-.372-.085-.422a1 1 0 00-.437-.437c-.05-.025-.15-.063-.422-.085C22.75 13 22.377 13 21.8 13zm2.3-2v-1a1 1 0 10-2 0v1zM7.759 8H14.5a1 1 0 110 2H7.8c-.857 0-1.439 0-1.889.038-.438.035-.663.1-.819.18a2 2 0 00-.874.874c-.08.156-.145.38-.18.819C4 12.361 4 12.943 4 13.8v.4c0 .857 0 1.439.038 1.889.035.438.1.663.18.819a2 2 0 00.874.874c.156.08.38.145.819.18C6.361 18 6.943 18 7.8 18H12a1 1 0 110 2H7.759c-.805 0-1.47 0-2.01-.044-.563-.046-1.08-.145-1.565-.392a4 4 0 01-1.748-1.748c-.247-.485-.346-1.002-.392-1.564C2 15.71 2 15.046 2 14.242v-.483c0-.805 0-1.47.044-2.01.046-.563.145-1.08.392-1.565a4 4 0 011.748-1.748c.485-.247 1.002-.346 1.564-.392C6.29 8 6.954 8 7.758 8zM12 14a1 1 0 11-2 0 1 1 0 012 0zm-5 1a1 1 0 100-2 1 1 0 000 2zm14 1.866v.634a.5.5 0 01-1 0v-.634a1 1 0 111 0z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28PincodeLockOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28PincodeLockOutline: FC<Icon28PincodeLockOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28PincodeLockOutline as any).mountIcon = mountIcon;

export default Icon28PincodeLockOutline;
