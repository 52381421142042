import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'hand_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="hand_16"><path d="M3.75 3a.75.75 0 01.75.75V7.5a.5.5 0 101 0V2A.75.75 0 017 2v4.75a.5.5 0 101 0v-5.5a.75.75 0 011.5 0v5.5a.5.5 0 001 0V2.5a.75.75 0 011.5 0v7.25l1.614-1.796a.898.898 0 011.36 1.176l-2.809 3.368C10.913 14 9.08 14.5 7.701 14.5 3.2 14.5 3 11.47 3 10.444V3.75A.75.75 0 013.75 3z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16HandProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16Hand: FC<Icon16HandProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16Hand as any).mountIcon = mountIcon;

export default Icon16Hand;
