import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'explicit_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="explicit_16"><path d="M2 4.841v7.318C2 13.173 2.816 14 3.823 14h7.354A1.828 1.828 0 0013 12.159V4.84A1.834 1.834 0 0011.177 3H3.823A1.828 1.828 0 002 4.841zM11.056 2A2.955 2.955 0 0114 4.962v7.076A2.95 2.95 0 0111.056 15H3.944A2.955 2.955 0 011 12.038V4.962A2.95 2.95 0 013.944 2h7.112zM6 11h3v-1l-2.001-.001V9H9V8H6.999V6.999L9 7V6H6v5z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16ExplicitProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16Explicit: FC<Icon16ExplicitProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16Explicit as any).mountIcon = mountIcon;

export default Icon16Explicit;
