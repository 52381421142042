import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'laptop_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="laptop_outline_28"><path clip-rule="evenodd" d="M8 5h-.151c-.781 0-1.4-.001-1.93.171A3.5 3.5 0 003.67 7.418C3.5 7.95 3.5 8.568 3.5 9.348V19H2.162a1.162 1.162 0 00-.367 2.265l1.466.489c.276.092.415.138.556.17.125.029.253.05.38.062.145.014.29.014.582.014H23.22c.291 0 .437 0 .581-.014.128-.012.256-.033.381-.062.141-.032.28-.078.556-.17l1.466-.49A1.162 1.162 0 0025.838 19H24.5V9.349c0-.781.001-1.4-.171-1.93a3.5 3.5 0 00-2.247-2.248C21.55 5 20.932 5 20.152 5H20zM6.536 7.073C6.724 7.013 6.992 7 8 7h12c1.008 0 1.276.012 1.464.073a1.5 1.5 0 01.963.963c.06.188.073.456.073 1.464V19h-17V9.5c0-1.008.012-1.276.073-1.464a1.5 1.5 0 01.963-.963z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28LaptopOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28LaptopOutline: FC<Icon28LaptopOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28LaptopOutline as any).mountIcon = mountIcon;

export default Icon28LaptopOutline;
