import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'money_transfer_outline_32';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="money_transfer_outline_32"><path d="M23.151 20.849l1.952 1.951v2.4l-1.952 1.951-.107.123a1.2 1.2 0 001.805 1.575l4-4 .107-.123a1.2 1.2 0 00-.107-1.575l-4-4-.123-.107a1.2 1.2 0 00-1.575.107l-.107.123a1.2 1.2 0 00.107 1.575z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M5.392 4.734C6.577 4.19 7.737 4 10.332 4h11.335c2.595 0 3.756.19 4.94.734 1.041.48 1.896 1.202 2.484 2.15.684 1.099.909 2.19.909 4.46V16a1.25 1.25 0 11-2.5 0v-3h-23v4.656c0 2.106.216 2.633.532 3.141.308.495.77.905 1.405 1.197.685.315 1.404.506 3.896.506h15.752a1.25 1.25 0 010 2.5H10.333c-2.596 0-3.756-.19-4.94-.734-1.041-.48-1.895-1.202-2.484-2.15-.684-1.099-.909-2.19-.909-4.46v-6.312c0-2.27.225-3.361.909-4.46.589-.948 1.443-1.67 2.483-2.15zM4.514 10.5h22.972c-.053-1.419-.25-1.865-.518-2.297-.308-.496-.77-.905-1.405-1.198-.684-.314-1.404-.505-3.896-.505H10.333c-2.492 0-3.211.19-3.896.505-.636.293-1.097.702-1.405 1.198-.269.432-.465.878-.518 2.297z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32MoneyTransferOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32MoneyTransferOutline: FC<Icon32MoneyTransferOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32MoneyTransferOutline as any).mountIcon = mountIcon;

export default Icon32MoneyTransferOutline;
