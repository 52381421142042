import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'video_camera_circle_fill_red_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="video_camera_circle_fill_red_28"><linearGradient id="video_camera_circle_fill_red_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#ff5263" /><stop offset="1" stop-color="#ff3347" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#video_camera_circle_fill_red_28_a)" /><path d="M16.5 16.628c0 .825-.086 1.124-.247 1.426s-.398.538-.7.699-.6.247-1.425.247H9.372c-.825 0-1.124-.086-1.426-.247s-.538-.398-.699-.7c-.161-.3-.247-.6-.247-1.425v-5.256c0-.825.086-1.124.247-1.426.161-.301.398-.538.7-.699.3-.161.6-.247 1.425-.247h4.756c.825 0 1.124.086 1.426.247.301.161.538.398.699.7s.247.6.247 1.425v.878l2.344-1.507a.75.75 0 011.156.63v5.253a.75.75 0 01-1.156.631L16.5 15.75z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28VideoCameraCircleFillRedProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28VideoCameraCircleFillRed: FC<Icon28VideoCameraCircleFillRedProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28VideoCameraCircleFillRed as any).mountIcon = mountIcon;

export default Icon28VideoCameraCircleFillRed;
