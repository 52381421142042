import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'lightbulb_star_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="lightbulb_star_outline_28"><path clip-rule="evenodd" d="M14 4a8 8 0 00-8 8c0 2.3.764 3.707 1.71 4.86.39.474.802.895 1.234 1.337.119.122.24.245.362.372.42.435.854.905 1.232 1.431H13v-2.5l-1.6-1.2a1 1 0 011.2-1.6l1.4 1.05 1.4-1.05a1 1 0 011.2 1.6L15 17.5V20h2.463c.474-.66 1.046-1.244 1.575-1.784v-.001a78.7 78.7 0 00.231-.236c.742-.763 1.42-1.506 1.92-2.448a1 1 0 011.767.938c-.644 1.212-1.497 2.127-2.253 2.904l-.223.229c-.64.656-1.164 1.193-1.548 1.792l-.507 2.03-.032.128c-.128.518-.257 1.037-.563 1.446a2.5 2.5 0 01-1.033.807c-.471.198-1.006.197-1.54.195h-2.514c-.534.002-1.069.003-1.54-.195a2.5 2.5 0 01-1.033-.807c-.306-.409-.435-.928-.563-1.446l-.032-.127-.507-2.031c-.305-.475-.71-.927-1.202-1.436a69.504 69.504 0 00-.322-.331c-.44-.45-.929-.95-1.38-1.499C4.987 16.693 4 14.85 4 12 4 6.477 8.477 2 14 2c1.595 0 3.105.374 4.445 1.04a1 1 0 11-.89 1.791A7.963 7.963 0 0014 4zm-2.484 18.94L11.28 22h5.439l-.236.94c-.185.74-.224.818-.255.86a.5.5 0 01-.206.161c-.048.02-.134.039-.897.039h-2.252c-.763 0-.849-.02-.896-.039a.5.5 0 01-.207-.161c-.031-.042-.07-.12-.255-.86zm9.44-18.574a.574.574 0 011.09 0l.588 1.765c.19.571.639 1.02 1.21 1.21l1.764.588a.574.574 0 010 1.09l-1.764.588a1.91 1.91 0 00-1.21 1.21l-.588 1.764a.574.574 0 01-1.09 0l-.588-1.764a1.914 1.914 0 00-1.21-1.21l-1.764-.589a.574.574 0 010-1.089l1.764-.588a1.914 1.914 0 001.21-1.21z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28LightbulbStarOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28LightbulbStarOutline: FC<Icon28LightbulbStarOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28LightbulbStarOutline as any).mountIcon = mountIcon;

export default Icon28LightbulbStarOutline;
