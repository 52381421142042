import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'user_add_outline_56';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="user_add_outline_56"><g fill="none" fill-rule="evenodd"><path d="M0 0h56v56H0z" /><path d="M45 19.5c0-3.592-2.908-6.5-6.5-6.5a6.498 6.498 0 00-6.5 6.5c0 3.592 2.908 6.5 6.5 6.5s6.5-2.908 6.5-6.5zm3 0c0 5.248-4.252 9.5-9.5 9.5a9.498 9.498 0 01-9.5-9.5c0-5.248 4.252-9.5 9.5-9.5s9.5 4.252 9.5 9.5zM25.5 41.071c0 1.932-.003 1.929 1.147 1.929h24.706c1.15 0 1.147.003 1.147-1.929C52.5 36.565 46.338 34 39 34s-13.5 2.565-13.5 7.071zM39 31c8.838 0 16.5 3.19 16.5 10.071 0 3.533-1.278 4.929-4.147 4.929H26.647c-2.87 0-4.147-1.396-4.147-4.929C22.5 34.19 30.162 31 39 31zM11.5 14a1.5 1.5 0 011.5 1.5V24h8.5a1.5 1.5 0 010 3H13v8.5a1.5 1.5 0 01-3 0V27H1.5a1.5 1.5 0 010-3H10v-8.5a1.5 1.5 0 011.5-1.5z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56UserAddOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56UserAddOutline: FC<Icon56UserAddOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56UserAddOutline as any).mountIcon = mountIcon;

export default Icon56UserAddOutline;
