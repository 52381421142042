import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'cross_shield_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="cross_shield_outline_24"><g fill="currentColor"><path clip-rule="evenodd" d="M11.697 2.152a.9.9 0 01.606 0l7 2.5a.9.9 0 01.597.848V13c0 2.85-1.568 5.077-3.243 6.55a11.883 11.883 0 01-2.531 1.71c-.758.374-1.537.64-2.126.64s-1.368-.266-2.126-.64a11.882 11.882 0 01-2.531-1.71C5.668 18.078 4.1 15.85 4.1 13V5.5a.9.9 0 01.597-.848zM5.9 6.134V13c0 2.15 1.182 3.923 2.632 5.2.72.633 1.482 1.12 2.14 1.446.696.344 1.167.454 1.328.454s.632-.11 1.328-.454c.658-.325 1.42-.813 2.14-1.447C16.918 16.923 18.1 15.15 18.1 13V6.134L12 3.956z" fill-rule="evenodd" /><path d="M10.136 8.864a.9.9 0 10-1.272 1.272L10.727 12l-1.863 1.864a.9.9 0 001.272 1.272L12 13.273l1.864 1.863a.9.9 0 001.272-1.272L13.273 12l1.863-1.864a.9.9 0 10-1.272-1.272L12 10.727z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24CrossShieldOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24CrossShieldOutline: FC<Icon24CrossShieldOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24CrossShieldOutline as any).mountIcon = mountIcon;

export default Icon24CrossShieldOutline;
