import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'music_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="music_outline_24"><path clip-rule="evenodd" d="M16.716 4.105c.61-.127 1.01-.21 1.317-.246.299-.036.408-.013.457.004a.9.9 0 01.485.395c.026.043.07.146.097.446.027.304.028.705.028 1.314L8.9 8.143v-.54c0-.477 0-.786.018-1.024.017-.228.046-.328.073-.39a1.1 1.1 0 01.374-.46c.055-.039.147-.088.366-.15.23-.067.533-.13 1-.227zM7.1 15.1h-.2c-1.795 0-3.125.452-4.02 1.215-.903.768-1.28 1.782-1.28 2.71A3.375 3.375 0 004.975 22.4c.928 0 1.942-.377 2.71-1.28.763-.895 1.215-2.225 1.215-4.02V9.982l10.2-2.125V13.1h-.2c-1.795 0-3.125.452-4.021 1.215-.902.768-1.279 1.782-1.279 2.71a3.375 3.375 0 003.375 3.375c.928 0 1.942-.377 2.71-1.28.763-.895 1.215-2.225 1.215-4.02V6.004c0-.573 0-1.061-.035-1.457-.036-.412-.116-.824-.34-1.204a2.7 2.7 0 00-1.457-1.185c-.417-.141-.836-.135-1.247-.087-.395.047-.873.147-1.434.264l-.038.007-5.985 1.247-.03.007c-.429.089-.797.166-1.099.252-.32.092-.624.21-.908.41a2.9 2.9 0 00-.987 1.214c-.139.32-.192.642-.217.974-.023.313-.023.689-.023 1.126v.031zm8.946.585c-.473.403-.646.901-.646 1.34 0 .87.705 1.575 1.575 1.575.439 0 .937-.173 1.34-.646.408-.479.785-1.349.785-2.854v-.2h-.2c-1.505 0-2.375.377-2.854.785zM3.4 19.025c0-.439.173-.937.646-1.34.479-.408 1.349-.785 2.854-.785h.2v.2c0 1.505-.377 2.375-.785 2.854-.403.473-.901.646-1.34.646-.87 0-1.575-.705-1.575-1.575z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24MusicOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24MusicOutline: FC<Icon24MusicOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24MusicOutline as any).mountIcon = mountIcon;

export default Icon24MusicOutline;
