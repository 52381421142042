import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'smile_16';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="smile_16"><g fill="none" fill-rule="evenodd"><path d="M0 0h16v16H0z" /><path d="M0 8a8 8 0 1116 0A8 8 0 010 8zm14.5 0a6.5 6.5 0 10-13 0 6.5 6.5 0 0013 0zm-9.125-.497a1.125 1.125 0 110-2.25 1.125 1.125 0 010 2.25zm5.25 0a1.125 1.125 0 110-2.25 1.125 1.125 0 010 2.25zm-6.2 3.047a.75.75 0 011.22-.872 2.886 2.886 0 004.703 0 .75.75 0 111.22.872 4.386 4.386 0 01-7.144 0z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16SmileProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16Smile: FC<Icon16SmileProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16Smile as any).mountIcon = mountIcon;

export default Icon16Smile;
