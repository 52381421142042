import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'employee_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="employee_outline_28"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M14 1.5a1 1 0 00-1 1v.078A6.416 6.416 0 007.65 8.01a1 1 0 00-.597 1.883L7.5 9c-.447.894-.446.895-.445.895l.002.001.004.002.008.004.017.008a1.375 1.375 0 00.13.053c.07.025.16.053.274.083.22.057.544.124 1.016.189A5.499 5.499 0 0014 16a5.499 5.499 0 005.494-5.765 8.805 8.805 0 001.016-.19 3.221 3.221 0 00.367-.118l.012-.006.025-.01.017-.009.008-.004.004-.002h.002c.001-.001.002-.002-.445-.896l.447.894a1 1 0 00-.597-1.883A6.416 6.416 0 0015 2.578V2.5a1 1 0 00-1-1zm.134 7c1.96-.004 3.326-.087 4.24-.178A4.416 4.416 0 0015 4.614V5a1 1 0 11-2 0v-.386a4.416 4.416 0 00-3.374 3.708c.914.091 2.28.174 4.24.178h.268zm.009 2a61.486 61.486 0 003.356-.086l.001.086c0 1.934-1.566 3.5-3.5 3.5a3.499 3.499 0 01-3.499-3.586A61.608 61.608 0 0014 10.5h.143zM14.25 17C9.299 17 5 18.763 5 22.643c0 2.016.781 2.857 2.456 2.857h13.588c1.675 0 2.456-.84 2.456-2.857 0-3.88-4.299-5.643-9.25-5.643zm-7.194 6.464c-.064-.055-.063-.188-.059-.5.002-.09.003-.197.003-.321C7 20.355 10.304 19 14.25 19s7.25 1.355 7.25 3.643c0 .124.001.23.003.321.004.312.005.445-.059.5-.048.04-.131.04-.276.037l-.124-.001H7.456l-.124.001c-.145.002-.228.004-.276-.037z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28EmployeeOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28EmployeeOutline: FC<Icon28EmployeeOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28EmployeeOutline as any).mountIcon = mountIcon;

export default Icon28EmployeeOutline;
