import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'freeze_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="freeze_outline_20"><path clip-rule="evenodd" d="M7.47 1.97a.75.75 0 011.06 0L10 3.44l1.47-1.47a.75.75 0 111.06 1.06l-1.78 1.78V8.7l3.37-1.944.65-2.432a.75.75 0 011.45.388l-.538 2.008 2.007.538a.75.75 0 01-.388 1.448l-2.432-.651L11.5 10l3.37 1.945 2.431-.652a.75.75 0 01.388 1.45l-2.007.537.538 2.008a.75.75 0 11-1.45.388l-.65-2.432-3.37-1.945v3.89l1.78 1.78a.75.75 0 11-1.06 1.061L10 16.56l-1.47 1.47a.75.75 0 01-1.06-1.06l1.78-1.78v-3.891l-3.37 1.945-.65 2.432a.75.75 0 11-1.45-.388l.538-2.008-2.007-.538a.75.75 0 01.388-1.449l2.432.652L8.5 10 5.13 8.055 2.7 8.707a.75.75 0 01-.388-1.45l2.007-.537-.538-2.008a.75.75 0 011.45-.388l.65 2.432L9.25 8.7v-3.89L7.47 3.03a.75.75 0 010-1.061z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20FreezeOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20FreezeOutline: FC<Icon20FreezeOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20FreezeOutline as any).mountIcon = mountIcon;

export default Icon20FreezeOutline;
