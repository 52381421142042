import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'smartphone_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="smartphone_outline_28"><g fill="currentColor"><path d="M13 20a1 1 0 100 2h2a1 1 0 100-2z" /><path clip-rule="evenodd" d="M6 8.4c0-2.24 0-3.36.436-4.216a4 4 0 011.748-1.748C9.04 2 10.16 2 12.4 2h3.2c2.24 0 3.36 0 4.216.436a4 4 0 011.748 1.748C22 5.04 22 6.16 22 8.4v11.2c0 2.24 0 3.36-.436 4.216a4 4 0 01-1.748 1.748C18.96 26 17.84 26 15.6 26h-3.2c-2.24 0-3.36 0-4.216-.436a4 4 0 01-1.748-1.748C6 22.96 6 21.84 6 19.6zM12.4 4h3.2c1.153 0 1.898.002 2.464.048.544.044.745.12.844.17a2 2 0 01.874.874c.05.099.126.3.17.844.046.566.048 1.31.048 2.464v11.2c0 1.153-.002 1.898-.048 2.464-.044.544-.12.745-.17.844a2 2 0 01-.874.874c-.099.05-.3.126-.844.17-.566.046-1.31.048-2.464.048h-3.2c-1.153 0-1.898-.002-2.464-.048-.544-.044-.745-.12-.844-.17a2 2 0 01-.874-.874c-.05-.099-.126-.3-.17-.844C8.002 21.498 8 20.754 8 19.6V8.4c0-1.153.002-1.898.048-2.464.044-.544.12-.745.17-.844a2 2 0 01.874-.874c.099-.05.3-.126.844-.17C10.502 4.002 11.246 4 12.4 4z" fill-rule="evenodd" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28SmartphoneOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28SmartphoneOutline: FC<Icon28SmartphoneOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28SmartphoneOutline as any).mountIcon = mountIcon;

export default Icon28SmartphoneOutline;
