import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'advertising_outline_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="advertising_outline_36"><path fill-rule="evenodd" clip-rule="evenodd" d="M21.022 9.983H9.604A7.106 7.106 0 008.429 24.1l.844 4.135a3.792 3.792 0 007.431-1.518l-.514-2.52h4.832l5.167 3.532c2.455 1.68 5.788-.08 5.788-3.054V9.506c0-2.975-3.333-4.734-5.788-3.055l-5.167 3.532zm.755 11.701v-9.188l5.823-3.98a1.2 1.2 0 011.877.99v15.168a1.2 1.2 0 01-1.877.99l-5.823-3.98zm-2.503-9.2v9.212H9.585a4.606 4.606 0 01.019-9.213h9.67zM11 24.195h2.638l.617 3.02a1.292 1.292 0 01-2.532.518L11 24.196z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36AdvertisingOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36AdvertisingOutline: FC<Icon36AdvertisingOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36AdvertisingOutline as any).mountIcon = mountIcon;

export default Icon36AdvertisingOutline;
