import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'sad_face_outline_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="sad_face_outline_16"><g fill="currentColor"><path d="M8 0a8 8 0 110 16A8 8 0 018 0zm0 1.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zM5.375 5.253a1.125 1.125 0 110 2.25 1.125 1.125 0 010-2.25zm5.245 0A1.125 1.125 0 119.5 6.378a1.12 1.12 0 011.12-1.125z" /><path d="M8 10.5a3.73 3.73 0 00-2.594 1.042.75.75 0 01-1.037-1.084 5.288 5.288 0 011.005-.755C6.147 9.256 7.045 9 8 9a5.229 5.229 0 013.631 1.458.75.75 0 01-1.037 1.084 3.792 3.792 0 00-.72-.54A3.73 3.73 0 008 10.5z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16SadFaceOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16SadFaceOutline: FC<Icon16SadFaceOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16SadFaceOutline as any).mountIcon = mountIcon;

export default Icon16SadFaceOutline;
