import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 12 12';
const id = 'fire_verified_12';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" id="fire_verified_12"><g fill="none" fill-rule="evenodd"><path d="M0 0h12v12H0z" /><path d="M6.093.787a.188.188 0 01.294.192.85.85 0 01-.1.27c-1.415 2.185-.794 3.5.161 3.64.988.147 1.71-.515 1.555-1.943l-.066-.622a.172.172 0 01.266-.162c.32.212.705.596 1.152 1.154C10.947 5.301 11.01 6.962 11 7.676 10.968 10.239 9.071 12 6.5 12 3.929 12 2 10.24 2 7.677c.011-1.94.991-4.67 3.696-6.6l.397-.29zM4.62 7.129a.525.525 0 00-.742.742l1.5 1.5a.525.525 0 00.742 0l3-3a.525.525 0 00-.742-.742L5.75 8.258l-1.129-1.13z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon12FireVerifiedProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon12FireVerified: FC<Icon12FireVerifiedProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 12,
    height: !isNaN(props.height) ? +props.height : 12,
  }));
};

(Icon12FireVerified as any).mountIcon = mountIcon;

export default Icon12FireVerified;
