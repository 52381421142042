import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'notification_slash_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="notification_slash_outline_24"><path d="M12.004 2.1c4.163 0 6.896 3.446 6.896 7.532v1.6c0 .249.146.537.579.99.473.497 1.015.935 1.4 1.51.318.474.533 1.043.52 1.689a.9.9 0 11-1.8-.037c.017-.815-.93-1.402-1.424-1.92-.474-.497-1.075-1.234-1.075-2.233v-1.6c0-3.311-2.134-5.731-5.096-5.731-1.008 0-1.884.269-2.623.735a.9.9 0 11-.961-1.521A6.666 6.666 0 0112.004 2.1zm4.347 15.801c-.629 2.333-2.13 3.6-4.351 3.6S8.277 20.232 7.648 17.9H5.737c-1.488 0-2.466-.502-2.902-1.41-.191-.398-.235-.72-.235-1.163 0-1.293.942-2.156 1.78-3.02.527-.544.72-.847.72-1.076 0-1.493-.038-2.969.455-4.403L3.264 4.536a.9.9 0 111.272-1.272l16.2 16.2a.9.9 0 11-1.272 1.272l-2.836-2.835zm-6.817 0h4.932c-.456 1.248-1.242 1.8-2.466 1.8s-2.01-.552-2.466-1.8zM6.9 11.232c0-.976-.06-1.971.123-2.935l7.804 7.804h-9.09c-.847 0-1.173-.167-1.28-.39-.335-.697.52-1.445.934-1.864.722-.731 1.509-1.517 1.509-2.615z" clip-rule="evenodd" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24NotificationSlashOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24NotificationSlashOutline: FC<Icon24NotificationSlashOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24NotificationSlashOutline as any).mountIcon = mountIcon;

export default Icon24NotificationSlashOutline;
