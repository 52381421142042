import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'place_32';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="place_32"><g fill="none" fill-rule="evenodd"><path d="M0 0h32v32H0z" /><path d="M6 12c0 4.024 2.938 9.823 8.815 17.398a1.5 1.5 0 002.37 0C23.062 21.823 26 16.024 26 12c0-5.529-4.471-10-10-10S6 6.471 6 12zm6.501.001c0-1.932 1.568-3.5 3.5-3.5s3.5 1.568 3.5 3.5-1.568 3.5-3.5 3.5a3.501 3.501 0 01-3.5-3.5z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32PlaceProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32Place: FC<Icon32PlaceProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32Place as any).mountIcon = mountIcon;

export default Icon32Place;
