import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'flip_vertical_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="flip_vertical_20"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.394 2.34a.75.75 0 01.767.033l8 5.25A.75.75 0 0111.75 9h-8A.75.75 0 013 8.25V3a.75.75 0 01.394-.66zM4.5 4.39V7.5h4.74L4.5 4.39zM3 11.75a.75.75 0 01.75-.75h8a.75.75 0 01.412 1.377l-8 5.25A.75.75 0 013 17v-5.25zm1.5.75v3.11l4.74-3.11H4.5zm10.97-9.03a.75.75 0 011.06 0l2 2a.75.75 0 01-1.06 1.06l-.72-.72v8.38l.72-.72a.75.75 0 111.06 1.06l-2 2a.75.75 0 01-1.06 0l-2-2a.75.75 0 111.06-1.06l.72.72V5.81l-.72.72a.75.75 0 11-1.06-1.06l2-2z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20FlipVerticalProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20FlipVertical: FC<Icon20FlipVerticalProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20FlipVertical as any).mountIcon = mountIcon;

export default Icon20FlipVertical;
