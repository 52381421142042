import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'palette_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="palette_outline_28"><g fill="none" fill-rule="evenodd"><path d="M0 0h28v28H0z" /><path d="M14 2.333c6.428 0 11.667 4.714 11.667 10.5 0 3.862-3.139 7-7 7h-2.065a.578.578 0 00-.584.584c0 .14.059.268.152.385.478.548.747 1.236.747 1.948A2.918 2.918 0 0114 25.667C7.572 25.667 2.333 20.428 2.333 14S7.572 2.333 14 2.333zm-.005 1.997c-5.328 0-9.665 4.337-9.665 9.665 0 5.328 4.337 9.665 9.665 9.665a.598.598 0 00.604-.604.653.653 0 00-.17-.423 3 3 0 01-.76-1.993 3.021 3.021 0 013.02-3.02h2.139a4.831 4.831 0 004.832-4.833c0-4.663-4.337-8.457-9.665-8.457zm-6.412 7.337a1.75 1.75 0 110 3.5 1.75 1.75 0 010-3.5zm12.834 0a1.75 1.75 0 110 3.5 1.75 1.75 0 010-3.5zM11.083 7a1.75 1.75 0 110 3.5 1.75 1.75 0 010-3.5zm5.834 0a1.75 1.75 0 110 3.5 1.75 1.75 0 010-3.5z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28PaletteOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28PaletteOutline: FC<Icon28PaletteOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28PaletteOutline as any).mountIcon = mountIcon;

export default Icon28PaletteOutline;
