import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'ghost_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="ghost_28"><path d="M14 3.001c2.745 0 4.913.96 6.915 2.82C22.887 7.65 24 10.15 24 12.76l-.016 8.53c-.112 1.523-1.146 2.652-2.647 2.652-1.502 0-1.9-1.058-3.183-.842-1.285.216-1.767 1.9-4.154 1.9s-2.917-1.692-4.154-1.9c-1.284-.216-1.681.842-3.183.842-1.446 0-2.572-1.06-2.647-2.652L4 12.76c0-2.611 1.113-5.11 3.085-6.94C9.003 4.038 11.255 3 14 3zm3.243 14.08a1 1 0 00-1.412-.074c-1.034.93-2.065.93-3.115.363a3.078 3.078 0 01-.547-.363l-.099-.078a1 1 0 00-1.239 1.564c.194.175.51.408.935.637 1.727.933 3.66.933 5.403-.637a1 1 0 00.074-1.412zM9.75 11a1.75 1.75 0 100 3.5 1.75 1.75 0 000-3.5zm7.145.878c-.25.135-.44.274-.564.385a.994.994 0 00-.074 1.407c.34.378.906.435 1.313.152l.099-.078c.014-.013.08-.06.177-.113.32-.172.587-.19.885.031l.1.082c.41.368 1.043.335 1.412-.074a.994.994 0 00-.074-1.407c-1.05-.94-2.24-.94-3.274-.385z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28GhostProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28Ghost: FC<Icon28GhostProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28Ghost as any).mountIcon = mountIcon;

export default Icon28Ghost;
