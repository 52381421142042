import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'explicit_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="explicit_outline_28"><g fill="currentColor"><path d="M18 8h-8v12h8v-2h-6v-3h5v-2h-5v-3h6z" /><path clip-rule="evenodd" d="M17.643 3h-7.286C9.273 3 8.4 3 7.691 3.058c-.728.06-1.369.185-1.96.487A5 5 0 003.544 5.73c-.302.592-.428 1.233-.487 1.961C3 8.4 3 9.273 3 10.357v7.286c0 1.084 0 1.958.058 2.666.06.729.185 1.369.487 1.961a5 5 0 002.185 2.185c.592.302 1.233.428 1.961.487C8.4 25 9.273 25 10.357 25h7.286c1.084 0 1.958 0 2.666-.058.729-.06 1.369-.185 1.961-.487a5 5 0 002.185-2.185c.302-.592.428-1.232.487-1.961C25 19.6 25 18.727 25 17.643v-7.286c0-1.084 0-1.958-.058-2.666-.06-.728-.185-1.369-.487-1.96a5 5 0 00-2.185-2.186c-.592-.302-1.232-.428-1.961-.487C19.6 3 18.727 3 17.643 3zM6.638 5.327c.263-.134.611-.226 1.216-.276C8.471 5.001 9.264 5 10.4 5h7.2c1.137 0 1.929 0 2.546.051.605.05.953.142 1.216.276a3 3 0 011.311 1.311c.134.263.226.611.276 1.216.05.617.051 1.41.051 2.546v7.2c0 1.137 0 1.929-.051 2.546-.05.605-.142.953-.276 1.216a3 3 0 01-1.311 1.311c-.263.134-.611.226-1.216.276-.617.05-1.41.051-2.546.051h-7.2c-1.137 0-1.929 0-2.546-.051-.605-.05-.953-.142-1.216-.276a3 3 0 01-1.311-1.311c-.134-.263-.226-.611-.276-1.216C5.001 19.529 5 18.736 5 17.6v-7.2c0-1.137 0-1.929.051-2.546.05-.605.142-.953.276-1.216a3 3 0 011.311-1.311z" fill-rule="evenodd" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28ExplicitOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28ExplicitOutline: FC<Icon28ExplicitOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28ExplicitOutline as any).mountIcon = mountIcon;

export default Icon28ExplicitOutline;
