import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'sticker_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="sticker_outline_24"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.639 2.106a.9.9 0 01.668.264l9.324 9.323a.9.9 0 01.262.668A9.87 9.87 0 0119 19.002a9.872 9.872 0 01-7 2.899A9.872 9.872 0 015 19a9.872 9.872 0 01-2.9-7c0-2.734 1.11-5.21 2.9-7a9.87 9.87 0 016.639-2.894zm-1.301 1.965a8.081 8.081 0 00-4.066 2.201A8.072 8.072 0 003.9 12c0 2.237.906 4.26 2.372 5.728A8.072 8.072 0 0012 20.1c2.237 0 4.26-.906 5.727-2.372a8.08 8.08 0 002.202-4.066A7.912 7.912 0 0118 13.9a7.878 7.878 0 01-5.586-2.314A7.878 7.878 0 0110.1 6c0-.665.082-1.311.238-1.93zm1.71.585A6.12 6.12 0 0011.9 6c0 1.685.682 3.208 1.787 4.313A6.078 6.078 0 0018 12.1a6.13 6.13 0 001.344-.148l-7.296-7.296z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24StickerOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24StickerOutline: FC<Icon24StickerOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24StickerOutline as any).mountIcon = mountIcon;

export default Icon24StickerOutline;
