import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'moon_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="moon_outline_56"><path clip-rule="evenodd" d="M29.124 4.976a1.5 1.5 0 01-.435 1.67A17.459 17.459 0 0022.5 20c0 9.665 7.835 17.5 17.5 17.5 2.878 0 5.59-.694 7.98-1.921a1.5 1.5 0 012.063 1.93C46.362 46.03 37.88 52 28 52 14.745 52 4 41.255 4 28 4 14.845 14.584 4.162 27.701 4.002a1.5 1.5 0 011.423.974zm-5.309 2.441C14.222 9.357 7 17.835 7 28c0 11.598 9.402 21 21 21 7.228 0 13.605-3.652 17.384-9.215A20.518 20.518 0 0140 40.5c-11.322 0-20.5-9.178-20.5-20.5 0-4.743 1.612-9.11 4.315-12.583z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56MoonOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56MoonOutline: FC<Icon56MoonOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56MoonOutline as any).mountIcon = mountIcon;

export default Icon56MoonOutline;
