import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'up_16';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="up_16"><g fill="none" fill-rule="evenodd"><path d="M0 0h16v16H0z" /><path d="M8.616 2.206l.095.083 4 4a1.005 1.005 0 01-1.327 1.505l-.095-.083-2.284-2.285V13a1.005 1.005 0 01-2.003.117L6.995 13V5.424L4.711 7.711c-.363.362-.933.39-1.327.083l-.095-.083a1.005 1.005 0 01-.083-1.327l.083-.095 4-4c.363-.362.933-.39 1.327-.083z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16UpProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16Up: FC<Icon16UpProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16Up as any).mountIcon = mountIcon;

export default Icon16Up;
