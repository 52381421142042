import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'gesture_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="gesture_outline_20"><path clip-rule="evenodd" d="M11.831 2.502a.936.936 0 00-1.07.799l-.38 2.794a1.282 1.282 0 01-.128.613.858.858 0 01-.284.369.9.9 0 01-.914.088.858.858 0 01-.35-.307 1.289 1.289 0 01-.109-.208 5.602 5.602 0 01-.134-.37l-.718-2.107a.963.963 0 00-1.872.385l.292 3.79a4.45 4.45 0 012.465-.091c.077.02.146.045.191.061a2.71 2.71 0 011.812 2.627c-.026 1.077-.708 2.148-2.019 2.432-.307.066-.624.123-.863.123a.75.75 0 010-1.5c.06 0 .235-.022.546-.09.555-.12.825-.533.836-1.001a1.21 1.21 0 00-.891-1.203 2.949 2.949 0 00-2.325.418c-.37.247-1.561 1.327-1.18 3.229.384 1.922 2.319 3.86 5.717 3.648 3.403-.212 5.258-2.437 5.05-4.939-.103-1.233-.765-2.06-1.44-2.601a5.113 5.113 0 00-1.214-.716 2.569 2.569 0 00-.073-.029l-.015-.005h.001a.75.75 0 01-.51-.773l.37-4.433a.936.936 0 00-.79-1.003zm1.961 5.022l.325-3.894a2.436 2.436 0 00-4.842-.532l-.09.656-.021-.065a2.463 2.463 0 00-4.788.984l.346 4.486c-.754.666-1.92 2.173-1.457 4.488.544 2.723 3.203 5.106 7.282 4.851 4.074-.254 6.742-3.062 6.45-6.56-.147-1.767-1.11-2.94-1.997-3.649a6.61 6.61 0 00-1.196-.759l-.012-.006zM12.76 8.711h.002-.002zm0 0z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20GestureOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20GestureOutline: FC<Icon20GestureOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20GestureOutline as any).mountIcon = mountIcon;

export default Icon20GestureOutline;
