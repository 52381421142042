import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'votes_transfer_circle_fill_turquoise_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="votes_transfer_circle_fill_turquoise_28"><linearGradient id="votes_transfer_circle_fill_turquoise_28_a" gradientUnits="userSpaceOnUse" x1="42" x2="14" y1="14" y2="-14"><stop offset="0" stop-color="#65c2c2" /><stop offset="1" stop-color="#86d1d1" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#votes_transfer_circle_fill_turquoise_28_a)" /><path d="M8.026 17.174c1.37 1.262 3.536 2.076 5.974 2.076s4.604-.814 5.974-2.075c.017.107.026.215.026.325 0 1.933-2.686 3.5-6 3.5s-6-1.567-6-3.5c0-.11.009-.219.026-.326zm11.948-3.498c.017.107.026.216.026.325 0 1.933-2.686 3.5-6 3.5s-6-1.567-6-3.5c0-.11.009-.218.026-.326 1.37 1.262 3.536 2.076 5.974 2.076s4.604-.814 5.974-2.075zM14 7c3.314 0 6 1.567 6 3.5S17.314 14 14 14s-6-1.567-6-3.5S10.686 7 14 7z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28VotesTransferCircleFillTurquoiseProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28VotesTransferCircleFillTurquoise: FC<Icon28VotesTransferCircleFillTurquoiseProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28VotesTransferCircleFillTurquoise as any).mountIcon = mountIcon;

export default Icon28VotesTransferCircleFillTurquoise;
