import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'followers_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="followers_24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" /><path d="M4.838 5.03c.271.012.495.027.67.045 7.036.702 12.64 6.267 13.403 13.285.023.205.042.472.058.8a.8.8 0 01-.799.84h-1.402a.8.8 0 01-.799-.756 11.1 11.1 0 00-.054-.683c-.667-5.575-5.154-9.975-10.77-10.507a9.438 9.438 0 00-.387-.027A.8.8 0 014 7.228v-1.4a.8.8 0 01.838-.799zm.02 5.03c.379.027.588.044.628.05a10.008 10.008 0 018.355 8.107c.042.232.078.539.108.921a.8.8 0 01-.798.862h-1.254a.8.8 0 01-.797-.732 5.708 5.708 0 00-.047-.404 7.148 7.148 0 00-5.912-5.916 37.963 37.963 0 00-.404-.033.8.8 0 01-.737-.798v-1.26a.8.8 0 01.857-.797zM8.118 20H4.8a.8.8 0 01-.8-.8v-3.324a.8.8 0 01.994-.777 5.007 5.007 0 013.864 3.713c.018.075.035.163.051.264a.8.8 0 01-.79.924z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24FollowersProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Followers: FC<Icon24FollowersProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Followers as any).mountIcon = mountIcon;

export default Icon24Followers;
