import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'money_transfer_32';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="money_transfer_32"><g fill="none" fill-rule="evenodd"><path d="M0 0h32v32H0z" /><path d="M28 18v-4H4v8a1 1 0 001 1h18v-3.93a.5.5 0 01.81-.392l5.998 4.737a.5.5 0 010 .785l-5.998 4.737a.5.5 0 01-.81-.392V25H5a3 3 0 01-3-3V8a3 3 0 013-3h22a3 3 0 013 3v10.05a1 1 0 01-2 0V18zm0-9V8a1 1 0 00-1-1H5a1 1 0 00-1 1v1h24z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32MoneyTransferProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32MoneyTransfer: FC<Icon32MoneyTransferProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32MoneyTransfer as any).mountIcon = mountIcon;

export default Icon32MoneyTransfer;
