import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'help_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="help_outline_24"><path d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1zm0 1.8a9.2 9.2 0 100 18.4 9.2 9.2 0 000-18.4zm-.198 13.774a1.1 1.1 0 011.1 1.1v.027a1.1 1.1 0 01-2.199 0v-.027a1.1 1.1 0 011.1-1.1zM11.74 5.8c1.967 0 3.561 1.63 3.561 3.642 0 .801-.32 1.53-.828 2.049l-.801.837c-.477.494-.82.923-.965 1.672a2.297 2.297 0 00-.03.31.887.887 0 01-.886.848.9.9 0 01-.9-.9v-.002c.001-.254.009-.42.024-.498a3.715 3.715 0 01.976-1.885l1.104-1.147c.316-.322.526-.783.526-1.284 0-1.002-.802-1.821-1.781-1.821-.622 0-1.173.33-1.491.83-.06.094-.137.243-.227.446a.924.924 0 01-1.145.497.858.858 0 01-.516-1.132c.065-.16.127-.301.186-.424C9.158 6.58 10.31 5.8 11.74 5.8z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24HelpOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24HelpOutline: FC<Icon24HelpOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24HelpOutline as any).mountIcon = mountIcon;

export default Icon24HelpOutline;
