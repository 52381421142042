import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'phone_circle_fill_green_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="phone_circle_fill_green_20"><linearGradient id="phone_circle_fill_green_20_a" gradientUnits="userSpaceOnUse" x1="-10" x2="10" y1="10" y2="30"><stop offset="0" stop-color="#50c750" /><stop offset="1" stop-color="#32b332" /></linearGradient><path d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z" fill="url(#phone_circle_fill_green_20_a)" /><path d="M11.173 11.81c.752-.752 1.937-.821 2.724-.16l.57.48c.649.547.715 1.532.146 2.2-.31.36-.747.585-1.22.63-1.818.245-3.726-.631-5.723-2.63-1.998-1.997-2.875-3.905-2.63-5.723a1.844 1.844 0 01.63-1.22c.668-.569 1.653-.503 2.2.147l.478.569c.663.787.595 1.971-.158 2.724l-.408.408a.396.396 0 00-.091.416c.148.406.527.913 1.136 1.522.61.61 1.117.988 1.522 1.137a.396.396 0 00.416-.092z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20PhoneCircleFillGreenProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20PhoneCircleFillGreen: FC<Icon20PhoneCircleFillGreenProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20PhoneCircleFillGreen as any).mountIcon = mountIcon;

export default Icon20PhoneCircleFillGreen;
