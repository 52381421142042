import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'game_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="game_outline_24"><path d="M17.123 4.2c2.904 0 4.83 1.835 5.893 4.876.71 2.031.984 4.397.984 6.738 0 3.407-.837 4.757-3.39 4.757-1.254 0-2.115-.793-3.638-2.76-1.219-1.572-1.682-2.061-1.841-2.061H8.869c-.195 0-.835.616-2.034 2.065l-.016.02C5.037 19.987 4.435 20.57 3.39 20.57c-2.553 0-3.39-1.35-3.39-4.757 0-2.341.275-4.707.984-6.738C2.046 6.036 3.973 4.2 6.877 4.2h10.246zm0 1.8H6.877C3.269 6 1.8 10.207 1.8 15.814c0 2.54.259 2.957 1.59 2.957.192 0 .826-.614 2.042-2.084l.017-.02c1.75-2.115 2.375-2.717 3.42-2.717h6.262c1.065 0 1.4.355 3.264 2.76 1.123 1.449 1.787 2.061 2.215 2.061 1.331 0 1.59-.417 1.59-2.957C22.2 10.207 20.73 6 17.123 6zM7.14 7.714c.317 0 .574.257.574.575v1.14h1.14c.317 0 .575.257.575.574v.566a.574.574 0 01-.575.574h-1.14v1.14a.574.574 0 01-.574.574h-.566A.574.574 0 016 12.283v-1.14H4.86a.574.574 0 01-.574-.574v-.566c0-.317.257-.574.574-.574H6v-1.14c0-.318.257-.575.574-.575h.566zm10.286 3.429c.317 0 .574.257.574.574v.566a.574.574 0 01-.574.574h-.566a.574.574 0 01-.574-.574v-.566c0-.317.257-.574.574-.574h.566zM15.71 9.429c.318 0 .575.257.575.574v.566a.574.574 0 01-.575.574h-.565a.574.574 0 01-.575-.574v-.566c0-.317.258-.574.575-.574h.565zm3.429 0c.317 0 .574.257.574.574v.566a.574.574 0 01-.574.574h-.566a.574.574 0 01-.574-.574v-.566c0-.317.257-.574.574-.574h.566zm-1.714-1.715c.317 0 .574.257.574.575v.565a.574.574 0 01-.574.575h-.566a.574.574 0 01-.574-.575V8.29c0-.318.257-.575.574-.575h.566z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24GameOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24GameOutline: FC<Icon24GameOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24GameOutline as any).mountIcon = mountIcon;

export default Icon24GameOutline;
