import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'phone_wave_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="phone_wave_outline_28"><g fill="currentColor"><path d="M15.574 3.13a1 1 0 011-1 9.283 9.283 0 019.283 9.283 1 1 0 11-2 0 7.283 7.283 0 00-7.283-7.283 1 1 0 01-1-1z" /><path clip-rule="evenodd" d="M13.31 14.698c.525.524.981.92 1.35 1.191 2.124-2.123 5.428-2.317 7.652-.447l1.023.862c2.03 1.708 2.232 4.751.49 6.798-.896 1.036-2.156 1.687-3.442 1.808-3.982.536-7.987-1.303-11.984-5.3-3.997-3.998-5.837-8.004-5.31-11.896.036-.414.122-.822.257-1.22a5.33 5.33 0 011.572-2.321c2.037-1.732 5.079-1.53 6.785.5l.86 1.023c1.873 2.22 1.681 5.527-.4 7.607.227.414.624.87 1.148 1.395zm2.81 2.561l-.734.734a.712.712 0 01-.75.166c-.73-.269-1.643-.95-2.74-2.047-1.095-1.096-1.778-2.01-2.045-2.74a.712.712 0 01.164-.75l.736-.734c1.354-1.354 1.478-3.487.284-4.903l-.862-1.024c-.984-1.17-2.756-1.288-3.96-.264A3.319 3.319 0 005.24 7.14a3.25 3.25 0 00-.159.752c-.44 3.272 1.137 6.707 4.733 10.303s7.03 5.174 10.303 4.733a3.318 3.318 0 002.195-1.134c1.024-1.202.905-2.976-.264-3.96l-1.024-.862c-1.417-1.192-3.55-1.067-4.903.287z" fill-rule="evenodd" /><path d="M19.6 10.895c.609-.068 1.018-.668.728-1.207a5.01 5.01 0 00-2.031-2.029c-.539-.29-1.139.12-1.207.728l-.176 1.58a1 1 0 001.105 1.105l1.582-.177z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28PhoneWaveOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28PhoneWaveOutline: FC<Icon28PhoneWaveOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28PhoneWaveOutline as any).mountIcon = mountIcon;

export default Icon28PhoneWaveOutline;
