import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'user_circle_outline_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="user_circle_outline_36"><clipPath id="user_circle_outline_36_a"><path d="M0 0h36v36H0z" /></clipPath><g clip-path="url(#user_circle_outline_36_a)" clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M24 16a6 6 0 11-12 0 6 6 0 0112 0zm-2.5 0a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0z" /><path d="M18 3c-4.142 0-7.894 1.68-10.607 4.393S3 13.858 3 18s1.68 7.894 4.393 10.607S13.858 33 18 33s7.894-1.68 10.607-4.393S33 22.142 33 18s-1.68-7.894-4.393-10.607S22.142 3 18 3zM9.161 9.161C11.425 6.898 14.548 5.5 18 5.5s6.575 1.398 8.839 3.661A12.457 12.457 0 0130.5 18c0 3.398-1.354 6.477-3.555 8.732C24.39 25.007 21.313 24 18 24s-6.391 1.007-8.945 2.732A12.455 12.455 0 015.5 18c0-3.452 1.398-6.575 3.661-8.839zM24.924 28.41C22.942 29.73 20.561 30.5 18 30.5s-4.942-.77-6.924-2.091C13.1 27.197 15.469 26.5 18 26.5s4.9.697 6.924 1.909z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36UserCircleOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36UserCircleOutline: FC<Icon36UserCircleOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36UserCircleOutline as any).mountIcon = mountIcon;

export default Icon36UserCircleOutline;
