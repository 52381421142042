import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'computer_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="computer_outline_24"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.5 5.9h-5c-1.15 0-1.904.001-2.48.048-.556.046-.787.125-.919.192a2.2 2.2 0 00-.961.961c-.067.132-.146.363-.191.918C4.9 8.596 4.9 9.35 4.9 10.5s.001 1.904.049 2.48c.045.556.124.787.19.919.212.414.548.75.962.961.132.067.363.146.918.192.577.047 1.331.048 2.481.048h5c1.15 0 1.904-.001 2.48-.048.556-.046.787-.125.919-.192a2.2 2.2 0 00.961-.961c.067-.132.146-.363.192-.918.047-.577.048-1.331.048-2.481s-.001-1.904-.049-2.48c-.045-.556-.124-.787-.19-.919a2.2 2.2 0 00-.962-.961c-.132-.067-.363-.146-.918-.192-.577-.047-1.331-.048-2.481-.048zm-10.964.384C3.1 7.14 3.1 8.26 3.1 10.5s0 3.36.436 4.216a4 4 0 001.748 1.748c.856.436 1.976.436 4.216.436h5c2.24 0 3.36 0 4.216-.436a4 4 0 001.748-1.748c.436-.856.436-1.976.436-4.216s0-3.36-.436-4.216a4 4 0 00-1.748-1.748C17.86 4.1 16.74 4.1 14.5 4.1h-5c-2.24 0-3.36 0-4.216.436a4 4 0 00-1.748 1.748z" fill="currentColor" /><path d="M6 20a.9.9 0 01.9-.9h10.2a.9.9 0 110 1.8H6.9A.9.9 0 016 20z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M11.373 16.218l-1 4-1.746-.436 1-4 1.746.436zm1.254 0l1 4 1.746-.436-1-4-1.746.436z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24ComputerOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24ComputerOutline: FC<Icon24ComputerOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24ComputerOutline as any).mountIcon = mountIcon;

export default Icon24ComputerOutline;
