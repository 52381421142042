import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'gesture_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="gesture_outline_28"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.5 17.512c0 3.585 2.91 6.49 6.5 6.49h1V26h-1c-4.694 0-8.5-3.8-8.5-8.488h2zM16.639 4.031a1.395 1.395 0 00-1.667 1.068l-.994 4.632a1 1 0 01-1.956-.419l.995-4.632A3.395 3.395 0 0119.7 5.84l-.71 5.314a1 1 0 01-1.982-.264l.71-5.314a1.393 1.393 0 00-1.08-1.544z" fill="currentColor" /><path d="M8.223 6.055a1.403 1.403 0 012.762-.462l1.045 4.173a1 1 0 001.94-.484l-1.045-4.173a3.404 3.404 0 00-3.302-2.575A3.402 3.402 0 006.23 6.228l.74 8.494 1.904-1.188-.652-7.479z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M12.473 14.752a1.409 1.409 0 00-1.47-1.099l-1.236.082A3.497 3.497 0 006.5 17.223v.289a1 1 0 01-2 0v-.29a5.495 5.495 0 015.134-5.48l1.236-.082a3.405 3.405 0 01.787 6.755l-.493.082a1 1 0 11-.328-1.97l.492-.082a1.408 1.408 0 001.145-1.693zM22 16.014a3.997 3.997 0 00-4-3.994v-1.997c3.314 0 6 2.682 6 5.991v.158C24 21.6 19.593 26 14.158 26H13.5v-1.997h.658c4.331 0 7.842-3.506 7.842-7.831v-.158z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28GestureOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28GestureOutline: FC<Icon28GestureOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28GestureOutline as any).mountIcon = mountIcon;

export default Icon28GestureOutline;
