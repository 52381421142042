import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'help_circle_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="help_circle_outline_28"><path d="M14 2c6.624 0 12 5.376 12 12s-5.376 12-12 12S2 20.624 2 14 7.376 2 14 2zm0 2C8.48 4 4 8.48 4 14s4.48 10 10 10 10-4.48 10-10S19.52 4 14 4zm0 14.1a1.4 1.4 0 110 2.8 1.4 1.4 0 010-2.8zm-.066-11.11c2.05 0 3.712 1.678 3.712 3.75 0 .825-.334 1.575-.863 2.11l-.835.862c-.497.509-.855.95-1.006 1.72-.014.07-.024.174-.03.31a.926.926 0 01-.925.883h-.01a.928.928 0 01-.928-.928v-.002c.001-.26.01-.43.025-.512a3.81 3.81 0 011.018-1.94l1.15-1.181a1.871 1.871 0 00-1.308-3.197c-.649 0-1.222.34-1.554.854a3.891 3.891 0 00-.248.485.928.928 0 01-1.147.499l-.035-.012a.886.886 0 01-.534-1.175c.066-.161.13-.304.19-.427.638-1.297 1.838-2.099 3.328-2.099z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28HelpCircleOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28HelpCircleOutline: FC<Icon28HelpCircleOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28HelpCircleOutline as any).mountIcon = mountIcon;

export default Icon28HelpCircleOutline;
