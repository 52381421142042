import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'pin_slash_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="pin_slash_outline_24"><path d="M5.944 3.683l.092.08 14.2 14.2a.9.9 0 01-1.18 1.354l-.092-.08-3.716-3.716-.259 3.366a1.206 1.206 0 01-2.055.76L9.28 15.994l-4.744 4.744a.9.9 0 01-1.353-1.18l.08-.093 4.744-4.744-3.654-3.653a1.206 1.206 0 01-.088-1.608l.088-.098c.204-.203.473-.327.76-.35l3.366-.259-3.715-3.716a.9.9 0 011.18-1.353zm4.182 6.716a2.2 2.2 0 01-.363.06l-3.224.248 6.754 6.754.249-3.223a2.11 2.11 0 01.06-.364zm4.46-7.213l.087.077 6.065 6.06a.9.9 0 01-.35 1.49l-.114.03-1.83.356-.053.027-.533.534a.9.9 0 01-1.355-1.178l.08-.092.534-.535a1.9 1.9 0 01.845-.492l.305-.064-3.674-3.672-.03.15a1.9 1.9 0 01-.416.891l-.113.124-.529.524a.9.9 0 01-1.348-1.186l.08-.092.526-.522.008-.012.023-.058.36-1.82a.9.9 0 011.431-.54z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24PinSlashOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24PinSlashOutline: FC<Icon24PinSlashOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24PinSlashOutline as any).mountIcon = mountIcon;

export default Icon24PinSlashOutline;
