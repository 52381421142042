import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'help_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="help_24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" /><path d="M2 12c0 5.52 4.48 10 10 10s10-4.48 10-10S17.52 2 12 2 2 6.48 2 12zm8.75 6.25v-.5a1 1 0 011-1h.5a1 1 0 011 1v.5a1 1 0 01-1 1h-.5a1 1 0 01-1-1zm3.42-6.08c-.535.543-.921 1.014-1.084 1.836a2.29 2.29 0 00-.022.13 1 1 0 01-.99.864H12a.958.958 0 01-.954-1.039 2.27 2.27 0 01.027-.221 4.05 4.05 0 011.097-2.07l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2-.699 0-1.317.363-1.675.91a1.59 1.59 0 00-.172.39A1 1 0 019.2 9H9a.854.854 0 01-.833-1.043c.06-.266.13-.482.206-.647A4 4 0 0112 5c2.21 0 4 1.79 4 4 0 .88-.36 1.68-.93 2.25l-.9.92z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24HelpProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Help: FC<Icon24HelpProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Help as any).mountIcon = mountIcon;

export default Icon24Help;
