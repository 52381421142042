import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'send_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="send_outline_24"><path clip-rule="evenodd" d="M4.891 9.77c-.562-1.438-.959-2.555-1.135-3.273l-.076-.305c-.077-.31-.154-.618-.204-.862a4.4 4.4 0 01-.086-.6 2.303 2.303 0 01.154-1.018 1.958 1.958 0 011.363-1.17 2.249 2.249 0 011.123.035c.413.114.857.343 1.22.537 1.04.557 5.078 2.674 8.151 4.284a3125.741 3125.741 0 013.493 1.832c.498.262.962.505 1.298.716.193.12.453.296.682.528.22.223.61.701.639 1.42.03.746-.346 1.265-.584 1.521a3.437 3.437 0 01-.687.552c-.312.197-.743.424-1.19.659l-.256.135-2.414 1.279c-3.115 1.65-7.907 4.19-9.153 4.837-.391.202-.847.43-1.254.544a2.241 2.241 0 01-1.152.031 1.943 1.943 0 01-1.38-1.299 2.34 2.34 0 01-.08-.991c.024-.206.069-.407.107-.566.037-.154.082-.326.126-.492l.009-.034c.048-.183.099-.376.151-.588.174-.707.564-1.814 1.118-3.242a4.894 4.894 0 011.771-2.245 4.893 4.893 0 01-1.754-2.224zm4.194 1.313l.372.073.32.064.3.06c.93.187 1.403.296 1.506.466a.5.5 0 01-.001.523c-.105.169-.579.274-1.509.456l-.3.058-.321.062a164.061 164.061 0 01-.499.095c-1.099.202-2.01.942-2.4 1.951-.49 1.261-.82 2.192-.989 2.793a7.151 7.151 0 00-.06.228l-.003.013c-.058.233-.113.444-.163.631-.333 1.264-.389 1.475 1.062.723a130.944 130.944 0 00.707-.37c1.825-.958 5.734-3.03 8.417-4.452l2.431-1.288.327-.172.016-.008c.935-.493 1.414-.75 1.416-1.013.003-.26-.462-.523-1.411-1.023l-.017-.009-.457-.24c-.604-.318-1.818-.954-3.242-1.7a3228.922 3228.922 0 01-7.674-4.03L6.4 4.7c-1.428-.765-1.361-.5-.983 1.017l.087.35.003.013a6.95 6.95 0 00.057.214c.172.608.506 1.548 1.003 2.82.393 1.006 1.301 1.742 2.397 1.944l.121.023z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24SendOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24SendOutline: FC<Icon24SendOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24SendOutline as any).mountIcon = mountIcon;

export default Icon24SendOutline;
