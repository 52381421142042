import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'phone_arrow_down_left_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="phone_arrow_down_left_24"><g fill="currentColor"><path d="M14.112 15.259c1.354-1.354 3.486-1.48 4.903-.287l1.024.863c1.17.983 1.288 2.757.264 3.96a3.318 3.318 0 01-2.195 1.133c-3.273.44-6.707-1.137-10.303-4.733C4.21 12.6 2.631 9.165 3.072 5.892a3.25 3.25 0 01.159-.752c.19-.56.527-1.058.974-1.443 1.204-1.024 2.976-.906 3.96.264l.862 1.024c1.194 1.416 1.07 3.55-.284 4.903l-.736.735a.712.712 0 00-.164.749c.267.73.95 1.644 2.046 2.74 1.096 1.097 2.01 1.778 2.74 2.047.26.095.553.03.749-.166zM17 3a1 1 0 10-2 0v5.657a1 1 0 001 1h5.657a1 1 0 000-2h-3.243l3.95-3.95a1 1 0 000-1.414l-.087-.078a1 1 0 00-1.327.078L17 6.243z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24PhoneArrowDownLeftProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24PhoneArrowDownLeft: FC<Icon24PhoneArrowDownLeftProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24PhoneArrowDownLeft as any).mountIcon = mountIcon;

export default Icon24PhoneArrowDownLeft;
