import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'printer_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="printer_24"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.6 2h8.8c.56 0 .84 0 1.054.109a1 1 0 01.437.437C18 2.76 18 3.04 18 3.6v.402C17.766 4 17.523 4 17.272 4H6.728c-.251 0-.494 0-.728.002V3.6c0-.56 0-.84.109-1.054a1 1 0 01.437-.437C6.76 2 7.04 2 7.6 2zM2 10.8c0-1.68 0-2.52.327-3.162a3 3 0 011.311-1.311C4.28 6 5.12 6 6.8 6h10.4c1.68 0 2.52 0 3.162.327a3 3 0 011.311 1.311C22 8.28 22 9.12 22 10.8v2.4c0 1.68 0 2.52-.327 3.162a3 3 0 01-1.311 1.311 2.19 2.19 0 01-.362.144V15a2 2 0 00-2-2H6a2 2 0 00-2 2v2.817a2.19 2.19 0 01-.362-.144 3 3 0 01-1.311-1.311C2 15.72 2 14.88 2 13.2v-2.4zm4 7.198V15h12v5.4c0 .56 0 .84-.109 1.054a1 1 0 01-.437.437C17.24 22 16.96 22 16.4 22H7.6c-.56 0-.84 0-1.054-.109a1 1 0 01-.437-.437C6 21.24 6 20.96 6 20.4v-2.402zM18.85 10.3a1.15 1.15 0 100-2.3 1.15 1.15 0 000 2.3z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24PrinterProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Printer: FC<Icon24PrinterProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Printer as any).mountIcon = mountIcon;

export default Icon24Printer;
