import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'replay_36';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="replay_36"><g fill="none" fill-rule="evenodd"><path d="M0 0h36v36H0z" /><path d="M16.976 3.524l-5.769 5.769a1 1 0 000 1.414l5.769 5.769A.6.6 0 0018 16.05V11c4.965 0 9.5 4.535 9.5 9.5S22.965 30 18 30c-4.273 0-8.228-3.36-9.247-7.46a7.558 7.558 0 01-.152-.895A1.3 1.3 0 007.31 20.5H7.25a1.165 1.165 0 00-1.16 1.274c.048.508.105.917.17 1.227C7.41 28.43 12.229 32.5 18 32.5c6.63 0 12-5.37 12-12s-5.37-12-12-12V3.949a.6.6 0 00-1.024-.425z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36ReplayProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36Replay: FC<Icon36ReplayProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36Replay as any).mountIcon = mountIcon;

export default Icon36Replay;
