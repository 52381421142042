import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'sart_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="sart_outline_24"><g fill="currentColor"><path clip-rule="evenodd" d="M4.377 4.924c-.052-.015-.138-.024-.685-.024H2.9a.9.9 0 110-1.8h.885c.392 0 .762-.001 1.097.096a2.4 2.4 0 011.504 1.296c.087.187.141.392.183.608h11.934c.336 0 .627 0 .865.014.247.015.51.048.768.148a2.4 2.4 0 011.523 2.01c.026.276-.013.538-.066.78-.05.233-.13.513-.22.836l-.932 3.314c-.01.031-.018.062-.026.093-.13.464-.24.851-.433 1.18a2.9 2.9 0 01-1.753 1.328c-.37.098-.771.098-1.253.097H9.3c-.54 0-.99.001-1.392-.117a2.9 2.9 0 01-1.819-1.565c-.175-.38-.242-.826-.321-1.36l-.017-.11-.874-5.826c-.081-.542-.103-.625-.125-.674a.6.6 0 00-.376-.324zM6.845 6.9l.687 4.582c.106.702.14.866.192.98a1.1 1.1 0 00.69.594c.12.035.287.044.997.044h7.468c.63 0 .777-.007.888-.037a1.1 1.1 0 00.665-.504c.059-.098.105-.237.276-.845l.925-3.29c.1-.354.164-.581.202-.756a.899.899 0 00.032-.225.6.6 0 00-.38-.503.898.898 0 00-.226-.029 14.84 14.84 0 00-.783-.011z" fill-rule="evenodd" /><path d="M7 18.25a1.75 1.75 0 113.5 0 1.75 1.75 0 01-3.5 0zM15 18.25a1.75 1.75 0 113.5 0 1.75 1.75 0 01-3.5 0z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24SartOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24SartOutline: FC<Icon24SartOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24SartOutline as any).mountIcon = mountIcon;

export default Icon24SartOutline;
