import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'share_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="share_16"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.944 2.5a.944.944 0 00-.943.953v2.08c-2.792.38-4.898 2.294-5.464 5.351-.114.616-.207 1.916-.207 1.916l1.466-1.269a6.707 6.707 0 013.886-1.558l.22-.01h.099v2.485c0 .229.084.424.185.563l.007.01.006.008a.945.945 0 001.348.158l5.548-4.517.005-.005a.968.968 0 00-.032-1.456l-5.54-4.502-.006-.004a.946.946 0 00-.578-.203z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16ShareProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16Share: FC<Icon16ShareProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16Share as any).mountIcon = mountIcon;

export default Icon16Share;
