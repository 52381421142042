import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'gesture_play_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="gesture_play_outline_20"><path clip-rule="evenodd" d="M10.761 3.301a.936.936 0 011.861.204l-.37 4.433a.75.75 0 00.508.773h.002l.02.008.094.037a4.988 4.988 0 011.442.925.75.75 0 001.021-1.1 6.489 6.489 0 00-1.547-1.056l.325-3.895a2.436 2.436 0 00-4.842-.532l-.09.656-.021-.065a2.463 2.463 0 00-4.788.984l.346 4.486c-.754.666-1.92 2.173-1.457 4.488.542 2.71 3.179 5.085 7.226 4.855l.035-.002.014-.001h.005a.75.75 0 00-.09-1.498h-.006l-.013.001-.03.002c-3.369.192-5.288-1.738-5.67-3.651-.381-1.902.81-2.982 1.18-3.229a2.949 2.949 0 012.325-.418c.01.002.027.008.067.022.568.207.836.7.824 1.18-.01.47-.28.883-.836 1.003A3.44 3.44 0 017.75 12a.75.75 0 000 1.5c.24 0 .556-.057.863-.123 1.31-.284 1.993-1.355 2.019-2.433a2.71 2.71 0 00-2.003-2.687 4.45 4.45 0 00-2.465.09l-.292-3.79a.963.963 0 011.872-.384l.718 2.108.007.02c.042.122.085.25.127.349.023.053.058.13.11.208a.858.858 0 00.349.307.9.9 0 00.914-.088.858.858 0 00.284-.37c.035-.085.055-.168.067-.224.022-.105.04-.239.057-.367l.003-.021zm5.549 14.694l1.38-.797.04-.023c.22-.127.466-.269.662-.408.202-.142.561-.42.772-.894a2.15 2.15 0 000-1.75c-.21-.473-.57-.751-.772-.894-.196-.139-.442-.28-.662-.408l-.04-.023-1.38-.796-.04-.023c-.22-.128-.466-.27-.684-.37-.224-.104-.645-.276-1.16-.221a2.15 2.15 0 00-1.515.874c-.305.42-.367.87-.39 1.116-.021.24-.021.523-.021.778v1.685c0 .254 0 .538.022.777.022.246.084.696.389 1.116.358.493.908.81 1.514.874.516.055.937-.117 1.161-.22.218-.101.464-.243.684-.37zm.63-3.898c.512.296.768.444.854.637a.65.65 0 010 .528c-.086.193-.342.341-.854.637l-1.38.797c-.512.295-.768.443-.978.42a.65.65 0 01-.458-.264C14 16.682 14 16.386 14 15.795V14.2c0-.59 0-.886.124-1.057a.65.65 0 01.458-.265c.21-.022.466.126.978.422z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20GesturePlayOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20GesturePlayOutline: FC<Icon20GesturePlayOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20GesturePlayOutline as any).mountIcon = mountIcon;

export default Icon20GesturePlayOutline;
