import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'view_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="view_outline_24"><path d="M12 4.6c5.2 0 10.4 4.237 10.4 7.4 0 3.163-5.2 7.4-10.4 7.4S1.6 15.163 1.6 12c0-3.163 5.2-7.4 10.4-7.4zm0 1.8c-4.278 0-8.6 3.522-8.6 5.6s4.322 5.6 8.6 5.6 8.6-3.522 8.6-5.6-4.322-5.6-8.6-5.6zm0 2.2a3.4 3.4 0 110 6.8 3.4 3.4 0 010-6.8zm0 1.8a1.6 1.6 0 100 3.2 1.6 1.6 0 000-3.2z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24ViewOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24ViewOutline: FC<Icon24ViewOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24ViewOutline as any).mountIcon = mountIcon;

export default Icon24ViewOutline;
