import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'bomb_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="bomb_outline_20"><path d="M12.537 6.405L13.72 5.22a.75.75 0 011.129.983l-.067.077-1.183 1.186a6.501 6.501 0 11-1.06-1.06zm-7.573 1.56a5 5 0 107.071 7.07 5 5 0 00-7.07-7.07zM16.89 5.013l.098.026 1.5.5a.75.75 0 01-.375 1.448l-.1-.025-1.5-.5a.75.75 0 01.377-1.449zm.876-2.806a.75.75 0 01.09.982l-.064.078-1.048 1.1a.75.75 0 01-1.151-.956l.065-.078 1.047-1.1a.75.75 0 011.06-.026zm-3.342-.788l.039.094.5 1.5a.75.75 0 01-1.385.569l-.038-.095-.5-1.5a.75.75 0 011.384-.568z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20BombOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20BombOutline: FC<Icon20BombOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20BombOutline as any).mountIcon = mountIcon;

export default Icon20BombOutline;
