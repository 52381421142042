import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'list_circle_fill_gray_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="list_circle_fill_gray_28"><linearGradient id="list_circle_fill_gray_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#b1b6bd" /><stop offset="1" stop-color="#99a2ad" /></linearGradient><rect fill="url(#list_circle_fill_gray_28_a)" height="28" rx="14" width="28" /><path d="M16 18a1 1 0 110 2H8a1 1 0 110-2zm4-5a1 1 0 110 2H8a1 1 0 110-2zm0-5a1 1 0 110 2H8a1 1 0 010-2z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28ListCircleFillGrayProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28ListCircleFillGray: FC<Icon28ListCircleFillGrayProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28ListCircleFillGray as any).mountIcon = mountIcon;

export default Icon28ListCircleFillGray;
