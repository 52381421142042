import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'folder_32';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="folder_32"><path d="M3 11.6c0-2.31 0-3.465.425-4.348A4.02 4.02 0 015.13 5.45C5.964 5 7.056 5 9.24 5h3.298c.318 0 .477 0 .627.038.133.034.26.09.376.165.131.085.243.204.468.441L16 8h6.76c2.184 0 3.276 0 4.11.45a4.02 4.02 0 011.705 1.802C29 11.135 29 12.29 29 14.6v5.8c0 2.31 0 3.465-.425 4.348a4.02 4.02 0 01-1.704 1.802c-.835.45-1.927.45-4.111.45H9.24c-2.184 0-3.276 0-4.11-.45a4.02 4.02 0 01-1.705-1.802C3 23.865 3 22.71 3 20.4v-8.8z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32FolderProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32Folder: FC<Icon32FolderProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32Folder as any).mountIcon = mountIcon;

export default Icon32Folder;
