import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'unfavorite_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="unfavorite_16"><path d="M11.953 9.138l1.643 1.643a.75.75 0 01-1.06 1.061L4.404 3.71a.75.75 0 011.06-1.06l1.147 1.147.473-1.097c.402-.933 1.432-.933 1.834 0l1.284 2.98 2.915.281c1.015.098 1.326 1.095.543 1.75l-1.707 1.427zm.324 3.945L4.955 5.761l-2.07.2c-1.019.098-1.329 1.092-.542 1.75l2.283 1.91-.848 3.11c-.278 1.02.589 1.637 1.45 1.024l2.773-1.971 2.774 1.971c.76.54 1.518.129 1.502-.672z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16UnfavoriteProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16Unfavorite: FC<Icon16UnfavoriteProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16Unfavorite as any).mountIcon = mountIcon;

export default Icon16Unfavorite;
