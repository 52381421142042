import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 48 48';
const id = 'services_48';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" id="services_48"><path d="M29 42h10a3 3 0 003-3V29a3 3 0 00-3-3H29a3 3 0 00-3 3v10a3 3 0 003 3zm-7-3V29a3 3 0 00-3-3H9a3 3 0 00-3 3v10a3 3 0 003 3h10a3 3 0 003-3zM9 22h10a3 3 0 003-3V9a3 3 0 00-3-3H9a3 3 0 00-3 3v10a3 3 0 003 3zm15.121-5.199L31.2 23.88a3 3 0 004.242 0L42.52 16.8a2.996 2.996 0 00.002-4.24c-.001-.001-.002-.002-.004 0l-7.078-7.065a3 3 0 00-4.238 0l-7.078 7.065a2.996 2.996 0 00-.005 4.24s0 .002.003 0z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon48ServicesProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon48Services: FC<Icon48ServicesProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 48,
    height: !isNaN(props.height) ? +props.height : 48,
  }));
};

(Icon48Services as any).mountIcon = mountIcon;

export default Icon48Services;
