import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'statistic_circle_fill_blue_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="statistic_circle_fill_blue_20"><linearGradient id="statistic_circle_fill_blue_20_a" gradientUnits="userSpaceOnUse" x1="-10" x2="10" y1="10" y2="30"><stop offset="0" stop-color="#70b2ff" /><stop offset="1" stop-color="#5c9ce6" /></linearGradient><path d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z" fill="url(#statistic_circle_fill_blue_20_a)" /><path clip-rule="evenodd" d="M14 5a1 1 0 011 1v8a1 1 0 11-2 0V6a1 1 0 011-1zm-8 5a1 1 0 011 1v3a1 1 0 11-2 0v-3a1 1 0 011-1zm4-2.5a1 1 0 011 1V14a1 1 0 11-2 0V8.5a1 1 0 011-1z" fill="#fff" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20StatisticCircleFillBlueProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20StatisticCircleFillBlue: FC<Icon20StatisticCircleFillBlueProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20StatisticCircleFillBlue as any).mountIcon = mountIcon;

export default Icon20StatisticCircleFillBlue;
