import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'face_id_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="face_id_outline_28"><path clip-rule="evenodd" d="M19 5a1 1 0 11.032-2c.681.002 1.26.011 1.748.051.646.053 1.224.165 1.763.44a4.5 4.5 0 011.966 1.966c.275.539.387 1.117.44 1.763A23.213 23.213 0 0125 9a1 1 0 11-2 0 21.622 21.622 0 00-.044-1.617c-.043-.522-.122-.808-.229-1.018a2.5 2.5 0 00-1.092-1.093c-.21-.106-.496-.185-1.018-.228C20.198 5.01 19.684 5.002 19 5zM5 9c.002-.684.01-1.198.044-1.617.043-.522.122-.808.228-1.018a2.5 2.5 0 011.093-1.093c.21-.106.496-.185 1.018-.228.419-.034.933-.042 1.617-.043a1 1 0 10-.032-2c-.681.001-1.26.01-1.748.05-.646.053-1.224.165-1.763.44A4.5 4.5 0 003.49 5.456c-.274.539-.386 1.117-.439 1.763A23.222 23.222 0 003 9a1 1 0 002 0zM3 19.032c.002.681.011 1.26.051 1.748.053.646.165 1.224.44 1.763a4.5 4.5 0 001.966 1.967c.539.274 1.117.386 1.763.439A23.21 23.21 0 009 25a1 1 0 100-2 21.622 21.622 0 01-1.617-.044c-.522-.043-.808-.121-1.018-.228a2.5 2.5 0 01-1.093-1.093c-.106-.21-.185-.496-.228-1.018A21.7 21.7 0 015.001 19a1 1 0 10-2 .032zM23 19a1 1 0 112 .032c-.002.681-.011 1.26-.051 1.748-.053.646-.165 1.224-.44 1.763a4.5 4.5 0 01-1.966 1.967c-.539.274-1.117.386-1.763.439A23.21 23.21 0 0119 25a1 1 0 110-2c.684-.002 1.198-.01 1.617-.044.522-.043.808-.121 1.018-.228a2.5 2.5 0 001.093-1.093c.107-.21.185-.496.228-1.018.034-.419.042-.933.044-1.617zm-5.097-.6a1 1 0 01-.474 1.333 7.973 7.973 0 01-3.429.77 7.974 7.974 0 01-3.43-.77 1 1 0 01.86-1.806c.777.37 1.648.577 2.57.577.922 0 1.793-.208 2.57-.577a1 1 0 011.333.474zM18.5 9a1 1 0 00-1 1v1a1 1 0 102 0v-1a1 1 0 00-1-1zm-10 1a1 1 0 012 0v1a1 1 0 11-2 0zm6.5 0a1 1 0 10-2 0v3.5a.5.5 0 01-.5.5 1 1 0 100 2 2.5 2.5 0 002.5-2.5z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28FaceIdOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28FaceIdOutline: FC<Icon28FaceIdOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28FaceIdOutline as any).mountIcon = mountIcon;

export default Icon28FaceIdOutline;
