import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'gift_outline_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="gift_outline_36"><path clip-rule="evenodd" d="M12.008 8.823c.655.925 2.092 1.916 4.733 2.164-.248-2.641-1.239-4.079-2.164-4.734-1.1-.78-2.154-.563-2.643-.073-.49.49-.706 1.543.074 2.643zm-1.842-4.411c1.51-1.51 3.957-1.544 5.856-.199.768.544 1.443 1.298 1.978 2.261.535-.963 1.21-1.717 1.978-2.26 1.899-1.346 4.346-1.312 5.856.198 1.51 1.51 1.544 3.957.199 5.856-.18.255-.384.5-.611.732h1.328c.984 0 1.92.22 2.733.675a4.764 4.764 0 011.842 1.842c.454.813.675 1.749.675 2.733v.521c0 1.101-.123 1.913-.534 2.683a3.65 3.65 0 01-1.47 1.488v.657c0 3.64 0 5.459-.707 6.849a6.498 6.498 0 01-2.84 2.84c-1.39.708-3.21.708-6.85.708h-3.205c-3.64 0-5.459 0-6.849-.708a6.498 6.498 0 01-2.84-2.84c-.708-1.39-.708-3.21-.708-6.85v-.66a3.65 3.65 0 01-1.463-1.484C4.123 18.684 4 17.872 4 16.77v-.521c0-.984.22-1.92.675-2.733a4.763 4.763 0 011.842-1.842C7.33 11.221 8.266 11 9.25 11h1.328a6.098 6.098 0 01-.61-.732c-1.346-1.899-1.312-4.346.198-5.856zm13.826 4.411c-.655.925-2.092 1.916-4.733 2.164.248-2.641 1.239-4.079 2.164-4.734 1.1-.78 2.153-.563 2.643-.073.49.49.706 1.543-.074 2.643zM9.25 13.5h7.494V19H8.626c-.842-.008-1.163-.111-1.4-.239a1.153 1.153 0 01-.487-.486c-.133-.248-.239-.584-.239-1.503v-.522c0-.611.136-1.117.357-1.513a2.27 2.27 0 01.88-.88c.396-.22.902-.357 1.513-.357zm9.994 0V19h8.13c.843-.008 1.163-.111 1.4-.239.214-.114.373-.273.487-.486.132-.248.239-.584.239-1.503v-.522c0-.611-.136-1.117-.357-1.513a2.262 2.262 0 00-.88-.88c-.396-.22-.902-.357-1.513-.357zm-10.515 8h8.015v7.996h-.35c-1.86 0-3.106-.002-4.064-.08-.93-.076-1.366-.211-1.65-.356a3.998 3.998 0 01-1.747-1.747c-.145-.284-.28-.72-.356-1.65-.078-.958-.08-2.203-.08-4.064v-.1h.117l.115.001zm10.515 0v7.996h.356c1.86 0 3.106-.002 4.064-.08.93-.076 1.366-.211 1.65-.356a3.999 3.999 0 001.747-1.747c.145-.284.28-.72.356-1.65.078-.958.08-2.203.08-4.064v-.1h-.111l-.115.001z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36GiftOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36GiftOutline: FC<Icon36GiftOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36GiftOutline as any).mountIcon = mountIcon;

export default Icon36GiftOutline;
