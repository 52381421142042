import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'bus_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="bus_outline_28"><path clip-rule="evenodd" d="M13 2h-.044c-1.363 0-2.447 0-3.321.071-.896.074-1.66.227-2.359.583a6 6 0 00-2.622 2.622c-.356.7-.51 1.463-.583 2.359C4 8.509 4 9.593 4 10.956V12h-.532a1 1 0 00-.967.968l-.001.072v.314c0 .932.67 1.66 1.5 1.826V16.643c0 1.084 0 1.958.058 2.666.06.728.185 1.369.487 1.961A5 5 0 006 23v1c0 .559 0 .838.079 1.062a1.4 1.4 0 00.859.86C7.162 26 7.44 26 8 26s.838 0 1.062-.079a1.4 1.4 0 00.86-.859C10 24.838 10 24.56 10 24v-.006c.411.006.862.006 1.356.006h5.287c.495 0 .946 0 1.357-.006V24c0 .559 0 .838.079 1.062a1.4 1.4 0 00.859.86C19.162 26 19.44 26 20 26s.838 0 1.062-.079a1.4 1.4 0 00.86-.859C22 24.838 22 24.56 22 24v-1a5 5 0 001.455-1.73c.302-.592.428-1.233.487-1.961C24 18.6 24 17.727 24 16.643V15.18a1.86 1.86 0 001.5-1.826v-.314-.072a1 1 0 00-.968-.968H24v-1.044c0-1.363 0-2.447-.071-3.321-.074-.896-.227-1.66-.583-2.359a6 6 0 00-2.622-2.622c-.7-.356-1.463-.51-2.359-.583C17.491 2 16.407 2 15.044 2H15zm9 10v-1c0-1.417 0-2.419-.065-3.203-.063-.771-.182-1.243-.371-1.613a4 4 0 00-1.748-1.748c-.37-.189-.842-.308-1.613-.371C17.419 4 16.417 4 15 4h-2c-1.417 0-2.419 0-3.203.065-.771.063-1.243.182-1.613.371a4 4 0 00-1.748 1.748c-.189.37-.308.842-.371 1.613C6 8.581 6 9.583 6 11v3h16zM6 16v.6c0 1.137 0 1.929.051 2.546.05.605.142.953.276 1.216a3 3 0 001.311 1.311c.263.134.611.226 1.216.276.617.05 1.41.051 2.546.051h5.2c1.137 0 1.929 0 2.546-.051.605-.05.953-.142 1.216-.276a3 3 0 001.311-1.311c.134-.263.226-.611.276-1.216.05-.617.051-1.41.051-2.546V16zm2 3a1 1 0 011-1h1a1 1 0 110 2H9a1 1 0 01-1-1zm11-1a1 1 0 110 2h-1a1 1 0 110-2z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28BusOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28BusOutline: FC<Icon28BusOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28BusOutline as any).mountIcon = mountIcon;

export default Icon28BusOutline;
