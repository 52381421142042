import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'game_circle_fill_blue_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="game_circle_fill_blue_20"><linearGradient id="game_circle_fill_blue_20_a" gradientUnits="userSpaceOnUse" x1="-10" x2="10" y1="10" y2="30"><stop offset="0" stop-color="#70b2ff" /><stop offset="1" stop-color="#5c9ce6" /></linearGradient><path d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z" fill="url(#game_circle_fill_blue_20_a)" /><path d="M12.5 6c3 0 3.5 4 3.5 5.7 0 1.3-.5 2.3-1.3 2.3-1.2 0-1.2-2.5-3.2-2.5h-3c-2 0-2 2.5-3.2 2.5-.8 0-1.3-1-1.3-2.3C4 10 4.5 6 7.5 6zM7.497 7.5a.559.559 0 00-.267.06.409.409 0 00-.17.17.559.559 0 00-.06.267V8.5h-.503c-.07 0-.133.01-.188.027l-.079.033a.409.409 0 00-.17.17.567.567 0 00-.06.273c0 .105.02.193.06.267.04.073.097.13.17.17.074.04.162.06.267.06H7v.503c0 .07.01.132.027.188l.033.079c.04.073.097.13.17.17s.162.06.273.06c.105 0 .193-.02.267-.06s.13-.097.17-.17a.559.559 0 00.06-.267V9.5h.503c.07 0 .133-.01.188-.027l.079-.033a.409.409 0 00.17-.17.567.567 0 00.06-.273.558.558 0 00-.06-.267.409.409 0 00-.17-.17.558.558 0 00-.267-.06H8v-.503c0-.07-.01-.133-.027-.188L7.94 7.73a.409.409 0 00-.17-.17.567.567 0 00-.273-.06zm6.006 1h-1.006a.558.558 0 00-.267.06c-.073.04-.13.097-.17.17s-.06.162-.06.273c0 .105.02.193.06.267s.097.13.17.17c.073.04.162.06.267.06h1.006c.105 0 .193-.02.267-.06.073-.04.13-.097.17-.17s.06-.162.06-.273c0-.105-.02-.193-.06-.267s-.097-.13-.17-.17a.558.558 0 00-.267-.06z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20GameCircleFillBlueProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20GameCircleFillBlue: FC<Icon20GameCircleFillBlueProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20GameCircleFillBlue as any).mountIcon = mountIcon;

export default Icon20GameCircleFillBlue;
