import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'phone_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="phone_20"><path d="M11.76 12.716c1.128-1.129 2.905-1.233 4.086-.24l.854.72c.974.82 1.073 2.297.22 3.3a2.765 2.765 0 01-1.83.944c-2.728.367-5.589-.947-8.586-3.944-2.996-2.997-4.311-5.86-3.944-8.586a2.8 2.8 0 01.132-.627c.16-.466.44-.881.813-1.203 1.003-.852 2.48-.754 3.3.22l.717.855c.995 1.18.892 2.957-.237 4.085l-.612.612a.594.594 0 00-.137.624c.223.61.792 1.37 1.705 2.284s1.675 1.482 2.283 1.705c.217.08.46.026.624-.137z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20PhoneProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20Phone: FC<Icon20PhoneProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20Phone as any).mountIcon = mountIcon;

export default Icon20Phone;
