import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'song_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="song_24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" /><path d="M13 11.485v5.648c0 4.513-.875 5.384-4.374 5.842C6.957 23.195 5 22.437 5 19.814c0-1.282.802-2.498 2.461-2.816 1.267-.243-.09.018 2.776-.515.696-.129.775-.378.775-.908l-.002-4.194h-.008V7.063s-.005-2.058 0-3.088c.007-1.23.68-1.683 2.664-2.084 0 0 3.023-.573 4.685-.875.368-.067.649.07.649.492 0 0-.01 2.622 0 4.054.003.416-.168.605-.59.68l-4.865.86c-.374.092-.545.355-.545.733v3.65z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24SongProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Song: FC<Icon24SongProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Song as any).mountIcon = mountIcon;

export default Icon24Song;
