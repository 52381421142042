import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'phone_circle_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="phone_circle_16"><path clip-rule="evenodd" d="M16 8A8 8 0 100 8a8 8 0 0016 0zm-4.882 1.32c-.63-.529-1.578-.473-2.18.128l-.326.327a.317.317 0 01-.333.073c-.324-.12-.73-.422-1.217-.91-.487-.486-.79-.892-.91-1.217a.317.317 0 01.074-.333l.326-.326c.602-.602.657-1.55.127-2.18l-.383-.455c-.438-.52-1.225-.572-1.76-.117a1.475 1.475 0 00-.504.975c-.196 1.455.506 2.981 2.104 4.58 1.598 1.598 3.124 2.299 4.579 2.103.377-.036.727-.217.975-.504.455-.534.403-1.323-.117-1.76z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16PhoneCircleProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16PhoneCircle: FC<Icon16PhoneCircleProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16PhoneCircle as any).mountIcon = mountIcon;

export default Icon16PhoneCircle;
