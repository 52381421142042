import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'health_outline_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="health_outline_36"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M7.393 7.393C10.106 4.68 13.858 3 18 3s7.894 1.68 10.607 4.393S33 13.858 33 18s-1.68 7.894-4.393 10.607S22.142 33 18 33s-7.894-1.68-10.607-4.393S3 22.142 3 18s1.68-7.894 4.393-10.607zM18 5.5c-3.452 0-6.575 1.398-8.839 3.661S5.5 14.548 5.5 18s1.398 6.575 3.661 8.839C11.425 29.102 14.548 30.5 18 30.5s6.575-1.398 8.839-3.661C29.102 24.575 30.5 21.452 30.5 18s-1.398-6.575-3.661-8.839A12.457 12.457 0 0018 5.5z" /><path d="M9.75 16.356c0-2.75 2.244-4.956 4.969-4.956 1.239 0 2.334.432 3.275 1.203.938-.774 2.038-1.203 3.287-1.203 2.724 0 4.969 2.207 4.969 4.956 0 1.432-.387 2.564-1.306 3.706-.844 1.05-2.151 2.118-3.91 3.5l-.004.003-1.818 1.418a1.97 1.97 0 01-2.424 0l-1.818-1.418-.004-.003c-1.759-1.382-3.066-2.45-3.91-3.5-.92-1.142-1.306-2.274-1.306-3.706zm4.969-2.456a2.467 2.467 0 00-2.469 2.456c0 .87.197 1.447.754 2.14.631.784 1.693 1.676 3.505 3.1h.001L18 22.759l1.49-1.161.002-.002c1.811-1.423 2.873-2.315 3.504-3.1.557-.692.754-1.27.754-2.14a2.467 2.467 0 00-2.469-2.455c-.819 0-1.575.348-2.306 1.26a1.25 1.25 0 01-1.942.009c-.757-.924-1.519-1.269-2.314-1.269z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36HealthOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36HealthOutline: FC<Icon36HealthOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36HealthOutline as any).mountIcon = mountIcon;

export default Icon36HealthOutline;
