import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'user_circle_fill_blue_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="user_circle_fill_blue_28"><linearGradient id="user_circle_fill_blue_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#70b2ff" /><stop offset="1" stop-color="#5c9ce6" /></linearGradient><rect fill="url(#user_circle_fill_blue_28_a)" height="28" rx="14" width="28" /><path d="M14 15c2.75 0 5.5 1 5.5 3.5v.245c0 .413-.046.58-.134.743a.908.908 0 01-.378.378c-.163.088-.33.134-.743.134h-8.49c-.413 0-.58-.046-.743-.134a.908.908 0 01-.378-.378c-.088-.163-.134-.33-.134-.743V18.5C8.5 16 11.25 15 14 15zm0-8a3 3 0 110 6 3 3 0 010-6z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28UserCircleFillBlueProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28UserCircleFillBlue: FC<Icon28UserCircleFillBlueProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28UserCircleFillBlue as any).mountIcon = mountIcon;

export default Icon28UserCircleFillBlue;
