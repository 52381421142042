import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'calendar_outline_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="calendar_outline_36"><g fill="currentColor"><path d="M22.009 24.054c-.009.098-.009.214-.009.446s0 .348.009.446a2.25 2.25 0 002.045 2.045c.098.009.214.009.446.009s.348 0 .446-.009a2.25 2.25 0 002.045-2.045c.009-.098.009-.214.009-.446s0-.348-.009-.446a2.25 2.25 0 00-2.045-2.045C24.848 22 24.732 22 24.5 22s-.348 0-.446.008a2.25 2.25 0 00-2.045 2.046z" /><path clip-rule="evenodd" d="M10 2.58a1.25 1.25 0 112.5 0v1.458c.42-.02.883-.033 1.395-.037L21.745 4c.657 0 1.238.013 1.755.038V2.58a1.25 1.25 0 112.5 0v1.762c.763.176 1.334.42 1.907.727a7.27 7.27 0 013.025 3.024c.608 1.137.968 2.267 1.05 4.907H32v2.5h-.001L32 21.744c0 3.566-.371 4.86-1.069 6.163a7.27 7.27 0 01-3.024 3.024l-.27.14c-1.223.607-2.572.929-5.893.929h-7.488l-.707-.005c-3.038-.048-4.243-.414-5.456-1.063a7.27 7.27 0 01-3.024-3.025l-.14-.27C4.322 26.415 4 25.065 4 21.744V13h.018c.082-2.64.442-3.77 1.05-4.907a7.27 7.27 0 013.025-3.024l.27-.14A7.2 7.2 0 0110 4.342zm13.5 3.956V8.08a1.25 1.25 0 102.5 0V6.945c.255.092.49.201.728.328a4.77 4.77 0 011.999 1.999c.48.897.699 1.773.757 3.728H6.516c.053-1.775.238-2.661.632-3.481l.125-.247a4.77 4.77 0 011.999-1.999c.237-.127.473-.236.728-.328V8.08a1.25 1.25 0 102.5 0V6.536c.5-.025 1.076-.036 1.756-.036h7.812c.542.004 1.014.015 1.432.036zm-17 15.208V15.5h23v6.244l-.004.632c-.03 2.402-.24 3.365-.77 4.352a4.77 4.77 0 01-1.998 1.999c-1.07.572-2.11.773-4.984.773h-7.812c-2.63-.016-3.632-.223-4.66-.773a4.77 4.77 0 01-1.999-1.999c-.572-1.07-.773-2.11-.773-4.984z" fill-rule="evenodd" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36CalendarOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36CalendarOutline: FC<Icon36CalendarOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36CalendarOutline as any).mountIcon = mountIcon;

export default Icon36CalendarOutline;
