import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'discussions_32';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="discussions_32"><g fill="none" fill-rule="evenodd"><path d="M0 0h32v32H0z" /><path d="M24.998 8.67v7.317c0 2.22-1.8 4.02-4.02 4.02H8.998v1.99c0 1.11.9 2.01 2.01 2.01h12.656l4.19 4.19a.67.67 0 001.144-.475V10.68c0-1.11-.9-2.01-2.01-2.01h-1.99zM20.32 4H5.01C3.9 4 3 4.9 3 6.01v15.709a.67.67 0 001.144.474l4.19-4.19H20.99c1.11 0 2.01-.9 2.01-2.01V6.68C23 5.2 21.8 4 20.32 4z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32DiscussionsProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32Discussions: FC<Icon32DiscussionsProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32Discussions as any).mountIcon = mountIcon;

export default Icon32Discussions;
