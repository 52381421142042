import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'money_circle_fill_blue_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="money_circle_fill_blue_56"><circle cx="28" cy="28" r="28" fill="url(#money_circle_fill_blue_56_paint0_linear)" /><path fill-rule="evenodd" clip-rule="evenodd" d="M29.5 16c.166.005.332 0 .5 0a8 8 0 01.4 15.99L30 32h-4.5v2.5h3.811c.388 0 .677.07.922.2.244.131.436.323.567.567.13.245.2.534.2.922v.122c0 .388-.07.677-.2.922a1.371 1.371 0 01-.567.567c-.245.13-.534.2-.922.2H25.5v1.311c0 .388-.07.677-.2.922a1.371 1.371 0 01-.567.567c-.245.13-.534.2-.922.2h-.122c-.388 0-.677-.07-.922-.2a1.364 1.364 0 01-.567-.567c-.13-.245-.2-.534-.2-.922V38h-1.311c-.388 0-.677-.07-.922-.2a1.364 1.364 0 01-.567-.567c-.13-.245-.2-.534-.2-.922v-.122c0-.388.07-.677.2-.922.131-.244.323-.436.567-.567.245-.13.534-.2.922-.2H22V32h-1.311c-.388 0-.677-.07-.922-.2a1.364 1.364 0 01-.567-.567c-.13-.245-.2-.534-.2-.922v-.122c0-.388.07-.677.2-.922.131-.244.323-.436.567-.567.245-.13.534-.2.922-.2H22V17.513c0-.164.016-.232.045-.293a1.421 1.421 0 01.722-1.02c.245-.13.487-.2 1.156-.2H29.5zm.5 3.5h-4.5v9H30a4.5 4.5 0 100-9z" fill="#fff" /><defs><linearGradient id="money_circle_fill_blue_56_paint0_linear" x1="-28" y1="28" x2="28" y2="84" gradientUnits="userSpaceOnUse"><stop stop-color="#70B2FF" /><stop offset="1" stop-color="#5C9CE6" /></linearGradient></defs></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56MoneyCircleFillBlueProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56MoneyCircleFillBlue: FC<Icon56MoneyCircleFillBlueProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56MoneyCircleFillBlue as any).mountIcon = mountIcon;

export default Icon56MoneyCircleFillBlue;
