import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'window_check_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="window_check_28"><path clip-rule="evenodd" d="M11.6 6h4.8c1.713 0 2.878.002 3.778.075.877.072 1.325.202 1.638.361a4 4 0 011.748 1.748c.16.313.29.761.36 1.638.074.9.076 2.065.076 3.778v.8c0 1.713-.002 2.878-.075 3.778-.072.877-.202 1.325-.361 1.638a4 4 0 01-1.748 1.748c-.313.16-.761.29-1.638.36-.9.074-2.065.076-3.778.076h-4.8c-1.713 0-2.878-.002-3.778-.075-.877-.072-1.325-.202-1.638-.361a4 4 0 01-1.748-1.748c-.16-.313-.29-.761-.36-1.638C4.001 17.278 4 16.113 4 14.4v-.8c0-1.713.002-2.878.075-3.778.072-.877.202-1.325.361-1.638a4 4 0 011.748-1.748c.313-.16.761-.29 1.638-.36C8.722 6.001 9.887 6 11.6 6zM2 13.6c0-3.36 0-5.04.654-6.324a6 6 0 012.622-2.622C6.56 4 8.24 4 11.6 4h4.8c3.36 0 5.04 0 6.324.654a6 6 0 012.622 2.622C26 8.56 26 10.24 26 13.6v.8c0 3.36 0 5.04-.654 6.324a6 6 0 01-2.622 2.622C21.44 24 19.76 24 16.4 24h-4.8c-3.36 0-5.04 0-6.324-.654a6 6 0 01-2.622-2.622C2 19.44 2 17.76 2 14.4zm6.5-4.35a1.25 1.25 0 10-2.5 0 1.25 1.25 0 002.5 0zm3.5 0a1.25 1.25 0 10-2.5 0 1.25 1.25 0 002.5 0zM14.25 8a1.25 1.25 0 110 2.5 1.25 1.25 0 010-2.5zm3.919 4.257a1 1 0 01.074 1.412l-4.5 5a1 1 0 01-1.383.1l-3-2.5a1 1 0 111.28-1.537l2.26 1.884 3.857-4.285a1 1 0 011.412-.074z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28WindowCheckProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28WindowCheck: FC<Icon28WindowCheckProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28WindowCheck as any).mountIcon = mountIcon;

export default Icon28WindowCheck;
