import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'camera_circle_fill_green_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="camera_circle_fill_green_28"><linearGradient id="camera_circle_fill_green_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#50c750" /><stop offset="1" stop-color="#32b332" /></linearGradient><rect fill="url(#camera_circle_fill_green_28_a)" height="28" rx="14" width="28" /><path d="M15.5 7.75c1.5 0 1.417 1.77 3 1.77h-.026c.626.04.936.147 1.247.314.407.218.727.537.945.945.218.407.334.811.334 1.926v4.09c0 1.114-.116 1.519-.334 1.926s-.538.727-.945.945-.812.334-1.926.334h-7.59c-1.115 0-1.519-.116-1.926-.334a2.272 2.272 0 01-.945-.945C7.116 18.314 7 17.91 7 16.795v-4.09c0-1.115.116-1.519.334-1.926.218-.408.538-.727.945-.945.309-.165.615-.272 1.23-.314.018 0 .045 0 .07-.002a.333.333 0 01.055-.005c1.446-.097 1.41-1.763 2.866-1.763zm-1.5 3a3.75 3.75 0 100 7.5 3.75 3.75 0 000-7.5zm0 1.5a2.25 2.25 0 110 4.5 2.25 2.25 0 010-4.5z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28CameraCircleFillGreenProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28CameraCircleFillGreen: FC<Icon28CameraCircleFillGreenProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28CameraCircleFillGreen as any).mountIcon = mountIcon;

export default Icon28CameraCircleFillGreen;
