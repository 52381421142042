import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'users_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="users_20"><path d="M13.5 10.5c1.941 0 5.5.714 5.5 3.571 0 1.25-.323 1.429-.97 1.429l-4.738.001c.139-.4.208-.878.208-1.43 0-1.483-.592-2.62-1.555-3.436A9.413 9.413 0 0113.5 10.5zm-7 0c1.941 0 5.5.714 5.5 3.571 0 1.25-.323 1.429-.97 1.429H1.97c-.646 0-.97-.179-.97-1.429C1 11.214 4.559 10.5 6.5 10.5zm0-6.5a2.5 2.5 0 11.001 4.999A2.5 2.5 0 016.5 4zm7 0a2.5 2.5 0 11.001 4.999A2.5 2.5 0 0113.5 4z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20UsersProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20Users: FC<Icon20UsersProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20Users as any).mountIcon = mountIcon;

export default Icon20Users;
