import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'services_circle_fill_turquoise_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="services_circle_fill_turquoise_28"><linearGradient id="services_circle_fill_turquoise_28_a" gradientUnits="userSpaceOnUse" x1="42" x2="14" y1="14" y2="-14"><stop offset="0" stop-color="#65c2c2" /><stop offset="1" stop-color="#86d1d1" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#services_circle_fill_turquoise_28_a)" /><path clip-rule="evenodd" d="M14.543 10.886c.093.286.34.533.836 1.028l.707.707c.495.495.742.743 1.028.836.25.081.521.081.772 0 .286-.093.533-.34 1.028-.836l.707-.707c.495-.495.743-.742.836-1.028a1.25 1.25 0 000-.772c-.093-.286-.34-.533-.836-1.028l-.707-.707c-.495-.495-.742-.743-1.028-.836a1.25 1.25 0 00-.772 0c-.286.093-.533.34-1.028.836l-.707.707c-.495.495-.743.742-.836 1.028a1.25 1.25 0 000 .772zM8.136 8.682C8 8.95 8 9.3 8 10v1c0 .7 0 1.05.136 1.317.12.236.311.427.547.547C8.95 13 9.3 13 10 13h1c.7 0 1.05 0 1.317-.136a1.25 1.25 0 00.547-.547C13 12.05 13 11.7 13 11v-1c0-.7 0-1.05-.136-1.318a1.25 1.25 0 00-.547-.546C12.05 8 11.7 8 11 8h-1c-.7 0-1.05 0-1.317.136a1.25 1.25 0 00-.547.546zm0 7C8 15.95 8 16.3 8 17v1c0 .7 0 1.05.136 1.317.12.236.311.427.547.547C8.95 20 9.3 20 10 20h1c.7 0 1.05 0 1.317-.136a1.25 1.25 0 00.547-.547C13 19.05 13 18.7 13 18v-1c0-.7 0-1.05-.136-1.317a1.25 1.25 0 00-.547-.547C12.05 15 11.7 15 11 15h-1c-.7 0-1.05 0-1.317.136a1.25 1.25 0 00-.547.547zM15 17c0-.7 0-1.05.136-1.317a1.25 1.25 0 01.547-.547C15.95 15 16.3 15 17 15h1c.7 0 1.05 0 1.317.136.236.12.427.311.547.547C20 15.95 20 16.3 20 17v1c0 .7 0 1.05-.136 1.317a1.25 1.25 0 01-.547.547C19.05 20 18.7 20 18 20h-1c-.7 0-1.05 0-1.317-.136a1.25 1.25 0 01-.547-.547C15 19.05 15 18.7 15 18z" fill="#fff" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28ServicesCircleFillTurquoiseProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28ServicesCircleFillTurquoise: FC<Icon28ServicesCircleFillTurquoiseProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28ServicesCircleFillTurquoise as any).mountIcon = mountIcon;

export default Icon28ServicesCircleFillTurquoise;
