import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'airplay_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="airplay_24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" /><path d="M18.828 17l-1.666-1.667h1.697c.223 0 .304-.023.385-.066a.454.454 0 00.19-.19c.043-.08.066-.162.066-.385V6.641c0-.223-.023-.304-.067-.385a.454.454 0 00-.189-.19C19.163 6.024 19.082 6 18.86 6H5.141c-.223 0-.304.023-.385.067a.454.454 0 00-.19.189c-.043.081-.066.162-.066.385v8.051c0 .223.023.304.067.386a.454.454 0 00.189.189c.081.043.162.066.385.066h1.697L5.172 17h-.108c-.892 0-1.215-.093-1.54-.267a1.817 1.817 0 01-.757-.756c-.174-.326-.267-.65-.267-1.54V6.563c0-.892.093-1.215.267-1.54.174-.327.43-.583.756-.757.326-.174.65-.267 1.54-.267h13.873c.892 0 1.215.093 1.54.267.327.174.583.43.757.756.174.326.267.65.267 1.54v7.873c0 .892-.093 1.215-.267 1.54-.174.327-.43.583-.756.757-.326.174-.65.267-1.54.267h-.109zM5.854 19.146l5.792-5.792a.5.5 0 01.708 0l5.792 5.792a.5.5 0 01-.353.854H6.207a.5.5 0 01-.353-.854z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24AirplayProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Airplay: FC<Icon24AirplayProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Airplay as any).mountIcon = mountIcon;

export default Icon24Airplay;
