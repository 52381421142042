import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'clip_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="clip_outline_24"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M10.286 22.329A7.329 7.329 0 012.957 15h1.8a5.529 5.529 0 005.529 5.529h.428a.9.9 0 010 1.8zM13.671 9.429a.9.9 0 01.9-.9c1.888 0 3.546.996 4.453 2.49a.9.9 0 01-1.54.934 3.399 3.399 0 00-2.913-1.624.9.9 0 01-.9-.9zM7.39 3.9c-.68 0-1.215.583-1.156 1.262l.537 6.175L5 11.73 4.44 5.318a2.961 2.961 0 015.822-.975l.896 3.582a.9.9 0 11-1.746.436L8.517 4.78A1.161 1.161 0 007.391 3.9z" /><path d="M13.395 3.47a1.153 1.153 0 00-1.377.884l-.852 3.977a.9.9 0 11-1.76-.377l.852-3.977a2.953 2.953 0 015.814 1.008l-.608 4.563a.9.9 0 11-1.785-.238l.609-4.562a1.153 1.153 0 00-.893-1.278zM9.792 12.64a1.165 1.165 0 00-1.215-.91l-.811.054A3.223 3.223 0 004.757 15h-1.8a5.023 5.023 0 014.689-5.012l.811-.054a2.965 2.965 0 01.685 5.883l-.423.07a.9.9 0 01-.296-1.775l.423-.07c.659-.11 1.09-.75.946-1.402zM19.69 17.535l-4.295-2.498v4.997zm1.423 1.487c1.138-.662 1.138-2.311 0-2.973l-5.147-2.994c-1.141-.664-2.57.163-2.57 1.487v5.987c0 1.324 1.429 2.15 2.57 1.486z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24ClipOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24ClipOutline: FC<Icon24ClipOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24ClipOutline as any).mountIcon = mountIcon;

export default Icon24ClipOutline;
