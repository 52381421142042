import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'mention_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="mention_outline_24"><path clip-rule="evenodd" d="M2.5 11.97c0 6.103 4.155 9.73 9.917 9.73 1.467 0 2.77-.188 3.639-.481.704-.235.95-.552.95-.963s-.27-.669-.75-.669c-.118 0-.27.012-.458.047-1.057.259-1.867.423-3.017.423-5.047 0-8.45-2.934-8.45-8.016 0-4.918 3.157-8.31 7.957-8.31 4.19 0 7.582 2.57 7.582 7.3 0 2.465-.892 4.19-2.195 4.19-.845 0-1.302-.457-1.302-1.255V8.144c0-.657-.376-1.056-1.01-1.056-.622 0-1.01.4-1.01 1.056v.587h-.117c-.48-1.044-1.572-1.643-2.863-1.643-2.336 0-3.99 1.972-3.99 4.87 0 2.947 1.654 4.965 4.072 4.965 1.361 0 2.418-.68 2.934-1.878h.117c.212 1.186 1.315 1.878 2.77 1.878 2.735 0 4.425-2.523 4.425-6.032 0-5.352-3.932-8.791-9.39-8.791C6.515 2.1 2.5 5.985 2.5 11.97zm9.366 3.122c-1.456 0-2.312-1.173-2.312-3.122 0-1.936.868-3.086 2.312-3.086 1.479 0 2.418 1.197 2.418 3.075 0 1.925-.951 3.133-2.418 3.133z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24MentionOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24MentionOutline: FC<Icon24MentionOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24MentionOutline as any).mountIcon = mountIcon;

export default Icon24MentionOutline;
