import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = '16_circle_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="16_circle_outline_24"><g fill="currentColor"><path d="M8.146 14.917c0 .632.336.993.934.993.592 0 .927-.356.927-.993V9.219c0-.689-.444-1.134-1.11-1.134-.38 0-.785.13-1.229.411l-.927.615c-.336.21-.507.457-.507.734 0 .372.274.643.65.643.187 0 .346-.056.642-.243l.598-.4h.022zm3.022-2.872c0 1.207.268 2.195.78 2.866.54.717 1.36 1.089 2.367 1.089 1.741 0 2.92-1.095 2.92-2.736 0-1.461-.99-2.483-2.413-2.483-.837 0-1.577.44-1.85 1.1h-.051c.006-1.585.535-2.448 1.548-2.448.387 0 .694.118 1.075.406.308.22.495.294.74.294.416 0 .706-.271.706-.66 0-.333-.25-.7-.666-.977C15.84 8.175 15.18 8 14.457 8c-2.1 0-3.289 1.461-3.289 4.045zm1.963 1.286c0-.71.49-1.224 1.173-1.224.688 0 1.16.502 1.16 1.23 0 .74-.472 1.241-1.143 1.241-.689 0-1.19-.513-1.19-1.247z" /><path clip-rule="evenodd" d="M12 2.1c5.467 0 9.9 4.433 9.9 9.9 0 5.467-4.433 9.9-9.9 9.9-5.467 0-9.9-4.433-9.9-9.9 0-5.467 4.433-9.9 9.9-9.9zm0 1.8A8.1 8.1 0 003.9 12c0 4.474 3.626 8.1 8.1 8.1s8.1-3.626 8.1-8.1A8.1 8.1 0 0012 3.9z" fill-rule="evenodd" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon2416CircleOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon2416CircleOutline: FC<Icon2416CircleOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon2416CircleOutline as any).mountIcon = mountIcon;

export default Icon2416CircleOutline;
