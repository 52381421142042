import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'donate_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="donate_outline_56"><path d="M28 4c13.255 0 24 10.745 24 24S41.255 52 28 52 4 41.255 4 28 14.745 4 28 4zm0 3C16.402 7 7 16.402 7 28s9.402 21 21 21 21-9.402 21-21S39.598 7 28 7zm-2.77 8.754a3 3 0 015.54 0l2.172 5.222a.25.25 0 00.21.153l5.639.452a3 3 0 011.712 5.27l-4.296 3.679a.25.25 0 00-.08.247l1.312 5.502a3 3 0 01-4.482 3.256l-4.827-2.948a.25.25 0 00-.26 0l-4.827 2.948a3 3 0 01-4.482-3.256l1.312-5.502a.25.25 0 00-.08-.247l-4.296-3.68a3 3 0 011.712-5.269l5.638-.452a.25.25 0 00.211-.153zM28 16.906l-2.172 5.222a3.25 3.25 0 01-2.741 1.992l-5.638.452 4.295 3.68a3.25 3.25 0 011.047 3.222l-1.312 5.501 4.827-2.948a3.25 3.25 0 013.388 0l4.827 2.948-1.312-5.501a3.25 3.25 0 011.047-3.223l4.295-3.68-5.638-.451a3.25 3.25 0 01-2.74-1.992z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56DonateOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56DonateOutline: FC<Icon56DonateOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56DonateOutline as any).mountIcon = mountIcon;

export default Icon56DonateOutline;
