import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'song_outline_32';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="song_outline_32"><path d="M25.493 3.12l.007.13v2.691a4.604 4.604 0 01-3.246 4.4l-.208.058-4.546 1.173V22.69c0 6.112-6.004 9.252-9.278 6.015-1.451-1.435-1.632-3.856-.423-5.801 1.26-2.03 3.766-3.268 7.2-3.393l.002-11.19c-.035-2.463 1.163-4.21 3.434-4.966l.24-.076c.92-.28 2.676-.691 5.316-1.251a1.25 1.25 0 011.502 1.092zM15 22l-.317.02c-2.416.175-4.004 1.004-4.751 2.198-.624.998-.538 2.144.052 2.723 1.58 1.552 5.017-.233 5.017-4.296zM23 4.797l-1.59.36c-1.056.246-1.787.437-2.184.57-1.161.386-1.691 1.093-1.724 2.37l-.002.892 3.922-1.01a2.104 2.104 0 001.571-1.87L23 5.941z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32SongOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32SongOutline: FC<Icon32SongOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32SongOutline as any).mountIcon = mountIcon;

export default Icon32SongOutline;
