import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'microphone_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="microphone_16"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 4.5a2.5 2.5 0 115 0v3a2.5 2.5 0 01-5 0v-3zM3.198 7.767a.75.75 0 01.89.576 4.002 4.002 0 007.823 0 .75.75 0 111.467.314A5.505 5.505 0 018.75 12.95v1.301a.75.75 0 01-1.5 0v-1.3a5.505 5.505 0 01-4.628-4.293.75.75 0 01.576-.89z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16MicrophoneProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16Microphone: FC<Icon16MicrophoneProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16Microphone as any).mountIcon = mountIcon;

export default Icon16Microphone;
