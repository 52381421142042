import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 12 12';
const id = 'market_12';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" id="market_12"><path d="M6 0a3 3 0 013 3h.718c.446 0 .607.046.77.134.163.087.291.215.378.378.088.163.134.324.134.77v3.513c0 1.115-.116 1.519-.334 1.926a2.271 2.271 0 01-.945.945c-.407.218-.811.334-1.926.334h-3.59c-1.115 0-1.519-.116-1.926-.334a2.272 2.272 0 01-.945-.945C1.116 9.314 1 8.91 1 7.795V4.282c0-.446.046-.607.134-.77a.909.909 0 01.378-.378c.163-.088.324-.134.77-.134H3a3 3 0 013-3zM3.537 4h-.074a.517.517 0 00-.258.053.363.363 0 00-.152.152.517.517 0 00-.053.258v.074c0 .119.019.193.053.258a.363.363 0 00.152.152c.065.034.14.053.258.053h.074a.517.517 0 00.258-.053.363.363 0 00.152-.152A.517.517 0 004 4.537v-.074a.517.517 0 00-.053-.258.363.363 0 00-.152-.152A.517.517 0 003.537 4zm5 0h-.074a.517.517 0 00-.258.053.363.363 0 00-.152.152.517.517 0 00-.053.258v.074c0 .119.019.193.053.258a.363.363 0 00.152.152c.065.034.14.053.258.053h.074a.517.517 0 00.258-.053.363.363 0 00.152-.152A.517.517 0 009 4.537v-.074a.517.517 0 00-.053-.258.363.363 0 00-.152-.152A.517.517 0 008.537 4zM6 1a2 2 0 00-2 2h4a2 2 0 00-2-2z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon12MarketProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon12Market: FC<Icon12MarketProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 12,
    height: !isNaN(props.height) ? +props.height : 12,
  }));
};

(Icon12Market as any).mountIcon = mountIcon;

export default Icon12Market;
