import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'article_outline_20';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 20" id="article_outline_20"><defs><path d="M17.5 5.25a.752.752 0 00-.744-.75H3.244a.745.745 0 00-.744.75c0 .414.341.75.744.75h13.512c.411 0 .744-.333.744-.75zm0 5a.752.752 0 00-.744-.75H3.244a.745.745 0 00-.744.75c0 .414.341.75.744.75h13.512c.411 0 .744-.333.744-.75zm-15 5c0 .414.337.75.755.75h8.99a.75.75 0 00.755-.75.752.752 0 00-.755-.75h-8.99a.75.75 0 00-.755.75z" id="article_outline_20_a" /></defs><g fill="none" fill-rule="evenodd"><path d="M0 0h20v20H0z" /><use fill="currentColor" fill-rule="nonzero" xlink:href="#article_outline_20_a" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20ArticleOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20ArticleOutline: FC<Icon20ArticleOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20ArticleOutline as any).mountIcon = mountIcon;

export default Icon20ArticleOutline;
