import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'services_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="services_28"><path d="M10.757 15c.78 0 1.063.081 1.348.234.285.152.509.376.661.661.153.285.234.568.234 1.348v4.514c0 .78-.081 1.063-.234 1.348a1.591 1.591 0 01-.661.661c-.285.153-.568.234-1.348.234H6.243c-.78 0-1.063-.081-1.348-.234a1.59 1.59 0 01-.661-.661C4.08 22.82 4 22.537 4 21.757v-4.514c0-.78.081-1.063.234-1.348a1.59 1.59 0 01.661-.661C5.18 15.08 5.463 15 6.243 15h4.514zm11 0c.78 0 1.063.081 1.348.234.285.152.509.376.661.661.153.285.234.568.234 1.348v4.514c0 .78-.081 1.063-.234 1.348a1.591 1.591 0 01-.661.661c-.285.153-.568.234-1.348.234h-4.514c-.78 0-1.063-.081-1.348-.234a1.591 1.591 0 01-.661-.661c-.153-.285-.234-.568-.234-1.348v-4.514c0-.78.081-1.063.234-1.348.152-.285.376-.509.661-.661.285-.153.568-.234 1.348-.234h4.514zm-1.79-12.066c.31.094.568.237 1.12.788l3.19 3.192c.552.551.695.809.789 1.118.093.31.093.626 0 .936-.094.31-.237.567-.788 1.118l-3.192 3.192c-.551.551-.809.694-1.118.788a1.59 1.59 0 01-.936 0c-.31-.094-.567-.237-1.118-.788l-3.192-3.192c-.551-.551-.694-.809-.788-1.118a1.59 1.59 0 010-.936c.094-.31.237-.567.788-1.118l3.192-3.192c.551-.551.809-.694 1.118-.788a1.59 1.59 0 01.936 0zM10.758 4c.78 0 1.063.081 1.348.234.285.152.509.376.661.661.153.285.234.568.234 1.348v4.514c0 .78-.081 1.063-.234 1.348a1.591 1.591 0 01-.661.661c-.285.153-.568.234-1.348.234H6.243c-.78 0-1.063-.081-1.348-.234a1.59 1.59 0 01-.661-.661C4.08 11.82 4 11.537 4 10.757V6.243c0-.78.081-1.063.234-1.348a1.59 1.59 0 01.661-.661C5.18 4.08 5.463 4 6.243 4h4.514z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28ServicesProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28Services: FC<Icon28ServicesProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28Services as any).mountIcon = mountIcon;

export default Icon28Services;
