import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'check_shield_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="check_shield_outline_24"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M11.697 2.152a.9.9 0 01.606 0l7 2.5a.9.9 0 01.597.848V13c0 2.85-1.568 5.077-3.243 6.55a11.883 11.883 0 01-2.531 1.71c-.758.374-1.537.64-2.126.64s-1.368-.266-2.126-.64a11.882 11.882 0 01-2.531-1.71C5.668 18.078 4.1 15.85 4.1 13V5.5a.9.9 0 01.597-.848zM5.9 6.134V13c0 2.15 1.182 3.923 2.632 5.2.72.633 1.482 1.12 2.14 1.446.696.344 1.167.454 1.328.454s.632-.11 1.328-.454c.658-.325 1.42-.813 2.14-1.447C16.918 16.923 18.1 15.15 18.1 13V6.134L12 3.956z" /><path d="M16.136 9.364a.9.9 0 010 1.272l-4.5 4.5a.9.9 0 01-1.272 0l-2.5-2.5a.9.9 0 011.272-1.272L11 13.227l3.864-3.863a.9.9 0 011.272 0z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24CheckShieldOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24CheckShieldOutline: FC<Icon24CheckShieldOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24CheckShieldOutline as any).mountIcon = mountIcon;

export default Icon24CheckShieldOutline;
