import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'rss_circle_fill_black_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="rss_circle_fill_black_20"><linearGradient id="rss_circle_fill_black_20_a" gradientUnits="userSpaceOnUse" x1="-10" x2="10" y1="10" y2="30"><stop offset="0" stop-color="#626466" /><stop offset="1" stop-color="#444647" /></linearGradient><path d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z" fill="url(#rss_circle_fill_black_20_a)" /><path d="M5.577 5.001c.173.009.317.019.43.03 4.7.469 8.444 4.186 8.954 8.873.014.134.027.307.037.518a.55.55 0 01-.549.578h-.905a.55.55 0 01-.55-.52 7.312 7.312 0 00-.035-.441A8.019 8.019 0 005.52 7.004.55.55 0 015 6.454v-.903A.55.55 0 015.577 5zm.012 3.36c.243.018.378.03.404.033a6.685 6.685 0 015.581 5.415c.027.151.05.35.07.598a.55.55 0 01-.548.593h-.807a.55.55 0 01-.548-.502 3.732 3.732 0 00-.03-.257 4.775 4.775 0 00-3.949-3.951l-.255-.021A.55.55 0 015 9.72v-.81a.55.55 0 01.59-.55zM7.733 15H5.55a.55.55 0 01-.55-.55v-2.186a.55.55 0 01.656-.54l.008.002a3.344 3.344 0 012.581 2.48c.011.045.021.096.03.154a.55.55 0 01-.542.64z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20RssCircleFillBlackProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20RssCircleFillBlack: FC<Icon20RssCircleFillBlackProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20RssCircleFillBlack as any).mountIcon = mountIcon;

export default Icon20RssCircleFillBlack;
