import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'attachments_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="attachments_24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" /><path d="M20 18V6.764A2.764 2.764 0 0017.236 4H6a2.01 2.01 0 012.01-2h10.473A3.518 3.518 0 0122 5.518V15.99A2.01 2.01 0 0120 18zM15.99 6H4.01C2.9 6 2 6.9 2 8.01v11.98C2 21.1 2.9 22 4.01 22h11.98c1.11 0 2.01-.9 2.01-2.01V8.01C18 6.9 17.1 6 15.99 6zM7 15.25l2.143 2.508 3-3.758 3.322 4.306a.43.43 0 01-.341.694H4.896a.43.43 0 01-.336-.7L7 15.25z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24AttachmentsProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Attachments: FC<Icon24AttachmentsProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Attachments as any).mountIcon = mountIcon;

export default Icon24Attachments;
