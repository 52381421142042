import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'logo_vk_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="logo_vk_56"><path d="M34.747 6C47.067 6 50 8.933 50 21.253v13.494C50 47.067 47.067 50 34.747 50H21.253C8.933 50 6 47.067 6 34.747V21.253C6 8.933 8.933 6 21.253 6zM17.458 19.75H14.25c-.917 0-1.1.431-1.1.907 0 .85 1.088 5.065 5.064 10.64 2.652 3.806 6.387 5.87 9.786 5.87 2.04 0 2.292-.459 2.292-1.248v-2.877c0-.917.193-1.1.839-1.1.476 0 1.291.238 3.195 2.073 2.175 2.176 2.534 3.152 3.757 3.152h3.209c.916 0 1.375-.459 1.11-1.363-.289-.902-1.328-2.21-2.706-3.76-.748-.884-1.87-1.835-2.21-2.311-.475-.612-.339-.884 0-1.428l.058-.08c.483-.688 3.88-5.56 4.26-7.296.203-.68 0-1.179-.97-1.179h-3.209c-.816 0-1.192.431-1.396.907 0 0-1.631 3.977-3.943 6.56-.747.748-1.087.986-1.495.986-.204 0-.5-.238-.5-.918V20.93c0-.815-.236-1.179-.916-1.179h-5.042c-.51 0-.816.379-.816.737 0 .774 1.156.952 1.275 3.127v4.725c0 1.036-.187 1.224-.595 1.224-1.088 0-3.734-3.995-5.303-8.566-.307-.888-.616-1.247-1.436-1.247z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56LogoVkProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56LogoVk: FC<Icon56LogoVkProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56LogoVk as any).mountIcon = mountIcon;

export default Icon56LogoVk;
