import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'user_star_badge_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="user_star_badge_outline_28"><path fill-rule="evenodd" clip-rule="evenodd" d="M14 14.5a5.5 5.5 0 100-11 5.5 5.5 0 000 11zm0-2a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM19 27a6 6 0 100-12 6 6 0 000 12zm-7.018-10.431a1 1 0 01-.795 1.17c-2.272.433-3.272 1.361-3.729 2.05a2.902 2.902 0 00-.408.917 2.016 2.016 0 00-.05.306v.488a.5.5 0 00.5.5h3a1 1 0 110 2h-3A2.5 2.5 0 015 21.5V21h1c-1 0-1-.002-1-.002v-.032a2.115 2.115 0 01.015-.215c.014-.13.04-.306.091-.515a4.9 4.9 0 01.686-1.553c.793-1.195 2.293-2.389 5.02-2.909a1 1 0 011.17.795zm9.954 2.942l-1.673-.165-.736-1.738c-.23-.544-.822-.544-1.052 0l-.737 1.738-1.673.165c-.584.057-.762.637-.31 1.02l1.309 1.114-.486 1.814c-.16.596.337.955.832.598l1.59-1.15 1.592 1.15c.496.359.992-.002.832-.598l-.486-1.814 1.31-1.113c.449-.383.27-.964-.312-1.021z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28UserStarBadgeOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28UserStarBadgeOutline: FC<Icon28UserStarBadgeOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28UserStarBadgeOutline as any).mountIcon = mountIcon;

export default Icon28UserStarBadgeOutline;
