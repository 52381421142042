import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'fragments_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="fragments_outline_56"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.996 15.257A7.5 7.5 0 005.5 8a1.5 1.5 0 100 3 4.5 4.5 0 014.5 4.5v25l-.005.212A4.5 4.5 0 015.5 45a1.5 1.5 0 000 3 7.5 7.5 0 007.5-7.5v-25l-.004-.243zm7.866-6.278C22.22 8.252 23.527 8 26.192 8l3.956.001c2.439.022 3.69.283 4.99.978a6.951 6.951 0 012.883 2.883c.663 1.24.931 2.437.973 4.664l.006.666v21.616c0 2.665-.252 3.972-.979 5.33a6.951 6.951 0 01-2.883 2.883c-1.24.663-2.437.931-4.664.973l-.666.006h-3.616c-2.665 0-3.972-.252-5.33-.979a6.951 6.951 0 01-2.883-2.883c-.727-1.358-.979-2.665-.979-5.33l.001-21.956c.022-2.439.283-3.69.978-4.99a6.951 6.951 0 012.883-2.883zM29.808 11l-3.91.001c-1.995.014-2.796.182-3.621.623a3.952 3.952 0 00-1.653 1.653c-.462.864-.624 1.703-.624 3.915v21.616c0 2.212.162 3.05.624 3.915.383.717.937 1.27 1.653 1.653.785.42 1.55.592 3.344.62l.57.004h3.617c2.212 0 3.05-.162 3.915-.624a3.952 3.952 0 001.653-1.653c.42-.785.592-1.55.62-3.344l.004-.57V17.191c0-2.212-.162-3.05-.624-3.915a3.952 3.952 0 00-1.653-1.653C32.86 11.162 32.02 11 29.808 11zM50.5 8a7.5 7.5 0 00-7.496 7.257L43 15.5v25a7.5 7.5 0 007.5 7.5 1.5 1.5 0 000-3 4.5 4.5 0 01-4.495-4.288L46 40.5v-25a4.5 4.5 0 014.5-4.5 1.5 1.5 0 000-3z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56FragmentsOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56FragmentsOutline: FC<Icon56FragmentsOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56FragmentsOutline as any).mountIcon = mountIcon;

export default Icon56FragmentsOutline;
