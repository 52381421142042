import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'reply_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="reply_outline_28"><g fill="none"><path d="M0 0h28v28H0z" /><path d="M14.138 4.007a2.588 2.588 0 012.393 2.589l.001 2.556.025.006C21.989 10.292 26 15.141 26 20.862c0 .416-.021.83-.063 1.24-.1.98-1.337 1.332-1.93.55l-.224-.286a10.866 10.866 0 00-6.995-3.942l-.256-.031v3.011c0 .58-.194 1.144-.55 1.601a2.564 2.564 0 01-3.617.442l-9.378-7.404a2.609 2.609 0 010-4.086l9.378-7.404A2.565 2.565 0 0113.955 4zm-.184 2.166a.418.418 0 00-.258.09l-9.378 7.404a.425.425 0 000 .666l9.378 7.404a.417.417 0 00.678-.333l.001-4.162c0-.607.494-1.097 1.096-1.087l.28.005.41.02a12.986 12.986 0 017.334 2.856l.209.174-.029-.163c-.77-4.124-4.117-7.372-8.352-7.892a1.084 1.084 0 01-.948-1.079l-.001-3.48a.421.421 0 00-.42-.423z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28ReplyOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28ReplyOutline: FC<Icon28ReplyOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28ReplyOutline as any).mountIcon = mountIcon;

export default Icon28ReplyOutline;
