import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = '2_circle_fill_silver_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="2_circle_fill_silver_20"><radialGradient id="2_circle_fill_silver_20_a" cx="0" cy="0" gradientTransform="matrix(0 19.6755 -19.6755 0 10 .324)" gradientUnits="userSpaceOnUse" r="1"><stop offset="0" stop-color="#b0bec5" /><stop offset="1" stop-color="#90a4ae" /></radialGradient><path d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z" fill="url(#2_circle_fill_silver_20_a)" /><path d="M6.527 8.055h1.655c0-1.04.683-1.75 1.756-1.75 1.033 0 1.648.69 1.648 1.586 0 .752-.3 1.223-1.545 2.481l-3.418 3.439V15h6.85v-1.463H9.016v-.116l2.119-2.085c1.695-1.661 2.194-2.461 2.194-3.61 0-1.64-1.333-2.836-3.295-2.836-2.057 0-3.507 1.298-3.507 3.165z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon202CircleFillSilverProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon202CircleFillSilver: FC<Icon202CircleFillSilverProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon202CircleFillSilver as any).mountIcon = mountIcon;

export default Icon202CircleFillSilver;
