import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'users_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="users_outline_20"><g fill="currentColor"><g clip-rule="evenodd" fill-rule="evenodd"><path d="M6.25 3.5a3 3 0 100 6 3 3 0 000-6zm-1.5 3a1.5 1.5 0 103 0 1.5 1.5 0 00-3 0zM2.688 11.57c.96-.555 2.226-.82 3.562-.82s2.602.265 3.562.82c.977.563 1.688 1.467 1.688 2.68 0 .707-.276 1.306-.781 1.71-.484.387-1.105.54-1.719.54H3.5c-.614 0-1.235-.153-1.719-.54C1.276 15.557 1 14.958 1 14.25c0-1.213.71-2.117 1.688-2.68zm.75 1.298c-.649.374-.938.845-.938 1.382 0 .293.1.444.219.54.141.113.395.21.781.21H9c.386 0 .64-.097.781-.21.12-.096.219-.247.219-.54 0-.537-.29-1.008-.937-1.382-.665-.383-1.65-.618-2.813-.618s-2.148.235-2.813.618zM13.75 3.5a3 3 0 100 6 3 3 0 000-6zm-1.5 3a1.5 1.5 0 103 0 1.5 1.5 0 00-3 0z" /></g><path d="M13.75 12.25c-.227 0-.454.011-.677.033a.75.75 0 11-.146-1.493c.272-.026.547-.04.823-.04 1.336 0 2.602.265 3.562.82.977.563 1.688 1.467 1.688 2.68 0 .707-.276 1.306-.781 1.71-.484.387-1.105.54-1.719.54h-3a.75.75 0 010-1.5h3c.386 0 .64-.097.781-.21.12-.096.219-.247.219-.54 0-.537-.29-1.008-.937-1.382-.665-.383-1.65-.618-2.813-.618z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20UsersOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20UsersOutline: FC<Icon20UsersOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20UsersOutline as any).mountIcon = mountIcon;

export default Icon20UsersOutline;
