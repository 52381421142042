import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'cake_circle_fill_purple_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="cake_circle_fill_purple_56"><circle cx="28" cy="28" r="28" fill="url(#cake_circle_fill_purple_56_paint0_linear)" /><path fill-rule="evenodd" clip-rule="evenodd" d="M35.186 16.108a.213.213 0 00-.371 0c-.32.582-.632 1.023-.905 1.408-.528.743-.91 1.282-.91 2.238a2 2 0 104 0c0-.957-.382-1.495-.91-2.238-.273-.385-.586-.826-.904-1.408zM16 26.754a2 2 0 012-2h20a2 2 0 012 2c0 1.958-.852 3.064-2 3.596l-.04.018c-.435.196-.911.31-1.4.359a5.699 5.699 0 01-.56.027c-2.991 0-3.994-1.988-4-2-.005.009-.475.94-1.727 1.529a4.642 4.642 0 01-.696.26c-.31.09-.655.156-1.038.189a6.336 6.336 0 01-.539.022c-2.991 0-3.994-1.988-4-2-.005.01-.487.965-1.776 1.551a4.652 4.652 0 01-.647.239c-.31.089-.655.155-1.038.188a6.336 6.336 0 01-.539.022c-2 0-4-1-4-4zm22 6.761a8.3 8.3 0 01-2 .239c-1.64 0-2.967-.413-4-.987-1.032.574-2.36.987-4 .987-1.64 0-2.967-.413-4-.987-1.032.574-2.36.987-4 .987a8.298 8.298 0 01-2-.239v1.414c0 1.12 0 1.68.218 2.108a2 2 0 00.874.874c.428.218.988.218 2.108.218h13.6c1.12 0 1.68 0 2.108-.218a2 2 0 00.874-.874c.218-.428.218-.988.218-2.108v-1.414zM20.814 16.108a.213.213 0 01.372 0c.318.582.63 1.023.904 1.408.528.743.91 1.282.91 2.238a2 2 0 11-4 0c0-.957.382-1.495.91-2.238.273-.385.586-.826.904-1.408zm7.372 0a.213.213 0 00-.372 0c-.318.582-.63 1.023-.904 1.408-.528.743-.91 1.282-.91 2.238a2 2 0 004 0c0-.957-.382-1.495-.91-2.238-.273-.385-.586-.826-.904-1.408z" fill="#fff" /><defs><linearGradient id="cake_circle_fill_purple_56_paint0_linear" x1="-28" y1="28" x2="28" y2="84" gradientUnits="userSpaceOnUse"><stop stop-color="#C48AFF" /><stop offset="1" stop-color="#AA65F0" /></linearGradient></defs></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56CakeCircleFillPurpleProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56CakeCircleFillPurple: FC<Icon56CakeCircleFillPurpleProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56CakeCircleFillPurple as any).mountIcon = mountIcon;

export default Icon56CakeCircleFillPurple;
