import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'radiowaves_left_and_right_circle_fill_red_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="radiowaves_left_and_right_circle_fill_red_28"><linearGradient id="radiowaves_left_and_right_circle_fill_red_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#ff5263" /><stop offset="1" stop-color="#ff3347" /></linearGradient><path d="M14 28c7.732 0 14-6.268 14-14S21.732 0 14 0 0 6.268 0 14s6.268 14 14 14z" fill="url(#radiowaves_left_and_right_circle_fill_red_28_a)" /><g fill="#fff"><path d="M16 14a2 2 0 11-4 0 2 2 0 014 0z" /><path clip-rule="evenodd" d="M17.229 10.054a1 1 0 011.408.137C19.487 11.227 20 12.555 20 14s-.512 2.773-1.364 3.808a1 1 0 01-1.544-1.27c.568-.69.908-1.573.908-2.538s-.34-1.847-.908-2.538a1 1 0 01.137-1.408zm-6.458 0a1 1 0 01.137 1.408C10.34 12.152 10 13.035 10 14s.34 1.847.908 2.538a1 1 0 01-1.544 1.27C8.512 16.773 8 15.445 8 14s.512-2.773 1.364-3.809a1 1 0 011.407-.137z" fill-rule="evenodd" /><path clip-rule="evenodd" d="M7.959 7.255a1 1 0 01.078 1.412C6.769 10.083 6 11.95 6 14s.77 3.917 2.037 5.333a1 1 0 11-1.49 1.334A9.966 9.966 0 014 14c0-2.56.964-4.898 2.547-6.667a1 1 0 011.412-.078zm12.082 0a1 1 0 011.412.078A9.967 9.967 0 0124 14c0 2.56-.964 4.898-2.546 6.667a1 1 0 11-1.49-1.334C21.23 17.917 22 16.05 22 14s-.77-3.917-2.037-5.333a1 1 0 01.078-1.412z" fill-rule="evenodd" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28RadiowavesLeftAndRightCircleFillRedProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28RadiowavesLeftAndRightCircleFillRed: FC<Icon28RadiowavesLeftAndRightCircleFillRedProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28RadiowavesLeftAndRightCircleFillRed as any).mountIcon = mountIcon;

export default Icon28RadiowavesLeftAndRightCircleFillRed;
