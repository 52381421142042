import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'virus_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="virus_outline_20"><path clip-rule="evenodd" d="M11.15 17.35c0-.349-.155-.66-.4-.872V15.45a5.46 5.46 0 001.192-.302l.491.904a1.15 1.15 0 101.318-.716l-.491-.905c.408-.3.773-.656 1.084-1.056l.891.515a1.15 1.15 0 10.75-1.3l-.89-.513c.17-.419.291-.863.354-1.326h1.03a1.15 1.15 0 100-1.5h-1.03a5.462 5.462 0 00-.354-1.325l.89-.515a1.15 1.15 0 10-.75-1.299l-.891.515a5.535 5.535 0 00-.97-.97l.515-.891a1.15 1.15 0 10-1.299-.75l-.515.89a5.46 5.46 0 00-1.325-.354V3.52a1.15 1.15 0 10-1.5 0v1.03a5.463 5.463 0 00-1.192.302l-.491-.904a1.15 1.15 0 10-1.318.716l.491.905c-.408.3-.773.656-1.084 1.056l-.891-.515a1.15 1.15 0 10-.75 1.299l.89.515c-.17.418-.291.862-.354 1.325H3.52a1.15 1.15 0 100 1.5h1.03c.063.463.183.907.354 1.326l-.89.514a1.15 1.15 0 10.75 1.3l.891-.516c.282.362.608.688.97.97l-.515.891a1.15 1.15 0 101.299.75l.514-.89c.42.17.863.291 1.326.354v1.03a1.15 1.15 0 101.9.872zM10.01 14h-.02a3.981 3.981 0 01-1.925-.498l-.124-.072a4.02 4.02 0 01-1.37-1.37l-.073-.126A3.982 3.982 0 016 10c0-.701.18-1.36.497-1.933l.074-.128a4.02 4.02 0 011.453-1.418l.14-.075A3.983 3.983 0 0110 6c.7 0 1.36.18 1.932.497l.13.074a4.02 4.02 0 011.367 1.368l.074.128C13.82 8.64 14 9.3 14 10c0 .702-.18 1.361-.498 1.934l-.072.125a4.02 4.02 0 01-1.457 1.421l-.134.073a3.982 3.982 0 01-1.83.447z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20VirusOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20VirusOutline: FC<Icon20VirusOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20VirusOutline as any).mountIcon = mountIcon;

export default Icon20VirusOutline;
