import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'pin_dot_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="pin_dot_16"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.25 4a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zm7.642 1.77l-3.657-3.664c-.231-.232-.634-.057-.634.263v.526a.896.896 0 01-.263.634L7.623 5.244a.895.895 0 01-.633.262H3.995c-.565 0-.847.71-.455 1.102l2.29 2.29-3.569 3.574a.894.894 0 001.263 1.264l3.57-3.572 2.301 2.301c.402.402 1.101.098 1.101-.456V9.013a.9.9 0 01.262-.633l1.715-1.715a.895.895 0 01.633-.263h.523c.327 0 .488-.407.263-.633z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16PinDotProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16PinDot: FC<Icon16PinDotProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16PinDot as any).mountIcon = mountIcon;

export default Icon16PinDot;
