import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'sun_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="sun_outline_28"><g fill="currentColor"><path d="M15 2a1 1 0 10-2 0v3a1 1 0 102 0zM15 23a1 1 0 10-2 0v3a1 1 0 102 0zM23.892 20.866a1 1 0 101-1.732l-2.598-1.5a1 1 0 10-1 1.732zM5.706 10.366a1 1 0 101-1.732l-2.598-1.5a1 1 0 00-1 1.732zM2.742 20.5a1 1 0 01.366-1.366l2.598-1.5a1 1 0 111 1.732l-2.598 1.5a1 1 0 01-1.366-.366zM20.928 10a1 1 0 01.366-1.366l2.598-1.5a1 1 0 111 1.732l-2.598 1.5A1 1 0 0120.928 10z" /><path clip-rule="evenodd" d="M14 8.031a5.969 5.969 0 100 11.938A5.969 5.969 0 0014 8.03zM9.992 14c0-2.355 1.653-4.008 4.008-4.008s4.016 1.653 4.016 4.008-1.661 4-4.016 4-4.008-1.645-4.008-4z" fill-rule="evenodd" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28SunOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28SunOutline: FC<Icon28SunOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28SunOutline as any).mountIcon = mountIcon;

export default Icon28SunOutline;
