import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'story_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="story_36"><path d="M12.857 18a5.143 5.143 0 1110.286 0 5.143 5.143 0 01-10.286 0z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M13.89 4c-3.157 0-4.54.28-5.944 1.03A7.027 7.027 0 005.03 7.946C4.28 9.35 4 10.733 4 13.89v8.222c0 3.156.28 4.539 1.03 5.942a7.026 7.026 0 002.916 2.917C9.35 31.72 10.733 32 13.89 32h8.222c3.156 0 4.539-.28 5.942-1.03a7.026 7.026 0 002.917-2.916C31.72 26.65 32 25.267 32 22.11v-8.222c0-3.156-.28-4.539-1.03-5.943a7.026 7.026 0 00-2.916-2.916C26.65 4.28 25.267 4 22.11 4h-8.222zM18 10.286a7.714 7.714 0 100 15.428 7.714 7.714 0 000-15.428z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36StoryProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36Story: FC<Icon36StoryProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36Story as any).mountIcon = mountIcon;

export default Icon36Story;
