import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'washer_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="washer_outline_28"><g fill="currentColor"><path clip-rule="evenodd" d="M19 17a5 5 0 11-10 0 5 5 0 0110 0zm-2 0a3 3 0 11-6 0 3 3 0 016 0z" fill-rule="evenodd" /><path d="M17 5a1 1 0 100 2h2a1 1 0 100-2zM15 6a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1z" /><path clip-rule="evenodd" d="M21.844 2.45C20.977 2 19.838 2 17.56 2h-7.12c-2.278 0-3.417 0-4.284.45A4 4 0 004.45 4.156C4 5.023 4 6.162 4 8.44v11.12c0 2.278 0 3.417.45 4.284a4 4 0 001.706 1.706c.867.45 2.006.45 4.284.45h7.12c2.278 0 3.417 0 4.284-.45a4 4 0 001.706-1.706c.45-.867.45-2.006.45-4.284V8.44c0-2.278 0-3.417-.45-4.284a4 4 0 00-1.706-1.706zM22 19.56V10H6v9.56c0 1.173.002 1.93.05 2.506.045.552.123.756.175.856a2 2 0 00.853.853c.1.052.304.13.856.176.576.047 1.333.049 2.506.049h7.12c1.173 0 1.93-.002 2.506-.05.552-.045.756-.123.856-.175a2 2 0 00.853-.853c.052-.1.13-.304.176-.856.047-.576.049-1.333.049-2.506zm-.05-13.626c.042.5.049 1.138.05 2.066H6c.001-.928.008-1.565.05-2.066.045-.552.123-.756.175-.856a2 2 0 01.853-.853c.1-.052.304-.13.856-.176C8.51 4.002 9.267 4 10.44 4h7.12c1.173 0 1.93.002 2.506.05.552.045.756.123.856.175a2 2 0 01.853.853c.052.1.13.304.176.856z" fill-rule="evenodd" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28WasherOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28WasherOutline: FC<Icon28WasherOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28WasherOutline as any).mountIcon = mountIcon;

export default Icon28WasherOutline;
