import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'document_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="document_36"><path clip-rule="evenodd" d="M22.948 3.653a3.918 3.918 0 00-1.227-.508c-.445-.103-.847-.145-2.01-.145H11.77c-2.006 0-2.734.209-3.467.601a4.089 4.089 0 00-1.701 1.701C6.209 6.035 6 6.762 6 8.77V25.73c0 2.006.209 2.734.601 3.467s.968 1.309 1.701 1.701c.733.392 1.46.601 3.467.601H25.73c2.006 0 2.734-.209 3.467-.601a4.09 4.09 0 001.701-1.701c.392-.733.601-1.46.601-3.467V14.788c0-1.162-.042-1.564-.145-2.01-.102-.445-.266-.839-.508-1.226s-.497-.702-1.319-1.524l-5.056-5.056c-.822-.822-1.136-1.077-1.524-1.319zm-2.096 2.511A.5.5 0 0020 6.52V12.5a.5.5 0 00.5.5h6.035a.5.5 0 00.352-.855z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36DocumentProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36Document: FC<Icon36DocumentProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36Document as any).mountIcon = mountIcon;

export default Icon36Document;
