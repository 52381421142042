import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'attach_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="attach_20"><path clip-rule="evenodd" d="M14.95 3.801a2.722 2.722 0 00-3.857 0L5.56 9.35a4.49 4.49 0 000 6.338 4.458 4.458 0 006.317 0l.002-.002 2.88-2.86a.75.75 0 011.057 1.064l-2.877 2.857-.002.002a5.958 5.958 0 01-8.439-.001 5.99 5.99 0 010-8.458l5.534-5.548a4.222 4.222 0 015.981 0 4.244 4.244 0 010 5.991l-5.534 5.548a2.486 2.486 0 01-3.521 0 2.497 2.497 0 010-3.525l.002-.002 3.102-3.083a.75.75 0 011.058 1.064l-3.1 3.08-.001.002a.997.997 0 000 1.405.986.986 0 001.398 0l5.534-5.548a2.744 2.744 0 000-3.873z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20AttachProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20Attach: FC<Icon20AttachProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20Attach as any).mountIcon = mountIcon;

export default Icon20Attach;
