import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'playlist_36';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="playlist_36"><g fill="none" fill-rule="evenodd"><path d="M0 0h36v36H0z" /><path d="M20.995 10H5.005C4.45 10 4 10.45 4 11.005v.99C4 12.55 4.45 13 5.005 13h15.99C21.55 13 22 12.55 22 11.995v-.99C22 10.45 21.55 10 20.995 10zm0 6H5.005C4.45 16 4 16.45 4 17.005v.99C4 18.55 4.45 19 5.005 19h15.99C21.55 19 22 18.55 22 17.995v-.99C22 16.45 21.55 16 20.995 16zm-15.99 9C4.45 25 4 24.55 4 23.995v-.99C4 22.45 4.45 22 5.005 22h10.99c.555 0 1.005.45 1.005 1.005v.99C17 24.55 16.55 25 15.995 25H5.005zM27 23.133c0 4.513-.875 5.384-4.374 5.842-1.669.22-3.626-.538-3.626-3.161 0-1.282.802-2.498 2.461-2.816 1.267-.243-.09.018 2.776-.515.696-.129.775-.378.775-.908 0-.267-.001-2.595-.01-4.194v-4.318s-.005-2.058 0-3.088c.007-1.23.68-1.683 2.664-2.084 0 0 3.023-.573 4.685-.875.368-.067.649.07.649.492 0 0-.01 2.622 0 4.054.003.416-.168.605-.59.68l-4.865.86c-.374.092-.545.355-.545.733v9.298z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36PlaylistProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36Playlist: FC<Icon36PlaylistProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36Playlist as any).mountIcon = mountIcon;

export default Icon36Playlist;
