import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'live_outline_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="live_outline_36"><g fill="currentColor"><path d="M20.5 18a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z" /><g clip-rule="evenodd" fill-rule="evenodd"><path d="M9 18a9 9 0 1118 0 9 9 0 01-18 0zm15.245 0a6.245 6.245 0 10-12.49 0 6.245 6.245 0 0012.49 0z" /><path d="M33 18c0-8.284-6.716-15-15-15C9.716 3 3 9.716 3 18c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15zM5.5 18c0-6.904 5.596-12.5 12.5-12.5S30.5 11.096 30.5 18 24.904 30.5 18 30.5 5.5 24.904 5.5 18z" /></g></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36LiveOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36LiveOutline: FC<Icon36LiveOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36LiveOutline as any).mountIcon = mountIcon;

export default Icon36LiveOutline;
