import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'horse_toy_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="horse_toy_outline_28"><path clip-rule="evenodd" d="M7.518 14.412A3 3 0 0110.459 12H13.4c.56 0 .84 0 1.054-.109a1 1 0 00.437-.437C15 11.24 15 10.96 15 10.4v-.9C15 6.467 17.467 4 20.5 4a5.47 5.47 0 013.39 1.171c.295.232.565.493.808.779.541.64.309.99-.698 1.05v4.25a.75.75 0 01-.75.75h-.132c-.34 0-.567 0-.97-.36A3.642 3.642 0 0122 11.5l-1.43-1.194a7.64 7.64 0 01-.066-.05c-.134-.103-.18-.14-.304-.09-.126.05-.206.527-.205.752A.526.526 0 0020 11l1.63 9.005c.05.276.075.414.147.51a.514.514 0 00.35.194c.119.012.243-.037.493-.134a.997.997 0 011.311.56 1 1 0 01-.567 1.296l-.016.007a21.235 21.235 0 01-3.14.941c-.292.064-.588.123-.888.177C17.672 23.852 15.9 24 14 24c-1.9 0-3.673-.148-5.32-.444a24.107 24.107 0 01-1.466-.311 21.199 21.199 0 01-2.562-.807l-.017-.007a1 1 0 11.745-1.856c.27.106.406.159.536.14a.515.515 0 00.31-.17c.085-.098.115-.247.174-.545zm-2.861 4.107l.9-4.5A5 5 0 0110.459 10H13v-.5C13 5.363 16.363 2 20.5 2a7.49 7.49 0 015.724 2.657c.214.254.491.634.644 1.122.172.552.196 1.314-.273 2.02a2.385 2.385 0 01-.595.616v2.835A2.75 2.75 0 0123.25 14h-.165c-.098 0-.283.002-.514-.027l.824 4.553a3 3 0 01.698 5.768C21.133 25.452 17.754 26 14 26s-7.134-.548-10.094-1.706a3 3 0 01.75-5.775zm4.351 2.773c.006.099.009.148.032.194.019.038.053.076.089.099.043.028.094.036.197.053C10.771 21.88 12.33 22 14 22c1.785 0 3.44-.138 4.966-.413a5 5 0 10-9.957-.295zm7.772-1.421a3 3 0 00-5.56 0c.878.085 1.804.13 2.78.13.976 0 1.902-.045 2.78-.13z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28HorseToyOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28HorseToyOutline: FC<Icon28HorseToyOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28HorseToyOutline as any).mountIcon = mountIcon;

export default Icon28HorseToyOutline;
