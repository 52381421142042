import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'coins_stacks_3_outline_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="coins_stacks_3_outline_36"><path d="M18 3c3.795 0 7 1.602 7 4v4.038c3.257.248 5.864 1.702 5.995 3.796L31 15v12l-.005.166C30.85 29.471 27.707 31 24 31c-3.364 0-6.263-1.259-6.88-3.214C15.832 28.556 13.997 29 12 29c-3.707 0-6.85-1.529-6.995-3.834L5 25v-8l.005-.166c.131-2.095 2.739-3.548 5.995-3.796V7l.005-.166C11.15 4.529 14.293 3 18 3zm11 22.856C27.72 26.583 25.937 27 24 27s-3.718-.417-5-1.143V27l.006.086C19.136 27.983 21.256 29 24 29s4.864-1.017 4.994-1.914L29 27zm-12-2C15.72 24.583 13.937 25 12 25s-3.718-.417-5-1.143V25l.006.086C7.136 25.983 9.256 27 12 27c2.744 0 4.864-1.017 4.994-1.914L17 25zm12-2C27.72 22.583 25.937 23 24 23s-3.718-.417-5-1.143V23c0 .916 2.168 2 5 2 2.744 0 4.864-1.017 4.994-1.914L29 23zm-12-2C15.72 20.583 13.937 21 12 21s-3.718-.417-5-1.143V21c0 .916 2.168 2 5 2 2.744 0 4.864-1.017 4.994-1.914L17 21zm12-2C27.72 18.583 25.937 19 24 19s-3.718-.417-5-1.143V19c0 .916 2.168 2 5 2 2.744 0 4.864-1.017 4.994-1.914L29 19zM12 15c-2.832 0-5 1.084-5 2s2.168 2 5 2 5-1.084 5-2-2.168-2-5-2zm12-2c-2.832 0-5 1.084-5 2s2.168 2 5 2 5-1.084 5-2-2.168-2-5-2zm-1-3.144C21.72 10.583 19.937 11 18 11s-3.718-.417-5-1.144V11c0 .916 2.168 2 5 2l-.13-.002c1.048-1.083 2.941-1.793 5.127-1.96L23 11zM18 5c-2.832 0-5 1.084-5 2s2.168 2 5 2 5-1.084 5-2-2.168-2-5-2z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36CoinsStacks3OutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36CoinsStacks3Outline: FC<Icon36CoinsStacks3OutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36CoinsStacks3Outline as any).mountIcon = mountIcon;

export default Icon36CoinsStacks3Outline;
