import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'story_circle_fill_violet_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="story_circle_fill_violet_28"><linearGradient id="story_circle_fill_violet_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#c48aff" /><stop offset="1" stop-color="#aa65f0" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#story_circle_fill_violet_28_a)" /><path d="M17.012 7c1.387 0 1.89.144 2.396.416s.905.669 1.176 1.176c.272.507.416 1.01.416 2.396v6.024c0 1.387-.144 1.89-.415 2.396-.272.507-.67.905-1.177 1.176-.507.272-1.01.416-2.396.416h-6.024c-1.387 0-1.89-.144-2.396-.415-.507-.272-.905-.67-1.176-1.177S7 18.398 7 17.012v-6.024c0-1.387.144-1.89.416-2.396a2.827 2.827 0 011.176-1.176C9.099 7.144 9.602 7 10.988 7zm-3.01 3.113a3.89 3.89 0 103.889 3.889 3.89 3.89 0 00-3.889-3.889zM14 12a2 2 0 110 4 2 2 0 010-4z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28StoryCircleFillVioletProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28StoryCircleFillViolet: FC<Icon28StoryCircleFillVioletProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28StoryCircleFillViolet as any).mountIcon = mountIcon;

export default Icon28StoryCircleFillViolet;
