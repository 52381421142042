import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'story_add_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="story_add_outline_28"><g fill="currentColor"><path d="M13.1 4a1 1 0 01.117 1.993L13.1 6h-1.837c-1.828 0-2.538.144-3.273.558a3.564 3.564 0 00-1.446 1.526c-.381.75-.528 1.482-.543 3.211L6 16.407c0 1.946.141 2.716.544 3.51a3.564 3.564 0 001.446 1.525c.617.347 1.216.505 2.472.546l.26.007.541.005h5.474l.541-.005c1.436-.028 2.074-.183 2.732-.553a3.564 3.564 0 001.446-1.526c.338-.665.492-1.314.533-2.655l.006-.277.005-.577V14a1 1 0 011.993-.117L24 14v2.407c0 2.235-.196 3.303-.761 4.415a5.563 5.563 0 01-2.248 2.363c-1.018.573-2.002.792-3.925.813l-.33.002h-5.473c-2.143 0-3.18-.21-4.254-.815a5.563 5.563 0 01-2.248-2.363c-.537-1.057-.74-2.073-.76-4.088L4 11.593c0-2.235.196-3.303.761-4.415a5.563 5.563 0 012.248-2.362c1.018-.573 2.002-.793 3.925-.814z" /><path clip-rule="evenodd" d="M14 9.7a4.5 4.5 0 110 9 4.5 4.5 0 010-9zm0 2a2.5 2.5 0 100 5 2.5 2.5 0 000-5z" fill-rule="evenodd" /><path d="M21 1a1 1 0 01.993.883L22 2v3h3a1 1 0 01.993.883L26 6a1 1 0 01-.883.993L25 7h-3v3a1 1 0 01-1.993.117L20 10V7h-3a1 1 0 01-.993-.883L16 6a1 1 0 01.883-.993L17 5h3V2a1 1 0 011-1z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28StoryAddOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28StoryAddOutline: FC<Icon28StoryAddOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28StoryAddOutline as any).mountIcon = mountIcon;

export default Icon28StoryAddOutline;
