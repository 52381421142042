import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'switch_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="switch_outline_28"><path clip-rule="evenodd" d="M9.5 6.203A9.001 9.001 0 0122.945 13H21.16a.556.556 0 00-.454.88l2.792 3.888c.222.31.684.31.906 0l2.792-3.888a.556.556 0 00-.454-.88h-1.786C24.45 7.394 19.738 3 14 3c-2.002 0-3.882.536-5.5 1.472a1 1 0 101 1.731zM3.046 15H1.259a.556.556 0 01-.454-.88l2.792-3.888a.558.558 0 01.906 0l2.792 3.888c.264.368 0 .88-.454.88H5.055a9.001 9.001 0 0013.484 6.773 1 1 0 011.01 1.726C17.92 24.454 16.023 25 14 25 8.262 25 3.55 20.606 3.045 15z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28SwitchOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28SwitchOutline: FC<Icon28SwitchOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28SwitchOutline as any).mountIcon = mountIcon;

export default Icon28SwitchOutline;
