import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'bug_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="bug_outline_24"><g fill="currentColor"><path d="M10.5 10.4a.9.9 0 01.9-.9h1.2a.9.9 0 110 1.8h-1.2a.9.9 0 01-.9-.9zm0 5a.9.9 0 01.9-.9h1.2a.9.9 0 110 1.8h-1.2a.9.9 0 01-.9-.9z" /><g clip-rule="evenodd" fill-rule="evenodd"><path d="M3 8a.9.9 0 01.9-.9h2.275a6.937 6.937 0 011.77-1.883l-1.681-1.68a.9.9 0 011.272-1.273l2.065 2.064C10.348 4.051 11.156 3.9 12 3.9s1.652.151 2.399.428l2.065-2.064a.9.9 0 011.272 1.272l-1.68 1.681a6.937 6.937 0 011.77 1.883H20.1a.9.9 0 110 1.8h-1.465c.173.604.265 1.241.265 1.9v1.3h3.2a.9.9 0 110 1.8h-3.2V15c0 .732-.114 1.438-.325 2.1H20.1a.9.9 0 110 1.8h-2.407a6.893 6.893 0 01-5.693 3 6.893 6.893 0 01-5.693-3H3.9a.9.9 0 010-1.8h1.525A6.896 6.896 0 015.1 15v-1.1H1.9a.9.9 0 010-1.8h3.2v-1.3c0-.659.092-1.296.265-1.9H3.9A.9.9 0 013 8zm3.9 2.8a5.1 5.1 0 1110.2 0V15a5.1 5.1 0 01-10.2 0z" /><path d="M10.5 10.4a.9.9 0 01.9-.9h1.2a.9.9 0 110 1.8h-1.2a.9.9 0 01-.9-.9zm0 5a.9.9 0 01.9-.9h1.2a.9.9 0 110 1.8h-1.2a.9.9 0 01-.9-.9z" /></g></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24BugOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24BugOutline: FC<Icon24BugOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24BugOutline as any).mountIcon = mountIcon;

export default Icon24BugOutline;
