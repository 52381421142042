import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'pin_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="pin_outline_24"><path d="M13.158 3.724a.9.9 0 011.52-.46l6.058 6.06a.9.9 0 01-.46 1.518l-1.8.36a.325.325 0 00-.166.09l-2.88 2.88a.324.324 0 00-.094.204l-.347 4.511a1.206 1.206 0 01-2.055.76L9.28 15.994l-4.744 4.744a.9.9 0 01-1.353-1.18l.08-.093 4.744-4.744-3.654-3.653a1.206 1.206 0 01-.088-1.608l.088-.098c.204-.203.473-.327.76-.35l4.512-.346a.324.324 0 00.204-.094l2.88-2.88a.324.324 0 00.09-.166l.36-1.8zm1.435 2.002l-.03.152a2.125 2.125 0 01-.58 1.085l-2.88 2.88a2.125 2.125 0 01-1.34.616l-3.224.248 6.754 6.754.248-3.223c.034-.443.206-.862.489-1.2l.127-.14 2.88-2.88a2.124 2.124 0 011.086-.58l.151-.031-3.681-3.681z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24PinOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24PinOutline: FC<Icon24PinOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24PinOutline as any).mountIcon = mountIcon;

export default Icon24PinOutline;
