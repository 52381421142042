import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'pen_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="pen_outline_24"><path clip-rule="evenodd" d="M14.26 7.478l-9.038 9.037a1.1 1.1 0 00-.322.778V19a.1.1 0 00.1.1h1.707a1.1 1.1 0 00.778-.322l9.037-9.037zm1.272-1.273l2.263 2.263 1.131-1.131a.6.6 0 000-.849l-1.414-1.414a.6.6 0 00-.849 0zM3.95 15.242L15.391 3.801a2.4 2.4 0 013.394 0l1.414 1.414a2.4 2.4 0 010 3.394L8.758 20.051a2.9 2.9 0 01-2.05.849H5A1.9 1.9 0 013.1 19v-1.707a2.9 2.9 0 01.85-2.05z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24PenOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24PenOutline: FC<Icon24PenOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24PenOutline as any).mountIcon = mountIcon;

export default Icon24PenOutline;
