import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'picture_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="picture_outline_20"><path clip-rule="evenodd" d="M6.838 16.437c.759.062 1.737.063 3.162.063 1.425 0 2.403-.001 3.162-.063.74-.06 1.139-.172 1.427-.318a3.5 3.5 0 001.53-1.53c.146-.288.257-.686.318-1.427.016-.194.027-.402.036-.628l-.003-.004-1.303-1.303a12.742 12.742 0 00-.666-.64c-.156-.133-.23-.166-.27-.179a.75.75 0 00-.463 0c-.04.013-.113.046-.269.179-.162.138-.36.335-.666.64L10.53 13.53a.75.75 0 01-1.06 0l-.303-.303a12.75 12.75 0 00-.666-.64c-.156-.133-.23-.166-.27-.179a.75.75 0 00-.463 0c-.04.013-.113.046-.269.179-.162.138-.36.335-.666.64l-2.277 2.278c.25.245.539.452.855.614.288.146.686.257 1.427.318zm9.39-6.27l.272.272V10c0-1.425-.001-2.403-.063-3.162-.06-.74-.172-1.139-.318-1.427a3.5 3.5 0 00-1.53-1.53c-.288-.146-.686-.257-1.427-.318C12.403 3.501 11.425 3.5 10 3.5c-1.425 0-2.403.001-3.162.063-.74.06-1.139.172-1.427.318a3.5 3.5 0 00-1.53 1.53c-.146.288-.257.686-.318 1.427C3.501 7.597 3.5 8.575 3.5 10c0 1.425.001 2.403.063 3.162.038.463.096.793.17 1.045l2.04-2.04.02-.02c.28-.28.52-.521.736-.704.226-.192.473-.363.776-.462a2.25 2.25 0 011.39 0c.303.099.55.27.776.462.16.136.335.304.529.497l1.773-1.773.02-.02c.28-.28.52-.521.736-.704.226-.192.473-.363.776-.462a2.25 2.25 0 011.39 0c.303.099.55.27.776.462.215.183.456.424.736.703zM2.544 4.73C2 5.8 2 7.2 2 10c0 2.8 0 4.2.545 5.27a5 5 0 002.185 2.185C5.8 18 7.2 18 10 18c2.8 0 4.2 0 5.27-.545a5 5 0 002.185-2.185C18 14.2 18 12.8 18 10c0-2.8 0-4.2-.545-5.27a5 5 0 00-2.185-2.185C14.2 2 12.8 2 10 2c-2.8 0-4.2 0-5.27.545A5 5 0 002.545 4.73zM7.25 6a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20PictureOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20PictureOutline: FC<Icon20PictureOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20PictureOutline as any).mountIcon = mountIcon;

export default Icon20PictureOutline;
