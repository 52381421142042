import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'open_in_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="open_in_24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" /><path d="M3 7v10a3 3 0 003 3h2a1 1 0 000-2H6a1 1 0 01-1-1V9a1 1 0 011-1h12a1 1 0 011 1v8a1 1 0 01-1 1h-2a1 1 0 000 2h2a3 3 0 003-3V7a3 3 0 00-3-3H6a3 3 0 00-3 3zm8.646 3.354l-2.792 2.792a.5.5 0 00.353.854H11v5a1 1 0 002 0v-5h1.793a.5.5 0 00.353-.854l-2.792-2.792a.5.5 0 00-.708 0z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24OpenInProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24OpenIn: FC<Icon24OpenInProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24OpenIn as any).mountIcon = mountIcon;

export default Icon24OpenIn;
