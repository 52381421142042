import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'story_reply_circle_fill_violet_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="story_reply_circle_fill_violet_28"><linearGradient id="story_reply_circle_fill_violet_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#c48aff" /><stop offset="1" stop-color="#aa65f0" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#story_reply_circle_fill_violet_28_a)" /><path clip-rule="evenodd" d="M11.905 9.494a1 1 0 01.1 1.41L10.19 13H14a7.992 7.992 0 016.568 3.431 1 1 0 01-1.641 1.144A5.992 5.992 0 0014 15h-3.81l1.816 2.095a1 1 0 11-1.512 1.31l-3.25-3.75a1 1 0 010-1.31l3.25-3.75a1 1 0 011.41-.1z" fill="#fff" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28StoryReplyCircleFillVioletProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28StoryReplyCircleFillViolet: FC<Icon28StoryReplyCircleFillVioletProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28StoryReplyCircleFillViolet as any).mountIcon = mountIcon;

export default Icon28StoryReplyCircleFillViolet;
