import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'bug_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="bug_24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" /><path d="M19 8h-1.81a5.985 5.985 0 00-1.82-1.96l.925-.925a.997.997 0 10-1.41-1.41L13.42 5.17C12.96 5.06 12.49 5 12 5c-.49 0-.96.06-1.41.17L9.117 3.703a1 1 0 00-1.414 1.414l.917.923C7.88 6.55 7.26 7.22 6.81 8H5a1 1 0 100 2h1.09c-.05.33-.09.66-.09 1v1H5a1 1 0 000 2h1v1c0 .34.04.67.09 1H5a1 1 0 000 2h1.81c1.04 1.79 2.97 3 5.19 3s4.15-1.21 5.19-3H19a1 1 0 000-2h-1.09c.05-.33.09-.66.09-1v-1h1a1 1 0 000-2h-1v-1c0-.34-.04-.67-.09-1H19a1 1 0 000-2zm-6 8h-2a1 1 0 010-2h2a1 1 0 010 2zm0-4h-2a1 1 0 010-2h2a1 1 0 010 2z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24BugProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Bug: FC<Icon24BugProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Bug as any).mountIcon = mountIcon;

export default Icon24Bug;
