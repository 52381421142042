import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'voice_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="voice_outline_24"><g fill="currentColor"><path clip-rule="evenodd" d="M7.1 7a4.9 4.9 0 119.8 0v4a4.9 4.9 0 11-9.8 0zM12 3.9A3.1 3.1 0 008.9 7v4a3.1 3.1 0 106.2 0V7A3.1 3.1 0 0012 3.9z" fill-rule="evenodd" /><path d="M5.8 14.464a.9.9 0 00-1.57.88 8.903 8.903 0 006.87 4.511V22a.9.9 0 101.8 0v-2.145a8.903 8.903 0 006.87-4.511.9.9 0 00-1.57-.88A7.098 7.098 0 0112 18.1a7.098 7.098 0 01-6.2-3.636z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24VoiceOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24VoiceOutline: FC<Icon24VoiceOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24VoiceOutline as any).mountIcon = mountIcon;

export default Icon24VoiceOutline;
