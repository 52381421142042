import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'unfavorite_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="unfavorite_outline_24"><path d="M9.782 4.005c.818-1.976 3.618-1.976 4.436 0l1.4 3.383 3.565.23c2.136.138 3.037 2.792 1.426 4.201l-1.477 1.292a.9.9 0 01-1.185-1.354l1.477-1.292a.6.6 0 00-.357-1.05l-4.125-.267a.9.9 0 01-.774-.554l-1.614-3.9a.6.6 0 00-1.108 0c-.204.492-.68.835-1.227.61-.545-.227-.64-.806-.437-1.299zM12 18.54l3.022 1.743c1.456.84 3.149-.035 3.526-1.462l1.416 1.415a.9.9 0 101.272-1.272l-15.2-15.2a.9.9 0 00-1.272 1.272l2.428 2.429-2.375.153C2.68 7.756 1.78 10.41 3.39 11.819l2.856 2.5-.802 3.322c-.495 2.05 1.706 3.696 3.533 2.642zM4.933 9.413a.6.6 0 00-.357 1.05l3.267 2.859a.9.9 0 01.282.888l-.93 3.852a.6.6 0 00.883.66l3.472-2.003a.9.9 0 01.9 0l3.472 2.004a.6.6 0 00.883-.66l-.284-1.177a.896.896 0 01-.02-.113L8.886 9.159z" clip-rule="evenodd" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24UnfavoriteOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24UnfavoriteOutline: FC<Icon24UnfavoriteOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24UnfavoriteOutline as any).mountIcon = mountIcon;

export default Icon24UnfavoriteOutline;
