import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'game_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="game_24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" /><path d="M8 10V8.75a.5.5 0 00-.5-.5h-.25a.5.5 0 00-.5.5V10H5.5a.5.5 0 00-.5.5v.25a.5.5 0 00.5.5h1.25v1.25a.5.5 0 00.5.5h.25a.5.5 0 00.5-.5v-1.25h1.25a.5.5 0 00.5-.5v-.25a.5.5 0 00-.5-.5H8zm7.5 5.5H8.496C7.183 15.5 5.5 20 3.683 20c-1.75 0-2.187-.875-2.187-3.5S1.933 6 6.746 6h10.5c4.812 0 5.25 7.875 5.25 10.5s-.438 3.5-2.188 3.5c-1.808 0-3.495-4.5-4.808-4.5zm-.75-5.5a.5.5 0 00-.5.5v.25a.5.5 0 00.5.5H15a.5.5 0 00.5-.5v-.25a.5.5 0 00-.5-.5h-.25zm3.5 0a.5.5 0 00-.5.5v.25a.5.5 0 00.5.5h.25a.5.5 0 00.5-.5v-.25a.5.5 0 00-.5-.5h-.25zm-1.75 1.75a.5.5 0 00-.5.5v.25a.5.5 0 00.5.5h.25a.5.5 0 00.5-.5v-.25a.5.5 0 00-.5-.5h-.25zm0-3.5a.5.5 0 00-.5.5V9a.5.5 0 00.5.5h.25a.5.5 0 00.5-.5v-.25a.5.5 0 00-.5-.5h-.25z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24GameProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Game: FC<Icon24GameProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Game as any).mountIcon = mountIcon;

export default Icon24Game;
