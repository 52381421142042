import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'message_unread_top_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="message_unread_top_outline_20"><path d="M15 3a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm-4.75 1c-4.794 0-8.751 5.182-5.61 9.349a.75.75 0 01.15.49c-.04.756-.403 1.785-1.085 3.135 1.483-.116 2.514-.534 3.123-1.222a.75.75 0 01.821-.208C11.603 17 17 14.675 17 10a5.4 5.4 0 00-.495-2.258.75.75 0 111.364-.624A6.9 6.9 0 0118.5 10c0 5.501-6.075 8.587-10.91 7.107-.996.881-2.456 1.336-4.348 1.393a1.203 1.203 0 01-1.101-1.767c.644-1.216 1.016-2.14 1.121-2.73C-.297 8.854 4.368 2.5 10.25 2.5c.55 0 1.09.05 1.61.143a.75.75 0 01-.265 1.476A7.596 7.596 0 0010.25 4z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20MessageUnreadTopOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20MessageUnreadTopOutline: FC<Icon20MessageUnreadTopOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20MessageUnreadTopOutline as any).mountIcon = mountIcon;

export default Icon20MessageUnreadTopOutline;
