import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'hammer_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="hammer_outline_24"><path clip-rule="evenodd" d="M10.123 3.84c-.283.24-.602.559-.977.933l-.49.49-.934-.935c-.221-.222-.449-.439-.754-.538a1.4 1.4 0 00-.865 0c-.215.07-.372.186-.479.276L4.066 5.624c-.09.107-.206.264-.276.479a1.4 1.4 0 000 .865c.099.305.316.533.538.754l.935.935-.49.49c-.374.374-.693.693-.934.976-.252.298-.47.615-.596 1.001a2.9 2.9 0 000 1.792c.125.387.344.704.596 1.001.24.284.56.603.934.977l1.33 1.33c.375.375.694.694.977.934.298.253.615.472 1.001.597a2.9 2.9 0 001.793 0c.386-.125.703-.344 1-.597.284-.24.603-.56.978-.934l.489-.489 5.547 5.547.016.016c.085.085.182.183.275.261.106.09.263.207.479.277a1.4 1.4 0 00.865 0 1.41 1.41 0 00.478-.277c.093-.078.19-.176.276-.261l1.02-1.021a4.27 4.27 0 00.262-.276 1.41 1.41 0 00.277-.478 1.4 1.4 0 000-.866 1.41 1.41 0 00-.277-.478c-.078-.093-.176-.19-.261-.275l-.016-.016-5.547-5.547.49-.49c.374-.374.693-.693.934-.976.252-.298.47-.615.596-1.001a2.9 2.9 0 000-1.793c-.125-.386-.344-.703-.596-1a17.83 17.83 0 00-.935-.978l-1.33-1.33a17.81 17.81 0 00-.977-.934c-.297-.252-.614-.471-1-.596a2.9 2.9 0 00-1.793 0c-.386.125-.703.344-1 .596zm-4.912 7.448c-.18.213-.234.322-.257.392a1.1 1.1 0 000 .68c.023.07.077.18.257.392.188.22.454.488.86.895l1.28 1.28c.407.406.674.672.894.86.213.18.322.233.393.256a1.1 1.1 0 00.68 0c.07-.023.18-.076.392-.257.22-.187.487-.453.894-.86l4.322-4.322c.407-.407.673-.674.86-.894.18-.213.234-.322.257-.393a1.1 1.1 0 000-.68c-.023-.07-.076-.18-.257-.392-.187-.22-.453-.487-.86-.894l-1.28-1.28a17.258 17.258 0 00-.894-.86c-.212-.18-.321-.234-.392-.257a1.1 1.1 0 00-.68 0c-.07.023-.18.077-.392.257-.22.188-.488.454-.894.86L6.07 10.394a17.68 17.68 0 00-.86.894zm13.88 8.65l.848-.848-5.477-5.476-.848.848zM6.535 7.385l-.849-.848.849-.849.848.849z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24HammerOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24HammerOutline: FC<Icon24HammerOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24HammerOutline as any).mountIcon = mountIcon;

export default Icon24HammerOutline;
