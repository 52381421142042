import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'user_circle_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="user_circle_outline_56"><g fill="currentColor"><path clip-rule="evenodd" d="M37.5 24.5c0 5.248-4.252 9.5-9.5 9.5s-9.5-4.252-9.5-9.5S22.752 15 28 15s9.5 4.252 9.5 9.5zm-3 0c0-3.592-2.908-6.5-6.5-6.5s-6.5 2.908-6.5 6.5S24.408 31 28 31s6.5-2.908 6.5-6.5z" fill-rule="evenodd" /><path d="M28 37.501a27.394 27.394 0 0117.107 5.966l-2.25 2.05c-4.122-3.147-9.27-5.016-14.857-5.016s-10.735 1.869-14.857 5.016l-2.25-2.05A27.394 27.394 0 0128 37.501z" /><path clip-rule="evenodd" d="M52 28c0 13.255-10.745 24-24 24S4 41.255 4 28 14.745 4 28 4s24 10.745 24 24zm-3 0c0 11.598-9.402 21-21 21S7 39.598 7 28 16.402 7 28 7s21 9.402 21 21z" fill-rule="evenodd" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56UserCircleOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56UserCircleOutline: FC<Icon56UserCircleOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56UserCircleOutline as any).mountIcon = mountIcon;

export default Icon56UserCircleOutline;
