import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'arrow_up_circle_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="arrow_up_circle_outline_28"><path d="M14 9l.707-.707a1 1 0 00-1.414 0zm-1 10a1 1 0 102 0zm-3.707-6.707a1 1 0 101.414 1.414zm8 1.414a1 1 0 001.414-1.414zM13 9v10h2V9zm-2.293 4.707l4-4-1.414-1.414-4 4zm2.586-4l4 4 1.414-1.414-4-4zM24 14c0 5.523-4.477 10-10 10v2c6.627 0 12-5.373 12-12zM14 24C8.477 24 4 19.523 4 14H2c0 6.627 5.373 12 12 12zM4 14C4 8.477 8.477 4 14 4V2C7.373 2 2 7.373 2 14zM14 4c5.523 0 10 4.477 10 10h2c0-6.627-5.373-12-12-12z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28ArrowUpCircleOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28ArrowUpCircleOutline: FC<Icon28ArrowUpCircleOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28ArrowUpCircleOutline as any).mountIcon = mountIcon;

export default Icon28ArrowUpCircleOutline;
