import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'user_added_outline_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="user_added_outline_24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" /><path d="M18.85 7.95A2.849 2.849 0 0016 5.1a2.849 2.849 0 00-2.85 2.85A2.849 2.849 0 0016 10.8a2.849 2.849 0 002.85-2.85zm1.8 0A4.649 4.649 0 0116 12.6a4.649 4.649 0 01-4.65-4.65A4.649 4.649 0 0116 3.3a4.649 4.649 0 014.65 4.65zM9.9 18.429c0 .768-.09.671.335.671h11.53c.426 0 .335.097.335-.671 0-1.893-2.778-3.029-6.1-3.029-3.322 0-6.1 1.136-6.1 3.029zm-1.8 0c0-3.327 3.673-4.829 7.9-4.829s7.9 1.502 7.9 4.829c0 1.735-.686 2.471-2.135 2.471h-11.53c-1.45 0-2.135-.736-2.135-2.471zm.264-9.565a.9.9 0 111.272 1.272l-5 5a.9.9 0 01-1.272 0l-2.5-2.5a.9.9 0 111.272-1.272L4 13.227l4.364-4.363z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24UserAddedOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24UserAddedOutline: FC<Icon24UserAddedOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24UserAddedOutline as any).mountIcon = mountIcon;

export default Icon24UserAddedOutline;
