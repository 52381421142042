import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'podcast_outline_20';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="podcast_outline_20"><g fill="none" fill-rule="evenodd"><path opacity=".4" d="M0 0h20v20H0z" /><path d="M10 5a4 4 0 014 4v3c0 1.953-1.4 3.578-3.25 3.93v1.32a.75.75 0 11-1.5 0v-1.32A4.001 4.001 0 016 12V9a4 4 0 014-4zm0 1.5A2.5 2.5 0 007.5 9v3a2.5 2.5 0 004.502 1.498 3.114 3.114 0 01-.13.002H10.45a.75.75 0 110-1.5h2.05v-1.5h-2.05a.75.75 0 110-1.5h2.05A2.5 2.5 0 0010 6.5zM10 2a7.001 7.001 0 016.938 6.065A1.5 1.5 0 0118 9.5v3a1.5 1.5 0 01-3 0v-3c0-.428.18-.815.468-1.088a5.499 5.499 0 00-5.247-4.908L10 3.5a5.5 5.5 0 00-5.469 4.912c.29.273.469.66.469 1.088v3a1.5 1.5 0 01-3 0v-3a1.5 1.5 0 011.062-1.435A7.002 7.002 0 0110 2z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20PodcastOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20PodcastOutline: FC<Icon20PodcastOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20PodcastOutline as any).mountIcon = mountIcon;

export default Icon20PodcastOutline;
