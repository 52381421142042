import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'advertising_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="advertising_16"><path d="M14.2 13c-.169 0-.388-.05-.529-.149L11.232 10.7s-.123-.11-.152-.146a.35.35 0 01-.062-.122.52.52 0 01-.018-.143V4.766a.68.68 0 01.019-.205.348.348 0 01.062-.122 2.43 2.43 0 01.152-.149l2.438-2.135c.141-.1.371-.152.529-.155a.8.8 0 01.8.8v9.4a.8.8 0 01-.8.8zM9.2 4.5a.3.3 0 01.3.3v5.4a.3.3 0 01-.3.3l-2.2.002c0 .607.326 1.373.583 1.698.391.493.286 1.12-.182 1.53-.47.412-1.344.345-1.734-.148-.59-.743-1.167-1.93-1.167-3.08L4 10.5a3 3 0 010-6h5.2z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16AdvertisingProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16Advertising: FC<Icon16AdvertisingProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16Advertising as any).mountIcon = mountIcon;

export default Icon16Advertising;
