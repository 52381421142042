import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'mention_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="mention_24"><path d="M12.007 22.869c-2.02 0-3.781-.408-5.287-1.224a8.532 8.532 0 01-3.489-3.476C2.411 16.667 2 14.916 2 12.915v-.724c0-1.993.428-3.761 1.284-5.306A9.043 9.043 0 016.89 3.284C8.441 2.428 10.211 2 12.204 2h.105c1.861 0 3.527.384 4.997 1.152 1.47.768 2.62 1.83 3.45 3.186C21.586 7.695 22 9.233 22 10.953v.659c0 1.176-.193 2.216-.58 3.12-.386.904-.925 1.609-1.619 2.113-.693.505-1.492.757-2.396.757-.817 0-1.519-.17-2.107-.513-.588-.342-.939-.79-1.053-1.343h-.132c-.096.342-.29.656-.58.941-.289.286-.642.51-1.059.672a3.578 3.578 0 01-1.31.243c-.72 0-1.347-.175-1.883-.526-.535-.351-.948-.852-1.238-1.501-.29-.65-.434-1.418-.434-2.304v-1.317c0-.86.147-1.61.441-2.252.294-.64.715-1.132 1.264-1.474.549-.343 1.192-.514 1.929-.514.65 0 1.22.16 1.712.48.491.321.816.74.974 1.258h.105V9.11c0-.351.097-.63.29-.836.193-.206.456-.31.79-.31.325 0 .584.104.777.31.193.206.29.485.29.836v5.267c0 .447.135.805.407 1.073.273.267.628.401 1.067.401.465 0 .873-.18 1.224-.54.352-.36.621-.87.81-1.534.189-.662.283-1.437.283-2.323v-.435c0-1.378-.327-2.611-.98-3.7-.655-1.088-1.563-1.94-2.726-2.554-1.163-.614-2.482-.922-3.957-.922h-.105c-1.598 0-3.01.351-4.24 1.054a7.292 7.292 0 00-2.863 2.969c-.68 1.277-1.02 2.745-1.02 4.404v.566c0 1.65.328 3.096.987 4.338a6.918 6.918 0 002.798 2.87c1.207.672 2.613 1.008 4.22 1.008h.105c.597 0 1.338-.066 2.225-.197a9.11 9.11 0 01.909-.093c.254 0 .465.077.632.23.166.154.25.35.25.587s-.07.439-.211.605c-.14.167-.342.286-.606.356-.851.22-1.94.329-3.265.329h-.118zM9.9 12.968c0 .886.169 1.56.507 2.02.338.462.836.692 1.494.692.65 0 1.146-.222 1.488-.665.342-.443.514-1.086.514-1.929V12.02c0-.773-.183-1.383-.547-1.83-.364-.448-.858-.672-1.481-.672-.623 0-1.108.235-1.455.704-.347.47-.52 1.126-.52 1.969v.777z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24MentionProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Mention: FC<Icon24MentionProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Mention as any).mountIcon = mountIcon;

export default Icon24Mention;
