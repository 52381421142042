import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'mail_circle_fill_blue_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="mail_circle_fill_blue_28"><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#mail_circle_fill_blue_28_paint0_linear)" /><path d="M20 12.29v4.338c0 .825-.086 1.124-.247 1.426a1.68 1.68 0 01-.7.699c-.3.161-.6.247-1.425.247h-7.256c-.825 0-1.124-.086-1.426-.247a1.68 1.68 0 01-.699-.7c-.161-.3-.247-.6-.247-1.425V12.29l6 2.787 6-2.787zM17.628 9c.825 0 1.124.086 1.426.247.301.161.538.398.699.7.102.19.174.38.213.705L14 13.423l-5.966-2.772c.039-.323.11-.514.213-.705.161-.301.398-.538.7-.699.3-.161.6-.247 1.425-.247h7.256z" fill="#fff" /><defs><linearGradient id="mail_circle_fill_blue_28_paint0_linear" x1="-14" y1="14" x2="14" y2="42" gradientUnits="userSpaceOnUse"><stop stop-color="#70B2FF" /><stop offset="1" stop-color="#5C9CE6" /></linearGradient></defs></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28MailCircleFillBlueProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28MailCircleFillBlue: FC<Icon28MailCircleFillBlueProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28MailCircleFillBlue as any).mountIcon = mountIcon;

export default Icon28MailCircleFillBlue;
