import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'power_socket_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="power_socket_outline_28"><path clip-rule="evenodd" d="M21.912 3.784c-1.033-.552-2.03-.763-3.988-.784h-7.851c-1.959.02-2.955.232-3.988.784a5.543 5.543 0 00-2.3 2.3C3.231 7.119 3.02 8.115 3 10.074v7.851c.02 1.958.232 2.955.784 3.988a5.543 5.543 0 002.3 2.3c1.034.553 2.03.764 3.989.785h7.851c1.958-.021 2.955-.232 3.988-.785a5.543 5.543 0 002.3-2.3c.553-1.033.764-2.03.785-3.988v-7.851c-.021-1.959-.232-2.955-.785-3.988a5.543 5.543 0 00-2.3-2.3zM10.408 4.998h7.18l.558.005c1.48.028 2.142.18 2.823.545.64.342 1.137.84 1.48 1.48.364.681.516 1.343.544 2.823l.005.557v7.18l-.005.558c-.028 1.48-.18 2.142-.544 2.823a3.542 3.542 0 01-1.48 1.48c-.681.364-1.344.516-2.823.544l-.557.005h-7.181l-.557-.005c-1.48-.028-2.142-.18-2.823-.544a3.543 3.543 0 01-1.48-1.48c-.364-.681-.517-1.344-.545-2.823l-.005-.557v-7.181l.005-.557c.028-1.48.18-2.142.545-2.823.342-.64.84-1.138 1.48-1.48.681-.364 1.343-.517 2.823-.545zM19 14a5.002 5.002 0 01-4 4.9v-.1c0-.28 0-.42-.054-.527a.5.5 0 00-.219-.219C14.62 18 14.48 18 14.2 18h-.4c-.28 0-.42 0-.527.054a.5.5 0 00-.218.219C13 18.38 13 18.52 13 18.8v.1a5.002 5.002 0 010-9.8v.1c0 .28 0 .42.054.527a.5.5 0 00.219.218c.107.055.247.055.527.055h.4c.28 0 .42 0 .527-.055a.5.5 0 00.218-.218C15 9.62 15 9.48 15 9.2v-.1c2.282.463 4 2.481 4 4.9zm2 0a7 7 0 11-14 0 7 7 0 0114 0zm-9-1a1 1 0 100 2 1 1 0 000-2zm3 1a1 1 0 112 0 1 1 0 01-2 0z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28PowerSocketOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28PowerSocketOutline: FC<Icon28PowerSocketOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28PowerSocketOutline as any).mountIcon = mountIcon;

export default Icon28PowerSocketOutline;
