import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'tshirt_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="tshirt_outline_28"><path clip-rule="evenodd" d="M6.788 11.635c.434.109.65.163.812.284a1 1 0 01.322.413c.078.186.078.409.078.856v8.486l.001.207.012.019.013.02c.906.427 2.878 1.08 6.475 1.08 2.947 0 4.659-.63 5.477-1.047l.012-.02.01-.016v-.018l.001-.225v-8.486c0-.447 0-.67.078-.856a1 1 0 01.323-.413c.161-.12.378-.175.81-.283l.288-.072.004-.002.1-.03c.099-.035.262-.095.473-.189a7.994 7.994 0 001.365-.788l-2.076-3.636A3.809 3.809 0 0018.059 5h-.016c-2.027 2.668-6.059 2.668-8.086 0h-.013a3.81 3.81 0 00-3.307 1.92l-2.078 3.635a7.98 7.98 0 001.365.788 5.451 5.451 0 00.573.22H6.5zM22.001 13.5s1.38-.345 2.994-1.61c.624-.488.732-1.367.339-2.056l-2.232-3.907A5.808 5.808 0 0018.06 3h-.57a.932.932 0 00-.767.403l-.182.264c-1.226 1.778-3.854 1.778-5.08 0l-.183-.265A.93.93 0 0010.511 3h-.567A5.81 5.81 0 004.9 5.927L2.667 9.834c-.393.689-.285 1.568.339 2.057C4.619 13.155 6 13.5 6 13.5v8.174c0 .383 0 .574.113.895.07.198.326.606.474.754.24.24.338.287.531.38 1.2.576 3.48 1.297 7.383 1.297 3.253 0 5.282-.7 6.397-1.272.208-.106.311-.16.542-.398.146-.151.386-.544.454-.743.107-.314.107-.514.107-.913z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28TshirtOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28TshirtOutline: FC<Icon28TshirtOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28TshirtOutline as any).mountIcon = mountIcon;

export default Icon28TshirtOutline;
