import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'music_outline_32';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="music_outline_32"><path d="M23.573 3.277a3.649 3.649 0 014.393 3.573v12.776c0 5.72-5.586 8.66-8.619 5.627-3.032-3.033-.092-8.619 5.627-8.619h.592v-5.81l-12.76 2.67v9.304c0 5.608-5.369 8.544-8.437 5.799l-.182-.172c-3.032-3.033-.092-8.619 5.627-8.619l.592-.001V9.969c0-1.024.076-1.606.293-2.17l.089-.213c.27-.603.68-1.108 1.216-1.495.566-.41 1.127-.625 2.253-.86zm-13.167 18.93h-.592c-3.711 0-5.346 3.105-3.93 4.521s4.522-.219 4.522-3.93v-.592zm15.16-3.173h-.592c-3.71 0-5.346 3.106-3.93 4.522s4.522-.219 4.522-3.93zM25.54 6.595a1.249 1.249 0 00-1.476-.968l-9.57 2.009c-.628.14-.859.237-1.084.4-.194.14-.334.312-.432.53-.127.286-.172.561-.172 1.403v1.073l12.76-2.67V6.85c0-.043-.002-.086-.006-.128z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32MusicOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32MusicOutline: FC<Icon32MusicOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32MusicOutline as any).mountIcon = mountIcon;

export default Icon32MusicOutline;
