import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 48 48';
const id = 'pause_48';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" id="pause_48"><g fill="none" fill-rule="evenodd"><path d="M0 0h48v48H0z" /><path d="M13.424 8h5.152A2.424 2.424 0 0121 10.424v27.152A2.424 2.424 0 0118.576 40h-5.152A2.424 2.424 0 0111 37.576V10.424A2.424 2.424 0 0113.424 8zm16 0h5.152A2.424 2.424 0 0137 10.424v27.152A2.424 2.424 0 0134.576 40h-5.152A2.424 2.424 0 0127 37.576V10.424A2.424 2.424 0 0129.424 8z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon48PauseProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon48Pause: FC<Icon48PauseProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 48,
    height: !isNaN(props.height) ? +props.height : 48,
  }));
};

(Icon48Pause as any).mountIcon = mountIcon;

export default Icon48Pause;
