import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'advertising_outline_32';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="advertising_outline_32"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M10.755 18.532l.794 4.502c.05.282.207.516.426.669.219.153.492.221.774.172s.515-.208.669-.426.22-.492.171-.774l-.61-3.458 2.462-.434.61 3.458c.17.96-.068 1.902-.586 2.642s-1.32 1.284-2.282 1.454a3.528 3.528 0 01-2.642-.586 3.528 3.528 0 01-1.454-2.282l-.794-4.502z" /><path d="M25.367 6.991a1.029 1.029 0 00-.78.138l-5.127 3.264H9.714a3.877 3.877 0 00-2.752 1.14c-.706.706-1.14 1.677-1.14 2.753s.434 2.047 1.14 2.752a3.877 3.877 0 002.752 1.14h9.746l5.127 3.265a1.036 1.036 0 001.592-.874V8.002a1.035 1.035 0 00-.812-1.01zM23.244 5.02a3.528 3.528 0 012.665-.47 3.536 3.536 0 012.77 3.452V20.57c0 .976-.397 1.862-1.036 2.5a3.531 3.531 0 01-4.399.482l-4.513-2.872H9.715a6.377 6.377 0 01-4.52-1.873c-1.156-1.155-1.873-2.755-1.873-4.52s.717-3.365 1.873-4.52 2.755-1.873 4.52-1.873h9.018z" /><path d="M19.429 9.143v10.286h-2.286V9.143z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32AdvertisingOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32AdvertisingOutline: FC<Icon32AdvertisingOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32AdvertisingOutline as any).mountIcon = mountIcon;

export default Icon32AdvertisingOutline;
