import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'link_circle_outline_56';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="link_circle_outline_56"><g fill-rule="nonzero" fill="none"><path d="M0 0h56v56H0z" /><path d="M28 4c13.255 0 24 10.745 24 24S41.255 52 28 52 4 41.255 4 28 14.745 4 28 4zm0 3C16.402 7 7 16.402 7 28s9.402 21 21 21 21-9.402 21-21S39.598 7 28 7zm7.5 12a1.5 1.5 0 011.493 1.356L37 20.5v12a1.5 1.5 0 01-2.993.144L34 32.5v-8.38L21.56 36.56a1.5 1.5 0 01-2.224-2.007l.103-.114L31.878 22H23.5a1.5 1.5 0 01-1.493-1.356L22 20.5a1.5 1.5 0 011.356-1.493L23.5 19h12z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56LinkCircleOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56LinkCircleOutline: FC<Icon56LinkCircleOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56LinkCircleOutline as any).mountIcon = mountIcon;

export default Icon56LinkCircleOutline;
