import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'place_outline_20';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="place_outline_20"><g fill="none" fill-rule="evenodd"><path opacity=".4" d="M0 0h20v20H0z" /><path d="M10 1c4.148 0 7.5 3.433 7.5 7.5 0 2.85-1.843 6.172-5.435 10.095a2.8 2.8 0 01-4.13 0C4.343 14.672 2.5 11.35 2.5 8.5 2.5 4.433 5.852 1 10 1zm0 1.5c-3.382 0-6 2.825-6 6 0 2.383 1.68 5.41 5.041 9.082a1.3 1.3 0 001.918 0C14.319 13.911 16 10.883 16 8.5c0-3.175-2.618-6-6-6zM10 5a3.5 3.5 0 11-.001 7.001A3.5 3.5 0 0110 5zm0 1.5a2 2 0 10.001 4.001A2 2 0 0010 6.5z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20PlaceOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20PlaceOutline: FC<Icon20PlaceOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20PlaceOutline as any).mountIcon = mountIcon;

export default Icon20PlaceOutline;
