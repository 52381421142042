import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'game_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="game_outline_20"><path clip-rule="evenodd" d="M11.5 4h-3c-2.317 0-4.245.431-5.577 1.761C1.593 7.088 1.02 9.147 1 11.995c-.006.8.054 1.982.455 2.99.204.512.511 1.02.98 1.402.482.391 1.09.613 1.815.613.799 0 1.373-.327 1.81-.77.312-.314.58-.722.806-1.066l.16-.243c.285-.418.557-.759.917-1.005.344-.235.818-.416 1.557-.416h1c.739 0 1.213.181 1.558.416.36.246.631.587.915 1.005.051.075.105.157.161.243.226.344.494.752.805 1.066.438.443 1.012.77 1.811.77.725 0 1.333-.222 1.815-.613.47-.382.776-.89.98-1.402.4-1.008.46-2.19.455-2.99-.02-2.848-.593-4.907-1.923-6.234C15.745 4.431 13.818 4 11.5 4zm-1 8h-1c-1.011 0-1.787.256-2.403.677-.601.41-1.002.945-1.311 1.402-.083.122-.157.234-.226.338-.211.32-.37.56-.566.759-.21.213-.418.324-.744.324-.4 0-.669-.115-.868-.277-.212-.173-.391-.436-.533-.792-.29-.728-.354-1.67-.349-2.426.02-2.707.571-4.273 1.482-5.182C4.89 5.917 6.317 5.5 8.5 5.5h3c2.183 0 3.61.417 4.518 1.323.91.91 1.463 2.475 1.482 5.182.005.756-.06 1.698-.349 2.426-.142.356-.32.62-.533.792-.2.162-.468.277-.868.277-.326 0-.533-.11-.744-.324-.196-.199-.355-.439-.566-.759a29.642 29.642 0 00-.226-.338c-.31-.457-.71-.991-1.31-1.401C12.287 12.255 11.51 12 10.5 12zM7.25 7.5a.75.75 0 00-1.5 0v.75H5a.75.75 0 000 1.5h.75v.75a.75.75 0 001.5 0v-.75H8a.75.75 0 000-1.5h-.75zm5.25.75a.75.75 0 000 1.5h2a.75.75 0 000-1.5z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20GameOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20GameOutline: FC<Icon20GameOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20GameOutline as any).mountIcon = mountIcon;

export default Icon20GameOutline;
