import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'logo_google_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="logo_google_24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" /><path d="M12.179 2.008c2.512-.114 4.874.96 6.798 2.468-.82.917-1.671 1.8-2.58 2.627-2.313-1.571-5.603-2.02-7.916-.206-3.31 2.249-3.46 7.558-.277 9.979 3.095 2.76 8.763 1.432 9.76-2.376-1.69-.029-4.047.062-5.986 0a483.28 483.28 0 010-3.75c3.241-.01 6.731-.024 9.977 0 .195 2.674-.212 5.348-1.884 7.558-2.532 3.5-7.615 4.521-11.58 3.022-3.98-1.49-6.8-5.62-6.464-9.83.088-5.152 4.908-9.663 10.152-9.492z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24LogoGoogleProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24LogoGoogle: FC<Icon24LogoGoogleProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24LogoGoogle as any).mountIcon = mountIcon;

export default Icon24LogoGoogle;
