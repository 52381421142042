import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'door_arrow_left_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="door_arrow_left_outline_24"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.754 20.366c-.763.407-1.512.534-3.082.534H13a.9.9 0 110-1.8h2.672c1.487 0 1.87-.128 2.233-.322.336-.18.594-.437.773-.773.194-.362.322-.746.322-2.233V8.228c0-1.487-.128-1.87-.322-2.233a1.847 1.847 0 00-.773-.774c-.362-.193-.746-.321-2.233-.321H13a.9.9 0 110-1.8h2.672c1.57 0 2.32.127 3.082.534.65.348 1.164.863 1.512 1.512.407.762.534 1.512.534 3.082v7.544c0 1.57-.127 2.32-.534 3.082a3.646 3.646 0 01-1.512 1.512zm-9.39-5.002l2.463-2.464H3.9a.9.9 0 110-1.8h7.927L9.364 8.636a.9.9 0 111.272-1.272l4 4a.9.9 0 010 1.272l-4 4a.9.9 0 11-1.272-1.272z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24DoorArrowLeftOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24DoorArrowLeftOutline: FC<Icon24DoorArrowLeftOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24DoorArrowLeftOutline as any).mountIcon = mountIcon;

export default Icon24DoorArrowLeftOutline;
