import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'camera_16';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="camera_16"><g fill="none"><path d="M0 0h16v16H0z" /><path d="M8 3l-.001.999H8V3h1.25c.747 0 .75.991 1.49 1h.017c.78 0 1.063.081 1.348.234.285.152.509.376.661.661.153.285.234.568.234 1.348v3.514c0 .78-.081 1.063-.234 1.348s-.376.509-.661.661-.568.234-1.348.234H5.243c-.78 0-1.063-.081-1.348-.234a1.59 1.59 0 01-.661-.661C3.08 10.82 3 10.537 3 9.757V6.243c0-.78.081-1.063.234-1.348a1.59 1.59 0 01.661-.661C4.18 4.08 4.463 4 5.243 4h.017c.74-.009.743-1 1.49-1zm0 2.25a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5zM8 6.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16CameraProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16Camera: FC<Icon16CameraProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16Camera as any).mountIcon = mountIcon;

export default Icon16Camera;
