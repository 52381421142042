import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'market_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="market_outline_20"><path clip-rule="evenodd" d="M7.25 5.75a2.75 2.75 0 015.5 0V6h-5.5zm-1.5.254V5.75a4.25 4.25 0 018.5 0v.254c1.013.014 1.62.073 2.112.323a3 3 0 011.311 1.311C18 8.28 18 9.12 18 10.8v.5c0 2.52 0 3.78-.49 4.743a4.5 4.5 0 01-1.967 1.966c-.963.491-2.223.491-4.743.491H9.2c-2.52 0-3.78 0-4.743-.49a4.5 4.5 0 01-1.967-1.967C2 15.08 2 13.82 2 11.3v-.5c0-1.68 0-2.52.327-3.162a3 3 0 011.311-1.311c.492-.25 1.1-.31 2.112-.323zm-.798 1.532C5.377 7.5 5.935 7.5 6.8 7.5h6.4c.865 0 1.423.001 1.848.036.408.033.559.09.633.127a1.5 1.5 0 01.655.656c.038.074.095.225.128.633.035.425.036.983.036 1.848v.5c0 1.285-.001 2.158-.056 2.833-.054.658-.151.994-.271 1.229a3 3 0 01-1.311 1.311c-.235.12-.57.217-1.229.27C12.958 17 12.085 17 10.8 17H9.2c-1.285 0-2.158-.001-2.833-.056-.658-.054-.994-.151-1.229-.271a3 3 0 01-1.311-1.311c-.12-.235-.217-.57-.27-1.229C3.5 13.458 3.5 12.585 3.5 11.3v-.5c0-.865.001-1.423.036-1.848.033-.408.09-.559.127-.633a1.5 1.5 0 01.656-.656c.074-.037.225-.094.633-.127zm.798 2.339a.875.875 0 111.75 0 .875.875 0 01-1.75 0zM13.375 9a.875.875 0 100 1.75.875.875 0 000-1.75z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20MarketOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20MarketOutline: FC<Icon20MarketOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20MarketOutline as any).mountIcon = mountIcon;

export default Icon20MarketOutline;
