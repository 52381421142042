import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'plane_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="plane_outline_28"><clipPath id="plane_outline_28_a"><path d="M0 0h28v28H0z" /></clipPath><g clip-path="url(#plane_outline_28_a)"><path clip-rule="evenodd" d="M7.528 17.446c.387.056.581.083.747.05a.938.938 0 00.405-.179c.136-.101.257-.279.498-.635a23.063 23.063 0 011.06-1.437c.406-.512.609-.767.654-1.013a.969.969 0 00-.092-.634c-.114-.223-.368-.4-.877-.757l-3.306-2.314a.972.972 0 01.748-1.75l6.262 1.253c.46.092.69.138.912.116a1.5 1.5 0 00.561-.17c.197-.105.363-.27.695-.603l1.449-1.448c1.952-1.953 4.168-2.903 4.95-2.122.78.781-.17 2.997-2.122 4.95l-1.449 1.449c-.332.332-.498.498-.603.694a1.5 1.5 0 00-.17.561c-.021.222.025.452.117.912l1.252 6.262a.972.972 0 01-1.75.749l-2.314-3.307c-.356-.508-.534-.763-.756-.876a.968.968 0 00-.635-.093c-.245.046-.5.248-1.012.654l-.167.132c-.442.345-.868.657-1.27.929-.356.24-.534.361-.636.497a.94.94 0 00-.18.405c-.032.167-.004.36.051.748l.248 1.73a.695.695 0 01-1.271.476L8.06 20.41a2.082 2.082 0 00-.146-.21 1.001 1.001 0 00-.119-.119 2.074 2.074 0 00-.21-.145L5.322 18.47a.695.695 0 01.476-1.271zM6.08 15.22a2.695 2.695 0 00-1.845 4.93l2.193 1.42 1.42 2.193a2.695 2.695 0 004.93-1.845l-.219-1.526c.381-.262.774-.55 1.175-.86l2.097 2.996a2.972 2.972 0 005.35-2.288l-1.295-6.472 1.6-1.6c1.11-1.11 2.003-2.358 2.51-3.54.251-.586.45-1.255.47-1.94.018-.69-.15-1.589-.858-2.298-.71-.71-1.608-.877-2.299-.858-.685.02-1.353.218-1.94.47-1.182.506-2.43 1.4-3.54 2.51l-1.6 1.6-6.472-1.295a2.972 2.972 0 00-2.287 5.35l2.996 2.097c-.31.4-.599.794-.86 1.174z" fill="currentColor" fill-rule="evenodd" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28PlaneOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28PlaneOutline: FC<Icon28PlaneOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28PlaneOutline as any).mountIcon = mountIcon;

export default Icon28PlaneOutline;
