import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'document_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="document_outline_20"><path clip-rule="evenodd" d="M17.205 6.783c.11.18.19.374.24.579.055.23.055.474.055.963V11.3c0 2.52 0 3.78-.49 4.743a4.5 4.5 0 01-1.967 1.966c-.963.491-2.223.491-4.743.491h-.6c-2.52 0-3.78 0-4.743-.49a4.5 4.5 0 01-1.967-1.967C2.5 15.08 2.5 13.82 2.5 11.3V8.7c0-2.52 0-3.78.49-4.743A4.5 4.5 0 014.957 1.99C5.92 1.5 7.18 1.5 9.7 1.5h.975c.489 0 .733 0 .963.055.205.05.4.13.579.24.201.123.374.296.72.642l3.626 3.626c.346.346.519.519.642.72zM10.3 17h-.6c-1.285 0-2.158-.001-2.833-.056-.658-.054-.994-.151-1.229-.271a3 3 0 01-1.311-1.311c-.12-.235-.217-.57-.27-1.229C4 13.458 4 12.585 4 11.3V8.7c0-1.285.001-2.158.056-2.833.054-.658.151-.994.271-1.229a3 3 0 011.311-1.311c.235-.12.57-.217 1.229-.27C7.542 3 8.415 3 9.7 3h.3v1.281c0 .674 0 1.224.037 1.672.037.463.118.882.317 1.272a3.25 3.25 0 001.42 1.42c.391.2.81.28 1.273.318.448.037.998.037 1.672.037H16v2.3c0 1.285-.001 2.158-.056 2.833-.054.658-.151.994-.271 1.229a3 3 0 01-1.311 1.311c-.235.12-.57.217-1.229.27C12.458 17 11.585 17 10.3 17zm5.57-9.5h-1.12c-.712 0-1.202 0-1.58-.032-.371-.03-.57-.085-.714-.159a1.75 1.75 0 01-.765-.765c-.074-.144-.13-.343-.16-.713-.03-.38-.031-.869-.031-1.581V3.13l.012.01c.08.074.182.176.365.358l3.625 3.625c.182.183.284.285.357.365l.011.012z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20DocumentOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20DocumentOutline: FC<Icon20DocumentOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20DocumentOutline as any).mountIcon = mountIcon;

export default Icon20DocumentOutline;
