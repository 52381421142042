import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 44 44';
const id = 'logo_vk_44';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="logo_vk_44"><path d="M27.482 4.125c10.01 0 12.393 2.383 12.393 12.393v10.964c0 10.01-2.383 12.393-12.393 12.393H16.518c-10.01 0-12.393-2.383-12.393-12.393V16.518c0-10.01 2.383-12.393 12.393-12.393h10.964zM13.435 15.297h-2.607c-.745 0-.894.35-.894.737 0 .69.884 4.115 4.115 8.644 2.154 3.093 5.19 4.77 7.951 4.77 1.657 0 1.862-.372 1.862-1.014v-2.338c0-.744.157-.893.682-.893.386 0 1.05.193 2.596 1.684 1.767 1.768 2.059 2.56 3.053 2.56H32.8c.744 0 1.117-.371.902-1.106-.235-.733-1.08-1.796-2.199-3.055-.607-.718-1.519-1.492-1.795-1.878-.387-.497-.276-.718 0-1.16l.047-.066c.392-.558 3.153-4.516 3.46-5.927.166-.552 0-.958-.788-.958H29.82c-.663 0-.968.35-1.134.737 0 0-1.325 3.231-3.203 5.33-.608.608-.884.801-1.216.801-.165 0-.405-.193-.405-.746v-5.164c0-.663-.192-.958-.745-.958h-4.096c-.414 0-.663.307-.663.6 0 .627.939.772 1.035 2.54v3.839c0 .841-.152.994-.483.994-.884 0-3.034-3.246-4.308-6.96-.25-.721-.5-1.013-1.167-1.013z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon44LogoVkProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon44LogoVk: FC<Icon44LogoVkProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 44,
    height: !isNaN(props.height) ? +props.height : 44,
  }));
};

(Icon44LogoVk as any).mountIcon = mountIcon;

export default Icon44LogoVk;
