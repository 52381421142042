import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'lightbulb_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="lightbulb_outline_20"><path clip-rule="evenodd" d="M10 3a5.75 5.75 0 00-5.75 5.75c0 1.534.682 2.61 1.522 3.527.31.338.621.635.934.931l.376.36c.29.284.595.594.836.932H9.25v-1.64l-.969-.774a.75.75 0 01.938-1.172l.781.625.782-.625a.75.75 0 01.937 1.172l-.969.774v1.64h1.332c.241-.338.546-.648.836-.931l.376-.36c.313-.298.625-.594.934-.932.84-.916 1.522-1.993 1.522-3.527A5.75 5.75 0 0010 3zm0 13H8.415c.036.179.067.313.098.425a.823.823 0 00.1.254.75.75 0 00.315.258.823.823 0 00.27.05c.177.013.407.013.769.013h.064c.362 0 .592 0 .77-.014a.823.823 0 00.269-.049.75.75 0 00.314-.258.826.826 0 00.101-.254 6.69 6.69 0 00.098-.425h-1.582zm-.032 2.5h.09c.329 0 .615 0 .854-.018.255-.019.508-.06.76-.17a2.25 2.25 0 00.943-.774c.157-.225.248-.465.316-.71.065-.232.12-.513.185-.835l.005-.025.092-.46c.136-.232.38-.503.752-.864.1-.099.211-.204.328-.315.324-.308.694-.66 1.041-1.039.973-1.06 1.916-2.484 1.916-4.54a7.25 7.25 0 10-14.5 0c0 2.056.943 3.48 1.916 4.54.347.38.717.731 1.041 1.04.117.11.227.215.328.314.372.361.616.632.752.863l.092.46.005.026c.064.322.12.603.185.834.068.246.159.486.316.71.237.34.564.609.944.775.251.11.504.151.759.17.239.018.525.018.854.018z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20LightbulbOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20LightbulbOutline: FC<Icon20LightbulbOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20LightbulbOutline as any).mountIcon = mountIcon;

export default Icon20LightbulbOutline;
