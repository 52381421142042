import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 8 16';
const id = 'more_vertical_16';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 16" id="more_vertical_16"><g fill="none" fill-rule="evenodd"><path d="M0 0h8v16H0z" /><path d="M4 4.5c.825 0 1.5-.675 1.5-1.5S4.825 1.5 4 1.5 2.5 2.175 2.5 3 3.175 4.5 4 4.5zm0 2c-.825 0-1.5.675-1.5 1.5S3.175 9.5 4 9.5 5.5 8.825 5.5 8 4.825 6.5 4 6.5zm0 5c-.825 0-1.5.675-1.5 1.5s.675 1.5 1.5 1.5 1.5-.675 1.5-1.5-.675-1.5-1.5-1.5z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16MoreVerticalProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16MoreVertical: FC<Icon16MoreVerticalProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 8,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16MoreVertical as any).mountIcon = mountIcon;

export default Icon16MoreVertical;
