import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'users_circle_fill_gray_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="users_circle_fill_gray_28"><linearGradient id="users_circle_fill_gray_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#b1b6bd" /><stop offset="1" stop-color="#99a2ad" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#users_circle_fill_gray_28_a)" /><path d="M10.5 15c2.25 0 4.5.9 4.5 2.846v.134c0 .295-.04.447-.114.585a.773.773 0 01-.32.321c-.14.075-.29.114-.586.114H7.02c-.295 0-.447-.04-.585-.114a.773.773 0 01-.321-.32c-.075-.14-.114-.29-.114-.586v-.134C6 15.9 8.25 15 10.5 15zm7 0c2.25 0 4.5.9 4.5 2.846v.134c0 .295-.04.447-.114.585a.773.773 0 01-.32.321c-.14.075-.29.114-.586.114H16.6c.084-.248.13-.508.144-.797l.006-.223v-.134c0-1.069-.358-1.957-.971-2.659A7.74 7.74 0 0117.5 15zm-7-6a2.25 2.25 0 110 4.5 2.25 2.25 0 010-4.5zm7 0a2.25 2.25 0 110 4.5 2.25 2.25 0 010-4.5z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28UsersCircleFillGrayProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28UsersCircleFillGray: FC<Icon28UsersCircleFillGrayProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28UsersCircleFillGray as any).mountIcon = mountIcon;

export default Icon28UsersCircleFillGray;
