import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = '0_circle_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="0_circle_outline_24"><g fill="currentColor"><path d="M9 12.197C9 14.518 10.13 16 11.98 16S15 14.484 15 12.197v-.411C15 9.465 13.886 8 12.02 8 10.157 8 9 9.493 9 11.786zm1.84-.405c0-1.46.426-2.305 1.157-2.305s1.17.851 1.17 2.305v.4c0 1.459-.438 2.32-1.164 2.32-.731 0-1.164-.856-1.164-2.32z" /><path clip-rule="evenodd" d="M12 2.1c5.467 0 9.9 4.433 9.9 9.9 0 5.467-4.433 9.9-9.9 9.9-5.467 0-9.9-4.433-9.9-9.9 0-5.467 4.433-9.9 9.9-9.9zm0 1.8A8.1 8.1 0 003.9 12c0 4.474 3.626 8.1 8.1 8.1s8.1-3.626 8.1-8.1A8.1 8.1 0 0012 3.9z" fill-rule="evenodd" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon240CircleOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon240CircleOutline: FC<Icon240CircleOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon240CircleOutline as any).mountIcon = mountIcon;

export default Icon240CircleOutline;
