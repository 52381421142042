import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'tear_off_flyer_outline_32';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="tear_off_flyer_outline_32"><path clip-rule="evenodd" d="M6.325 3.868C7.535 3.222 8.725 3 11.333 3h9.334c2.609 0 3.799.222 5.008.868a5.923 5.923 0 012.457 2.457c.646 1.21.868 2.4.868 5.008v9.334c0 2.609-.222 3.799-.868 5.008a5.923 5.923 0 01-2.457 2.457c-1.21.646-2.4.868-5.008.868h-9.334c-2.609 0-3.799-.222-5.008-.868a5.923 5.923 0 01-2.457-2.457c-.646-1.21-.868-2.4-.868-5.008v-9.334c0-2.609.222-3.799.868-5.008a5.923 5.923 0 012.457-2.457zM13.5 26.5v-3.75a1.25 1.25 0 10-2.5 0v3.749c-2.21-.02-2.866-.235-3.496-.572a3.424 3.424 0 01-1.431-1.431c-.353-.66-.573-1.35-.573-3.829v-9.334c0-2.479.22-3.169.573-3.829.331-.62.81-1.1 1.431-1.431.66-.353 1.35-.573 3.829-.573h9.334c2.479 0 3.168.22 3.829.573.62.331 1.1.81 1.431 1.431.353.66.573 1.35.573 3.829v9.334c0 2.479-.22 3.168-.573 3.829-.332.62-.81 1.1-1.431 1.431-.58.31-1.182.517-2.996.563v-3.74a1.25 1.25 0 10-2.5 0v3.75zM8 11.25c0-.69.56-1.25 1.25-1.25h13a1.25 1.25 0 110 2.5h-13c-.69 0-1.25-.56-1.25-1.25zm0 5c0-.69.56-1.25 1.25-1.25h10a1.25 1.25 0 110 2.5h-10c-.69 0-1.25-.56-1.25-1.25z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32TearOffFlyerOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32TearOffFlyerOutline: FC<Icon32TearOffFlyerOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32TearOffFlyerOutline as any).mountIcon = mountIcon;

export default Icon32TearOffFlyerOutline;
