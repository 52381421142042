import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'lightbulb_star_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="lightbulb_star_outline_20"><path clip-rule="evenodd" d="M4.25 8.75a5.75 5.75 0 018.403-5.103.75.75 0 10.694-1.33A7.25 7.25 0 002.75 8.75c0 2.056.943 3.48 1.916 4.54.347.38.717.731 1.041 1.04.117.11.227.215.328.314.372.362.616.632.752.863l.097.486c.064.322.12.603.185.834.068.246.159.486.316.71.237.34.564.609.944.775.251.11.504.151.759.17.239.018.525.018.854.018h.116c.329 0 .615 0 .854-.018.255-.019.508-.06.76-.17a2.25 2.25 0 00.943-.774c.157-.225.248-.465.316-.71.065-.232.12-.513.185-.835l.097-.485a3.41 3.41 0 01.484-.597c.182-.188.378-.375.598-.584l.345-.33c.701-.678 1.524-1.536 2.054-2.682a.75.75 0 10-1.362-.63c-.406.88-1.055 1.577-1.734 2.234l-.306.292c-.234.222-.47.447-.675.66a5.678 5.678 0 00-.535.629H10.75v-1.64l.969-.774a.75.75 0 00-.937-1.172L10 11.54l-.781-.625a.75.75 0 00-.938 1.172l.969.774v1.64H7.918c-.241-.338-.546-.648-.836-.931l-.376-.36c-.313-.298-.625-.594-.934-.932-.84-.916-1.522-1.993-1.522-3.527zm4.264 7.675A6.684 6.684 0 018.416 16h3.168a6.69 6.69 0 01-.098.425.826.826 0 01-.1.254.75.75 0 01-.315.258.823.823 0 01-.27.05c-.177.013-.407.013-.769.013h-.064c-.362 0-.592 0-.77-.014a.823.823 0 01-.269-.049.75.75 0 01-.314-.258.823.823 0 01-.101-.254zm6.623-13.163a.383.383 0 01.726 0l.392 1.176c.127.38.426.68.807.807l1.176.392a.383.383 0 010 .726l-1.176.392c-.38.127-.68.426-.807.807l-.392 1.176a.383.383 0 01-.726 0l-.392-1.176a1.276 1.276 0 00-.807-.807l-1.176-.392a.383.383 0 010-.726l1.176-.392c.38-.127.68-.426.807-.807z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20LightbulbStarOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20LightbulbStarOutline: FC<Icon20LightbulbStarOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20LightbulbStarOutline as any).mountIcon = mountIcon;

export default Icon20LightbulbStarOutline;
