import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 12 12';
const id = 'star_circle_fill_yellow_12';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" id="star_circle_fill_yellow_12"><linearGradient id="star_circle_fill_yellow_12_a" gradientUnits="userSpaceOnUse" x1="-6" x2="6" y1="6" y2="18"><stop offset="0" stop-color="#ffb73d" /><stop offset="1" stop-color="#ffa000" /></linearGradient><g clip-rule="evenodd" fill-rule="evenodd"><path d="M6 0a6 6 0 110 12A6 6 0 016 0zm.11 2.59a.25.25 0 00-.334.114l-.952 1.928-2.127.31a.25.25 0 00-.138.426l1.539 1.5-.364 2.119a.25.25 0 00.363.263L6 8.25l1.903 1a.25.25 0 00.363-.263l-.364-2.119 1.54-1.5a.25.25 0 00-.14-.427l-2.126-.309-.952-1.928a.25.25 0 00-.113-.113z" fill="url(#star_circle_fill_yellow_12_a)" /><path d="M6.11 2.59a.25.25 0 00-.334.114l-.952 1.928-2.127.31a.25.25 0 00-.138.426l1.539 1.5-.364 2.119a.25.25 0 00.363.263L6 8.25l1.903 1a.25.25 0 00.363-.263l-.364-2.119 1.54-1.5a.25.25 0 00-.14-.427l-2.126-.309-.952-1.928a.25.25 0 00-.113-.113z" fill="#fff" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon12StarCircleFillYellowProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon12StarCircleFillYellow: FC<Icon12StarCircleFillYellowProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 12,
    height: !isNaN(props.height) ? +props.height : 12,
  }));
};

(Icon12StarCircleFillYellow as any).mountIcon = mountIcon;

export default Icon12StarCircleFillYellow;
