import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'advertising_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="advertising_24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" /><path d="M6 15a4 4 0 110-8h6.359c.223 0 .304.023.385.067a.454.454 0 01.19.189c.043.081.066.162.066.385v6.718c0 .223-.023.304-.067.385a.454.454 0 01-.189.19c-.081.043-.162.066-.385.066H10c0 .783.303 1.62.652 2.04a1.5 1.5 0 01-2.304 1.92C7.548 18.001 7 16.484 7 15H6zM20.805 4.524c.127.19.195.414.195.643v11.666a1.16 1.16 0 01-1.803.965l-3.881-2.587a.834.834 0 01-.206-.168.442.442 0 01-.084-.158.639.639 0 01-.025-.184L15 7.38c0-.152.008-.206.026-.265a.442.442 0 01.084-.158.834.834 0 01.206-.168l3.88-2.587a1.16 1.16 0 011.61.322z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24AdvertisingProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Advertising: FC<Icon24AdvertisingProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Advertising as any).mountIcon = mountIcon;

export default Icon24Advertising;
