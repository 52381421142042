import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'ghost_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="ghost_20"><path d="M10 1c2.196 0 3.93.742 5.532 2.178C17.109 4.593 18 6.524 18 8.542l-.013 6.591c-.09 1.177-.916 2.05-2.118 2.05-1.2 0-1.519-.818-2.546-.651C12.296 16.699 11.91 18 10 18s-2.334-1.307-3.323-1.468c-1.027-.167-1.345.65-2.546.65-1.157 0-2.058-.82-2.118-2.049L2 8.542c0-2.018.89-3.949 2.468-5.364C6.003 1.802 7.804 1 10 1zm2.85 10.858c-.256-.386-.752-.474-1.108-.196-.968.756-1.987.756-2.98.291a2.881 2.881 0 01-.504-.29l-.084-.058c-.347-.205-.788-.103-1.024.253-.257.386-.176.925.18 1.202.166.13.44.305.803.475 1.459.684 3.054.684 4.537-.475.356-.277.437-.816.18-1.202zM6.75 7a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm5.607.957a2.178 2.178 0 00-.414.262.642.642 0 00-.055.957c.25.256.665.295.964.103l.072-.053a.851.851 0 01.13-.077c.234-.117.43-.129.648.021l.074.056a.776.776 0 001.036-.05.642.642 0 00-.055-.957c-.77-.64-1.643-.64-2.4-.262z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20GhostProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20Ghost: FC<Icon20GhostProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20Ghost as any).mountIcon = mountIcon;

export default Icon20Ghost;
