import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'story_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="story_outline_20"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M10 6.25a3.75 3.75 0 100 7.5 3.75 3.75 0 000-7.5zM7.75 10a2.25 2.25 0 114.5 0 2.25 2.25 0 01-4.5 0z" /><path d="M2 8.4c0-2.24 0-3.36.436-4.216a4 4 0 011.748-1.748C5.04 2 6.16 2 8.4 2h3.2c2.24 0 3.36 0 4.216.436a4 4 0 011.748 1.748C18 5.04 18 6.16 18 8.4v3.2c0 2.24 0 3.36-.436 4.216a4 4 0 01-1.748 1.748C14.96 18 13.84 18 11.6 18H8.4c-2.24 0-3.36 0-4.216-.436a4 4 0 01-1.748-1.748C2 14.96 2 13.84 2 11.6zm6.4-4.9h3.2c1.145 0 1.913.001 2.505.05.574.046.849.13 1.03.222a2.5 2.5 0 011.093 1.093c.092.181.176.456.222 1.03.049.592.05 1.36.05 2.505v3.2c0 1.145-.001 1.913-.05 2.505-.046.574-.13.849-.223 1.03a2.5 2.5 0 01-1.092 1.092c-.181.093-.456.177-1.03.223-.592.049-1.36.05-2.505.05H8.4c-1.145 0-1.913-.001-2.505-.05-.574-.046-.849-.13-1.03-.223a2.5 2.5 0 01-1.093-1.092c-.092-.181-.176-.456-.222-1.03-.049-.592-.05-1.36-.05-2.505V8.4c0-1.145.001-1.913.05-2.505.046-.574.13-.849.222-1.03a2.5 2.5 0 011.093-1.093c.181-.092.456-.176 1.03-.222.592-.049 1.36-.05 2.505-.05z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20StoryOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20StoryOutline: FC<Icon20StoryOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20StoryOutline as any).mountIcon = mountIcon;

export default Icon20StoryOutline;
