import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'message_request_circle_fill_blue_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="message_request_circle_fill_blue_28"><linearGradient id="message_request_circle_fill_blue_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#70b2ff" /><stop offset="1" stop-color="#5c9ce6" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#message_request_circle_fill_blue_28_a)" /><path clip-rule="evenodd" d="M14 8c3.314 0 6 2.406 6 5.375s-2.686 5.375-6 5.375a6.654 6.654 0 01-1.702-.22c-.583.512-1.218.968-1.895 1.36-.115.066-.25.138-.401.214l-.159.078a.6.6 0 01-.818-.763c.094-.235.169-.442.224-.619.176-.557.312-1.132.404-1.722C8.628 16.114 8 14.81 8 13.375 8 10.406 10.686 8 14 8zm.015 7.15h-.03a.935.935 0 00-.451.1.681.681 0 00-.284.284.935.935 0 00-.1.45v.031c0 .182.035.33.1.451a.681.681 0 00.284.284c.122.065.268.1.45.1h.031a.935.935 0 00.451-.1.681.681 0 00.284-.284.935.935 0 00.1-.45v-.031a.935.935 0 00-.1-.451.681.681 0 00-.284-.284.935.935 0 00-.45-.1zM14 10h-.029a.77.77 0 00-.741.8l.096 2.55a.675.675 0 001.348 0l.096-2.55v-.03A.77.77 0 0014 10z" fill="#fff" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28MessageRequestCircleFillBlueProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28MessageRequestCircleFillBlue: FC<Icon28MessageRequestCircleFillBlueProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28MessageRequestCircleFillBlue as any).mountIcon = mountIcon;

export default Icon28MessageRequestCircleFillBlue;
