import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'music_mic_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="music_mic_outline_24"><g fill="currentColor"><path d="M9.249 12.83a.9.9 0 10-1.35-1.19L3.92 16.147a3.029 3.029 0 004.276 4.275l4.508-3.978a.9.9 0 10-1.19-1.35l-4.51 3.979a1.229 1.229 0 01-1.734-1.735z" /><path clip-rule="evenodd" d="M16.1 12.3a4.2 4.2 0 100-8.4 4.2 4.2 0 000 8.4zm0 1.8a6 6 0 100-12 6 6 0 000 12z" fill-rule="evenodd" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24MusicMicOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24MusicMicOutline: FC<Icon24MusicMicOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24MusicMicOutline as any).mountIcon = mountIcon;

export default Icon24MusicMicOutline;
