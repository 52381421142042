import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'globe_circle_fill_yellow_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="globe_circle_fill_yellow_28"><linearGradient id="globe_circle_fill_yellow_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#ffb73d" /><stop offset="1" stop-color="#ffa000" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#globe_circle_fill_yellow_28_a)" /><g fill="#fff"><path clip-rule="evenodd" d="M11.536 11.498c-.107.807-.161 1.651-.161 2.5 0 .848.054 1.691.161 2.499h4.928c.107-.808.161-1.651.161-2.5 0-.848-.054-1.692-.161-2.499z" fill-rule="evenodd" /><path clip-rule="evenodd" d="M21.602 16.497c.258-.787.398-1.627.398-2.5s-.14-1.712-.398-2.499h-3.626c.1.82.149 1.662.149 2.5s-.05 1.68-.15 2.499zm-.672 1.5h-3.2c-.131.622-.294 1.22-.491 1.779a9.249 9.249 0 01-.883 1.866 8.02 8.02 0 004.574-3.646zm-9.286 3.645a9.249 9.249 0 01-.883-1.866 14.06 14.06 0 01-.49-1.78h-3.2a8.02 8.02 0 004.573 3.646zm-5.246-5.145C6.14 15.71 6 14.87 6 13.997s.14-1.712.398-2.499h3.626c-.1.82-.149 1.662-.149 2.5s.05 1.68.15 2.499zm.672-6.498h3.2c.131-.623.295-1.221.491-1.78a9.248 9.248 0 01.883-1.867A8.02 8.02 0 007.07 10zm9.286-3.647c.356.563.648 1.2.883 1.867.196.559.36 1.157.49 1.78h3.2a8.02 8.02 0 00-4.573-3.647zm-4.82 5.146c-.107.807-.161 1.651-.161 2.5 0 .848.054 1.691.161 2.499h4.928c.107-.808.161-1.651.161-2.5 0-.848-.054-1.692-.161-2.499z" fill-rule="evenodd" /><path d="M16.193 9.999c-.104-.45-.228-.88-.37-1.282C15.824 8.717 15 6 14 6s-1.824 2.717-1.824 2.717c-.142.402-.265.832-.37 1.282zM11.807 17.996h4.386c-.104.45-.228.88-.37 1.282C15.824 19.278 15 22 14 22s-1.824-2.722-1.824-2.722a12.185 12.185 0 01-.37-1.282z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28GlobeCircleFillYellowProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28GlobeCircleFillYellow: FC<Icon28GlobeCircleFillYellowProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28GlobeCircleFillYellow as any).mountIcon = mountIcon;

export default Icon28GlobeCircleFillYellow;
