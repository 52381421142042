import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'replay_15_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="replay_15_20"><path d="M9.683 1.831a.337.337 0 00-.514-.282L5.025 4.05a.328.328 0 000 .565L9.17 7.116a.337.337 0 00.514-.282V5.042c3.325 0 6.021 2.647 6.021 5.913 0 3.265-2.696 5.913-6.021 5.913-2.908 0-5.385-2.04-5.917-4.812a.722.722 0 00-.844-.566.71.71 0 00-.576.829c.66 3.44 3.731 5.968 7.337 5.968 4.123 0 7.466-3.283 7.466-7.332 0-4.05-3.343-7.332-7.466-7.332V1.831z" fill="currentColor" /><path d="M7.15 13.698a.334.334 0 01-.337-.33V9.981l-.836.323a.27.27 0 01-.368-.247v-.46a.33.33 0 01.206-.305l1.777-.738a.337.337 0 01.469.305v4.507a.334.334 0 01-.338.331H7.15zm2.165-1.461a.564.564 0 00-.138.385c0 .203.081.394.242.575.162.181.382.327.662.437.28.11.588.165.926.165.41 0 .772-.078 1.083-.234.311-.155.551-.375.72-.658.169-.284.253-.613.253-.987 0-.336-.07-.632-.21-.888a1.49 1.49 0 00-.587-.596 1.752 1.752 0 00-.873-.212 1.72 1.72 0 00-.617.108.927.927 0 00-.42.286h-.063l.083-1.123h1.897c.184 0 .325-.042.422-.127a.456.456 0 00.145-.362.474.474 0 00-.147-.372c-.098-.089-.238-.133-.42-.133h-2.147c-.471 0-.72.203-.748.61l-.125 1.845a.624.624 0 00.066.334.55.55 0 00.223.23.665.665 0 00.558.038c.073-.03.155-.08.245-.15.114-.081.232-.144.354-.188a1.05 1.05 0 01.357-.067.88.88 0 01.422.101.72.72 0 01.29.283c.07.121.105.26.105.417a.86.86 0 01-.11.437.772.772 0 01-.304.296.905.905 0 01-.44.106.96.96 0 01-.47-.116 1.408 1.408 0 01-.408-.358.722.722 0 00-.217-.183.537.537 0 00-.248-.056.457.457 0 00-.36.157z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20Replay15Props extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20Replay15: FC<Icon20Replay15Props> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20Replay15 as any).mountIcon = mountIcon;

export default Icon20Replay15;
