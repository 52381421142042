import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'photos_stack_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="photos_stack_outline_24"><g fill="currentColor"><path clip-rule="evenodd" d="M6.1 11.5a1.4 1.4 0 112.8 0 1.4 1.4 0 01-2.8 0z" fill-rule="evenodd" /><path d="M7 3.9a1.8 1.8 0 011.8-1.8h6.4A1.8 1.8 0 0117 3.9z" /><path clip-rule="evenodd" d="M7.762 6.1h8.476c.808 0 1.469 0 2.006.044.556.045 1.058.142 1.527.381a3.9 3.9 0 011.704 1.704c.239.47.336.971.381 1.527.044.537.044 1.198.044 2.006v3.476c0 .808 0 1.469-.044 2.006-.045.556-.142 1.058-.381 1.527a3.9 3.9 0 01-1.704 1.704c-.47.239-.971.336-1.527.381-.537.044-1.198.044-2.006.044H7.762c-.808 0-1.469 0-2.006-.044-.556-.045-1.058-.142-1.527-.381a3.9 3.9 0 01-1.704-1.704c-.239-.47-.336-.971-.381-1.527-.044-.537-.044-1.198-.044-2.006v-3.476c0-.808 0-1.469.044-2.006.045-.556.142-1.058.381-1.527A3.9 3.9 0 014.23 6.525c.47-.239.971-.336 1.527-.381C6.293 6.1 6.954 6.1 7.762 6.1zm3.873 11.393a4.9 4.9 0 00.298.236 1.4 1.4 0 00.502.234c.288.066.59.04.863-.077.208-.09.354-.22.452-.32.085-.086.174-.192.251-.285l3.532-4.236 2.567 2.001.001.4c.003.55.006 1.103-.039 1.651-.036.445-.103.683-.19.856a2.1 2.1 0 01-.919.918c-.172.088-.411.155-.856.191-.455.037-1.042.038-1.897.038H7.8c-.463 0-.848 0-1.176-.006l3.156-3.156zm6.614-6.16c.1.065.206.148.298.22l1.553 1.21V11.8c0-.855 0-1.442-.038-1.897-.036-.445-.103-.684-.19-.856a2.1 2.1 0 00-.919-.918c-.172-.088-.411-.155-.856-.191C17.642 7.9 17.055 7.9 16.2 7.9H7.8c-.855 0-1.442 0-1.897.038-.445.036-.684.103-.856.19a2.1 2.1 0 00-.918.919c-.088.172-.155.411-.191.856-.037.455-.038 1.042-.038 1.897v3.4c0 .855 0 1.442.038 1.897.036.445.103.683.19.856.12.235.282.443.476.616l3.983-3.983c.078-.078.169-.17.256-.244a1.404 1.404 0 011.73-.076c.093.066.192.15.277.22l1.847 1.548 3.53-4.232c.074-.09.16-.194.244-.278.096-.098.238-.225.44-.314a1.4 1.4 0 01.842-.09c.216.044.382.138.496.213zM7.5 10.1a1.4 1.4 0 100 2.8 1.4 1.4 0 000-2.8z" fill-rule="evenodd" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24PhotosStackOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24PhotosStackOutline: FC<Icon24PhotosStackOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24PhotosStackOutline as any).mountIcon = mountIcon;

export default Icon24PhotosStackOutline;
