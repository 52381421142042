import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'basketball_ball_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="basketball_ball_outline_28"><path clip-rule="evenodd" d="M25.834 11.999a11.954 11.954 0 00-2.67-5.748c-.432-.51-.906-.983-1.415-1.414A11.953 11.953 0 0014 2a11.944 11.944 0 00-8.187 3.227 11.975 11.975 0 00-3.797 9.39 11.948 11.948 0 002.821 7.132c.431.51.905.983 1.414 1.414A11.95 11.95 0 0014 26a11.976 11.976 0 008.773-3.813A11.944 11.944 0 0026 14c0-.682-.057-1.35-.166-2.001zM14.166 4a11.953 11.953 0 002.671 5.748L15 11.586 8.844 5.43A9.953 9.953 0 0114.166 4zm4.085 7.162L16.414 13l6.156 6.156A9.954 9.954 0 0024 13.834a11.953 11.953 0 01-5.748-2.67zm5.494.582a9.965 9.965 0 00-2.002-4.074l-2.072 2.072a9.965 9.965 0 004.074 2.002zm-2.387 9.027a9.987 9.987 0 01-5.36 3.028 11.95 11.95 0 00-2.835-7.549L15 14.414zM14 24a9.96 9.96 0 00-2.257-6.329l-4.072 4.072A9.96 9.96 0 0014 24zm-.414-11l-1.837 1.837a11.95 11.95 0 00-7.55-2.835 9.987 9.987 0 013.029-5.36zM6.257 20.33A9.958 9.958 0 014 14c2.401 0 4.605.846 6.329 2.257zm9.998-16.074a9.964 9.964 0 014.074 2.002L18.257 8.33a9.964 9.964 0 01-2.002-4.074z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28BasketballBallOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28BasketballBallOutline: FC<Icon28BasketballBallOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28BasketballBallOutline as any).mountIcon = mountIcon;

export default Icon28BasketballBallOutline;
