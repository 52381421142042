import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'globe_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="globe_16"><g fill="currentColor"><path clip-rule="evenodd" d="M5.536 5.498c-.107.807-.161 1.651-.161 2.5s.054 1.691.161 2.499h4.928c.107-.808.161-1.651.161-2.5s-.054-1.692-.161-2.499z" fill-rule="evenodd" /><path clip-rule="evenodd" d="M15.602 10.497a7.989 7.989 0 00.398-2.5c0-.872-.14-1.712-.398-2.499h-3.626c.1.82.149 1.662.149 2.5s-.05 1.68-.15 2.499zm-.672 1.5h-3.2c-.131.622-.295 1.22-.491 1.779a9.249 9.249 0 01-.883 1.866 8.02 8.02 0 004.574-3.646zm-9.286 3.645a9.249 9.249 0 01-.883-1.866 14.054 14.054 0 01-.49-1.78h-3.2a8.02 8.02 0 004.573 3.646zM.398 10.497A7.988 7.988 0 010 7.997c0-.872.14-1.712.398-2.499h3.626c-.1.82-.149 1.662-.149 2.5s.05 1.68.15 2.499zm.672-6.498h3.2c.131-.623.294-1.221.491-1.78A9.248 9.248 0 015.644.352 8.02 8.02 0 001.07 4zM10.356.352c.356.563.648 1.2.883 1.867.196.559.36 1.157.49 1.78h3.2A8.02 8.02 0 0010.357.352zm-4.82 5.146c-.107.807-.161 1.651-.161 2.5s.054 1.691.161 2.499h4.928c.107-.808.161-1.651.161-2.5s-.054-1.692-.161-2.499z" fill-rule="evenodd" /><path d="M10.193 3.999a12.18 12.18 0 00-.37-1.282S9 0 8 0 6.176 2.717 6.176 2.717a12.16 12.16 0 00-.37 1.282zM5.807 11.996h4.386c-.104.45-.228.88-.37 1.282C9.824 13.278 9 16 8 16s-1.824-2.722-1.824-2.722a12.157 12.157 0 01-.37-1.282z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16GlobeProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16Globe: FC<Icon16GlobeProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16Globe as any).mountIcon = mountIcon;

export default Icon16Globe;
