import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'car_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="car_outline_28"><path clip-rule="evenodd" d="M11.285 5h5.43c1.27 0 2.148 0 2.943.273a5 5 0 011.868 1.155c.601.588.993 1.373 1.561 2.51l.076.153.955 1.909H25.5a1 1 0 011 1v.354A1.86 1.86 0 0125 14.18v8.122c0 .106 0 .238-.01.355-.01.137-.04.339-.148.551a1.45 1.45 0 01-.634.634 1.457 1.457 0 01-.551.149c-.117.01-.25.01-.355.009H22.698c-.106 0-.238 0-.355-.01a1.457 1.457 0 01-.551-.148 1.45 1.45 0 01-.634-.634 1.454 1.454 0 01-.148-.551c-.01-.117-.01-.25-.01-.355V22H7V22.302c0 .106 0 .238-.01.355-.01.137-.04.339-.148.551a1.45 1.45 0 01-.634.634 1.457 1.457 0 01-.551.149c-.117.01-.25.01-.355.009H4.698c-.106 0-.238 0-.355-.01a1.457 1.457 0 01-.551-.148 1.45 1.45 0 01-.634-.634 1.457 1.457 0 01-.149-.551C3 22.54 3 22.407 3 22.302V14.18a1.86 1.86 0 01-1.5-1.826V12a1 1 0 011-1h1.382l.955-1.91.076-.152c.568-1.137.96-1.922 1.56-2.51a5 5 0 011.869-1.155C9.137 5 10.014 5 11.285 5zM5.017 19.504c-.015-.18-.017-.413-.017-.775V14.02c.071.014.16.03.266.046.321.05.812.116 1.518.18 1.412.128 3.693.254 7.216.254s5.804-.126 7.215-.254c.707-.064 1.198-.13 1.52-.18L23 14.02V18.6c0 .437 0 .704-.017.904a1.285 1.285 0 01-.034.215.5.5 0 01-.226.228l-.004.002a1.292 1.292 0 01-.216.034c-.2.016-.467.017-.903.017H6.4c-.437 0-.704 0-.904-.017a1.29 1.29 0 01-.215-.034l-.008-.003a.5.5 0 01-.222-.227 1.281 1.281 0 01-.034-.215zm17.41-7.414l-1.052-2.105c-.675-1.349-.917-1.804-1.248-2.128a3 3 0 00-1.12-.693c-.438-.15-.954-.164-2.462-.164h-5.09c-1.508 0-2.024.013-2.461.164a3 3 0 00-1.121.693c-.33.324-.573.779-1.248 2.128L5.573 12.09h.005c.272.043.719.103 1.388.164 1.338.122 3.557.246 7.034.246s5.696-.124 7.035-.246a21.613 21.613 0 001.392-.164zm-1.303 5.902a1 1 0 10-.248-1.984l-2 .25a1 1 0 00.248 1.984zM6.008 16.876a1 1 0 00.868 1.116l2 .25a1 1 0 10.248-1.984l-2-.25a1 1 0 00-1.116.868z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28CarOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28CarOutline: FC<Icon28CarOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28CarOutline as any).mountIcon = mountIcon;

export default Icon28CarOutline;
