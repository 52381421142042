import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'pin_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="pin_16"><path d="M10.235 2.106l3.657 3.663c.225.226.064.633-.263.633h-.523a.895.895 0 00-.633.263L10.758 8.38a.896.896 0 00-.262.633v2.996c0 .554-.7.858-1.1.456l-2.301-2.3-3.57 3.57a.893.893 0 11-1.264-1.263L5.83 8.898l-2.29-2.29c-.391-.392-.109-1.102.456-1.102H6.99a.895.895 0 00.633-.262L9.338 3.53a.896.896 0 00.263-.634V2.37c0-.32.403-.495.634-.263z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16PinProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16Pin: FC<Icon16PinProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16Pin as any).mountIcon = mountIcon;

export default Icon16Pin;
