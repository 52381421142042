import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'rouble_circle_fill_blue_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="rouble_circle_fill_blue_20"><linearGradient id="rouble_circle_fill_blue_20_a" gradientUnits="userSpaceOnUse" x1="-10" x2="10" y1="10" y2="30"><stop offset="0" stop-color="#70b2ff" /><stop offset="1" stop-color="#5c9ce6" /></linearGradient><path d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z" fill="url(#rouble_circle_fill_blue_20_a)" /><path clip-rule="evenodd" d="M8.267 5H7.65a.65.65 0 00-.65.65v4.85h-.271c-.165 0-.29.03-.396.087a.591.591 0 00-.246.246.816.816 0 00-.087.395v.044c0 .163.03.29.087.395.056.106.14.19.246.246a.816.816 0 00.396.087H7v1.5h-.271c-.165 0-.29.03-.396.087a.591.591 0 00-.246.246.816.816 0 00-.087.395v.044c0 .163.03.29.087.395.056.106.14.19.246.246a.816.816 0 00.396.087H7v.35c0 .359.291.65.65.65h.2a.65.65 0 00.65-.65V15h2.272c.163 0 .29-.03.395-.087a.591.591 0 00.246-.246.817.817 0 00.087-.395v-.044c0-.163-.03-.29-.087-.395a.591.591 0 00-.246-.246.817.817 0 00-.395-.087H8.5V12H11c.966 0 1.842-.393 2.475-1.025.632-.633 1.025-1.509 1.025-2.475s-.393-1.842-1.025-2.475a3.491 3.491 0 00-2.403-1.024h-.007zM8.5 6.5v4H11c.552 0 1.052-.223 1.414-.586.363-.363.586-.861.586-1.414s-.223-1.051-.586-1.414A1.991 1.991 0 0011.05 6.5z" fill="#fff" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20RoubleCircleFillBlueProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20RoubleCircleFillBlue: FC<Icon20RoubleCircleFillBlueProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20RoubleCircleFillBlue as any).mountIcon = mountIcon;

export default Icon20RoubleCircleFillBlue;
