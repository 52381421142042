import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'camera_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="camera_36"><g fill="currentColor"><path d="M23 19a5 5 0 11-10 0 5 5 0 0110 0z" /><path clip-rule="evenodd" d="M21.557 5c1.034 0 1.64.304 2.235 1.01l.307.382c.2.252.479.6.77.963.546.68 1.657 1.433 2.676 1.433h.682c3.273 0 4.773 2.02 4.773 5.387v10.438C33 27.588 30.558 30 27.545 30H8.456C5.442 30 3 27.588 3 24.613V14.175c0-3.367 1.5-5.387 4.773-5.387h.682c1.02 0 2.13-.753 2.676-1.433a673.902 673.902 0 001.077-1.346C12.802 5.304 13.408 5 14.443 5zM25 19a7 7 0 11-14 0 7 7 0 0114 0z" fill-rule="evenodd" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36CameraProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36Camera: FC<Icon36CameraProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36Camera as any).mountIcon = mountIcon;

export default Icon36Camera;
