import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'mail_circle_fill_gray_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="mail_circle_fill_gray_28"><linearGradient id="mail_circle_fill_gray_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#b1b6bd" /><stop offset="1" stop-color="#99a2ad" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#mail_circle_fill_gray_28_a)" /><path d="M13.875 21c-1.385 0-2.595-.274-3.63-.822s-1.835-1.33-2.4-2.344C7.283 16.82 7 15.638 7 14.285v-.42c0-1.352.298-2.547.895-3.585a6.19 6.19 0 012.516-2.418C11.49 7.287 12.735 7 14.143 7h.072c1.307 0 2.475.258 3.504.774s1.834 1.234 2.413 2.155c.579.922.868 1.965.868 3.131v.428c0 .805-.133 1.513-.398 2.125-.266.613-.643 1.086-1.133 1.421-.489.336-1.053.503-1.691.503-.585 0-1.091-.118-1.518-.354s-.696-.55-.81-.94h-.08c-.114.39-.36.702-.739.936-.379.233-.828.35-1.347.35-.525 0-.982-.116-1.37-.346s-.686-.561-.895-.993c-.209-.43-.313-.941-.313-1.53v-1.067c0-.565.106-1.058.318-1.477.212-.42.511-.745.9-.976.387-.23.84-.345 1.36-.345.49 0 .909.114 1.258.341.349.228.58.534.693.918h.063v-.166c0-.28.088-.507.264-.682.176-.175.404-.262.685-.262.274 0 .497.087.667.262s.255.402.255.682v3.42c0 .244.074.445.224.603s.343.236.581.236c.269 0 .503-.115.703-.346.2-.23.355-.555.466-.975.11-.42.165-.906.165-1.46v-.236c0-.892-.218-1.692-.653-2.4a4.478 4.478 0 00-1.813-1.658c-.773-.396-1.646-.594-2.618-.594h-.08c-1.08 0-2.028.224-2.843.673-.814.449-1.446 1.086-1.893 1.91s-.671 1.78-.671 2.865v.306c0 1.072.214 2.008.644 2.807.43.798 1.04 1.415 1.83 1.85s1.718.65 2.78.65h.09c.4 0 .87-.04 1.414-.122.274-.035.445-.052.51-.052a.73.73 0 01.52.192.64.64 0 01.205.49c0 .344-.194.577-.582.7-.519.169-1.247.253-2.184.253zm-1.146-6.637c0 1.06.397 1.591 1.19 1.591.376 0 .661-.124.855-.371.194-.248.291-.617.291-1.106v-.735c0-.443-.103-.79-.309-1.04-.205-.251-.49-.377-.854-.377-.382 0-.673.136-.873.407-.2.271-.3.663-.3 1.176z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28MailCircleFillGrayProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28MailCircleFillGray: FC<Icon28MailCircleFillGrayProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28MailCircleFillGray as any).mountIcon = mountIcon;

export default Icon28MailCircleFillGray;
