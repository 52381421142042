import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'palette_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="palette_outline_24"><path fill-rule="evenodd" clip-rule="evenodd" d="M5 5a9.872 9.872 0 017-2.9c2.734 0 5.21 1.11 7 2.9a9.872 9.872 0 012.9 7c0 1.933-.61 3.282-1.77 4.041-1.09.714-2.46.76-3.652.631h-.002a2280.088 2280.088 0 00-1.307-.14l-.025-.002c-.657-.045-.948.14-1.063.28-.116.14-.244.46-.077 1.096.052.197.109.378.172.58l.105.339A2.384 2.384 0 0112 21.9 9.872 9.872 0 015 19a9.872 9.872 0 01-2.9-7c0-2.734 1.11-5.21 2.9-7zm7-1.1c-2.237 0-4.26.906-5.728 2.372A8.072 8.072 0 003.9 12c0 2.237.906 4.26 2.372 5.727A8.072 8.072 0 0012 20.1a.584.584 0 00.559-.753 25.023 25.023 0 00-.083-.267 16 16 0 01-.213-.717c-.256-.976-.18-1.964.431-2.701.61-.738 1.567-.997 2.573-.928.056.004.462.048 1.198.127l.204.022c1.075.115 1.92.016 2.475-.348.486-.318.956-.98.956-2.535 0-2.237-.906-4.26-2.372-5.728A8.072 8.072 0 0012 3.9z" fill="currentColor" /><path d="M13 7.4a1.4 1.4 0 112.8 0 1.4 1.4 0 01-2.8 0zm2.75 4a1.4 1.4 0 112.8 0 1.4 1.4 0 01-2.8 0zm-10.3 0a1.4 1.4 0 112.8 0 1.4 1.4 0 01-2.8 0zM8 7.4a1.4 1.4 0 112.8 0 1.4 1.4 0 01-2.8 0z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24PaletteOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24PaletteOutline: FC<Icon24PaletteOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24PaletteOutline as any).mountIcon = mountIcon;

export default Icon24PaletteOutline;
