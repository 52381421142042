import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'hanger_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="hanger_outline_28"><path clip-rule="evenodd" d="M14 5c1.808 0 3.273 1.497 3.273 3.344 0 1.148-.744 2.234-2.182 3.354v.2l9.075 4.124A3.13 3.13 0 0122.87 22H5.129a3.13 3.13 0 01-1.295-5.978l9.075-4.125v-.199c0-.696.318-1.352.86-1.773.89-.694 1.322-1.271 1.322-1.58 0-.616-.488-1.115-1.091-1.115s-1.09.499-1.09 1.114-.49 1.115-1.092 1.115-1.09-.5-1.09-1.115C10.727 6.497 12.192 5 14 5zM4.662 17.843L14 13.598l9.338 4.245A1.129 1.129 0 0122.871 20H5.129a1.13 1.13 0 01-.467-2.157z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28HangerOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28HangerOutline: FC<Icon28HangerOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28HangerOutline as any).mountIcon = mountIcon;

export default Icon28HangerOutline;
