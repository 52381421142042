import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'edit_circle_fill_blue_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="edit_circle_fill_blue_20"><linearGradient id="edit_circle_fill_blue_20_a" gradientUnits="userSpaceOnUse" x1="-10" x2="10" y1="10" y2="30"><stop offset="0" stop-color="#70b2ff" /><stop offset="1" stop-color="#5c9ce6" /></linearGradient><path d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z" fill="url(#edit_circle_fill_blue_20_a)" /><path clip-rule="evenodd" d="M12.988 9.519l-4.47 4.47c-.278.278-.372.363-.486.449-.113.086-.224.152-.355.21s-.25.098-.628.205l-2.23.637a.25.25 0 01-.31-.31l.637-2.229c.108-.377.15-.497.207-.628s.123-.242.209-.356.171-.207.45-.485l4.469-4.47a.2.2 0 01.283 0l2.224 2.224a.2.2 0 010 .283zM12.5 4.992c.316-.316.464-.398.641-.452a.911.911 0 01.536 0c.178.054.325.136.641.452l.69.689c.315.316.397.463.45.64a.912.912 0 010 .537c-.053.177-.135.325-.45.64l-.675.675a.2.2 0 01-.283 0l-2.224-2.224a.2.2 0 010-.283z" fill="#fff" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20EditCircleFillBlueProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20EditCircleFillBlue: FC<Icon20EditCircleFillBlueProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20EditCircleFillBlue as any).mountIcon = mountIcon;

export default Icon20EditCircleFillBlue;
