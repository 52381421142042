import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'user_added_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="user_added_outline_28"><path fill-rule="evenodd" clip-rule="evenodd" d="M23 8.5c0 3.039-2.462 5.5-5.5 5.5A5.499 5.499 0 0112 8.5C12 5.461 14.461 3 17.5 3 20.538 3 23 5.461 23 8.5zm-2 0C21 6.566 19.434 5 17.5 5A3.499 3.499 0 0014 8.5c0 1.934 1.566 3.5 3.5 3.5S21 10.434 21 8.5zM10.556 21.464c-.064-.055-.063-.188-.059-.5.002-.09.003-.197.003-.321 0-2.288 3.304-3.643 7.25-3.643S25 18.355 25 20.643c0 .124.001.23.003.321.004.312.005.445-.059.5-.048.04-.131.04-.276.037a7.43 7.43 0 00-.124-.001H10.956l-.124.001c-.145.002-.228.004-.276-.037zM8.5 20.643c0-3.88 4.299-5.643 9.25-5.643S27 16.763 27 20.643c0 2.016-.781 2.857-2.456 2.857H10.956c-1.675 0-2.456-.84-2.456-2.857zm1.207-10.85a1 1 0 00-1.414 0L3.7 14.386l-1.993-1.993-.094-.083a1 1 0 00-1.32 1.497l2.7 2.7.094.083a1 1 0 001.32-.083l5.3-5.3.083-.094a1 1 0 00-.083-1.32z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28UserAddedOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28UserAddedOutline: FC<Icon28UserAddedOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28UserAddedOutline as any).mountIcon = mountIcon;

export default Icon28UserAddedOutline;
