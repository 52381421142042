import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'phone_32';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="phone_32"><path d="M18.816 20.345c1.805-1.805 4.648-1.972 6.538-.383l1.365 1.15c1.559 1.313 1.717 3.677.352 5.28a4.424 4.424 0 01-2.927 1.512c-4.364.588-8.942-1.515-13.737-6.31-4.794-4.795-6.899-9.375-6.31-13.738.03-.341.1-.678.21-1.003a4.425 4.425 0 011.3-1.924c1.605-1.365 3.968-1.208 5.28.352l1.149 1.366c1.591 1.888 1.426 4.732-.38 6.538l-.98.978a.95.95 0 00-.219.999c.357.975 1.267 2.193 2.728 3.654 1.462 1.462 2.68 2.37 3.653 2.729a.95.95 0 00.999-.22z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32PhoneProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32Phone: FC<Icon32PhoneProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32Phone as any).mountIcon = mountIcon;

export default Icon32Phone;
