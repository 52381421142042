import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'money_transfer_outline_56';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="money_transfer_outline_56"><g fill="none" fill-rule="evenodd"><path d="M0 0h56v56H0z" /><path d="M42.308 8c2.675 0 3.645.278 4.623.801a5.452 5.452 0 012.268 2.268c.523.978.801 1.948.801 4.623V32.5a1.5 1.5 0 01-3 0V20.999H9v14.155c0 1.254.122 1.758.353 2.22l.048.091c.261.49.645.873 1.134 1.134.458.245.913.383 2.07.4l.24.001 32.032-.001-5.438-5.438a1.5 1.5 0 012.122-2.122l8 8a1.5 1.5 0 010 2.122l-8 8a1.5 1.5 0 01-2.122-2.122L44.878 42H13.692c-2.675 0-3.645-.278-4.623-.801a5.452 5.452 0 01-2.268-2.268C6.278 37.953 6 36.983 6 34.308V15.692c0-2.675.278-3.645.801-4.623A5.452 5.452 0 019.07 8.801C10.047 8.278 11.017 8 13.692 8h28.616zm.846 3H12.846c-1.337 0-1.822.14-2.311.4A2.726 2.726 0 009.4 12.536c-.245.458-.383.913-.4 2.07l-.001.24V18h38v-3.153c0-1.254-.122-1.758-.353-2.22l-.048-.091a2.726 2.726 0 00-1.134-1.134c-.489-.262-.974-.401-2.31-.401z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56MoneyTransferOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56MoneyTransferOutline: FC<Icon56MoneyTransferOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56MoneyTransferOutline as any).mountIcon = mountIcon;

export default Icon56MoneyTransferOutline;
