import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'cup_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="cup_outline_24"><path clip-rule="evenodd" d="M16.89 2.257c-.461-.158-.967-.158-1.57-.157H8.68c-.603 0-1.109-.001-1.57.157A2.991 2.991 0 005.242 4.28a3.03 3.03 0 00-.08.847 2.672 2.672 0 00-1.37.161C2.799 5.686 2.1 6.66 2.1 8c0 2.36 2.094 4.526 4.71 4.856.613 1.341 1.495 2.63 2.787 3.392-.018.628-.08 1.331-.517 1.82-.199.222-.42.418-.588.665-.253.369-.392.802-.392 1.367v.9a.9.9 0 00.9.9h6a.9.9 0 00.9-.9v-.9c0-.565-.14-.998-.392-1.367-.169-.247-.39-.443-.587-.665-.439-.489-.5-1.192-.518-1.82 1.292-.762 2.174-2.05 2.788-3.392C19.806 12.527 21.9 10.36 21.9 8c0-1.34-.7-2.314-1.69-2.71a2.673 2.673 0 00-1.371-.162 3.031 3.031 0 00-.08-.847 2.991 2.991 0 00-1.87-2.024zM8.8 3.9c-.788 0-.967.012-1.106.06-.319.109-.626.44-.71.767-.036.145-.035.297.02 1 .227 2.854.817 5.265 1.716 6.933.895 1.66 2.003 2.44 3.28 2.44s2.385-.78 3.28-2.44c.899-1.668 1.489-4.08 1.715-6.933.056-.703.058-.855.02-1a1.197 1.197 0 00-.709-.768c-.139-.047-.318-.059-1.106-.059zM4.46 6.96c-.26.104-.56.38-.56 1.04 0 1.077.86 2.282 2.163 2.818a22.984 22.984 0 01-.692-3.411c-.013-.096-.02-.144-.055-.218-.153-.313-.565-.345-.857-.228zm15.08 0c.26.104.56.38.56 1.04 0 1.077-.86 2.282-2.163 2.818.305-1.05.534-2.198.692-3.411a.597.597 0 01.055-.218c.153-.313.563-.346.857-.228zm-9.12 12.31c-.143.16-.321.302-.443.48-.029.042-.077.115-.077.35h4.2c0-.235-.048-.308-.077-.35-.122-.178-.3-.32-.443-.48-.602-.673-.86-1.527-.943-2.411a5.015 5.015 0 01-1.274 0c-.083.884-.34 1.738-.942 2.41z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24CupOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24CupOutline: FC<Icon24CupOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24CupOutline as any).mountIcon = mountIcon;

export default Icon24CupOutline;
