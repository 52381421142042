import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'motorcycle_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="motorcycle_outline_28"><path clip-rule="evenodd" d="M12 6a1 1 0 011-1h4a1 1 0 01.944.67L18.41 7h2.047a3 3 0 012.824 2.053l.013.04.001.003.016.05a3 3 0 01-2.107 3.799l-.653.175.662 1.889A4.5 4.5 0 1117.028 20h-5.056a4.5 4.5 0 01-8.945 0H3a1 1 0 01-1-1v-1.325c0-.766 0-1.399.04-1.915.042-.535.131-1.029.356-1.495a4 4 0 011.868-1.869c.467-.225.96-.314 1.496-.356C6.276 12 6.91 12 7.675 12h.36c.902 0 1.63 0 2.222.04.608.042 1.147.13 1.656.34a5 5 0 012.706 2.707c.211.51.3 1.048.34 1.656.025.365.035.78.039 1.257h2.258a4.514 4.514 0 012.037-2.422L16.29 7H13a1 1 0 01-1-1zM5.05 20a2.5 2.5 0 004.9 0zm7.914-3.12c.021.31.03.674.034 1.12H4v-.286c0-.815 0-1.37.034-1.798.033-.419.092-.634.164-.784a2 2 0 01.934-.934c.15-.072.365-.131.784-.164.429-.033.983-.034 1.798-.034H8c.946 0 1.605 0 2.12.036.508.034.803.099 1.028.192a3 3 0 011.624 1.624c.093.225.158.52.192 1.027zm6.925-5.653L19.109 9h1.333a1 1 0 01.942.684l.007.025.011.034a1 1 0 01-.686 1.262l-.034.01zM24 19.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28MotorcycleOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28MotorcycleOutline: FC<Icon28MotorcycleOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28MotorcycleOutline as any).mountIcon = mountIcon;

export default Icon28MotorcycleOutline;
