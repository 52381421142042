import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'video_outline_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="video_outline_36"><path fill-rule="evenodd" clip-rule="evenodd" d="M18 3c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15C3 9.716 9.716 3 18 3zm0 2.5C11.096 5.5 5.5 11.096 5.5 18S11.096 30.5 18 30.5 30.5 24.904 30.5 18 24.904 5.5 18 5.5zm-1.9 5.98a2.6 2.6 0 00-2.6 2.6v7.84a2.6 2.6 0 003.972 2.209l6.31-3.92a2.6 2.6 0 000-4.418l-6.31-3.92a2.6 2.6 0 00-1.372-.391zm0 2.5a.1.1 0 01.053.015l6.31 3.92a.1.1 0 010 .17l-6.31 3.92A.1.1 0 0116 21.92v-7.84a.1.1 0 01.1-.1z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36VideoOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36VideoOutline: FC<Icon36VideoOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36VideoOutline as any).mountIcon = mountIcon;

export default Icon36VideoOutline;
