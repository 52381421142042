import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'lightbulb_circle_fill_yellow_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="lightbulb_circle_fill_yellow_28"><linearGradient id="lightbulb_circle_fill_yellow_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#ffb73d" /><stop offset="1" stop-color="#ffa000" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#lightbulb_circle_fill_yellow_28_a)" /><path clip-rule="evenodd" d="M14.005 5h.002c.548 0 .992.444.992.992v.005l-.004 1.008a1 1 0 01-1 .995h-.002a.992.992 0 01-.992-.992v-.005l.004-1.008a1 1 0 011-.995zm6.363 2.64a.992.992 0 010 1.404l-.002.004-.716.709a1 1 0 01-1.411-.003l-.001-.002a.992.992 0 010-1.403l.003-.003.716-.71a1 1 0 011.41.004zM23 14.007v-.002a1 1 0 00-.995-1l-1.008-.004h-.005a.992.992 0 00-.992.992v.002a1 1 0 00.995 1l1.008.004h.005a.992.992 0 00.992-.992zm-18-.012v-.002c0-.548.444-.992.992-.992h.005l1.008.004a1 1 0 01.995 1v.002a.992.992 0 01-.992.992h-.005l-1.008-.004a1 1 0 01-.995-1zM7.641 7.63l-.001.001a1 1 0 00-.003 1.411l.709.716.003.003a.993.993 0 001.404 0 1 1 0 00.004-1.412l-.71-.716-.002-.003a.992.992 0 00-1.404 0zM14 20.5a1 1 0 110 2 1 1 0 010-2zm4-6.5a4.001 4.001 0 01-2.5 3.71V18c0 .466 0 .699-.076.883a1 1 0 01-.541.54c-.184.077-.417.077-.883.077s-.699 0-.883-.076a1 1 0 01-.54-.541c-.077-.184-.077-.417-.077-.883v-.29A4.001 4.001 0 1118 14z" fill="#fff" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28LightbulbCircleFillYellowProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28LightbulbCircleFillYellow: FC<Icon28LightbulbCircleFillYellowProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28LightbulbCircleFillYellow as any).mountIcon = mountIcon;

export default Icon28LightbulbCircleFillYellow;
