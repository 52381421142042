import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'gift_circle_fill_red_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="gift_circle_fill_red_20"><linearGradient id="gift_circle_fill_red_20_a" gradientUnits="userSpaceOnUse" x1="-10" x2="10" y1="10" y2="30"><stop offset="0" stop-color="#ff5263" /><stop offset="1" stop-color="#ff3347" /></linearGradient><path d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z" fill="url(#gift_circle_fill_red_20_a)" /><path d="M8.709 12.3c.223 0 .304.023.385.067s.146.107.19.189c.043.081.066.162.066.385v2.418c0 .223-.023.304-.067.385a.454.454 0 01-.189.19c-.081.043-.162.066-.385.066H7.282c-.446 0-.607-.046-.77-.134a.91.91 0 01-.378-.377c-.088-.164-.134-.325-.134-.77V12.94c0-.223.023-.304.067-.385.043-.082.107-.146.189-.19s.162-.066.385-.066zm4.65 0c.223 0 .304.023.385.067s.146.107.19.189.066.162.066.385v1.777c0 .446-.046.607-.134.77a.909.909 0 01-.378.379c-.162.087-.324.133-.77.133h-1.427c-.223 0-.304-.023-.385-.067a.454.454 0 01-.19-.189c-.043-.081-.066-.162-.066-.385v-2.418c0-.223.023-.304.067-.385s.107-.146.189-.19.162-.066.385-.066zM8.709 8c.223 0 .304.023.385.067s.146.107.19.189c.043.081.066.162.066.385v1.718c0 .223-.023.304-.067.385a.454.454 0 01-.189.19c-.081.043-.162.066-.385.066H5.641c-.223 0-.304-.023-.385-.067a.454.454 0 01-.19-.189C5.024 10.663 5 10.582 5 10.36V9.255c0-.413.046-.58.134-.743a.909.909 0 01.378-.378c.163-.088.33-.134.743-.134zm5.036 0c.413 0 .58.046.743.134.163.087.291.215.378.378.088.163.134.33.134.743v1.104c0 .223-.023.304-.067.385a.454.454 0 01-.189.19c-.081.043-.162.066-.385.066h-3.068c-.223 0-.304-.023-.385-.067a.454.454 0 01-.19-.189c-.043-.081-.066-.162-.066-.385V8.641c0-.223.023-.304.067-.385s.107-.146.189-.19S11.068 8 11.29 8zM10 7C6.5 7 6.2 4.8 7 4s3-.5 3 3zm3-3.003c.8.8.5 3.003-3 3.003 0-3.5 2.2-3.803 3-3.003z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20GiftCircleFillRedProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20GiftCircleFillRed: FC<Icon20GiftCircleFillRedProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20GiftCircleFillRed as any).mountIcon = mountIcon;

export default Icon20GiftCircleFillRed;
