import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'market_slash_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="market_slash_outline_28"><g fill="currentColor"><path d="M19 12a1 1 0 110 2 1 1 0 010-2z" /><path clip-rule="evenodd" d="M22.032 10.024C21.75 10 21.377 10 20.8 10h-6.144a1 1 0 110-2h6.183c.527 0 .982 0 1.356.03.395.033.789.104 1.167.297a3 3 0 011.311 1.311c.193.378.264.772.296 1.167.031.374.031.83.031 1.356v6.192a1 1 0 01-2-.003V12.2c0-.577 0-.949-.024-1.232-.022-.272-.06-.372-.085-.422a1 1 0 00-.437-.437c-.05-.025-.15-.063-.422-.085zM4.293 4.293a1 1 0 011.414 0l20 20a1 1 0 01-1.414 1.414l-1.116-1.116-1.418-1.418L8.582 9.997 6.587 8.002 4.293 5.707a1 1 0 010-1.414z" fill-rule="evenodd" /><path d="M7.2 10h1.3c.028 0 .055-.001.082-.003L6.587 8.002c-.29.002-.551.01-.782.029-.395.032-.789.103-1.167.296a3 3 0 00-1.311 1.311c-.193.378-.264.772-.296 1.167C3 11.179 3 11.635 3 12.162v5.681c0 1.224 0 2.203.065 2.994.066.812.205 1.514.534 2.16A5.5 5.5 0 006.003 25.4c.646.329 1.348.468 2.16.534.791.065 1.77.065 2.994.065h5.686c1.224 0 2.203 0 2.994-.065.812-.066 1.514-.205 2.16-.534.43-.22.825-.492 1.18-.81l-1.418-1.418a3.499 3.499 0 01-.67.446c-.317.161-.726.267-1.415.323-.7.057-1.597.058-2.874.058h-5.6c-1.277 0-2.174 0-2.874-.058-.689-.056-1.098-.162-1.415-.324a3.5 3.5 0 01-1.53-1.529c-.161-.317-.267-.726-.323-1.415C5.001 19.974 5 19.077 5 17.8v-5.6c0-.577 0-.949.024-1.232.022-.272.06-.372.085-.422a1 1 0 01.437-.437c.05-.025.15-.063.422-.085C6.25 10 6.623 10 7.2 10zM18 8h2A6 6 0 009.55 3.975a1 1 0 101.483 1.342A4 4 0 0118 8z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28MarketSlashOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28MarketSlashOutline: FC<Icon28MarketSlashOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28MarketSlashOutline as any).mountIcon = mountIcon;

export default Icon28MarketSlashOutline;
