import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'archive_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="archive_outline_28"><g fill="none" fill-rule="evenodd"><path d="M0 0h28v28H0z" /><path d="M21.5 4A3.5 3.5 0 0125 7.5v2a1.5 1.5 0 01-1.5 1.5H23v9a4 4 0 01-4 4H9a4 4 0 01-4-4v-9h-.5A1.5 1.5 0 013 9.5v-2A3.5 3.5 0 016.5 4h15zm-.5 7H7v9a2 2 0 002 2h10a2 2 0 002-2v-9zm-4 2.5a1 1 0 010 2h-6a1 1 0 010-2zM21.5 6h-15A1.5 1.5 0 005 7.5V9h18V7.5A1.5 1.5 0 0021.5 6z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28ArchiveOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28ArchiveOutline: FC<Icon28ArchiveOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28ArchiveOutline as any).mountIcon = mountIcon;

export default Icon28ArchiveOutline;
