import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'playlist_cached_36';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="playlist_cached_36"><g fill="none" fill-rule="evenodd"><path d="M0 0h36v36H0z" /><path d="M23 10.999a1 1 0 00-.995-.999H4.995a.994.994 0 00-.995.999v1.002a1 1 0 00.995.999h17.01c.55 0 .995-.443.995-.999v-1.002zm-4 6c0-.552-.449-.999-1.007-.999H5.007A1 1 0 004 16.999v1.002c0 .552.449.999 1.007.999h12.986A1 1 0 0019 18.001v-1.002zM4 24.001v-1.002A.998.998 0 015.002 22h9.996c.546 0 1.002.447 1.002.999v1.002a.998.998 0 01-1.002.999H5.002A1.005 1.005 0 014 24.001zM32.034 22a.4.4 0 01.283.683l-6.034 6.034a.4.4 0 01-.566 0l-6.034-6.034a.4.4 0 01.283-.683H23v-6h6v6h3.034z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36PlaylistCachedProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36PlaylistCached: FC<Icon36PlaylistCachedProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36PlaylistCached as any).mountIcon = mountIcon;

export default Icon36PlaylistCached;
