import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'podcasts_outline_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="podcasts_outline_36"><g fill="currentColor"><path d="M8.25 21a2 2 0 11-4 0v-5a2 2 0 011.558-1.951C6.412 7.847 11.64 3 18 3s11.588 4.847 12.192 11.049A2 2 0 0131.75 16v5a2 2 0 11-4 0v-5.75c0-5.385-4.365-9.75-9.75-9.75s-9.75 4.365-9.75 9.75z" /><path clip-rule="evenodd" d="M25 15.25a7 7 0 10-14 0v7c0 3.44 2.48 6.3 5.75 6.889v2.741c0 .619.501 1.12 1.12 1.12h.26a1.12 1.12 0 001.12-1.12v-2.741A7.002 7.002 0 0025 22.25zM13.507 15a4.5 4.5 0 018.986 0h-.623a1.25 1.25 0 100 2.5h.63V20h-.63a1.25 1.25 0 100 2.5h.623a4.5 4.5 0 01-8.986 0h2.243a1.25 1.25 0 100-2.5H13.5v-2.5h2.25a1.25 1.25 0 100-2.5z" fill-rule="evenodd" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36PodcastsOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36PodcastsOutline: FC<Icon36PodcastsOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36PodcastsOutline as any).mountIcon = mountIcon;

export default Icon36PodcastsOutline;
