import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'user_microphone_badge_slash_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="user_microphone_badge_slash_outline_28"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.5 9a3.5 3.5 0 00-6.107-2.336l-.002-.002a1 1 0 11-1.419-1.407 5.5 5.5 0 117.773 7.773 1 1 0 11-1.408-1.417l-.003-.003A3.491 3.491 0 0017.5 9zM4.293 5.293a1 1 0 011.414 0l21 21a1 1 0 11-1.414 1.414l-2.262-2.262a6 6 0 01-8.475-8.475L4.292 6.707a1 1 0 010-1.414zM17.5 19.914v.336a1.5 1.5 0 001.805 1.469L17.5 19.914zm2.642 2.642A2.73 2.73 0 0119 22.8c-1.24 0-2.198-.786-2.42-1.723a.75.75 0 10-1.46.346c.346 1.459 1.603 2.54 3.13 2.811V25a.75.75 0 101.5 0v-.766a4.162 4.162 0 001.495-.575l-1.103-1.103zm-8.16-5.987a1 1 0 01-.795 1.17c-2.272.433-3.272 1.361-3.729 2.05a2.902 2.902 0 00-.408.917 2.016 2.016 0 00-.05.306v.488a.5.5 0 00.5.5h3a1 1 0 010 2h-3A2.5 2.5 0 015 21.5V21h1c-1 0-1-.002-1-.002v-.032a2.098 2.098 0 01.015-.215c.014-.13.04-.306.091-.515a4.9 4.9 0 01.686-1.553c.793-1.195 2.293-2.389 5.02-2.909a1 1 0 011.17.795zm12.469 4.64c.205.205.55.082.55-.209a6 6 0 00-6-6c-.291 0-.415.344-.21.55l5.66 5.658z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28UserMicrophoneBadgeSlashOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28UserMicrophoneBadgeSlashOutline: FC<Icon28UserMicrophoneBadgeSlashOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28UserMicrophoneBadgeSlashOutline as any).mountIcon = mountIcon;

export default Icon28UserMicrophoneBadgeSlashOutline;
