import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'favorite_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="favorite_36"><path d="M23.015 12.727l6.645.638c2.312.222 3.02 2.48 1.235 3.964l-5.201 4.323 1.93 7.043c.635 2.313-1.333 3.713-3.303 2.321L18 26.551l-6.32 4.465c-1.963 1.386-3.939-.008-3.304-2.321l1.93-7.043-5.201-4.323c-1.793-1.49-1.087-3.741 1.235-3.964l6.643-.638L15.91 5.98c.917-2.113 3.264-2.112 4.18 0z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36FavoriteProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36Favorite: FC<Icon36FavoriteProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36Favorite as any).mountIcon = mountIcon;

export default Icon36Favorite;
