import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'users_16';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="users_16"><g fill="none" fill-rule="evenodd"><path d="M0 0h16v16H0z" /><path d="M9.886 8.65a7.356 7.356 0 011.447-.15c1.53 0 4.334.571 4.334 2.857 0 1-.255 1.143-.765 1.143h-4.06c.105-.326.158-.708.158-1.143 0-1.148-.42-2.044-1.114-2.707zM1 11.357C1 9.071 3.804 8.5 5.333 8.5c1.53 0 4.334.571 4.334 2.857 0 1-.255 1.143-.765 1.143H1.765c-.51 0-.765-.143-.765-1.143zM7.333 5.5a2 2 0 11-3.999.001 2 2 0 014-.001zm6 0a2 2 0 11-3.999.001 2 2 0 014-.001z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16UsersProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16Users: FC<Icon16UsersProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16Users as any).mountIcon = mountIcon;

export default Icon16Users;
