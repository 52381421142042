import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'touch_id_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="touch_id_outline_28"><path clip-rule="evenodd" d="M14.493 5h-.9c-4.039 0-8.64 3.84-8.64 9.056v6.742a1 1 0 01-2 0v-6.742C2.952 7.98 8.102 3 13.592 3c.37 0 .736.018 1.098.054a1 1 0 01.896 1.094c-.054.55-.547.888-1.094.852zm3.48.914a1 1 0 01-.516-1.316c.221-.506.817-.751 1.317-.516 3.457 1.625 6.276 5.395 6.223 9.94v1.973a1 1 0 11-2 0v-1.973c-.017-3.476-2.122-6.784-5.024-8.108zm5.024 14.107a1 1 0 112 0v1.985a1 1 0 01-2 0zM14 9c-2.761 0-5 2.489-5 5.25a1 1 0 01-1 1c-.552 0-.992-.448-1-1C6.938 9.943 10.266 7 14 7c.603 0 1.19.076 1.75.22a1 1 0 11-.5 1.937A5.01 5.01 0 0014 9zm4.069.542a1 1 0 011.412.078C20.604 10.875 21 12.446 21 14.25v6.5a1 1 0 11-2 0v-6.5c0-1.281-.217-2.41-1.01-3.296a1 1 0 01.079-1.412zM8 17.212a1 1 0 011 1v3.968a1 1 0 11-2 0v-3.969a1 1 0 011-1zm6-4.204a1 1 0 00-1 1v8.984a1 1 0 11-2 0v-8.984a3 3 0 016 0v1.984a1 1 0 01-2 0v-1.984a1 1 0 00-1-1zm2 6.008a1 1 0 011 1v3.96a1 1 0 11-2 0v-3.96a1 1 0 011-1z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28TouchIdOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28TouchIdOutline: FC<Icon28TouchIdOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28TouchIdOutline as any).mountIcon = mountIcon;

export default Icon28TouchIdOutline;
