import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'newsfeed_outline_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="newsfeed_outline_16"><g fill="currentColor"><path clip-rule="evenodd" d="M8.6 2.5H7.4c-1.145 0-1.913.001-2.505.05-.574.046-.849.13-1.03.222a2.5 2.5 0 00-1.093 1.093c-.092.181-.176.456-.222 1.03-.049.592-.05 1.36-.05 2.505v1.2c0 1.145.001 1.913.05 2.505.046.574.13.849.222 1.03a2.5 2.5 0 001.093 1.093c.181.092.456.176 1.03.222.592.049 1.36.05 2.505.05h1.2c1.145 0 1.913-.001 2.505-.05.574-.046.849-.13 1.03-.222a2.5 2.5 0 001.093-1.093c.092-.181.176-.456.222-1.03.049-.592.05-1.36.05-2.505V7.4c0-1.145-.001-1.913-.05-2.505-.046-.574-.13-.849-.222-1.03a2.5 2.5 0 00-1.093-1.093c-.181-.092-.456-.176-1.03-.222-.592-.049-1.36-.05-2.505-.05zm-7.164.684C1 4.04 1 5.16 1 7.4v1.2c0 2.24 0 3.36.436 4.216a4 4 0 001.748 1.748C4.04 15 5.16 15 7.4 15h1.2c2.24 0 3.36 0 4.216-.436a4 4 0 001.748-1.748C15 11.96 15 10.84 15 8.6V7.4c0-2.24 0-3.36-.436-4.216a4 4 0 00-1.748-1.748C11.96 1 10.84 1 8.6 1H7.4c-2.24 0-3.36 0-4.216.436a4 4 0 00-1.748 1.748z" fill-rule="evenodd" /><path d="M1.75 5.25h12.5v1.5H1.75z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16NewsfeedOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16NewsfeedOutline: FC<Icon16NewsfeedOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16NewsfeedOutline as any).mountIcon = mountIcon;

export default Icon16NewsfeedOutline;
