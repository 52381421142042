import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'picture_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="picture_36"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.946 5.03C9.35 4.28 10.733 4 13.89 4h8.222c3.156 0 4.539.28 5.942 1.03a7.026 7.026 0 012.917 2.916C31.72 9.35 32 10.733 32 13.89v8.222c0 3.156-.28 4.539-1.03 5.942a7.026 7.026 0 01-2.916 2.917C26.65 31.72 25.267 32 22.11 32h-8.222c-3.156 0-4.539-.28-5.943-1.03a7.026 7.026 0 01-2.916-2.916C4.28 26.65 4 25.267 4 22.11v-8.222c0-3.156.28-4.539 1.03-5.943A7.027 7.027 0 017.946 5.03zM21.31 18.227a.554.554 0 01.901.008l5.676 7.827a.6.6 0 01-.114.82.554.554 0 01-.34.118H9.567A.577.577 0 019 26.414a.6.6 0 01.12-.36l4.145-5.513a.554.554 0 01.883-.015L17 24l4.31-5.773zM12 14a2 2 0 100-4 2 2 0 000 4z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36PictureProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36Picture: FC<Icon36PictureProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36Picture as any).mountIcon = mountIcon;

export default Icon36Picture;
