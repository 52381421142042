import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'linked_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="linked_16"><path d="M7.303 7.973a1 1 0 01-1.782.909 4.001 4.001 0 01.736-4.646l2.121-2.122a4 4 0 115.657 5.657 1 1 0 11-1.414-1.414 2 2 0 10-2.829-2.829L7.672 5.65a2.001 2.001 0 00-.37 2.323zm1.546.2a1 1 0 111.782-.908 4.001 4.001 0 01-.736 4.646l-2.121 2.121a4 4 0 11-5.657-5.657A1 1 0 113.531 9.79a2 2 0 002.829 2.828l2.121-2.121a2.001 2.001 0 00.368-2.323z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16LinkedProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16Linked: FC<Icon16LinkedProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16Linked as any).mountIcon = mountIcon;

export default Icon16Linked;
