import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'keyboard_bots_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="keyboard_bots_outline_28"><g fill="none" fill-rule="evenodd"><path d="M0 0h28v28H0z" /><path d="M16.308 3c2.59 0 3.818.237 5.094.92a6.452 6.452 0 012.678 2.678c.683 1.276.92 2.504.92 5.094v4.616c0 2.59-.237 3.818-.92 5.094a6.452 6.452 0 01-2.678 2.678c-1.276.683-2.504.92-5.094.92h-4.616c-2.59 0-3.818-.237-5.094-.92a6.452 6.452 0 01-2.678-2.678C3.237 20.126 3 18.898 3 16.308v-4.616c0-2.59.237-3.818.92-5.094A6.452 6.452 0 016.598 3.92C7.874 3.237 9.102 3 11.692 3h4.616zm0 2h-4.616c-2.288 0-3.204.177-4.15.683a4.452 4.452 0 00-1.859 1.858C5.177 8.488 5 9.404 5 11.691v4.617c0 2.288.177 3.204.683 4.15a4.452 4.452 0 001.858 1.859c.947.506 1.863.683 4.15.683h4.617c2.288 0 3.204-.177 4.15-.683a4.452 4.452 0 001.859-1.858c.506-.947.683-1.863.683-4.15v-4.617c0-2.288-.177-3.204-.683-4.15a4.452 4.452 0 00-1.858-1.859C19.512 5.177 18.596 5 16.309 5zM11 15a2 2 0 110 4 2 2 0 010-4zm6 0a2 2 0 110 4 2 2 0 010-4zm-6-6a2 2 0 110 4 2 2 0 010-4zm6 0a2 2 0 110 4 2 2 0 010-4z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28KeyboardBotsOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28KeyboardBotsOutline: FC<Icon28KeyboardBotsOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28KeyboardBotsOutline as any).mountIcon = mountIcon;

export default Icon28KeyboardBotsOutline;
