import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'add_circle_dotted_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="add_circle_dotted_outline_24"><g fill="currentColor"><path d="M13 13v3a1 1 0 11-2 0v-3H8a1 1 0 110-2h3V8a1 1 0 112 0v3h3a1 1 0 110 2z" /><path clip-rule="evenodd" d="M10.069 2.288a9.944 9.944 0 013.862 0 .9.9 0 11-.35 1.766 8.144 8.144 0 00-3.163 0 .9.9 0 11-.349-1.766zM7.747 4.015A.9.9 0 017.5 5.264 8.145 8.145 0 005.264 7.5.9.9 0 013.768 6.5a9.944 9.944 0 012.73-2.731.9.9 0 011.25.247zm8.506 0a.9.9 0 011.248-.247 9.945 9.945 0 012.731 2.73.9.9 0 01-1.496 1.002A8.143 8.143 0 0016.5 5.264a.9.9 0 01-.247-1.249zm4.401 5.346a.9.9 0 011.058.708 9.942 9.942 0 010 3.862.9.9 0 11-1.766-.35 8.14 8.14 0 000-3.163.9.9 0 01.708-1.057zm-17.308 0a.9.9 0 01.708 1.057 8.144 8.144 0 000 3.164.9.9 0 01-1.766.349 9.944 9.944 0 010-3.862.9.9 0 011.058-.708zm.67 6.892a.9.9 0 011.248.247A8.14 8.14 0 007.5 18.736.9.9 0 016.5 20.232a9.95 9.95 0 01-2.731-2.73.9.9 0 01.247-1.25zm15.969 0a.9.9 0 01.247 1.248 9.943 9.943 0 01-2.73 2.731.9.9 0 01-1.002-1.496 8.146 8.146 0 002.236-2.236.9.9 0 011.249-.247zM9.36 20.654a.9.9 0 011.057-.708 8.14 8.14 0 003.164 0 .9.9 0 11.349 1.766 9.943 9.943 0 01-3.862 0 .9.9 0 01-.708-1.058z" fill-rule="evenodd" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24AddCircleDottedOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24AddCircleDottedOutline: FC<Icon24AddCircleDottedOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24AddCircleDottedOutline as any).mountIcon = mountIcon;

export default Icon24AddCircleDottedOutline;
