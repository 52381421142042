import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'upload_outline_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="upload_outline_16"><path d="M12 12.25a.75.75 0 010 1.5H4a.75.75 0 010-1.5h8zm-4-1.5a.75.75 0 01-.75-.75V4.561l-1.72 1.72a.75.75 0 01-.976.072L4.47 6.28a.75.75 0 010-1.06l3-3A.755.755 0 018 2l-.073.004A.752.752 0 017.97 2H8a.751.751 0 01.53.22l-.078-.069a.755.755 0 01.078.069l3 3a.75.75 0 01-1.06 1.06L8.75 4.561V10a.75.75 0 01-.648.743L8 10.75z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16UploadOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16UploadOutline: FC<Icon16UploadOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16UploadOutline as any).mountIcon = mountIcon;

export default Icon16UploadOutline;
