import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'music_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="music_28"><g fill="none" fill-rule="evenodd"><path d="M0 0h28v28H0z" /><path d="M19.505 13H19.5V9.203c0-.276-.085-.406-.402-.327l-8.173 1.548c-.355.089-.425.237-.425.576v8.456c0 4.339-1.545 5.274-4.101 5.618C5.071 25.252 3 24.556 3 22.034c0-1.233 1.026-2.457 2.307-2.708 1.188-.234-.385.071 2.303-.44.433-.083.396-.391.396-.725L8.005 16h-.008V9.975s-.005-1.836 0-2.754c.005-1.098.595-1.502 2.333-1.86L12 5.054l7.663-1.415s2.334-.73 2.334 1.167v5.67H22v6.857c0 4.339-1.545 5.274-4.101 5.617-1.328.179-3.399-.518-3.399-3.04 0-1.232 1.026-2.456 2.307-2.708 1.188-.233-.385.072 2.303-.44.433-.082.396-.39.396-.725L19.505 13z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28MusicProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28Music: FC<Icon28MusicProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28Music as any).mountIcon = mountIcon;

export default Icon28Music;
