import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'article_outline_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="article_outline_16"><path d="M13.182 3.5c.452 0 .818.336.818.75 0 .38-.308.693-.707.743L13.182 5H2.818C2.366 5 2 4.664 2 4.25c0-.38.308-.693.707-.743l.111-.007zm0 4c.452 0 .818.336.818.75 0 .38-.308.693-.707.743L13.182 9H2.818C2.366 9 2 8.664 2 8.25c0-.38.308-.693.707-.743l.111-.007zm-3.727 4c.3 0 .545.336.545.75 0 .38-.205.694-.471.743L9.455 13h-6.91c-.3 0-.545-.336-.545-.75 0-.38.205-.694.471-.743l.074-.007z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16ArticleOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16ArticleOutline: FC<Icon16ArticleOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16ArticleOutline as any).mountIcon = mountIcon;

export default Icon16ArticleOutline;
