import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'phone_circle_fill_green_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="phone_circle_fill_green_28"><linearGradient id="phone_circle_fill_green_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#50c750" /><stop offset="1" stop-color="#32b332" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#phone_circle_fill_green_28_a)" /><path d="M15.642 16.535c1.054-1.054 2.712-1.15 3.814-.224l.797.671c.91.766 1.002 2.145.205 3.08a2.58 2.58 0 01-1.707.882c-2.546.343-5.217-.884-8.014-3.681-2.796-2.797-4.024-5.469-3.68-8.014.017-.199.058-.395.122-.585.149-.435.41-.822.759-1.122.936-.796 2.314-.705 3.08.205l.67.797c.928 1.101.832 2.76-.222 3.814l-.571.57a.554.554 0 00-.128.583c.208.569.739 1.28 1.59 2.131.854.853 1.564 1.384 2.132 1.592.203.075.43.024.582-.128z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28PhoneCircleFillGreenProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28PhoneCircleFillGreen: FC<Icon28PhoneCircleFillGreenProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28PhoneCircleFillGreen as any).mountIcon = mountIcon;

export default Icon28PhoneCircleFillGreen;
