import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'gift_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="gift_outline_24"><path d="M12.002 3.61c1.41-2.54 4.075-2.85 5.598-1.327 1.244 1.244 1.265 3.254-.148 4.718L18 7a4 4 0 014 4v.43c0 1.006-.14 1.546-.4 2.035a2.744 2.744 0 01-1.598 1.336L20 16.872c0 1.783-.186 2.43-.534 3.082a3.635 3.635 0 01-1.512 1.512c-.652.348-1.299.534-3.082.534H9.128c-1.783 0-2.43-.186-3.082-.534s-1.163-.86-1.512-1.512C4.186 19.302 4 18.655 4 16.872v-2.071a2.744 2.744 0 01-1.6-1.336c-.239-.448-.376-.939-.397-1.793L2 11a4 4 0 014-4l.55.001c-1.411-1.464-1.39-3.474-.146-4.718 1.525-1.524 4.192-1.213 5.6 1.326zM11.1 15H5.799l.002 2.115c.013.673-.005 1.382.32 1.99.55 1.026 1.725 1.085 2.763 1.094H11.1zm7.1 0h-5.3v5.199h2.215c1.038-.02 2.214-.067 2.763-1.094.223-.417.31-.825.321-1.99zM18 8.8l-5.1.001V13.2h5.624c.545-.004 1.2-.043 1.488-.583.117-.219.176-.464.186-.997L20.2 11A2.2 2.2 0 0018 8.8zm-12 0A2.2 2.2 0 003.8 11l.002.619c.01.533.069.778.186.997.288.54.94.579 1.485.584l5.627-.001V8.801L6 8.8zm1.676-5.244C6.606 4.625 7.6 6.632 10.85 6.975l.254.023c-.26-3.46-2.229-4.64-3.427-3.442zm8.651 0C15.13 2.358 13.16 3.538 12.9 6.998l.254-.023c3.25-.343 4.242-2.35 3.173-3.42z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24GiftOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24GiftOutline: FC<Icon24GiftOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24GiftOutline as any).mountIcon = mountIcon;

export default Icon24GiftOutline;
