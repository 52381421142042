import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'message_reply_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="message_reply_outline_28"><clipPath id="message_reply_outline_28_a"><path d="M0 0h28v28H0z" /></clipPath><g clip-path="url(#message_reply_outline_28_a)" fill="currentColor"><path clip-rule="evenodd" d="M14.902 4.491a1 1 0 01-.99 1.01c-5.334.05-9.375 3.746-9.375 7.998 0 1.537.514 2.979 1.422 4.2a1 1 0 01.197.597c0 1.042-.274 2.052-.61 2.963a25.572 25.572 0 01-.705 1.675c2.056-.218 3.405-.877 4.219-1.834a1 1 0 011.113-.289c1.173.44 2.473.687 3.845.687 4.847 0 8.681-3.034 9.369-6.761a1 1 0 111.967.363c-.898 4.864-5.73 8.398-11.336 8.398-1.373 0-2.693-.21-3.918-.597C8.656 24.25 6.556 24.898 3.988 25c-1.046.041-1.8-1.047-1.363-2.021l.27-.597c.276-.605.547-1.199.775-1.815.256-.694.43-1.349.475-1.966-1.017-1.48-1.608-3.228-1.608-5.101 0-5.622 5.22-9.941 11.356-9.998a1 1 0 011.01.99zM4.45 23.8l.002-.004z" fill-rule="evenodd" /><path d="M19.196 12.039L15.638 9.08c-.198-.164-.296-.246-.363-.324a1.174 1.174 0 010-1.514c.067-.078.165-.16.363-.324l3.558-2.958c.253-.21.38-.316.473-.363a.916.916 0 011.304.618c.023.102.023.268.023.599v1.217c3.147.502 4.402 2.653 4.9 4.361.068.232.102.348.105.43.021.481-.429.824-.883.673-.077-.026-.246-.133-.584-.347-.878-.556-2.076-1.058-3.538-1.058v1.094c0 .331 0 .497-.023.599a.915.915 0 01-1.304.618c-.093-.047-.22-.153-.473-.363z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28MessageReplyOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28MessageReplyOutline: FC<Icon28MessageReplyOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28MessageReplyOutline as any).mountIcon = mountIcon;

export default Icon28MessageReplyOutline;
