import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'hand_slash_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="hand_slash_outline_28"><g fill="currentColor"><path d="M24.634 14.721a2.779 2.779 0 00-.192-2.663C23.357 10.43 21.3 10.24 20 11.518V7.255c0-1.806-1.79-3.137-3.504-2.65A2.748 2.748 0 0013.75 2a2.749 2.749 0 00-2.679 2.125A2.737 2.737 0 0010.25 4c-.67 0-1.47.249-1.47 1.06a1 1 0 001 1c.157 0 .304-.06.47-.06.414 0 .75.344.75.753v.517c0 .55.444 1 1 1 .552 0 1-.46 1-1V4.752a.749.749 0 111.5 0v6.018a1 1 0 102 0V7.247c0-.413.333-.747.75-.747.414 0 .75.332.75.755v6.947c0 1.138 1.476 1.584 2.107.638l1.122-1.683c.426-.64 1.12-.632 1.55.01a.787.787 0 01.049.694l-1.49 3.128c-.363.645.116 1.491.872 1.491a1 1 0 00.902-.567h.001z" /><path clip-rule="evenodd" d="M12 26c-4.422 0-8-3.58-8-8.009v-7.863a2.74 2.74 0 011.35-2.364L3.293 5.707a1 1 0 011.414-1.414l19.004 19.004a1 1 0 11-1.414 1.414l-2.007-2.006C18.055 25.02 15.15 26 12 26zM8.5 14.5c.552 0 1-.457 1-1.008v-1.578l9.376 9.376C17.016 23.235 14.62 24 12 24c-3.314 0-6-2.681-6-6.009v-7.863a.751.751 0 011.5.01V13.5c0 .552.444.999 1 .999z" fill-rule="evenodd" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28HandSlashOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28HandSlashOutline: FC<Icon28HandSlashOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28HandSlashOutline as any).mountIcon = mountIcon;

export default Icon28HandSlashOutline;
