import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'phone_circle_fill_green_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="phone_circle_fill_green_56"><circle cx="28" cy="28" r="28" fill="url(#phone_circle_fill_green_56_paint0_linear)" /><path d="M30.112 31.259c1.354-1.354 3.486-1.48 4.903-.287l1.024.863c1.17.983 1.288 2.757.264 3.96a3.318 3.318 0 01-2.195 1.133c-3.273.44-6.707-1.137-10.303-4.733-3.596-3.596-5.174-7.03-4.733-10.303a3.317 3.317 0 011.133-2.196c1.204-1.023 2.976-.905 3.96.265l.862 1.024c1.194 1.416 1.07 3.55-.285 4.903l-.735.734a.712.712 0 00-.164.75c.267.73.95 1.644 2.046 2.74 1.096 1.097 2.01 1.778 2.74 2.046.26.096.552.031.749-.165l.734-.734z" fill="#fff" /><defs><linearGradient id="phone_circle_fill_green_56_paint0_linear" x1="-28" y1="28" x2="28" y2="84" gradientUnits="userSpaceOnUse"><stop stop-color="#50C750" /><stop offset="1" stop-color="#32B332" /></linearGradient></defs></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56PhoneCircleFillGreenProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56PhoneCircleFillGreen: FC<Icon56PhoneCircleFillGreenProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56PhoneCircleFillGreen as any).mountIcon = mountIcon;

export default Icon56PhoneCircleFillGreen;
