import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'cake_circle_fill_purple_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="cake_circle_fill_purple_20"><linearGradient id="cake_circle_fill_purple_20_a" gradientUnits="userSpaceOnUse" x1="-10" x2="10" y1="10" y2="30"><stop offset="0" stop-color="#937ff5" /><stop offset="1" stop-color="#735ce6" /></linearGradient><path d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z" fill="url(#cake_circle_fill_purple_20_a)" /><g fill="#fff"><path d="M15 12.88c-.993.248-2.104.125-3-.374-1.2.669-2.8.669-4 0-.896.499-2.007.622-3 .375V14a1 1 0 001 1h8a1 1 0 001-1zM4 9.5a1 1 0 011-1h10a1 1 0 011 1c0 1.5-1 2-2 2-1.5 0-2-1-2-1s-.5 1-2 1-2-1-2-1-.5 1-2 1c-1 0-2-.5-2-2zM13.407 4.05a.107.107 0 01.186 0c.467.853.907 1.224.907 1.95a1 1 0 11-2 0c0-.726.44-1.097.907-1.95zM6.407 4.05a.107.107 0 01.186 0C7.06 4.903 7.5 5.274 7.5 6a1 1 0 01-2 0c0-.726.44-1.097.907-1.95zM9.907 4.05a.107.107 0 01.186 0C10.56 4.903 11 5.274 11 6a1 1 0 11-2 0c0-.726.44-1.097.907-1.95z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20CakeCircleFillPurpleProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20CakeCircleFillPurple: FC<Icon20CakeCircleFillPurpleProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20CakeCircleFillPurple as any).mountIcon = mountIcon;

export default Icon20CakeCircleFillPurple;
