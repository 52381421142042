import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 58 58';
const id = 'ticket_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58" id="ticket_outline_56"><path fill-rule="evenodd" clip-rule="evenodd" d="M41.947 9.776a1.5 1.5 0 00-.164-1.934c-1.834-1.825-3.175-3.123-4.809-3.654a7.5 7.5 0 00-4.635 0c-.948.308-1.76.854-2.597 1.564-.812.689-1.74 1.616-2.889 2.766l-.045.045-3.818 3.819L8.565 26.806l-.046.045c-1.15 1.15-2.077 2.077-2.766 2.889-.71.836-1.255 1.649-1.564 2.597a7.5 7.5 0 000 4.635c.531 1.634 1.83 2.975 3.654 4.81a1.5 1.5 0 001.934.163 4.502 4.502 0 016.276 6.275 1.5 1.5 0 00.163 1.934c1.835 1.825 3.175 3.123 4.81 3.654a7.5 7.5 0 004.635 0c.948-.308 1.76-.854 2.597-1.563.812-.69 1.74-1.617 2.889-2.767l.045-.045 14.425-14.425 3.818-3.819.046-.045c1.15-1.15 2.076-2.077 2.766-2.889.71-.836 1.255-1.648 1.564-2.597a7.5 7.5 0 000-4.635c-.531-1.634-1.83-2.975-3.654-4.809a1.5 1.5 0 00-1.934-.164 4.502 4.502 0 01-6.276-6.275zm2.61 22.05l-3.183-3.181a1.5 1.5 0 10-2.12 2.121l3.181 3.182-13.364 13.365c-1.206 1.205-2.047 2.045-2.755 2.645-.694.59-1.16.861-1.582.998a4.501 4.501 0 01-2.782 0c-.691-.225-1.385-.752-2.795-2.117a7.502 7.502 0 00-9.997-9.997c-1.366-1.411-1.893-2.104-2.117-2.796a4.5 4.5 0 010-2.781c.137-.422.408-.888.997-1.583.6-.707 1.44-1.549 2.646-2.754L24.05 15.563l3.182 3.182a1.5 1.5 0 002.122-2.12l-3.182-3.183 2.757-2.758c1.206-1.205 2.047-2.045 2.755-2.645.694-.59 1.16-.86 1.582-.998a4.5 4.5 0 012.782 0c.691.225 1.384.752 2.795 2.117a7.502 7.502 0 009.997 9.997c1.366 1.411 1.893 2.104 2.117 2.796a4.5 4.5 0 010 2.781c-.137.422-.408.888-.997 1.583-.6.707-1.44 1.549-2.646 2.754l-2.758 2.758zm-13.082-10.96a1.5 1.5 0 012.121 0l3.536 3.536a1.5 1.5 0 11-2.122 2.122l-3.535-3.536a1.5 1.5 0 010-2.121z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56TicketOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56TicketOutline: FC<Icon56TicketOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 58,
    height: !isNaN(props.height) ? +props.height : 58,
  }));
};

(Icon56TicketOutline as any).mountIcon = mountIcon;

export default Icon56TicketOutline;
