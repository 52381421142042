import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'unpin_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="unpin_outline_28"><g fill="none" fill-rule="evenodd"><path d="M0 0h28v28H0z" /><path d="M12.37 8.954l1.414 1.415 3.889 3.89 1.415 1.413 4.62 4.62a1 1 0 01-1.415 1.415l-4.37-4.37-.398 5.17a1.375 1.375 0 01-2.343.867l-4.55-4.55-5.657 5.657c-.943.943-2.357-.471-1.414-1.414l5.657-5.657-4.55-4.55a1.375 1.375 0 01.867-2.343l5.17-.398-4.412-4.412a1 1 0 011.414-1.414l4.662 4.662h.001zm2.121-2.121l.416-.416a.5.5 0 00.137-.255l.441-2.206a1 1 0 011.688-.511l7.424 7.424a1 1 0 01-.51 1.688l-2.207.441a.5.5 0 00-.255.137l-.416.415a1 1 0 01-1.414-1.414l.415-.415a2.5 2.5 0 011.278-.684l.374-.075L17.08 6.18l-.075.374a2.5 2.5 0 01-.684 1.278l-.415.415a1 1 0 11-1.415-1.414zm-2.173 4.9a2.5 2.5 0 01-1.102.353l-4.173.32L15.635 21l.321-4.173a2.5 2.5 0 01.354-1.102l-3.992-3.992z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28UnpinOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28UnpinOutline: FC<Icon28UnpinOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28UnpinOutline as any).mountIcon = mountIcon;

export default Icon28UnpinOutline;
