import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'fullscreen_exit_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="fullscreen_exit_24"><path fill-rule="evenodd" clip-rule="evenodd" d="M5 13.9a.9.9 0 01.9-.9h4.2a.9.9 0 01.9.9v4.2a.9.9 0 11-1.8 0v-2.027l-3.664 3.663a.9.9 0 11-1.272-1.272L7.927 14.8H5.9a.9.9 0 01-.9-.9zm14.736-9.636a.9.9 0 010 1.272L16.073 9.2h2.025a.9.9 0 010 1.8H13.9a.9.9 0 01-.9-.9V5.9a.9.9 0 111.8 0v2.027l3.664-3.663a.9.9 0 011.272 0z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24FullscreenExitProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24FullscreenExit: FC<Icon24FullscreenExitProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24FullscreenExit as any).mountIcon = mountIcon;

export default Icon24FullscreenExit;
