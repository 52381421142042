import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'messages_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="messages_outline_24"><g fill="currentColor"><path clip-rule="evenodd" d="M9.5 3.1c-3.94 0-7.4 2.73-7.4 6.4 0 1.21.388 2.334 1.046 3.286a13.5 13.5 0 01-.792 2.463c-.323.748.235 1.726 1.196 1.597.74-.1 2.335-.393 3.748-1.233A8.49 8.49 0 009.5 15.9c3.94 0 7.4-2.73 7.4-6.4 0-3.67-3.46-6.4-7.4-6.4zM3.9 9.5c0-2.405 2.36-4.6 5.6-4.6 3.24 0 5.6 2.195 5.6 4.6s-2.36 4.6-5.6 4.6c-.73 0-1.425-.115-2.06-.322a.9.9 0 00-.788.113c-.694.475-1.514.77-2.213.95.204-.598.414-1.328.552-2.117a.9.9 0 00-.182-.716C4.226 11.275 3.9 10.414 3.9 9.5z" fill-rule="evenodd" /><path d="M18.975 8.425a.9.9 0 00-.04 1.272c.623.663 1.02 1.454 1.132 2.303h-.001c.022.162.034.325.034.49v.046c-.009.9-.333 1.749-.909 2.472a.9.9 0 00-.182.716c.138.788.348 1.52.552 2.117-.7-.18-1.519-.475-2.213-.95a.9.9 0 00-.788-.113 6.443 6.443 0 01-1.552.303 6.868 6.868 0 01-1.553-.061.9.9 0 10-.277 1.778 8.587 8.587 0 003.524-.186c1.413.84 3.008 1.134 3.748 1.234.961.129 1.52-.85 1.196-1.597a13.507 13.507 0 01-.792-2.463A5.749 5.749 0 0021.878 12c-.12-1.349-.722-2.568-1.63-3.535a.9.9 0 00-1.273-.04z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24MessagesOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24MessagesOutline: FC<Icon24MessagesOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24MessagesOutline as any).mountIcon = mountIcon;

export default Icon24MessagesOutline;
