import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'repost_circle_fill_green_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="repost_circle_fill_green_28"><linearGradient id="repost_circle_fill_green_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#50c750" /><stop offset="1" stop-color="#32b332" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#repost_circle_fill_green_28_a)" /><path d="M16.75 7.651a.75.75 0 01.416.126l3.898 2.599a.75.75 0 010 1.248l-3.898 2.599A.75.75 0 0116 13.599V12h-4.718c-.446 0-.607.046-.77.134a.908.908 0 00-.378.378c-.077.142-.122.284-.132.615l-.002.155v3.436c0 .446.046.607.134.77.087.163.215.291.378.378.142.077.284.122.615.132l.155.002H15a1 1 0 110 2h-3.154c-1.337 0-1.822-.14-2.311-.4A2.726 2.726 0 018.4 18.464c-.262-.489-.401-.974-.401-2.31v-2.31c0-1.337.14-1.821.4-2.31s.646-.873 1.135-1.134c.489-.262.973-.401 2.31-.401H16V8.401a.75.75 0 01.75-.75z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28RepostCircleFillGreenProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28RepostCircleFillGreen: FC<Icon28RepostCircleFillGreenProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28RepostCircleFillGreen as any).mountIcon = mountIcon;

export default Icon28RepostCircleFillGreen;
