import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'money_transfer_circle_fill_red_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="money_transfer_circle_fill_red_28"><linearGradient id="money_transfer_circle_fill_red_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#ff5263" /><stop offset="1" stop-color="#ff3347" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#money_transfer_circle_fill_red_28_a)" /><path d="M17.513 7c1.56 0 2.126.162 2.696.467s1.019.753 1.324 1.324c.305.57.467 1.136.467 2.696V14a1 1 0 11-2 0v-1H8v2.727c.009.949.072 1.242.231 1.54.119.221.28.383.503.502.319.17.632.231 1.753.231H17v-1.505a.8.8 0 011.244-.666l3.758 2.505a.8.8 0 010 1.332l-3.758 2.505A.8.8 0 0117 21.505V20h-6.513c-1.56 0-2.126-.162-2.696-.467-.57-.305-1.018-.753-1.324-1.323S6 17.073 6 15.513v-4.026c0-1.56.162-2.126.467-2.696s.753-1.018 1.324-1.324S8.927 7 10.487 7zm.214 2h-7.24c-1.12 0-1.434.06-1.753.231-.222.119-.384.28-.503.503-.143.267-.208.53-.226 1.265h11.99c-.018-.735-.083-.998-.226-1.265a1.181 1.181 0 00-.503-.503c-.297-.159-.59-.222-1.54-.23z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28MoneyTransferCircleFillRedProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28MoneyTransferCircleFillRed: FC<Icon28MoneyTransferCircleFillRedProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28MoneyTransferCircleFillRed as any).mountIcon = mountIcon;

export default Icon28MoneyTransferCircleFillRed;
