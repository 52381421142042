import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'question_circle_fill_violet_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="question_circle_fill_violet_20"><linearGradient id="question_circle_fill_violet_20_a" gradientUnits="userSpaceOnUse" x1="-10" x2="10" y1="10" y2="30"><stop offset="0" stop-color="#c48aff" /><stop offset="1" stop-color="#aa65f0" /></linearGradient><path d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z" fill="url(#question_circle_fill_violet_20_a)" /><path d="M8.855 11.686c0-.406.096-.763.29-1.073.192-.31.516-.654.973-1.034.276-.228.48-.408.614-.538.133-.13.242-.276.328-.437s.128-.343.128-.546c0-.229-.058-.428-.175-.597a1.137 1.137 0 00-.47-.39 1.506 1.506 0 00-.639-.136c-.552 0-.992.234-1.32.702-.133.15-.255.259-.364.324a.77.77 0 01-.4.097c-.261 0-.463-.086-.606-.257S7 7.418 7 7.169c0-.24.057-.476.171-.71.114-.234.271-.447.471-.64.271-.26.617-.461 1.038-.604A4.483 4.483 0 0110.125 5c.661 0 1.248.116 1.759.347.511.232.908.566 1.192 1.003.283.437.424.957.424 1.56 0 .421-.069.788-.207 1.1a2.709 2.709 0 01-.539.807 7.96 7.96 0 01-.852.738c-.314.25-.547.463-.7.64a2.33 2.33 0 00-.385.655 5.236 5.236 0 01-.267.46c-.074.11-.172.198-.293.265s-.275.102-.46.102c-.304 0-.538-.093-.7-.277-.161-.185-.242-.423-.242-.714zm-.321 3.081c0-.244.056-.46.168-.647.111-.187.263-.332.453-.433.19-.101.4-.152.628-.152.228 0 .437.05.628.152.19.101.34.246.449.433.11.187.164.403.164.647 0 .245-.055.46-.164.648s-.26.332-.45.433c-.19.101-.399.152-.627.152-.229 0-.438-.05-.628-.152-.19-.101-.342-.246-.453-.433s-.168-.403-.168-.648z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20QuestionCircleFillVioletProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20QuestionCircleFillViolet: FC<Icon20QuestionCircleFillVioletProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20QuestionCircleFillViolet as any).mountIcon = mountIcon;

export default Icon20QuestionCircleFillViolet;
