import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'text_live_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="text_live_outline_56"><g fill="currentColor"><path d="M12.724 11.413c.6.57.624 1.52.054 2.12C9.196 17.302 7 22.393 7 28s2.196 10.699 5.778 14.466a1.5 1.5 0 01-2.175 2.067C6.513 40.231 4 34.407 4 28s2.513-12.23 6.603-16.534a1.5 1.5 0 012.12-.053zM43.276 11.413a1.5 1.5 0 012.12.054C49.488 15.768 52 21.593 52 28s-2.513 12.23-6.603 16.534a1.5 1.5 0 01-2.175-2.067C46.804 38.699 49 33.607 49 28s-2.196-10.699-5.778-14.466a1.5 1.5 0 01.054-2.121zM22.5 21a1.5 1.5 0 000 3h4v11.5a1.5 1.5 0 003 0V24h4a1.5 1.5 0 000-3z" /><path d="M41.09 15.646a1.5 1.5 0 10-2.18 2.059C41.446 20.393 43 24.013 43 28s-1.553 7.607-4.09 10.295a1.5 1.5 0 102.18 2.06C44.134 37.131 46 32.781 46 28s-1.867-9.132-4.91-12.355zM17.09 17.705a1.5 1.5 0 10-2.18-2.06C11.866 18.868 10 23.217 10 28s1.867 9.132 4.91 12.354a1.5 1.5 0 102.18-2.059C14.554 35.607 13 31.987 13 28s1.553-7.607 4.09-10.295z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56TextLiveOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56TextLiveOutline: FC<Icon56TextLiveOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56TextLiveOutline as any).mountIcon = mountIcon;

export default Icon56TextLiveOutline;
