import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'notification_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="notification_outline_20"><path clip-rule="evenodd" d="M5.5 8a4.5 4.5 0 019-.008v1.674c0 .297.106.584.299.81l1.523 1.787a.75.75 0 01-.571 1.237H4.249a.75.75 0 01-.57-1.237L5.2 10.477a1.25 1.25 0 00.299-.811V8.003 8zm-1.488-.385a6 6 0 0111.976 0c.008.044.012.089.012.135v1.823l1.463 1.717c1.245 1.461.207 3.71-1.712 3.71h-2.076a3.751 3.751 0 01-7.35 0H4.249c-1.919 0-2.957-2.249-1.712-3.71L4 9.575V7.75c0-.046.004-.091.012-.135zM10 16.5A2.25 2.25 0 017.878 15h4.244A2.251 2.251 0 0110 16.5z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20NotificationOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20NotificationOutline: FC<Icon20NotificationOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20NotificationOutline as any).mountIcon = mountIcon;

export default Icon20NotificationOutline;
