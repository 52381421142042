import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'school_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="school_outline_56"><path clip-rule="evenodd" d="M25.12 12.647a4 4 0 015.76 0l9.54 9.894A1.5 1.5 0 0041.5 23H49v13.9c0 1.705-.001 2.893-.077 3.819-.074.907-.212 1.429-.414 1.824a4.5 4.5 0 01-1.966 1.967c-.395.2-.917.34-1.824.413C43.793 45 42.605 45 40.9 45H35v-8a7 7 0 10-14 0v8h-5.9c-1.705 0-2.893-.001-3.819-.077-.907-.074-1.429-.212-1.824-.414a4.5 4.5 0 01-1.967-1.966c-.2-.395-.34-.917-.413-1.824C7 39.793 7 38.605 7 36.9V23h7.5a1.5 1.5 0 001.08-.459zM24 45h8v-8a4 4 0 00-8 0zm-3 3h-5.964c-1.626 0-2.937 0-3.999-.087-1.093-.09-2.053-.278-2.942-.73a7.5 7.5 0 01-3.278-3.278c-.452-.889-.641-1.849-.73-2.942C4 39.9 4 38.59 4 36.964V23.036c0-1.626 0-2.937.087-3.999.089-1.093.278-2.054.73-2.942a7.5 7.5 0 013.278-3.278c.889-.452 1.849-.64 2.942-.73C12.1 12 13.41 12 15.036 12h6.541l1.384-1.435a7 7 0 0110.078 0L34.423 12h6.541c1.626 0 2.937 0 3.999.087 1.093.09 2.053.278 2.942.73a7.5 7.5 0 013.277 3.278c.453.888.642 1.849.731 2.942C52 20.1 52 21.41 52 23.036v13.928c0 1.626 0 2.937-.087 3.999-.09 1.093-.278 2.053-.73 2.942a7.5 7.5 0 01-3.278 3.277c-.889.453-1.849.642-2.942.731C43.9 48 42.59 48 40.964 48H24zm27.923-28.719c.018.223.032.462.043.719h-6.829l-4.821-5H40.9c1.705 0 2.893.001 3.819.077.907.074 1.429.212 1.824.414a4.5 4.5 0 011.966 1.966c.202.395.34.917.414 1.824zM15.1 15h3.584l-4.821 5H7.034c.01-.257.025-.496.043-.719.074-.907.212-1.429.413-1.824a4.5 4.5 0 011.967-1.966c.395-.202.917-.34 1.824-.414C12.207 15 13.395 15 15.1 15zM28 18a3 3 0 100 6 3 3 0 000-6z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56SchoolOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56SchoolOutline: FC<Icon56SchoolOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56SchoolOutline as any).mountIcon = mountIcon;

export default Icon56SchoolOutline;
