import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'qr_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="qr_24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" /><path d="M19.5 19H21a.5.5 0 01.5.5V21a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5v-1.5a.5.5 0 01.5-.5zM14 19h1.5a.5.5 0 01.5.5V21a.5.5 0 01-.5.5H14a.5.5 0 01-.5-.5v-1.5a.5.5 0 01.5-.5zm2.75-2.75h1.5a.5.5 0 01.5.5v1.5a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5v-1.5a.5.5 0 01.5-.5zm2.75-2.75H21a.5.5 0 01.5.5v1.5a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5V14a.5.5 0 01.5-.5zm-5.5 0h1.5a.5.5 0 01.5.5v1.5a.5.5 0 01-.5.5H14a.5.5 0 01-.5-.5V14a.5.5 0 01.5-.5zM4.5 13h4a2.5 2.5 0 012.5 2.5v4A2.5 2.5 0 018.5 22h-4A2.5 2.5 0 012 19.5v-4A2.5 2.5 0 014.5 13zm.25 1.75a1 1 0 00-1 1v3.5a1 1 0 001 1h3.5a1 1 0 001-1v-3.5a1 1 0 00-1-1h-3.5zm1 1.5h1.5a.5.5 0 01.5.5v1.5a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5v-1.5a.5.5 0 01.5-.5zM15.5 2h4A2.5 2.5 0 0122 4.5v4a2.5 2.5 0 01-2.5 2.5h-4A2.5 2.5 0 0113 8.5v-4A2.5 2.5 0 0115.5 2zm.25 1.75a1 1 0 00-1 1v3.5a1 1 0 001 1h3.5a1 1 0 001-1v-3.5a1 1 0 00-1-1h-3.5zm1 1.5h1.5a.5.5 0 01.5.5v1.5a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5v-1.5a.5.5 0 01.5-.5zM4.5 2h4A2.5 2.5 0 0111 4.5v4A2.5 2.5 0 018.5 11h-4A2.5 2.5 0 012 8.5v-4A2.5 2.5 0 014.5 2zm.25 1.75a1 1 0 00-1 1v3.5a1 1 0 001 1h3.5a1 1 0 001-1v-3.5a1 1 0 00-1-1h-3.5zm1 1.5h1.5a.5.5 0 01.5.5v1.5a.5.5 0 01-.5.5h-1.5a.5.5 0 01-.5-.5v-1.5a.5.5 0 01.5-.5z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24QrProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Qr: FC<Icon24QrProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Qr as any).mountIcon = mountIcon;

export default Icon24Qr;
