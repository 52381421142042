import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'emblem_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="emblem_outline_28"><path clip-rule="evenodd" d="M4 2h20v12.044c0 1.363 0 2.447-.071 3.321-.074.896-.227 1.66-.583 2.359a6 6 0 01-2.622 2.622c-.7.356-1.463.51-2.359.583l-.174.013a1.005 1.005 0 01-.191.018c-.969 0-1.764.394-2.342.823a4.988 4.988 0 00-.816.765l-.035.042-.004.006a1 1 0 01-1.606 0l-.004-.006a3.812 3.812 0 00-.195-.224 4.988 4.988 0 00-.656-.583c-.578-.429-1.373-.823-2.342-.823-.065 0-.13-.006-.191-.018a16.014 16.014 0 01-.174-.013c-.896-.074-1.66-.227-2.359-.583a6 6 0 01-2.622-2.622c-.356-.7-.51-1.463-.583-2.359C4 16.491 4 15.407 4 14.044V3zm6.5 18.982c1.295.117 2.325.67 3.033 1.195.173.128.329.256.467.378.138-.122.294-.25.467-.378.708-.526 1.738-1.078 3.033-1.195v-.007c.254-.01.486-.022.703-.04.772-.063 1.243-.182 1.613-.371a4 4 0 001.748-1.748c.189-.37.308-.842.371-1.613C22 16.419 22 15.417 22 14V4H6v10c0 1.417 0 2.419.065 3.203.063.772.182 1.243.371 1.613a4 4 0 001.748 1.748c.37.189.842.308 1.613.371.217.018.45.03.703.04z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28EmblemOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28EmblemOutline: FC<Icon28EmblemOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28EmblemOutline as any).mountIcon = mountIcon;

export default Icon28EmblemOutline;
