import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'lifebuoy_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="lifebuoy_outline_20"><path clip-rule="evenodd" d="M16.036 13.548A6.969 6.969 0 0017 10a6.968 6.968 0 00-.964-3.548L14.45 7.72c.351.684.549 1.459.549 2.28 0 .821-.198 1.597-.549 2.28zm1.18.945a8.5 8.5 0 10-14.434 0h.001a8.54 8.54 0 001.25 1.56A8.473 8.473 0 0010 18.5a8.473 8.473 0 005.967-2.446 8.541 8.541 0 001.25-1.561zm-2.43.616L13.2 13.84A4.982 4.982 0 0110 15a5 5 0 01-4.452-7.28L3.964 6.452A6.968 6.968 0 003 10c0 1.295.352 2.507.964 3.548L5.55 12.28a5.02 5.02 0 001.25 1.561L5.215 15.11C6.466 16.282 8.149 17 10 17s3.534-.718 4.785-1.891zM5.214 4.89L6.799 6.16A4.98 4.98 0 0110 5a4.98 4.98 0 013.2 1.159l1.585-1.268C13.534 3.718 11.851 3 10 3s-3.534.718-4.785 1.891zM10 13.5a3.5 3.5 0 100-7 3.5 3.5 0 000 7z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20LifebuoyOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20LifebuoyOutline: FC<Icon20LifebuoyOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20LifebuoyOutline as any).mountIcon = mountIcon;

export default Icon20LifebuoyOutline;
