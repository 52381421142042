import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'mic_slash_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="mic_slash_outline_28"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M9.559 3.966A6 6 0 0120 8v5c0 .133-.004.266-.013.398a1 1 0 11-1.996-.131c.006-.088.009-.177.009-.267V8a4 4 0 00-6.961-2.69 1 1 0 01-1.48-1.344zM23 12a1 1 0 011 1c0 1.42-.296 2.772-.831 3.997a1 1 0 11-1.833-.8A7.969 7.969 0 0022 13a1 1 0 011-1zM4.293 4.293a1 1 0 011.414 0l18 18a1 1 0 11-1.414 1.414l-2.532-2.532A9.944 9.944 0 0115 22.951L15 25a1 1 0 11-2 0v-2.05C7.947 22.45 4 18.186 4 13a1 1 0 112 0 8 8 0 0012.32 6.734l-1.459-1.459A6 6 0 018 13V9.414L4.293 5.707a1 1 0 010-1.414zM14 17a4 4 0 01-4-4v-1.586l5.352 5.352A3.995 3.995 0 0114 17z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28MicSlashOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28MicSlashOutline: FC<Icon28MicSlashOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28MicSlashOutline as any).mountIcon = mountIcon;

export default Icon28MicSlashOutline;
