import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'more_horizontal_circle_fill_gray_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="more_horizontal_circle_fill_gray_28"><linearGradient id="more_horizontal_circle_fill_gray_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#b1b6bd" /><stop offset="1" stop-color="#99a2ad" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#more_horizontal_circle_fill_gray_28_a)" /><path d="M15 6.667c.18.6.306 1.201 1.305 1.777 1.118.304 1.665.003 2.173-.337.53-.353.688-.25.9-.043l.515.515c.235.236.33.33 0 .943-.298.552-.633 1.066-.335 2.18.577 1.004 1.176 1.178 1.775 1.298.625.125.664.309.667.606v.727c0 .334 0 .467-.667.667-.6.18-1.201.306-1.777 1.305-.303 1.118-.002 1.665.337 2.173.353.53.25.688.043.9l-.515.515c-.236.235-.33.33-.943 0-.552-.298-1.066-.633-2.18-.335-1.004.577-1.178 1.176-1.298 1.775-.125.625-.309.664-.606.667h-.727c-.334 0-.467 0-.667-.667-.18-.6-.306-1.201-1.305-1.777-1.118-.303-1.665-.002-2.173.337-.53.353-.688.25-.9.043l-.515-.515c-.235-.236-.33-.33 0-.943.298-.552.634-1.066.335-2.18C7.865 15.294 7.266 15.12 6.667 15c-.625-.125-.664-.309-.667-.606v-.727c0-.334 0-.467.667-.667.6-.18 1.201-.306 1.777-1.305.304-1.118.003-1.665-.337-2.173-.353-.53-.25-.688-.043-.9l.515-.515c.236-.235.33-.33.943 0 .552.298 1.066.634 2.18.335C12.706 7.865 12.88 7.266 13 6.667c.125-.625.309-.664.606-.667h.727c.334 0 .467 0 .667.667zM14 10.2a3.8 3.8 0 100 7.6 3.8 3.8 0 000-7.6z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28MoreHorizontalCircleFillGrayProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28MoreHorizontalCircleFillGray: FC<Icon28MoreHorizontalCircleFillGrayProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28MoreHorizontalCircleFillGray as any).mountIcon = mountIcon;

export default Icon28MoreHorizontalCircleFillGray;
