import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'stars_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="stars_20"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.5 8.604c-.299.612-.68 1.238-1.17 1.727-.488.489-1.114.87-1.726 1.17.612.298 1.238.68 1.727 1.168.488.49.87 1.115 1.169 1.727.298-.612.68-1.238 1.17-1.727.488-.489 1.114-.87 1.726-1.169-.612-.298-1.238-.68-1.727-1.17-.489-.488-.87-1.114-1.17-1.726zm-.79-2.037c-.356 1.044-.836 2.099-1.44 2.703-.605.605-1.66 1.084-2.703 1.441a16.244 16.244 0 01-.798.252l-.275.079c-.38.105-.38.811 0 .917a19.497 19.497 0 011.073.33c1.044.357 2.098.837 2.703 1.441.604.605 1.084 1.66 1.44 2.703a16.237 16.237 0 01.253.797l.078.276c.105.38.812.38.917 0a23.159 23.159 0 01.265-.877l.066-.196c.357-1.044.836-2.098 1.44-2.703.605-.604 1.66-1.084 2.703-1.441a18.191 18.191 0 011.074-.33c.38-.106.38-.812 0-.917a21.42 21.42 0 01-1.074-.33c-1.043-.358-2.098-.837-2.702-1.442-.605-.604-1.084-1.659-1.441-2.703a18.194 18.194 0 01-.33-1.073c-.106-.38-.813-.38-.918 0a22.582 22.582 0 01-.264.877l-.066.196z" fill="currentColor" /><path d="M5.38 2.139a.4.4 0 00-.76 0l-.304.912a2 2 0 01-1.265 1.265l-.913.305a.4.4 0 000 .759l.913.304a2 2 0 011.265 1.265l.304.913a.4.4 0 00.76 0l.304-.913a2 2 0 011.265-1.265l.912-.304a.4.4 0 000-.76l-.912-.304a2 2 0 01-1.265-1.265l-.305-.913z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20StarsProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20Stars: FC<Icon20StarsProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20Stars as any).mountIcon = mountIcon;

export default Icon20Stars;
