import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'wifi_outline_56';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="wifi_outline_56"><g fill="none" fill-rule="evenodd"><path d="M0 0h56v56H0z" /><path d="M28 39.5a2 2 0 110 4 2 2 0 010-4zm0-9.5c2.694 0 5.273.792 7.47 2.253a1.5 1.5 0 11-1.662 2.498A10.443 10.443 0 0028 33c-2.097 0-4.098.614-5.805 1.75a1.5 1.5 0 11-1.662-2.499A13.442 13.442 0 0128 30zm0-9a21.42 21.42 0 0114.225 5.378 1.5 1.5 0 01-1.986 2.249A18.421 18.421 0 0028 24a18.421 18.421 0 00-12.238 4.626 1.5 1.5 0 01-1.986-2.249A21.42 21.42 0 0128 21zm0-9a29.389 29.389 0 0119.515 7.377 1.5 1.5 0 11-1.985 2.249A26.39 26.39 0 0028 15a26.39 26.39 0 00-17.528 6.625 1.5 1.5 0 11-1.986-2.25A29.389 29.389 0 0128 12z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56WifiOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56WifiOutline: FC<Icon56WifiOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56WifiOutline as any).mountIcon = mountIcon;

export default Icon56WifiOutline;
