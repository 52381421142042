import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'place_outline_32';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="place_outline_32"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M16 9c2.484 0 4.5 2.016 4.5 4.5S18.484 18 16 18s-4.5-2.016-4.5-4.5S13.516 9 16 9zm0 2.2c-1.269 0-2.3 1.031-2.3 2.3s1.031 2.3 2.3 2.3 2.3-1.031 2.3-2.3-1.031-2.3-2.3-2.3z" /><path d="M16 2.9C9.886 2.9 4.9 7.562 4.9 13.35c0 4.04 2.59 8.89 7.704 14.633l.352.393a4.1 4.1 0 006.085.003c5.35-5.917 8.059-10.897 8.059-15.028C27.1 7.56 22.114 2.9 16 2.9zm0 2.2c4.931 0 8.9 3.711 8.9 8.25 0 3.457-2.474 8.004-7.49 13.553a1.9 1.9 0 01-2.819 0l-.347-.386C9.459 21.143 7.1 16.727 7.1 13.35 7.1 8.81 11.069 5.1 16 5.1z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32PlaceOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32PlaceOutline: FC<Icon32PlaceOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32PlaceOutline as any).mountIcon = mountIcon;

export default Icon32PlaceOutline;
