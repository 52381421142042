import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'train_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="train_outline_28"><path clip-rule="evenodd" d="M13 2h-.044c-1.363 0-2.447 0-3.321.071-.896.074-1.66.227-2.359.583a6 6 0 00-2.622 2.622c-.356.7-.51 1.463-.583 2.359C4 8.509 4 9.593 4 10.956V16.643c0 1.084 0 1.958.058 2.666.06.728.185 1.369.487 1.961a5 5 0 002.185 2.185c.142.072.287.135.436.189l-.594 1.485a1 1 0 001.856.742L9.177 24h9.646l.749 1.871a1 1 0 001.857-.742l-.595-1.486c.149-.053.294-.116.436-.188a5 5 0 002.185-2.185c.302-.592.428-1.232.487-1.961C24 18.6 24 17.727 24 16.643V10.956c0-1.363 0-2.447-.071-3.321-.074-.896-.227-1.66-.583-2.359a6 6 0 00-2.622-2.622c-.7-.356-1.463-.51-2.359-.583C17.491 2 16.407 2 15.044 2H15zm3.68 20c1.094 0 1.863-.002 2.466-.051.605-.05.953-.142 1.216-.276a3 3 0 001.311-1.311c.134-.263.226-.611.276-1.216.05-.617.051-1.41.051-2.546V16H6v.6c0 1.137 0 1.929.051 2.546.05.605.142.953.276 1.216a3 3 0 001.311 1.311c.263.134.611.226 1.216.276.603.049 1.372.05 2.467.051H16.6zM6 14h16v-3c0-1.417 0-2.419-.065-3.203-.063-.771-.182-1.243-.371-1.613a4 4 0 00-1.748-1.748c-.37-.189-.842-.308-1.613-.371A19.684 19.684 0 0017 4.01a3 3 0 01-6 0c-.457.01-.852.025-1.203.054-.771.063-1.243.182-1.613.371a4 4 0 00-1.748 1.748c-.189.37-.308.842-.371 1.613C6 8.581 6 9.583 6 11zm7-10a1 1 0 002 0zM9 18a1 1 0 100 2h1a1 1 0 100-2zm8 1a1 1 0 011-1h1a1 1 0 110 2h-1a1 1 0 01-1-1z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28TrainOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28TrainOutline: FC<Icon28TrainOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28TrainOutline as any).mountIcon = mountIcon;

export default Icon28TrainOutline;
