import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'bomb_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="bomb_16"><path d="M10.712 5.202a.75.75 0 01.106.973l-.793.858a5 5 0 11-1.06-1.06l.687-.735a.75.75 0 011.06-.036zM6 6.5a3.5 3.5 0 100 7 3.5 3.5 0 000-7zm7.119-1.19l1.25.2a.75.75 0 01.622.858c-.069.427-.455.657-.86.623l-1.25-.2a.75.75 0 01-.622-.86c.069-.425.455-.656.86-.622zm.646-3.105c.314.296.287.745.03 1.06l-.85.9a.75.75 0 01-1.06.03c-.314-.296-.287-.745-.03-1.06l.85-.9a.75.75 0 011.06-.03zm-3.27-.544l.15 1.25a.75.75 0 01-.656.834c-.428.051-.756-.257-.834-.656l-.15-1.25a.75.75 0 01.656-.834c.428-.051.756.257.834.656z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16BombProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16Bomb: FC<Icon16BombProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16Bomb as any).mountIcon = mountIcon;

export default Icon16Bomb;
