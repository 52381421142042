import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'stars_circle_fill_violet_32';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="stars_circle_fill_violet_32"><path d="M0 16C0 7.163 7.163 0 16 0s16 7.163 16 16-7.163 16-16 16S0 24.837 0 16z" fill="url(#stars_circle_fill_violet_32_paint0_linear)" /><path fill-rule="evenodd" clip-rule="evenodd" d="M16.813 12.077c-.409 1.193-.956 2.398-1.647 3.089-.691.69-1.896 1.239-3.09 1.647a19.15 19.15 0 01-.91.288l-.316.09c-.434.12-.434.927 0 1.047a23.654 23.654 0 011.227.378c1.193.408 2.398.956 3.089 1.647.69.69 1.238 1.896 1.646 3.088a19.164 19.164 0 01.288.912c.032.107.061.212.09.316.12.433.928.433 1.048 0a23.654 23.654 0 01.378-1.227c.408-1.193.956-2.398 1.647-3.09.69-.69 1.896-1.238 3.088-1.646a18.005 18.005 0 01.912-.288l.316-.09c.433-.12.433-.927 0-1.048a23.654 23.654 0 01-1.228-.377c-1.192-.408-2.397-.956-3.088-1.647-.691-.691-1.24-1.896-1.647-3.089a17.992 17.992 0 01-.288-.912 24.089 24.089 0 01-.09-.315c-.12-.434-.928-.434-1.048 0a24.089 24.089 0 01-.378 1.227z" fill="#fff" /><path d="M10.72 7.015a.457.457 0 00-.868 0L9.504 8.06A2.286 2.286 0 018.06 9.504l-1.044.348a.457.457 0 000 .867l1.044.348a2.286 2.286 0 011.445 1.446l.348 1.043a.457.457 0 00.867 0l.348-1.043a2.286 2.286 0 011.446-1.446l1.043-.348a.457.457 0 000-.867l-1.043-.348a2.286 2.286 0 01-1.446-1.445l-.348-1.044z" fill="#fff" /><defs><linearGradient id="stars_circle_fill_violet_32_paint0_linear" x1="-16" y1="16" x2="16" y2="48" gradientUnits="userSpaceOnUse"><stop stop-color="#C48AFF" /><stop offset="1" stop-color="#AA65F0" /></linearGradient></defs></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32StarsCircleFillVioletProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32StarsCircleFillViolet: FC<Icon32StarsCircleFillVioletProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32StarsCircleFillViolet as any).mountIcon = mountIcon;

export default Icon32StarsCircleFillViolet;
