import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'share_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="share_outline_56"><path d="M31.325 8.38c.554 0 1.09.198 1.512.556l20.335 17.285a2.334 2.334 0 01.266 3.29 2.334 2.334 0 01-.266.268L32.837 47.063a2.334 2.334 0 01-3.29-.266l-.114-.145a2.335 2.335 0 01-.443-1.367v-8.472l-.326.007c-10.584.245-18.085 2.653-22.548 7.132l-.256.262a1.997 1.997 0 01-2.817.054 2 2 0 01-.569-1.89l.109-.468c3.43-14.416 12.22-22.063 26.09-22.67l.317-.012v-8.513a2.334 2.334 0 012.335-2.334zM50.632 28L31.99 12.154v8.547a1.5 1.5 0 01-1.5 1.5l-.443.003c-12.209.134-20.028 5.839-23.701 17.35l-.105.335.21-.156c5.466-3.981 13.488-5.934 24.04-5.934l.144.007a1.5 1.5 0 011.355 1.493v8.546L50.632 28z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56ShareOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56ShareOutline: FC<Icon56ShareOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56ShareOutline as any).mountIcon = mountIcon;

export default Icon56ShareOutline;
