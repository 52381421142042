import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'chain_circle_fill_blue_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="chain_circle_fill_blue_28"><linearGradient id="chain_circle_fill_blue_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#70b2ff" /><stop offset="1" stop-color="#5c9ce6" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#chain_circle_fill_blue_28_a)" /><path clip-rule="evenodd" d="M13.022 16.184a.837.837 0 01-1.187-.001 3.861 3.861 0 010-5.419l2.618-2.643a3.772 3.772 0 015.366 0c1.261 1.274 1.557 3.033.67 4.508-.296.49-.947.47-1.326.09-.274-.276-.253-.707-.076-1.019.465-.817.24-1.683-.456-2.379a2.094 2.094 0 00-2.997 0l-2.611 2.636a2.143 2.143 0 00-.001 3.025.83.83 0 010 1.202zm1.95-3.166c.833.835.832 2.19-.001 3.025L12.36 18.68a2.094 2.094 0 01-2.997 0c-.696-.696-.911-1.555-.447-2.371.178-.312.199-.744-.076-1.019-.379-.38-1.03-.4-1.325.09-.888 1.474-.602 3.226.659 4.5a3.772 3.772 0 005.367 0l2.617-2.643a3.86 3.86 0 000-5.419.837.837 0 00-1.187 0 .831.831 0 000 1.2z" fill="#fff" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28ChainCircleFillBlueProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28ChainCircleFillBlue: FC<Icon28ChainCircleFillBlueProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28ChainCircleFillBlue as any).mountIcon = mountIcon;

export default Icon28ChainCircleFillBlue;
