import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 48 48';
const id = 'stars_circle_fill_violet_48';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" id="stars_circle_fill_violet_48"><path fill-rule="evenodd" clip-rule="evenodd" d="M24 48c13.255 0 24-10.745 24-24S37.255 0 24 0 0 10.745 0 24s10.745 24 24 24z" fill="url(#stars_circle_fill_violet_48_paint0_linear)" /><path fill-rule="evenodd" clip-rule="evenodd" d="M25.138 18.508c-.572 1.67-1.339 3.357-2.306 4.324-.967.967-2.655 1.734-4.324 2.306l-.315.105a30.436 30.436 0 01-1.403.423c-.607.169-.607 1.3 0 1.468a33.12 33.12 0 011.718.528c1.67.572 3.357 1.339 4.324 2.306.967.967 1.734 2.654 2.306 4.324a27.729 27.729 0 01.403 1.277c.043.149.085.296.125.441.169.607 1.3.607 1.468 0a34.222 34.222 0 01.423-1.403l.105-.315c.572-1.67 1.339-3.357 2.306-4.324.967-.967 2.654-1.734 4.324-2.306a27.705 27.705 0 011.276-.403l.442-.125c.607-.169.607-1.3 0-1.468a34.693 34.693 0 01-1.403-.423l-.315-.105c-1.67-.572-3.357-1.339-4.324-2.306-.967-.967-1.735-2.655-2.306-4.324a25.866 25.866 0 01-.403-1.277 39.005 39.005 0 01-.125-.441c-.169-.607-1.3-.607-1.468 0a34.231 34.231 0 01-.423 1.403l-.105.315z" fill="#fff" /><path d="M16.607 11.421c-.194-.583-1.02-.583-1.214 0l-.487 1.461a3.2 3.2 0 01-2.024 2.024l-1.46.487c-.584.194-.584 1.02 0 1.214l1.46.487a3.2 3.2 0 012.024 2.024l.487 1.46c.194.584 1.02.584 1.214 0l.487-1.46a3.2 3.2 0 012.024-2.024l1.46-.487c.584-.194.584-1.02 0-1.214l-1.46-.487a3.2 3.2 0 01-2.024-2.024l-.487-1.46z" fill="#fff" /><defs><linearGradient id="stars_circle_fill_violet_48_paint0_linear" x1="-24" y1="24" x2="24" y2="72" gradientUnits="userSpaceOnUse"><stop stop-color="#C48AFF" /><stop offset="1" stop-color="#AA65F0" /></linearGradient></defs></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon48StarsCircleFillVioletProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon48StarsCircleFillViolet: FC<Icon48StarsCircleFillVioletProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 48,
    height: !isNaN(props.height) ? +props.height : 48,
  }));
};

(Icon48StarsCircleFillViolet as any).mountIcon = mountIcon;

export default Icon48StarsCircleFillViolet;
