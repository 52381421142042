import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'thumb_up_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="thumb_up_24"><path fill="currentColor" fill-rule="evenodd" d="M6 10c0-1.5-.5-2-2-2H2v12h2c1.5 0 2-.5 2-2v-8zm2-.636c1.034-.52 1.867-1.31 2.5-2.364C12 4.5 12 2 13 1.5S15 2 15 4s-1 3.5-.5 4 3 0 5 0 2.5.5 2.5 1.5-.667 1.5-2 1.5c1 0 1.5.5 1.5 1.5s-.667 1.5-2 1.5c1 0 1.5.5 1.5 1.5s-.667 1.5-2 1.5c1 0 1.5.5 1.5 1.5S20 20 18 20c-5.145 0-5.477-.875-10-.988V9.364z" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24ThumbUpProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24ThumbUp: FC<Icon24ThumbUpProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24ThumbUp as any).mountIcon = mountIcon;

export default Icon24ThumbUp;
