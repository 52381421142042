import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'archive_outline_56';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="archive_outline_56"><g fill="none" fill-rule="evenodd"><path d="M0 0h56v56H0z" /><path d="M44.5 18a1.5 1.5 0 011.5 1.5v21a7.5 7.5 0 01-7.5 7.5h-21a7.5 7.5 0 01-7.5-7.5v-21a1.5 1.5 0 011.5-1.5h33zM43 21H13v19.5a4.5 4.5 0 004.5 4.5h21a4.5 4.5 0 004.5-4.5V21zm-21.5 9h13a1.5 1.5 0 000-3h-13a1.5 1.5 0 000 3zm-9-22h31a6.5 6.5 0 016.5 6.5v4a2.5 2.5 0 01-2.5 2.5h-39A2.5 2.5 0 016 18.5v-4A6.5 6.5 0 0112.5 8zM47 18v-3.5a3.5 3.5 0 00-3.5-3.5h-31A3.5 3.5 0 009 14.5V18h38z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56ArchiveOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56ArchiveOutline: FC<Icon56ArchiveOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56ArchiveOutline as any).mountIcon = mountIcon;

export default Icon56ArchiveOutline;
