import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'poll_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="poll_outline_24"><path clip-rule="evenodd" d="M14.039 3.1H9.96c-1.017 0-1.835 0-2.497.054-.681.056-1.276.173-1.825.453a4.65 4.65 0 00-2.032 2.032c-.28.549-.397 1.144-.453 1.825C3.1 8.126 3.1 8.944 3.1 9.96v4.078c0 1.017 0 1.835.054 2.497.056.681.173 1.276.453 1.825a4.65 4.65 0 002.032 2.032c.549.28 1.144.397 1.825.453.662.054 1.48.054 2.497.054h4.078c1.017 0 1.835 0 2.497-.054.681-.056 1.276-.173 1.825-.453a4.65 4.65 0 002.032-2.032c.28-.55.397-1.144.453-1.825.054-.662.054-1.48.054-2.497V9.96c0-1.017 0-1.835-.054-2.497-.056-.681-.173-1.276-.453-1.825a4.65 4.65 0 00-2.032-2.032c-.55-.28-1.144-.397-1.825-.453-.662-.054-1.48-.054-2.497-.054zM6.456 5.21c.253-.128.585-.215 1.154-.262C8.19 4.901 8.935 4.9 10 4.9h4c1.065 0 1.81 0 2.39.048.57.047.9.134 1.154.263.536.273.972.709 1.245 1.245.13.253.216.585.263 1.154.047.58.048 1.325.048 2.39v4c0 1.065 0 1.81-.048 2.39-.047.57-.134.9-.263 1.154a2.85 2.85 0 01-1.245 1.245c-.253.13-.585.216-1.154.263-.58.047-1.325.048-2.39.048h-4c-1.065 0-1.81 0-2.39-.048-.57-.047-.9-.134-1.154-.263a2.85 2.85 0 01-1.245-1.245c-.13-.253-.216-.585-.263-1.154-.047-.58-.048-1.325-.048-2.39v-4c0-1.065 0-1.81.048-2.39.047-.57.134-.9.263-1.154a2.85 2.85 0 011.245-1.245zM12.9 7.9a.9.9 0 00-1.8 0v8.2a.9.9 0 001.8 0zM8 10a.9.9 0 01.9.9v5.2a.9.9 0 11-1.8 0v-5.2A.9.9 0 018 10zm8.9 2.9a.9.9 0 00-1.8 0v3.2a.9.9 0 101.8 0z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24PollOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24PollOutline: FC<Icon24PollOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24PollOutline as any).mountIcon = mountIcon;

export default Icon24PollOutline;
