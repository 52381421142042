import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'live_disable_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="live_disable_outline_24"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M12 3.9c-1.06 0-2.071.203-2.997.572A.9.9 0 118.337 2.8 9.876 9.876 0 0112 2.1c5.468 0 9.9 4.432 9.9 9.9a9.878 9.878 0 01-.7 3.662.9.9 0 01-1.672-.666A8.1 8.1 0 0012 3.9z" /><path d="M11.131 6.995a.9.9 0 01.906-.895 5.9 5.9 0 015.863 5.854.9.9 0 11-1.8.014A4.1 4.1 0 0012.026 7.9a.9.9 0 01-.895-.905zM5 19a9.872 9.872 0 007 2.9c2.41 0 4.619-.862 6.335-2.292l1.129 1.128a.9.9 0 101.272-1.272l-16.2-16.2a.9.9 0 10-1.272 1.272l1.128 1.129A9.863 9.863 0 002.1 12c0 2.734 1.11 5.21 2.9 7zm-1.1-7c0 2.237.906 4.26 2.372 5.728S9.763 20.1 12 20.1c1.913 0 3.67-.662 5.056-1.771l-1.57-1.57A5.876 5.876 0 0112 17.9a5.884 5.884 0 01-4.172-1.728A5.884 5.884 0 016.1 12a5.88 5.88 0 011.14-3.487L5.671 6.944A8.063 8.063 0 003.9 12zm4 0c0 1.132.458 2.156 1.2 2.9a4.085 4.085 0 002.9 1.2 4.07 4.07 0 002.192-.635l-1.645-1.645c-.661.199-1.4.013-1.89-.476s-.676-1.23-.477-1.891L8.535 9.807A4.078 4.078 0 007.9 12z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24LiveDisableOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24LiveDisableOutline: FC<Icon24LiveDisableOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24LiveDisableOutline as any).mountIcon = mountIcon;

export default Icon24LiveDisableOutline;
