import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = '1_circle_fill_gold_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="1_circle_fill_gold_20"><rect width="20" height="20" rx="10" fill="url(#1_circle_fill_gold_20_paint0_radial)" /><path d="M9.438 15h1.764V5.136H9.445L6.868 6.947v1.661l2.454-1.736h.116V15z" fill="#fff" /><defs><radialGradient id="1_circle_fill_gold_20_paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(90 5 5) scale(19.9542)"><stop stop-color="#FFD54F" /><stop offset="1" stop-color="#FFC105" /></radialGradient></defs></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon201CircleFillGoldProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon201CircleFillGold: FC<Icon201CircleFillGoldProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon201CircleFillGold as any).mountIcon = mountIcon;

export default Icon201CircleFillGold;
