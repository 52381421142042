import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'share_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="share_outline_20"><path d="M9.978 3.423a1.792 1.792 0 00-1.645 1.785v1.765l-.1.021a8.254 8.254 0 00-6.44 8.917.75.75 0 001.343.379l.175-.22a7.577 7.577 0 015.022-2.721v2.08a1.792 1.792 0 002.897 1.41l6.52-5.11a1.792 1.792 0 000-2.82l-6.52-5.11a1.791 1.791 0 00-1.105-.382zm.147 1.494c.065 0 .129.022.18.062l6.52 5.11a.292.292 0 010 .459l-6.52 5.11a.292.292 0 01-.472-.23v-2.873a.75.75 0 00-.767-.75l-.215.005-.305.016a9.064 9.064 0 00-5.162 2.056l-.037.032.004-.027a6.752 6.752 0 015.823-5.533.75.75 0 00.659-.744V5.208c0-.16.13-.291.292-.291z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20ShareOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20ShareOutline: FC<Icon20ShareOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20ShareOutline as any).mountIcon = mountIcon;

export default Icon20ShareOutline;
