import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'story_question_circle_fill_violet_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="story_question_circle_fill_violet_28"><linearGradient id="story_question_circle_fill_violet_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#c48aff" /><stop offset="1" stop-color="#aa65f0" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#story_question_circle_fill_violet_28_a)" /><path d="M12.211 15.117c0-.553.141-1.04.423-1.463.281-.422.756-.892 1.423-1.41.403-.311.702-.556.897-.733s.355-.376.48-.596.187-.468.187-.745c0-.312-.085-.583-.255-.814a1.64 1.64 0 00-.688-.532 2.337 2.337 0 00-.934-.186c-.806 0-1.45.32-1.929.958a2.33 2.33 0 01-.532.441c-.16.089-.354.133-.584.133-.382 0-.678-.117-.886-.35a1.249 1.249 0 01-.313-.863c0-.326.083-.648.25-.968.167-.319.397-.61.689-.872.396-.355.902-.63 1.517-.824C12.57 6.098 13.275 6 14.068 6c.966 0 1.823.158 2.57.473.747.316 1.328.772 1.742 1.367.413.596.62 1.305.62 2.128 0 .575-.1 1.075-.302 1.5s-.464.793-.788 1.101c-.323.309-.738.644-1.246 1.005-.459.34-.8.632-1.022.873-.222.24-.41.539-.563.893a7.08 7.08 0 01-.391.628c-.108.149-.25.27-.428.362s-.401.138-.672.138c-.445 0-.786-.126-1.022-.378-.236-.251-.355-.576-.355-.973zm-.469 4.202a1.602 1.602 0 01.907-1.473c.278-.139.584-.208.918-.208s.64.07.918.208.497.335.657.59.24.55.24.883c0 .333-.08.628-.24.883s-.38.452-.657.59-.584.208-.918.208-.64-.07-.918-.207-.498-.336-.662-.59-.245-.55-.245-.884z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28StoryQuestionCircleFillVioletProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28StoryQuestionCircleFillViolet: FC<Icon28StoryQuestionCircleFillVioletProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28StoryQuestionCircleFillViolet as any).mountIcon = mountIcon;

export default Icon28StoryQuestionCircleFillViolet;
