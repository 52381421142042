import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'arrow_up_rectangle_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="arrow_up_rectangle_outline_28"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.07 4.801C6.164 4.216 7.243 4 9.691 4h8.616c2.448 0 3.527.216 4.622.801A5.465 5.465 0 0125.2 7.07c.585 1.096.801 2.175.801 4.623v4.616c0 2.448-.216 3.527-.801 4.622A5.465 5.465 0 0122.93 23.2c-1.095.585-2.174.801-4.622.801H9.692c-2.448 0-3.527-.216-4.623-.801a5.465 5.465 0 01-2.268-2.269C2.216 19.835 2 18.756 2 16.308v-4.616c0-2.448.216-3.527.801-4.623A5.466 5.466 0 015.07 4.801zM9.691 6c-2.335 0-3.019.212-3.68.565a3.466 3.466 0 00-1.447 1.448C4.212 8.673 4 9.357 4 11.692v4.616c0 2.335.212 3.019.565 3.68a3.466 3.466 0 001.448 1.447c.66.353 1.344.565 3.679.565h8.616c2.335 0 3.019-.212 3.68-.565a3.467 3.467 0 001.447-1.448c.353-.66.565-1.344.565-3.679v-4.616c0-2.335-.212-3.019-.565-3.68a3.466 3.466 0 00-1.448-1.447C21.327 6.212 20.643 6 18.308 6H9.692z" fill="currentColor" /><path d="M13 18a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 12.414V18z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28ArrowUpRectangleOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28ArrowUpRectangleOutline: FC<Icon28ArrowUpRectangleOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28ArrowUpRectangleOutline as any).mountIcon = mountIcon;

export default Icon28ArrowUpRectangleOutline;
