import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'message_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="message_outline_56"><path clip-rule="evenodd" d="M6 25.638C6 14.594 16.287 6 28.5 6S51 14.594 51 25.638c0 11.045-10.287 19.639-22.5 19.639a25.72 25.72 0 01-5.276-.543c-2.682 1.688-6.377 3.07-10.996 4.192a2.482 2.482 0 01-1.82-.259c-1.494-.856-1.42-2.503-.68-3.804 1.004-1.776 1.951-3.603 2.65-5.523C8.461 35.831 6 31.007 6 25.638zM28.5 9C17.517 9 9 16.648 9 25.638c0 4.699 2.29 8.99 6.067 12.053a1.5 1.5 0 01.49 1.601c-.56 1.841-1.527 3.987-2.868 6.423 4.196-1.102 7.3-2.391 9.398-3.822a1.5 1.5 0 011.19-.22c1.66.393 3.412.604 5.223.604 10.983 0 19.5-7.648 19.5-16.639C48 16.648 39.483 9 28.5 9z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56MessageOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56MessageOutline: FC<Icon56MessageOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56MessageOutline as any).mountIcon = mountIcon;

export default Icon56MessageOutline;
