import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'delete_outline_android_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="delete_outline_android_20"><path clip-rule="evenodd" d="M5.729 3A2.213 2.213 0 017.72 1.75h4.558c.857 0 1.627.493 1.992 1.25h.071c.374 0 .697 0 .963.02.28.022.56.07.832.2a2.25 2.25 0 011.06 1.06c.13.271.177.55.2.832.02.266.02.588.02.962v.396c0 .122 0 .255-.01.37a1.26 1.26 0 01-.127.477 1.25 1.25 0 01-.546.547 1.26 1.26 0 01-.478.127c-.114.01-.247.009-.37.009h-.136v4.732c0 .813 0 1.469-.043 2-.045.546-.14 1.026-.366 1.47a3.75 3.75 0 01-1.638 1.64c-.445.226-.925.32-1.471.365-.531.043-1.187.043-2 .043h-.464c-.813 0-1.469 0-2-.043-.546-.045-1.026-.14-1.47-.366a3.75 3.75 0 01-1.64-1.639c-.226-.444-.32-.924-.365-1.47-.043-.531-.043-1.187-.043-2V8h-.137c-.122 0-.254 0-.37-.01a1.259 1.259 0 01-.477-.126 1.25 1.25 0 01-.546-.547 1.259 1.259 0 01-.127-.478 4.75 4.75 0 01-.01-.369v-.396c0-.374 0-.696.02-.962.023-.281.07-.56.2-.832a2.25 2.25 0 011.06-1.06c.272-.13.551-.178.832-.2.266-.02.589-.02.963-.02zm.021 5v4.7c0 .853 0 1.447.038 1.91.037.453.107.714.207.911.216.424.56.768.984.984.197.1.458.17.912.207.462.037 1.057.038 1.909.038h.4c.853 0 1.447 0 1.91-.038.453-.037.714-.107.911-.207a2.25 2.25 0 00.984-.983c.1-.198.17-.459.207-.913.037-.462.038-1.057.038-1.909V8zm1.97-4.75a.712.712 0 00-.675.487 1.116 1.116 0 01-1.059.763h-.301c-.409 0-.673 0-.874.016-.194.015-.266.04-.301.057a.75.75 0 00-.353.354c-.017.035-.043.107-.058.3-.015.202-.016.466-.016.875V6.5H15.917V6.102c0-.41 0-.673-.016-.875-.015-.193-.04-.265-.058-.3a.75.75 0 00-.353-.354c-.035-.016-.107-.042-.3-.057a12.726 12.726 0 00-.875-.016h-.301c-.48 0-.907-.307-1.059-.763a.713.713 0 00-.676-.487z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20DeleteOutlineAndroidProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20DeleteOutlineAndroid: FC<Icon20DeleteOutlineAndroidProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20DeleteOutlineAndroid as any).mountIcon = mountIcon;

export default Icon20DeleteOutlineAndroid;
