import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'money_transfer_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="money_transfer_outline_24"><path d="M17.795 3.1c1.152 0 1.74.113 2.35.44a3.17 3.17 0 011.315 1.315c.302.564.421 1.107.438 2.094l.002.256V12a.9.9 0 01-1.793.113L20.1 12l-.001-3.1h-16.2l.001 5.895c0 .88.06 1.187.228 1.502.134.25.325.441.575.575.291.156.574.218 1.309.227l.193.001h12.622l-1.463-1.464a.9.9 0 01-.08-1.18l.08-.092a.9.9 0 011.18-.08l.092.08 3 3a.9.9 0 01.08 1.18l-.08.092-3 3a.9.9 0 01-1.353-1.18l.08-.092 1.464-1.464H6.205c-1.063 0-1.646-.097-2.21-.369l-.14-.071a3.171 3.171 0 01-1.315-1.314c-.301-.565-.421-1.108-.438-2.095l-.002-.256v-7.59c0-1.152.113-1.74.44-2.35A3.171 3.171 0 013.855 3.54c.564-.301 1.107-.421 2.094-.438l.256-.002zm0 1.8H6.205c-.88 0-1.187.06-1.502.228-.25.134-.441.325-.575.575-.156.291-.218.574-.227 1.309L3.9 7.1h16.198v-.088c-.008-.734-.07-1.018-.225-1.309a1.372 1.372 0 00-.576-.575c-.291-.156-.574-.218-1.309-.227z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24MoneyTransferOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24MoneyTransferOutline: FC<Icon24MoneyTransferOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24MoneyTransferOutline as any).mountIcon = mountIcon;

export default Icon24MoneyTransferOutline;
