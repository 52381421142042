import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'message_check_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="message_check_outline_28"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M2.537 13.499c0-5.66 5.289-9.999 11.481-9.999 6.193 0 11.482 4.339 11.482 9.999s-5.289 9.999-11.482 9.999c-1.373 0-2.693-.21-3.918-.597C8.656 24.25 6.556 24.898 3.988 25c-1.046.041-1.8-1.047-1.363-2.021.622-1.391 1.424-2.987 1.52-4.378-1.017-1.48-1.608-3.228-1.608-5.101zM14.018 5.5c-5.385 0-9.481 3.719-9.481 7.999 0 1.537.514 2.979 1.422 4.2a1 1 0 01.197.597c0 1.634-.651 3.175-1.315 4.638 2.056-.219 3.405-.877 4.219-1.834a1 1 0 011.113-.289 10.94 10.94 0 003.845.687c5.386 0 9.482-3.719 9.482-8 0-4.28-4.096-7.998-9.482-7.998z" /><path d="M18.207 10.293a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414l1.293 1.293 4.293-4.293a1 1 0 011.414 0z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28MessageCheckOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28MessageCheckOutline: FC<Icon28MessageCheckOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28MessageCheckOutline as any).mountIcon = mountIcon;

export default Icon28MessageCheckOutline;
