import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'smile_outline_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="smile_outline_36"><g fill="currentColor"><path d="M12.938 21.125a1.167 1.167 0 00-.057 1.648 7 7 0 0010.236.004 1.167 1.167 0 00-1.704-1.594 4.667 4.667 0 01-6.825-.001 1.167 1.167 0 00-1.65-.057zM21.938 17.125a1.896 1.896 0 100-3.792 1.896 1.896 0 000 3.792zM15.958 15.23a1.896 1.896 0 11-3.791 0 1.896 1.896 0 013.791 0z" /><path clip-rule="evenodd" d="M18 3c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15C3 9.716 9.716 3 18 3zm0 2.5C11.096 5.5 5.5 11.096 5.5 18S11.096 30.5 18 30.5 30.5 24.904 30.5 18 24.904 5.5 18 5.5z" fill-rule="evenodd" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36SmileOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36SmileOutline: FC<Icon36SmileOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36SmileOutline as any).mountIcon = mountIcon;

export default Icon36SmileOutline;
