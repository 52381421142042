import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'mention_circle_fill_blue_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="mention_circle_fill_blue_20"><linearGradient id="mention_circle_fill_blue_20_a" gradientUnits="userSpaceOnUse" x1="-10" x2="10" y1="10" y2="30"><stop offset="0" stop-color="#70b2ff" /><stop offset="1" stop-color="#5c9ce6" /></linearGradient><path d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z" fill="url(#mention_circle_fill_blue_20_a)" /><path d="M9.893 16c-1.187 0-2.224-.235-3.112-.705s-1.573-1.139-2.056-2.008C4.242 12.417 4 11.403 4 10.244v-.36c0-1.16.256-2.184.767-3.073s1.23-1.58 2.156-2.073C7.85 4.246 8.916 4 10.123 4h.061c1.12 0 2.122.221 3.004.663.882.443 1.572 1.058 2.068 1.848S16 8.195 16 9.194v.368c0 .69-.114 1.296-.341 1.82-.228.526-.552.932-.971 1.219-.42.287-.903.43-1.45.43-.501 0-.935-.1-1.3-.303-.366-.202-.598-.47-.695-.806h-.07a1.375 1.375 0 01-.632.802c-.325.2-.71.3-1.155.3-.45 0-.841-.098-1.174-.296a1.95 1.95 0 01-.767-.85c-.179-.37-.269-.807-.269-1.312V9.65c0-.484.091-.906.273-1.266s.438-.639.771-.836.721-.296 1.166-.296c.42 0 .779.097 1.078.292.3.195.498.458.595.787h.054V8.19c0-.24.075-.435.226-.585a.797.797 0 01.587-.225c.235 0 .426.075.571.225.146.15.22.345.22.585v2.93c0 .21.063.383.19.518a.656.656 0 00.5.202c.23 0 .43-.099.602-.296.171-.197.304-.476.399-.836.095-.36.142-.777.142-1.251v-.203c0-.764-.187-1.45-.56-2.057-.374-.607-.892-1.08-1.554-1.42s-1.41-.51-2.244-.51h-.07c-.925 0-1.737.192-2.435.577s-1.24.93-1.623 1.638c-.384.707-.576 1.525-.576 2.454v.263c0 .92.185 1.721.553 2.406s.891 1.213 1.569 1.585c.678.372 1.472.558 2.382.558h.077c.343 0 .747-.035 1.212-.105.236-.03.381-.045.438-.045.179 0 .327.055.445.165a.55.55 0 01.176.42c0 .295-.166.495-.499.6-.445.145-1.069.217-1.872.217zm-.936-5.689c0 .91.294 1.364.974 1.364.322 0 .566-.106.733-.318.166-.213.275-.529.275-.948v-.63c0-.38-.114-.677-.29-.892-.177-.215-.421-.322-.733-.322-.328 0-.577.116-.748.348s-.211.569-.211 1.008z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20MentionCircleFillBlueProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20MentionCircleFillBlue: FC<Icon20MentionCircleFillBlueProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20MentionCircleFillBlue as any).mountIcon = mountIcon;

export default Icon20MentionCircleFillBlue;
