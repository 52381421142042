import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'logo_facebook_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="logo_facebook_24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" /><path d="M3 5v14a2 2 0 002 2h5.997v-6.008H8.995v-3.001h2.003V9.959c0-2.196 1.469-3.95 4.038-3.95.938 0 2.228.128 2.463.16l-.1 2.795-1.63-.039c-.989.039-1.782.799-1.782 1.542v1.524h3.006l-.331 3.002h-2.675V21H19a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24LogoFacebookProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24LogoFacebook: FC<Icon24LogoFacebookProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24LogoFacebook as any).mountIcon = mountIcon;

export default Icon24LogoFacebook;
