import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'lifebuoy_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="lifebuoy_outline_24"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M5 5a9.872 9.872 0 017-2.9c2.734 0 5.21 1.11 7 2.9a9.872 9.872 0 012.9 7c0 2.733-1.11 5.21-2.9 7a9.872 9.872 0 01-7 2.9A9.872 9.872 0 015 19a9.872 9.872 0 01-2.9-7c0-2.734 1.11-5.21 2.9-7zm7-1.1c-2.237 0-4.26.906-5.728 2.372S3.9 9.763 3.9 12c0 2.237.906 4.26 2.372 5.728S9.763 20.1 12 20.1s4.26-.906 5.728-2.373S20.1 14.237 20.1 12s-.906-4.26-2.372-5.728S14.237 3.9 12 3.9z" /><path d="M8.535 8.535A4.888 4.888 0 0112 7.1c1.353 0 2.58.55 3.465 1.435A4.887 4.887 0 0116.9 12a4.89 4.89 0 01-1.435 3.465A4.887 4.887 0 0112 16.9c-1.353 0-2.58-.55-3.465-1.435S7.1 13.353 7.1 12c0-1.353.55-2.58 1.435-3.465zM12 8.9c-.856 0-1.63.346-2.192.908C9.246 10.37 8.9 11.144 8.9 12s.346 1.63.908 2.192c.562.562 1.336.908 2.192.908s1.63-.346 2.192-.908c.562-.562.908-1.336.908-2.192s-.346-1.63-.908-2.192A3.087 3.087 0 0012 8.9z" /><path d="M15.447 9.826l3.69-3.69-1.273-1.272-3.69 3.69zm-9.31 9.31l3.749-3.749-1.273-1.273-3.75 3.75zm2.416-9.31l-3.69-3.69 1.273-1.272 3.69 3.69zm9.31 9.31l-3.749-3.749 1.273-1.273 3.75 3.75z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24LifebuoyOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24LifebuoyOutline: FC<Icon24LifebuoyOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24LifebuoyOutline as any).mountIcon = mountIcon;

export default Icon24LifebuoyOutline;
