import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'lock_outline_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="lock_outline_16"><path clip-rule="evenodd" d="M8 1.25a3.5 3.5 0 00-3.5 3.5v1.274c-.49.03-.838.097-1.135.248a2.5 2.5 0 00-1.093 1.093C2 7.9 2 8.6 2 10v1c0 1.4 0 2.1.272 2.635a2.5 2.5 0 001.093 1.093C3.9 15 4.6 15 6 15h4c1.4 0 2.1 0 2.635-.273a2.5 2.5 0 001.093-1.092C14 13.1 14 12.4 14 11v-1c0-1.4 0-2.1-.273-2.635a2.5 2.5 0 00-1.092-1.093c-.297-.15-.645-.218-1.135-.248V4.75A3.5 3.5 0 008 1.25zM10 6V4.75a2 2 0 10-4 0V6zM4.48 7.53C4.822 7.5 5.275 7.5 6 7.5h4c.725 0 1.178.001 1.52.03.324.026.413.068.434.079a1 1 0 01.437.437c.01.02.053.11.08.434.028.342.029.795.029 1.52v1c0 .725-.001 1.178-.03 1.52-.026.324-.069.413-.079.434a1 1 0 01-.437.437c-.02.01-.11.053-.434.08-.342.028-.795.029-1.52.029H6c-.725 0-1.178-.001-1.52-.03-.324-.026-.413-.069-.434-.079a1 1 0 01-.437-.437c-.01-.02-.053-.11-.08-.434-.028-.342-.029-.795-.029-1.52v-1c0-.725.001-1.178.03-1.52.026-.324.068-.413.079-.434a1 1 0 01.437-.437c.02-.01.11-.053.434-.08z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16LockOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16LockOutline: FC<Icon16LockOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16LockOutline as any).mountIcon = mountIcon;

export default Icon16LockOutline;
