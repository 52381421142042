import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'flip_album_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="flip_album_outline_56"><path d="M43.372 25c1.852 0 2.801.183 3.79.712a5.136 5.136 0 012.127 2.127c.528.988.711 1.937.711 3.789v12.744c0 1.852-.183 2.801-.712 3.79a5.136 5.136 0 01-2.127 2.127c-.988.528-1.937.711-3.789.711H12.628c-1.852 0-2.801-.183-3.79-.712a5.135 5.135 0 01-2.126-2.127C6.183 47.173 6 46.224 6 44.372V31.628c0-1.852.183-2.801.712-3.79a5.135 5.135 0 012.127-2.127c.988-.528 1.937-.711 3.789-.711h30.744zm0 3H12.628c-1.4 0-1.88.093-2.374.357-.39.209-.688.506-.897.897-.264.493-.357.975-.357 2.374v12.744c0 1.4.093 1.88.357 2.374.209.39.506.688.897.897.494.264.975.357 2.374.357h30.744c1.4 0 1.88-.093 2.374-.357.39-.209.688-.506.897-.897.264-.494.357-.975.357-2.374V31.628c0-1.4-.093-1.88-.357-2.374a2.135 2.135 0 00-.897-.897c-.494-.264-.975-.357-2.374-.357zm-18-23c1.852 0 2.801.183 3.79.712a5.135 5.135 0 012.127 2.127c.528.988.711 1.937.711 3.789V20.5a1.5 1.5 0 01-3 0v-8.872c0-1.4-.093-1.88-.357-2.374a2.135 2.135 0 00-.897-.897C27.253 8.093 26.771 8 25.372 8H11.5A2.5 2.5 0 009 10.5v10a1.5 1.5 0 01-3 0v-10A5.5 5.5 0 0111.5 5h13.872zM39.5 9a5.5 5.5 0 015.5 5.5v2.738l1.024-.877a1.5 1.5 0 012.01.051l.105.112a1.5 1.5 0 01-.163 2.115l-3.5 3a1.5 1.5 0 01-1.952 0l-3.5-3a1.5 1.5 0 011.952-2.278L42 17.24V14.5a2.5 2.5 0 00-2.336-2.495L39.5 12h-3a1.5 1.5 0 010-3h3z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56FlipAlbumOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56FlipAlbumOutline: FC<Icon56FlipAlbumOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56FlipAlbumOutline as any).mountIcon = mountIcon;

export default Icon56FlipAlbumOutline;
