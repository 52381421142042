import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'stars_circle_fill_violet_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="stars_circle_fill_violet_28"><linearGradient id="stars_circle_fill_violet_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#c48aff" /><stop offset="1" stop-color="#aa65f0" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#stars_circle_fill_violet_28_a)" /><g fill="#fff"><path clip-rule="evenodd" d="M14.711 10.568c-.357 1.043-.837 2.098-1.441 2.702-.605.605-1.66 1.084-2.703 1.441a16.25 16.25 0 01-.797.252 20.97 20.97 0 01-.276.079c-.38.105-.38.811 0 .917a19.534 19.534 0 011.073.33c1.044.357 2.098.837 2.703 1.441.605.605 1.084 1.66 1.441 2.703a16.237 16.237 0 01.252.797l.079.276c.105.38.811.38.917 0a21.42 21.42 0 01.33-1.073c.357-1.044.836-2.098 1.441-2.703.604-.605 1.659-1.084 2.703-1.441a18.176 18.176 0 011.073-.33c.38-.106.38-.812 0-.917a21.42 21.42 0 01-1.073-.33c-1.044-.358-2.099-.837-2.703-1.442-.605-.604-1.084-1.659-1.441-2.702a18.191 18.191 0 01-.33-1.074c-.106-.38-.812-.38-.918 0a20.926 20.926 0 01-.33 1.074z" fill-rule="evenodd" /><path d="M9.38 6.138a.4.4 0 00-.76 0l-.304.913a2 2 0 01-1.265 1.265l-.913.305a.4.4 0 000 .759l.913.304a2 2 0 011.265 1.265l.304.913a.4.4 0 00.76 0l.304-.913a2 2 0 011.265-1.265l.912-.304a.4.4 0 000-.76l-.912-.304a2 2 0 01-1.265-1.265z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28StarsCircleFillVioletProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28StarsCircleFillViolet: FC<Icon28StarsCircleFillVioletProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28StarsCircleFillViolet as any).mountIcon = mountIcon;

export default Icon28StarsCircleFillViolet;
