import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'volume_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="volume_outline_28"><g fill="none" fill-rule="evenodd"><path d="M0 0h28v28H0z" /><path d="M16 4a2 2 0 012 2v16a2 2 0 01-2 2c-.906 0-1.774-.36-2.414-1l-5-5H6.458A2.458 2.458 0 014 15.542v-3.084C4 11.101 5.1 10 6.458 10h2.128l5-5c.64-.64 1.508-1 2.414-1zm0 2c-.375 0-.735.149-1 .414l-5.293 5.293A1 1 0 019 12H6.458a.458.458 0 00-.458.458v3.084c0 .253.205.458.458.458H9a1 1 0 01.707.293L15 21.586c.265.265.625.414 1 .414zm7.864 1.436a1 1 0 011.415 0 9.283 9.283 0 010 13.128 1 1 0 01-1.415-1.414 7.283 7.283 0 000-10.3 1 1 0 010-1.414zm-2.97 2.97a1 1 0 011.415 0 5.083 5.083 0 010 7.188 1 1 0 11-1.414-1.414 3.083 3.083 0 000-4.36 1 1 0 010-1.414z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28VolumeOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28VolumeOutline: FC<Icon28VolumeOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28VolumeOutline as any).mountIcon = mountIcon;

export default Icon28VolumeOutline;
