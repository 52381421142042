import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'repost_circle_fill_green_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="repost_circle_fill_green_20"><linearGradient id="repost_circle_fill_green_20_a" gradientUnits="userSpaceOnUse" x1="-10" x2="10" y1="10" y2="30"><stop offset="0" stop-color="#50c750" /><stop offset="1" stop-color="#32b332" /></linearGradient><path d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z" fill="url(#repost_circle_fill_green_20_a)" /><path d="M12.52 4.247a.52.52 0 01.304.098l2.879 2.08a.71.71 0 010 1.15l-2.878 2.08A.52.52 0 0112 9.233V8H8.09c-.38 0-.517.04-.655.113s-.247.183-.321.322c-.065.121-.104.241-.112.523L7 9.09v2.82c0 .38.04.517.114.655s.182.247.32.321c.122.065.242.104.524.112L8.09 13H12a1 1 0 01.017 2H13 8.525c-1.226 0-1.67-.128-2.118-.367-.448-.24-.8-.592-1.04-1.04C5.127 13.145 5 12.7 5 11.475v-1.95c0-1.226.128-1.67.367-2.118s.592-.8 1.04-1.04C6.855 6.127 7.299 6 8.525 6H12V4.767a.52.52 0 01.52-.52z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20RepostCircleFillGreenProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20RepostCircleFillGreen: FC<Icon20RepostCircleFillGreenProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20RepostCircleFillGreen as any).mountIcon = mountIcon;

export default Icon20RepostCircleFillGreen;
