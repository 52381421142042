import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'cancel_circle_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="cancel_circle_outline_56"><path clip-rule="evenodd" d="M28 49c11.598 0 21-9.402 21-21S39.598 7 28 7 7 16.402 7 28s9.402 21 21 21zm0 3c13.255 0 24-10.745 24-24S41.255 4 28 4 4 14.745 4 28s10.745 24 24 24zm-9.9-14.1a1.5 1.5 0 010-2.122L25.88 28 18.1 20.222a1.5 1.5 0 112.122-2.121L28 25.879l7.778-7.778a1.5 1.5 0 112.121 2.12L30.121 28l7.778 7.778a1.5 1.5 0 11-2.12 2.122l-7.78-7.779-7.777 7.779a1.5 1.5 0 01-2.122 0z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56CancelCircleOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56CancelCircleOutline: FC<Icon56CancelCircleOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56CancelCircleOutline as any).mountIcon = mountIcon;

export default Icon56CancelCircleOutline;
