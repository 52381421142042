import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'airplay_video_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="airplay_video_outline_28"><g fill="none" fill-rule="evenodd"><path d="M0 0h28v28H0z" /><path d="M12.5 15.375a2.5 2.5 0 013 0L21 19.5a2.5 2.5 0 01-1.5 4.5h-11A2.5 2.5 0 017 19.5zm1.8 1.6a.5.5 0 00-.6 0L8.2 21.1a.5.5 0 00.3.9h11a.5.5 0 00.3-.9zM20 4a5 5 0 014.995 4.783L25 9v6c0 1.235-.45 2.403-1.252 3.31a1 1 0 01-1.499-1.325c.433-.49.695-1.104.743-1.764L23 15V9a3 3 0 00-2.824-2.995L20 6H8a3 3 0 00-2.995 2.824L5 9v6c0 .742.269 1.44.75 1.984a1 1 0 01-1.5 1.324 4.982 4.982 0 01-1.243-3.045L3 15V9a5 5 0 014.783-4.995L8 4h12z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28AirplayVideoOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28AirplayVideoOutline: FC<Icon28AirplayVideoOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28AirplayVideoOutline as any).mountIcon = mountIcon;

export default Icon28AirplayVideoOutline;
