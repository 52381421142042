import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 48 48';
const id = 'podcast_48';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" id="podcast_48"><path d="M24 10c1.84 0 3.566.497 5.047 1.365s2.72 2.106 3.588 3.588S34 18.159 34 20v12c0 1.84-.497 3.566-1.365 5.047s-2.106 2.72-3.588 3.588a9.934 9.934 0 01-3.046 1.165L26 44.2a1.8 1.8 0 01-1.8 1.8h-.4a1.8 1.8 0 01-1.8-1.8l-.003-2.4a9.936 9.936 0 01-3.044-1.165c-1.482-.868-2.72-2.106-3.588-3.588S14 33.841 14 32V20c0-1.84.498-3.566 1.365-5.047s2.106-2.72 3.588-3.588S22.159 10 24 10zm0 3.5a6.453 6.453 0 00-3.278.885 6.548 6.548 0 00-2.337 2.337A6.453 6.453 0 0017.5 20h3.512c.419 0 .774.084 1.067.24.293.157.524.388.68.68.157.294.241.65.241 1.068v.024c0 .419-.084.774-.24 1.067a1.636 1.636 0 01-.68.68c-.294.157-.65.241-1.068.241H17.5v4h3.512c.419 0 .774.084 1.067.24.293.157.524.388.68.68.157.294.241.65.241 1.068v.024c0 .419-.084.774-.24 1.067a1.636 1.636 0 01-.68.68c-.294.157-.65.241-1.068.241H17.5l.014.437a6.437 6.437 0 00.871 2.841 6.548 6.548 0 002.337 2.337c.985.577 2.105.885 3.278.885s2.293-.308 3.278-.885a6.548 6.548 0 002.337-2.337 6.454 6.454 0 00.885-3.276L29.488 32c-.419 0-.774-.084-1.067-.24a1.636 1.636 0 01-.68-.68c-.158-.294-.241-.65-.241-1.068v-.024c0-.419.083-.774.24-1.067.157-.294.387-.524.68-.68.294-.158.65-.241 1.068-.241H30.5v-4.002L29.488 24c-.419 0-.774-.084-1.067-.24a1.636 1.636 0 01-.68-.68c-.158-.294-.241-.65-.241-1.068v-.024c0-.419.083-.774.24-1.067.157-.294.387-.524.68-.68.294-.158.65-.241 1.068-.241l1.012-.002-.014-.435a6.437 6.437 0 00-.871-2.841 6.548 6.548 0 00-2.337-2.337A6.453 6.453 0 0024 13.5zM24 1c10.057 0 18.29 7.814 18.956 17.703.539.406.962.968 1.196 1.635C44.717 21.943 45 23.83 45 26s-.282 4.057-.848 5.662a3.5 3.5 0 01-3.3 2.338H40a3 3 0 01-3-3V21a3 3 0 012.381-2.936C38.43 10.419 31.906 4.5 24 4.5S9.57 10.42 8.62 18.068A2.994 2.994 0 0111 21v10a3 3 0 01-3 3h-.851a3.5 3.5 0 01-3.301-2.338C3.283 30.057 3 28.17 3 26s.283-4.057.848-5.662a3.495 3.495 0 011.196-1.634C5.71 8.814 13.943 1 24 1z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon48PodcastProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon48Podcast: FC<Icon48PodcastProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 48,
    height: !isNaN(props.height) ? +props.height : 48,
  }));
};

(Icon48Podcast as any).mountIcon = mountIcon;

export default Icon48Podcast;
