import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'lock_16';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="lock_16"><g fill="none" fill-rule="evenodd"><path d="M0 0h16v16H0z" /><path d="M4 7V5.002A4.004 4.004 0 018 1c2.21 0 4 1.782 4 4.001V7h.998C13.55 7 14 7.453 14 7.997v6.006c0 .55-.456.997-1.002.997H3.002A1.004 1.004 0 012 14.003V7.997C2 7.447 2.456 7 3.002 7H4zm2-2v2h4V5c0-1.112-.895-2-2-2-1.112 0-2 .895-2 2z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16LockProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16Lock: FC<Icon16LockProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16Lock as any).mountIcon = mountIcon;

export default Icon16Lock;
