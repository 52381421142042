import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'ball_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="ball_outline_24"><path fill-rule="evenodd" clip-rule="evenodd" d="M5 5a9.872 9.872 0 017-2.9c2.734 0 5.21 1.11 7 2.9a9.872 9.872 0 012.9 7c0 2.733-1.11 5.21-2.9 7a9.872 9.872 0 01-7 2.9A9.872 9.872 0 015 19a9.872 9.872 0 01-2.9-7c0-2.734 1.11-5.21 2.9-7zm7-1.1c-2.237 0-4.26.906-5.728 2.372A8.072 8.072 0 003.9 12c0 2.237.906 4.26 2.372 5.727A8.072 8.072 0 0012 20.1c2.237 0 4.26-.906 5.728-2.373A8.072 8.072 0 0020.1 12c0-2.237-.906-4.26-2.372-5.728A8.072 8.072 0 0012 3.9z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M15.383 7.344A7.069 7.069 0 0113.9 3h-1.8c0 2.133.75 4.092 2.002 5.625L12.5 10.227 6.636 4.364 5.363 5.636l5.864 5.864-1.602 1.602A8.863 8.863 0 004 11.1c-.57 0-1.127.053-1.668.156l.336 1.769c.43-.082.876-.125 1.332-.125a7.07 7.07 0 014.344 1.483l-3.48 3.48 1.272 1.273 3.48-3.48A7.07 7.07 0 0111.1 20c0 .456-.043.901-.125 1.332l1.769.336A9 9 0 0012.9 20a8.861 8.861 0 00-2.003-5.625l1.603-1.602 5.863 5.863 1.273-1.272-5.863-5.864 1.602-1.602A8.863 8.863 0 0021 11.9v-1.8a7.07 7.07 0 01-4.344-1.483l2.48-2.48-1.273-1.273-2.48 2.48z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24BallOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24BallOutline: FC<Icon24BallOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24BallOutline as any).mountIcon = mountIcon;

export default Icon24BallOutline;
