import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'arrow_right_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="arrow_right_outline_28"><g fill="none" fill-rule="evenodd"><path d="M28 0H0v28h28z" /><path d="M22.707 13.293l-7-7a1 1 0 00-1.497 1.32l.083.094L19.586 13H6a1 1 0 00-.993.883L5 14a1 1 0 00.883.993L6 15h13.586l-5.293 5.293a1 1 0 00-.083 1.32l.083.094a1 1 0 001.32.083l.094-.083 7-7 .097-.112.071-.11.054-.114.035-.105.025-.118.007-.059.004-.108-.004-.07-.016-.112-.03-.111-.044-.111-.052-.098-.067-.096a1.006 1.006 0 00-.08-.09l-7-7 7 7z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28ArrowRightOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28ArrowRightOutline: FC<Icon28ArrowRightOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28ArrowRightOutline as any).mountIcon = mountIcon;

export default Icon28ArrowRightOutline;
