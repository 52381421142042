import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'stars_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="stars_16"><g clip-path="url(#stars_16_clip0)" fill="currentColor"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.887 4.724C8.53 5.773 8.047 6.832 7.44 7.44c-.608.607-1.667 1.089-2.716 1.447a16.937 16.937 0 01-.801.253l-.277.08c-.381.105-.381.815 0 .92a21.173 21.173 0 011.078.332c1.049.36 2.108.84 2.716 1.448.607.608 1.089 1.667 1.447 2.715a16.942 16.942 0 01.253.802l.08.277c.105.381.815.381.92 0a22.857 22.857 0 01.266-.88 16.4 16.4 0 01.067-.199c.358-1.048.84-2.107 1.447-2.715.608-.607 1.667-1.089 2.716-1.447a16.386 16.386 0 01.8-.254l.278-.079c.381-.105.381-.815 0-.92a21.17 21.17 0 01-1.079-.333c-1.048-.358-2.107-.84-2.715-1.447-.607-.608-1.089-1.667-1.447-2.716a16.394 16.394 0 01-.254-.801 22.853 22.853 0 01-.079-.277c-.105-.381-.815-.381-.92 0a21.167 21.167 0 01-.333 1.078z" /><path d="M3.53.275a.402.402 0 00-.762 0l-.305.917c-.2.6-.671 1.07-1.271 1.27l-.917.306a.402.402 0 000 .763l.917.306c.6.2 1.07.67 1.27 1.27l.306.918c.123.366.64.366.763 0l.306-.918c.2-.6.67-1.07 1.27-1.27l.918-.306a.402.402 0 000-.763l-.918-.305c-.6-.2-1.07-.671-1.27-1.271L3.53.275z" /></g><defs><clipPath id="stars_16_clip0"><path fill="#fff" d="M0 0h16v16H0z" /></clipPath></defs></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16StarsProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16Stars: FC<Icon16StarsProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16Stars as any).mountIcon = mountIcon;

export default Icon16Stars;
