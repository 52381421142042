import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'memory_card_32';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="memory_card_32"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.601 3.247c.345-.215.695-.36 1.091-.452.396-.09.754-.128 1.786-.128h7.06c1.784 0 2.43.185 3.082.534.652.349 1.164.86 1.512 1.512.349.652.535 1.298.535 3.081v15.078c0 1.783-.186 2.43-.535 3.082a3.635 3.635 0 01-1.512 1.512c-.652.348-1.298.534-3.081.534H9.128c-1.783 0-2.43-.186-3.082-.534a3.635 3.635 0 01-1.512-1.512C4.186 25.302 4 24.655 4 22.872v-9.727c0-1.033.037-1.39.129-1.786a3.43 3.43 0 01.452-1.09c.215-.345.441-.625 1.172-1.355l4.495-4.495c.73-.73 1.009-.956 1.353-1.172zm1.732 3.42C12.597 6.667 12 7.264 12 8v2.667a1.333 1.333 0 002.667 0V8c0-.736-.597-1.333-1.334-1.333zM16 8a1.333 1.333 0 012.667 0v2.667a1.333 1.333 0 01-2.667 0V8zm5.333-1.333C20.597 6.667 20 7.264 20 8v2.667a1.333 1.333 0 002.667 0V8c0-.736-.597-1.333-1.334-1.333z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32MemoryCardProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32MemoryCard: FC<Icon32MemoryCardProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32MemoryCard as any).mountIcon = mountIcon;

export default Icon32MemoryCard;
