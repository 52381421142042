import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'backspace_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="backspace_outline_56"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.746 12.598c1.536-1.629 3.64-2.519 5.937-2.593L42.998 10c4.957 0 9 4.043 9 9v18c0 4.957-4.043 9-9 9h-22c-2.525 0-4.84-.974-6.458-2.825l-8.864-8.854c-3.502-3.503-3.57-9.004-.185-12.6l9.255-9.123zM48.998 19c0-3.3-2.7-6-6-6h-22c-1.65 0-3.15.6-4.2 1.8l-9 8.85c-2.4 2.4-2.4 6.15 0 8.55l9 9c1.05 1.2 2.55 1.8 4.2 1.8h22c3.3 0 6-2.7 6-6V19zm-26.559.44a1.5 1.5 0 012.008-.104l.114.103L31 25.88l6.44-6.44a1.5 1.5 0 012.224 2.008l-.103.114L33.12 28l6.44 6.44a1.5 1.5 0 01-2.008 2.224l-.114-.103L31 30.12l-6.44 6.44a1.5 1.5 0 01-2.224-2.008l.103-.114L28.88 28l-6.44-6.44a1.5 1.5 0 010-2.12z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56BackspaceOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56BackspaceOutline: FC<Icon56BackspaceOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56BackspaceOutline as any).mountIcon = mountIcon;

export default Icon56BackspaceOutline;
