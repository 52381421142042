import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'money_history_backward_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="money_history_backward_outline_28"><g fill="none" fill-rule="evenodd"><path d="M0 0h28v28H0z" /><path d="M14 2c6.627 0 12 5.373 12 12s-5.373 12-12 12c-3.226 0-6.251-1.28-8.487-3.516a1 1 0 011.415-1.414A9.964 9.964 0 0014 24c5.523 0 10-4.477 10-10S19.523 4 14 4a9.974 9.974 0 00-7.143 3H8.5a1 1 0 01.117 1.993L8.5 9h-4a1 1 0 01-.993-.883L3.5 8V4a1 1 0 011.993-.117L5.5 4v1.529A11.973 11.973 0 0114 2zm1.177 6.67c2.481 0 3.884 1.386 3.884 3.56 0 2.084-1.295 3.52-3.91 3.52h-1.593v1.195h1.793c.523 0 .847.283.847.74 0 .456-.333.73-.847.73h-1.835v.78c-.116.58-.547.888-1.079.888-.523 0-.963-.307-1.079-.888v-.78h-.78c-.531 0-.847-.274-.847-.73 0-.457.316-.74.847-.74h.739V15.75h-.615c-.606-.008-.97-.332-.97-.872 0-.53.364-.863.97-.88h.615v-3.602c0-1.187.548-1.727 1.71-1.727zm-.382 1.784H13.55V14h1.054c1.477 0 2.175-.474 2.175-1.76 0-1.279-.706-1.785-1.984-1.785z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28MoneyHistoryBackwardOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28MoneyHistoryBackwardOutline: FC<Icon28MoneyHistoryBackwardOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28MoneyHistoryBackwardOutline as any).mountIcon = mountIcon;

export default Icon28MoneyHistoryBackwardOutline;
