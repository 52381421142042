import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'magic_wand_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="magic_wand_outline_24"><g fill="currentColor"><path d="M5.374 8.397a.2.2 0 01-.27-.27l.991-1.877-.992-1.876a.2.2 0 01.27-.27l1.877.991 1.876-.992a.2.2 0 01.27.27L8.406 6.25l.992 1.876a.2.2 0 01-.27.27L7.25 7.406zM17.25 6.905l-1.876.992a.2.2 0 01-.27-.27l.991-1.877-.992-1.876a.2.2 0 01.27-.27l1.877.991 1.876-.992a.2.2 0 01.27.27l-.991 1.877.992 1.876a.2.2 0 01-.27.27zM15.374 17.397a.2.2 0 01-.27-.27l.991-1.877-.992-1.876a.2.2 0 01.27-.27l1.877.991 1.876-.992a.2.2 0 01.27.27l-.991 1.877.992 1.876a.2.2 0 01-.27.27l-1.877-.991z" /><path clip-rule="evenodd" d="M13.136 10.364a.9.9 0 010 1.272l-9.5 9.5a.9.9 0 11-1.272-1.272l9.5-9.5a.9.9 0 011.272 0z" fill-rule="evenodd" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24MagicWandOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24MagicWandOutline: FC<Icon24MagicWandOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24MagicWandOutline as any).mountIcon = mountIcon;

export default Icon24MagicWandOutline;
