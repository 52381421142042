import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'live_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="live_outline_24"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M5 5a9.872 9.872 0 017-2.9c2.734 0 5.21 1.11 7 2.9a9.872 9.872 0 012.9 7c0 2.734-1.11 5.21-2.9 7a9.872 9.872 0 01-7 2.9A9.872 9.872 0 015 19a9.872 9.872 0 01-2.9-7c0-2.734 1.11-5.21 2.9-7zm7-1.1c-2.237 0-4.26.906-5.728 2.372S3.9 9.763 3.9 12s.906 4.26 2.372 5.728S9.763 20.1 12 20.1s4.26-.906 5.728-2.372S20.1 14.237 20.1 12s-.906-4.26-2.372-5.728S14.237 3.9 12 3.9z" /><path d="M7.828 7.828C8.895 6.761 10.371 6.1 12 6.1s3.105.661 4.172 1.728C17.239 8.895 17.9 10.371 17.9 12s-.661 3.105-1.728 4.172S13.629 17.9 12 17.9s-3.105-.661-4.172-1.728C6.761 15.105 6.1 13.629 6.1 12s.661-3.105 1.728-4.172zM12 7.9c-1.132 0-2.156.458-2.9 1.2S7.9 10.869 7.9 12a4.094 4.094 0 004.1 4.1 4.092 4.092 0 002.9-1.2c.742-.744 1.2-1.768 1.2-2.9s-.458-2.156-1.2-2.9A4.084 4.084 0 0012 7.9z" /><path d="M13.344 10.656a1.897 1.897 0 00-2.688 0 1.897 1.897 0 000 2.688 1.897 1.897 0 002.688 0 1.897 1.897 0 000-2.688z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24LiveOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24LiveOutline: FC<Icon24LiveOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24LiveOutline as any).mountIcon = mountIcon;

export default Icon24LiveOutline;
