import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'pause_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="pause_24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" /><path d="M6.788 5h1.924c.448 0 .61.047.774.134a.913.913 0 01.38.38c.087.164.134.326.134.774v11.424c0 .448-.047.61-.134.774a.913.913 0 01-.38.38c-.164.087-.326.134-.774.134H6.788c-.448 0-.61-.047-.774-.134a.913.913 0 01-.38-.38c-.087-.164-.134-.326-.134-.774V6.288c0-.448.047-.61.134-.774a.913.913 0 01.38-.38C6.178 5.047 6.34 5 6.788 5zm8 0h1.924c.448 0 .61.047.774.134a.913.913 0 01.38.38c.087.164.134.326.134.774v11.424c0 .448-.047.61-.134.774a.913.913 0 01-.38.38c-.164.087-.326.134-.774.134h-1.924c-.448 0-.61-.047-.774-.134a.913.913 0 01-.38-.38c-.087-.164-.134-.326-.134-.774V6.288c0-.448.047-.61.134-.774a.913.913 0 01.38-.38c.164-.087.326-.134.774-.134z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24PauseProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Pause: FC<Icon24PauseProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Pause as any).mountIcon = mountIcon;

export default Icon24Pause;
