import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'reply_outline_16';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="reply_outline_16"><g fill="none" fill-rule="evenodd"><path d="M0 0h16v16H0z" /><path d="M7.498 12.825a.95.95 0 01-1.602.69L.787 8.692.75 8.652A.95.95 0 01.787 7.31l5.109-4.825a.95.95 0 011.602.691V5.1c4.218.82 6.652 3.573 7.753 7.782l.128.558a.85.85 0 01-1.498.714l-.361-.463c-1.511-1.754-3.333-2.603-6.022-2.732v1.867zm-.856-6.35l-.644-.091V4.451L2.241 8l3.757 3.549V9.44h.75c2.65 0 4.683.57 6.366 1.825-1.193-2.685-3.242-4.33-6.472-4.79z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16ReplyOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16ReplyOutline: FC<Icon16ReplyOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16ReplyOutline as any).mountIcon = mountIcon;

export default Icon16ReplyOutline;
