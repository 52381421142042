import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'mortar_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="mortar_outline_28"><path clip-rule="evenodd" d="M8.405 2.972l.012-.005.011-.006a3 3 0 013.97 1.432l1.501 3.17.219.437h6.757c.42 0 .78 0 1.077.018.31.02.622.062.93.18a3 3 0 011.815 1.987c.09.318.104.633.095.944-.008.297-.041.656-.079 1.073l-.003.03-.019.21c-.186 2.054-.302 3.336-.769 4.39A7 7 0 0121 20.074v.726c0 .545 0 1.015-.033 1.402-.034.408-.11.814-.316 1.202a3 3 0 01-1.247 1.247c-.388.206-.794.282-1.202.316-.387.033-.857.033-1.402.033h-5.6c-.545 0-1.015 0-1.402-.033-.408-.034-.814-.11-1.202-.316a3 3 0 01-1.247-1.247c-.206-.388-.282-.794-.316-1.202A17.922 17.922 0 017 20.8V20.074a7 7 0 01-2.922-3.243c-.467-1.053-.583-2.335-.77-4.39l-.018-.21-.003-.029c-.038-.417-.07-.776-.08-1.073-.008-.311.006-.626.096-.944a3 3 0 011.814-1.987c.309-.118.621-.16.931-.18C6.345 8 6.705 8 7.125 8h.322l-.49-1.05a3 3 0 011.448-3.978zM11.894 8H9.652l-.03-.067-.851-1.824a1 1 0 01.482-1.326l.015-.007a1 1 0 011.324.477zm-1.93 2h-2.81c-.457 0-.754 0-.981.015-.22.013-.304.037-.34.051a1 1 0 00-.605.662c-.011.038-.027.123-.02.344.006.228.032.523.073.979.213 2.334.31 3.258.626 3.972a5 5 0 002.972 2.714c.74.25 1.668.263 4.012.263h2.218c2.344 0 3.272-.013 4.012-.263a5 5 0 002.972-2.714c.316-.714.413-1.638.625-3.972.042-.456.068-.751.075-.98.006-.22-.01-.305-.02-.343a1 1 0 00-.606-.662c-.036-.014-.12-.038-.34-.051a17.77 17.77 0 00-.981-.015h-5.728zm2.319 11H9c0 .463.004.782.026 1.033.023.28.063.384.09.435a1 1 0 00.416.416c.051.027.154.067.435.09.292.025.677.026 1.273.026h5.52c.596 0 .98 0 1.273-.025.28-.024.384-.064.435-.091a1 1 0 00.416-.416c.027-.051.067-.154.09-.435.022-.25.025-.57.026-1.033h-6.717z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28MortarOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28MortarOutline: FC<Icon28MortarOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28MortarOutline as any).mountIcon = mountIcon;

export default Icon28MortarOutline;
