import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'ghost_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="ghost_outline_56"><path d="M33.566 34.518c.613.653.571 1.67-.093 2.272-3.01 2.727-6.35 2.727-9.336 1.104-.732-.398-1.278-.803-1.61-1.104a1.588 1.588 0 01-.094-2.272 1.657 1.657 0 012.184-.197l.129.105c.153.139.496.393.976.654 1.862 1.013 3.693 1.013 5.532-.654.665-.602 1.7-.561 2.312.092zM19.5 22a3.5 3.5 0 110 7 3.5 3.5 0 010-7zm8.498-17.996c5.831 0 10.753 2.054 15.258 6.262 4.205 3.927 6.618 9.228 6.74 14.786l-.02 20.818c-.069 2.545-1.887 4.564-4.487 4.564-.596-.02-1.3-.12-2.252-.504l-1.921-.812c-1.354-.568-2.219-.808-3.074-.808l-.15.005c-.421.034-.831.237-1.968 1.028l-1.75 1.248C32.156 52.157 30.026 52.931 28 53c-2.04 0-3.832-.69-6.003-2.178l-1.936-1.373-.548-.376c-.764-.51-1.14-.693-1.48-.745l-.256-.018c-.858 0-1.725.24-3.082.808l-1.924.812c-.804.323-1.224.443-1.693.484l-.561.02c-2.506 0-4.314-1.964-4.495-4.43L6 25.49c0-5.717 2.433-11.195 6.756-15.225 4.398-4.1 9.624-6.153 15.242-6.26zm.018 3l-.425.014c-4.784.197-9.116 2.017-12.789 5.441-3.615 3.37-5.682 7.89-5.797 12.61L9 25.488l.021 20.37.011.104c.122.86.65 1.402 1.344 1.466l.118.005.371-.013.032-.005c.09-.018.216-.057.444-.144l.264-.104 1.933-.816c1.61-.673 2.763-.996 3.977-1.036l.262-.004.212.007.255.018c.082.006.163.015.243.028.979.15 1.674.51 3.045 1.455l.265.184 1.896 1.346c1.659 1.136 2.838 1.6 4.086 1.648l.161.003.228-.013c1.274-.103 2.655-.624 4.144-1.617l.32-.22 1.779-1.268c1.698-1.182 2.514-1.571 3.831-1.571 1.225 0 2.35.278 3.903.905l.34.14 1.872.792c.513.206.854.276 1.132.287.818 0 1.39-.548 1.477-1.403l.01-.163.02-20.75c-.103-4.745-2.17-9.281-5.788-12.66-3.868-3.613-7.94-5.35-12.77-5.449l-.422-.005zM39.482 24.85c.604.522.693 1.464.199 2.102a1.36 1.36 0 01-1.87.301l-.117-.09-.159-.13c-.9-.695-1.803-.668-2.734-.184a3.823 3.823 0 00-.28.16l-.133.09-.199.154a1.36 1.36 0 01-1.87-.3c-.494-.64-.405-1.58.199-2.103.222-.192.573-.44 1.038-.683 1.89-.981 4.004-.981 5.926.683z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56GhostOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56GhostOutline: FC<Icon56GhostOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56GhostOutline as any).mountIcon = mountIcon;

export default Icon56GhostOutline;
