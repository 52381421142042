import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'phone_down_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="phone_down_outline_56"><path clip-rule="evenodd" d="M6.014 32.004a5.067 5.067 0 011.21-3.662c4.845-6.157 13.401-7.35 20.776-7.35 7.344.001 15.913 1.17 20.74 7.306.883 1.03 1.35 2.355 1.245 3.715-.559 6.696-12.646 4.183-12.646-2.233l.001-2.076c0-1.126-.675-2.146-1.726-2.618-2.332-1.05-5.089-1.125-7.614-1.125-2.527 0-5.282.077-7.617 1.123-1.053.474-1.726 1.495-1.725 2.619v2.076c0 6.424-12.09 8.93-12.644 2.225zm15.644-4.208v1.982c0 6.875-8.907 11.233-14.781 8.287C4.754 37 3.25 34.992 3.024 32.25c-.172-2.078.536-4.207 1.874-5.8C10.346 19.57 19.704 18 28 18c8.264 0 17.632 1.545 23.063 8.402 1.339 1.589 2.08 3.664 1.914 5.84-.19 2.46-1.643 4.719-3.86 5.827-5.873 2.936-14.778-1.417-14.778-8.288l.001-1.982c-1.938-.85-4.25-.837-6.34-.837-2.091 0-4.4-.01-6.342.834z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56PhoneDownOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56PhoneDownOutline: FC<Icon56PhoneDownOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56PhoneDownOutline as any).mountIcon = mountIcon;

export default Icon56PhoneDownOutline;
