import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'message_unread_top_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="message_unread_top_28"><g fill="none"><path d="M0 0h28v28H0z" /><path d="M14.023 3c.926 0 1.828.09 2.695.26.548.108.766.755.561 1.274-.17.435-.623.747-1.083.662A12.012 12.012 0 0014.023 5c-5.545 0-9.978 3.727-9.978 8.23 0 1.548.521 3.033 1.493 4.315a1 1 0 01.203.604c0 .988-.393 2.384-1.173 4.25l-.151.354-.083.187.408-.046c1.698-.22 2.965-.74 3.825-1.542l.167-.164.14-.153a1 1 0 011.1-.283c1.263.464 2.634.707 4.049.707 5.545 0 9.978-3.726 9.978-8.23 0-1.05-.241-2.057-.681-2.985-.195-.411-.057-.914.288-1.21.435-.375 1.152-.385 1.412.128.63 1.243.98 2.618.98 4.068 0 5.692-5.397 10.23-11.977 10.23-1.181 0-2.34-.147-3.447-.43l-.471-.13-.202-.063-.08.076c-1.32 1.177-3.184 1.849-5.564 2.042l-.401.028-.371.017a1.433 1.433 0 01-1.474-1.628l.025-.132.028-.097.065-.173.156-.343c.776-1.717 1.239-3.034 1.395-3.902l.027-.168.013-.109-.188-.278a9.067 9.067 0 01-1.46-4.24l-.021-.35-.007-.35C2.046 7.538 7.443 3 14.023 3zM21 4a2 2 0 110 4 2 2 0 010-4z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28MessageUnreadTopProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28MessageUnreadTop: FC<Icon28MessageUnreadTopProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28MessageUnreadTop as any).mountIcon = mountIcon;

export default Icon28MessageUnreadTop;
