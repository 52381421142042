import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'music_outline_20';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="music_outline_20"><g fill="none" fill-rule="evenodd"><path d="M0 0h20v20H0z" /><path d="M14.733 2.048a2.28 2.28 0 012.746 2.233v7.985c0 3.575-3.491 5.413-5.387 3.517-1.895-1.895-.058-5.387 3.517-5.387h.37V6.765L8.004 8.434v5.815c0 3.505-3.356 5.34-5.274 3.624l-.113-.107c-1.895-1.896-.058-5.387 3.517-5.387l.37-.001V6.231c0-.64.047-1.004.183-1.357l.055-.133c.169-.377.425-.692.76-.934.354-.256.705-.39 1.409-.537zm-8.23 11.83h-.37c-2.319 0-3.34 1.942-2.455 2.827.885.885 2.826-.137 2.826-2.456v-.37zm9.476-1.982h-.37c-2.32 0-3.341 1.942-2.456 2.827.885.885 2.826-.137 2.826-2.457v-.37zm-.017-7.774a.78.78 0 00-.922-.605L9.058 4.772c-.392.088-.536.149-.677.25a.802.802 0 00-.27.332c-.08.178-.107.35-.107.877v.67l7.975-1.668v-.952a.78.78 0 00-.004-.08z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20MusicOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20MusicOutline: FC<Icon20MusicOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20MusicOutline as any).mountIcon = mountIcon;

export default Icon20MusicOutline;
