import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'play_speed_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="play_speed_outline_28"><g fill="none" fill-rule="evenodd"><path d="M0 0h28v28H0z" /><path d="M16.179 2.197a12.003 12.003 0 01-.001 23.606 1 1 0 01-.361-1.967 10.003 10.003 0 000-19.671 1 1 0 11.362-1.968zM6.375 20.47a10.005 10.005 0 005.802 3.364 1 1 0 01-.362 1.967 12.005 12.005 0 01-6.964-4.036 1 1 0 111.524-1.295zM3.992 9.32a1 1 0 01.6 1.28A9.98 9.98 0 004 14c0 1.127.186 2.228.547 3.27a1 1 0 01-1.89.654A11.984 11.984 0 012 14c0-1.407.243-2.783.712-4.08a1 1 0 011.28-.6zm8.208.759a.8.8 0 01.397.105l5.462 3.121a.8.8 0 010 1.39l-5.462 3.121a.8.8 0 01-1.197-.695V10.88a.8.8 0 01.8-.8zM12.981 3a1 1 0 01-.802 1.164 9.987 9.987 0 00-5.747 3.298 1 1 0 11-1.513-1.308 11.987 11.987 0 016.898-3.957 1 1 0 011.164.803z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28PlaySpeedOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28PlaySpeedOutline: FC<Icon28PlaySpeedOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28PlaySpeedOutline as any).mountIcon = mountIcon;

export default Icon28PlaySpeedOutline;
