import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'education_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="education_24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" /><path d="M21.996 8.86H22V15a1 1 0 01-2 0v-4.42l-7.504 4.283a1 1 0 01-.992 0L2.506 9.728a1 1 0 010-1.737l8.998-5.134a1 1 0 01.992 0l8.995 5.134a1 1 0 01.505.869zM18.5 13.738V16c0 2.761-2.91 5-6.5 5s-6.5-2.239-6.5-5v-2.26l5.013 2.86a3 3 0 002.974 0l5.013-2.862z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24EducationProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Education: FC<Icon24EducationProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Education as any).mountIcon = mountIcon;

export default Icon24Education;
