import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'gift_outline_32';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="gift_outline_32"><path clip-rule="evenodd" d="M10.634 4.954c.468-.468 1.307-.615 2.225.097.77.599 1.576 1.846 1.818 3.946-2.1-.241-3.348-1.048-3.946-1.818-.712-.918-.565-1.757-.097-2.225zm5.36.001a6.381 6.381 0 00-1.603-1.879c-1.707-1.325-3.993-1.422-5.525.11-1.532 1.532-1.435 3.819-.11 5.526.077.1.159.197.244.292h-.163c-.777 0-1.166.037-1.493.08-2.24.22-4.004 1.996-4.3 4.237a10.694 10.694 0 00-.01 2.687A4 4 0 005 18.948v1.247c0 3.08 0 4.62.6 5.797a5.5 5.5 0 002.403 2.403c1.177.6 2.717.6 5.797.6h4.4c3.08 0 4.62 0 5.797-.6a5.5 5.5 0 002.404-2.403C27 24.815 27 23.275 27 20.195v-1.247a4 4 0 001.965-2.94c.114-.855.105-1.834-.008-2.687-.297-2.241-2.055-4.014-4.301-4.239C24.329 9.039 23.938 9 23.16 9h-.166c.083-.094.163-.19.24-.288 1.324-1.707 1.422-3.994-.11-5.526-1.532-1.532-3.819-1.435-5.526-.11a6.381 6.381 0 00-1.603 1.88zm5.362-.001c-.468-.468-1.307-.615-2.225.097-.77.599-1.577 1.846-1.819 3.946 2.101-.241 3.348-1.048 3.947-1.818.712-.918.565-1.757.097-2.225zM8.837 11.477h5.913v5.507H7.67c-.702 0-.81-.006-.867-.013a1.5 1.5 0 01-1.29-1.29 8.171 8.171 0 01.009-2.032 2.5 2.5 0 012.15-2.15c.122-.017.308-.022 1.165-.022zm8.413 5.507v-5.507h5.913c.857 0 1.043.005 1.165.021a2.5 2.5 0 012.15 2.15c.085.64.094 1.394.009 2.033a1.5 1.5 0 01-1.29 1.29c-.057.007-.165.013-.867.013zM7.5 19.513h7.25v6.982h-.95c-1.581 0-2.617-.002-3.409-.067-.762-.062-1.076-.17-1.253-.26a3 3 0 01-1.311-1.311c-.09-.177-.198-.49-.26-1.253-.065-.792-.067-1.828-.067-3.41zm9.75 0v6.982h.95c1.581 0 2.617-.002 3.409-.067.762-.062 1.076-.17 1.253-.26a3 3 0 001.311-1.311c.09-.177.198-.49.26-1.253.065-.792.067-1.828.067-3.41v-.681z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32GiftOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32GiftOutline: FC<Icon32GiftOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32GiftOutline as any).mountIcon = mountIcon;

export default Icon32GiftOutline;
