import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'music_32';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="music_32"><g fill="none" fill-rule="evenodd"><path d="M0 0h32v32H0z" /><path d="M25 13.5v5.937c0 4.628-1.481 5.626-4.208 5.992-1.416.19-3.625-.552-3.625-3.243 0-1.314 1.094-2.62 2.46-2.889 1.268-.249-.41.077 2.457-.469.461-.088.423-.417.423-.773l-.003-4.555H22.5l.007-3.435c0-.315-.098-.464-.46-.373l-9.561 1.65c-.406.101-.486.27-.486.658v9.981c0 4.628-1.648 5.626-4.374 5.992C6.209 28.163 4 27.421 4 24.73c0-1.314 1.095-2.62 2.461-2.889 1.267-.249-.244.077 2.623-.469.461-.088.423-.417.423-.773l-.004-5.818L9.5 14v-2.6s-.005-2.099 0-3.148c.007-1.255.514-1.716 2.5-2.124L22.333 4.28S25 3.447 25 5.614V13.5z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32MusicProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32Music: FC<Icon32MusicProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32Music as any).mountIcon = mountIcon;

export default Icon32Music;
