import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'lock_outline_56';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="lock_outline_56"><g fill="none" fill-rule="evenodd"><path d="M0 0h56v56H0z" /><path d="M28 5c5.523 0 10 4.477 10 10l.002 6.028c1.416.079 2.374.33 3.366.86a6.634 6.634 0 012.743 2.744c.66 1.234.889 2.417.889 4.496v11.744c0 2.079-.228 3.262-.889 4.496a6.634 6.634 0 01-2.743 2.743c-1.234.66-2.417.889-4.496.889H19.128c-2.079 0-3.262-.228-4.496-.889a6.634 6.634 0 01-2.743-2.743c-.66-1.234-.889-2.417-.889-4.496V29.128c0-2.079.228-3.262.889-4.496a6.634 6.634 0 012.743-2.743c.992-.53 1.95-.783 3.367-.861L18 15c0-5.523 4.477-10 10-10zm8.872 19H19.128c-1.783 0-2.43.186-3.082.534a3.635 3.635 0 00-1.512 1.512c-.348.652-.534 1.299-.534 3.082v11.744c0 1.783.186 2.43.534 3.082.349.652.86 1.163 1.512 1.512.652.348 1.299.534 3.082.534h17.744c1.783 0 2.43-.186 3.082-.534a3.635 3.635 0 001.512-1.512c.348-.652.534-1.299.534-3.082V29.128c0-1.783-.186-2.43-.534-3.082a3.635 3.635 0 00-1.512-1.512c-.652-.348-1.299-.534-3.082-.534zM28 30a3 3 0 011 5.829V39a1 1 0 01-2 0v-3.17A3.001 3.001 0 0128 30zm0-22a7 7 0 00-7 7v6h14v-6a7 7 0 00-7-7z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56LockOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56LockOutline: FC<Icon56LockOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56LockOutline as any).mountIcon = mountIcon;

export default Icon56LockOutline;
