import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'pause_circle_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="pause_circle_20"><path clip-rule="evenodd" d="M20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0s10 4.477 10 10zM6 7.5c0-.466 0-.699.076-.883a1 1 0 01.541-.54C6.801 6 7.034 6 7.5 6s.699 0 .883.076a1 1 0 01.54.541C9 6.801 9 7.034 9 7.5v5c0 .466 0 .699-.076.883a1 1 0 01-.541.54C8.199 14 7.966 14 7.5 14s-.699 0-.883-.076a1 1 0 01-.54-.541C6 13.199 6 12.966 6 12.5zm5.076-.883C11 6.801 11 7.034 11 7.5v5c0 .466 0 .699.076.883a1 1 0 00.541.54c.184.077.417.077.883.077s.699 0 .883-.076a1 1 0 00.54-.541c.077-.184.077-.417.077-.883v-5c0-.466 0-.699-.076-.883a1 1 0 00-.541-.54C13.199 6 12.966 6 12.5 6s-.699 0-.883.076a1 1 0 00-.54.541z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20PauseCircleProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20PauseCircle: FC<Icon20PauseCircleProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20PauseCircle as any).mountIcon = mountIcon;

export default Icon20PauseCircle;
