import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'image_filter_32';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="image_filter_32"><path d="M20 6c5.523 0 10 4.477 10 10s-4.477 10-10 10a9.966 9.966 0 01-4-.832l.074-.033A9.965 9.965 0 0112 26C6.477 26 2 21.523 2 16S6.477 6 12 6c1.404 0 2.74.29 3.953.812l.048.02A9.957 9.957 0 0120 6zm-3.712 19.036l-.288.132.213-.096zM12 8.5a7.5 7.5 0 100 15 7.5 7.5 0 000-15zm6.929 14.71l-.196.183a7.5 7.5 0 10.311-14.833l-.312.047.155.143.127.123.19.192.094.099.307.343.1.119c.157.19.307.384.45.585l.169.246.146.225-.053-.083a9.944 9.944 0 011.297 3.018l.094.425.05.265.056.376.021.173.024.232.024.329.012.277L22 16c0 .3-.013.598-.04.892l-.05.457a9.94 9.94 0 01-1.57 4.17l-.178.26-.187.255c-.132.174-.27.344-.413.51l-.22.245-.203.214zM12 11a5 5 0 11-.69 9.953C10.476 19.492 10 17.802 10 16s.476-3.492 1.31-4.952c.226-.032.456-.048.69-.048z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32ImageFilterProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32ImageFilter: FC<Icon32ImageFilterProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32ImageFilter as any).mountIcon = mountIcon;

export default Icon32ImageFilter;
