import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'music_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="music_24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" /><path d="M19 10.033v4.453c0 3.471-1.236 4.219-3.28 4.494-1.063.143-2.72-.414-2.72-2.432 0-.986.821-1.965 1.846-2.167.95-.187-.308.058 1.842-.352.346-.066.317-.313.317-.58l-.002-3.416H17l.005-2.576c0-.237-.073-.348-.345-.28l-7.295 1.33C9.06 8.582 9 8.708 9 9v7.486c0 3.471-1.236 4.219-3.28 4.494-1.063.143-2.72-.414-2.72-2.432 0-.986.821-1.965 1.846-2.167.95-.187-.308.058 1.842-.352.346-.066.317-.313.317-.58l-.002-4.363h.001L7 8.55s-.004-1.574 0-2.36c.005-.942.51-1.288 2-1.594l8-1.478s2-.625 2 1v5.915z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24MusicProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Music: FC<Icon24MusicProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Music as any).mountIcon = mountIcon;

export default Icon24Music;
