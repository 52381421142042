import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'donate_circle_fill_yellow_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="donate_circle_fill_yellow_20"><linearGradient id="donate_circle_fill_yellow_20_a" gradientUnits="userSpaceOnUse" x1="-10" x2="10" y1="10" y2="30"><stop offset="0" stop-color="#ffb73d" /><stop offset="1" stop-color="#ffa000" /></linearGradient><path d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z" fill="url(#donate_circle_fill_yellow_20_a)" /><path clip-rule="evenodd" d="M10 4a6 6 0 110 12 6 6 0 010-12zm.11 2.59a.25.25 0 00-.334.114l-.952 1.928-2.127.31a.25.25 0 00-.138.426l1.539 1.5-.364 2.119a.25.25 0 00.363.263l1.903-1 1.903 1a.25.25 0 00.362-.263l-.363-2.119 1.54-1.5a.25.25 0 00-.14-.427l-2.126-.309-.952-1.928a.25.25 0 00-.113-.113z" fill="#fff" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20DonateCircleFillYellowProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20DonateCircleFillYellow: FC<Icon20DonateCircleFillYellowProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20DonateCircleFillYellow as any).mountIcon = mountIcon;

export default Icon20DonateCircleFillYellow;
