import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'stop_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="stop_24"><path clip-rule="evenodd" d="M5.305 6.529C5 7.128 5 7.912 5 9.48v5.04c0 1.568 0 2.352.305 2.951a2.8 2.8 0 001.224 1.224C7.128 19 7.912 19 9.48 19h5.04c1.568 0 2.352 0 2.951-.305a2.8 2.8 0 001.224-1.224C19 16.872 19 16.088 19 14.52V9.48c0-1.568 0-2.352-.305-2.951a2.8 2.8 0 00-1.224-1.224C16.872 5 16.088 5 14.52 5H9.48c-1.568 0-2.352 0-2.951.305A2.8 2.8 0 005.305 6.53z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24StopProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Stop: FC<Icon24StopProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Stop as any).mountIcon = mountIcon;

export default Icon24Stop;
