import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'microphone_32';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="microphone_32"><g fill="currentColor"><path d="M11 8a5 5 0 0110 0v8a5 5 0 01-10 0zM14.75 27.25a1.25 1.25 0 112.5 0v2.5a1.25 1.25 0 11-2.5 0zM28 15.25a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM6.5 15.25a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z" /><path d="M16 27c6.627 0 12-5.373 12-12h-2.5a9.5 9.5 0 01-19 0H4c0 6.627 5.373 12 12 12z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32MicrophoneProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32Microphone: FC<Icon32MicrophoneProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32Microphone as any).mountIcon = mountIcon;

export default Icon32Microphone;
