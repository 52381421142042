import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'sync_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="sync_outline_28"><g fill="currentColor"><path d="M21.509 4.5a1 1 0 011 1V9a1 1 0 01-1 1h-3.502a1 1 0 110-2h1.292a7.975 7.975 0 00-5.294-2 8.004 8.004 0 00-7.955 7.11 1 1 0 01-1.989-.22A10.004 10.004 0 0114.005 4a9.97 9.97 0 016.503 2.4v-.9a1 1 0 011-1zM23.064 14.006a1 1 0 01.885 1.104A10.004 10.004 0 0114.005 24a9.97 9.97 0 01-6.503-2.4v.9a1 1 0 01-2.001 0V19a1 1 0 011-1h3.502a1 1 0 110 2H8.71a7.974 7.974 0 005.295 2 8.004 8.004 0 007.955-7.11 1 1 0 011.104-.884z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28SyncOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28SyncOutline: FC<Icon28SyncOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28SyncOutline as any).mountIcon = mountIcon;

export default Icon28SyncOutline;
