import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'download_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="download_outline_24"><path d="M19 19a1 1 0 110 2H5a1 1 0 110-2zM12 2a1 1 0 011 1v10.585l4.293-4.292a1 1 0 011.32-.083l.094.083a1 1 0 010 1.414l-6 6A.997.997 0 0112 17h-.032l-.054-.004L12 17a1.017 1.017 0 01-.52-.146 1.045 1.045 0 01-.187-.147l.09.08a.997.997 0 01-.09-.08l-6-6a1 1 0 011.414-1.414L11 13.585V3a1 1 0 011-1z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24DownloadOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24DownloadOutline: FC<Icon24DownloadOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24DownloadOutline as any).mountIcon = mountIcon;

export default Icon24DownloadOutline;
