import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'ghost_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="ghost_16"><path d="M8 2c1.647 0 2.948.567 4.149 1.666C13.332 4.748 14 6.224 14 7.767l-.01 5.04c-.067.901-.687 1.568-1.588 1.568-.9 0-1.14-.625-1.91-.498C9.722 14.005 9.432 15 8 15s-1.75-1-2.492-1.123c-.77-.127-1.01.498-1.91.498-.868 0-1.543-.627-1.588-1.567L2 7.768c0-1.544.668-3.02 1.851-4.102C5.002 2.613 6.353 2 8 2zm2.317 8.22a.753.753 0 00-1.01-.05c-.738.617-1.474.617-2.224.241a2.217 2.217 0 01-.39-.24l-.071-.053a.754.754 0 00-.939.102.63.63 0 00.053.936c.139.116.365.27.668.422 1.234.62 2.614.62 3.86-.422a.63.63 0 00.053-.936zM5.5 6.5a1 1 0 100 2 1 1 0 000-2zm5 0a1 1 0 100 2 1 1 0 000-2z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16GhostProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16Ghost: FC<Icon16GhostProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16Ghost as any).mountIcon = mountIcon;

export default Icon16Ghost;
