import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'lightbulb_star_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="lightbulb_star_outline_24"><path clip-rule="evenodd" d="M5.4 10.5a6.6 6.6 0 019.68-5.84.9.9 0 00.84-1.59A8.35 8.35 0 0012 2.1a8.4 8.4 0 00-8.4 8.4c0 2.373 1.02 3.953 2.107 5.157.354.392.743.771 1.089 1.11.158.153.306.299.44.433.424.428.727.78.914 1.117l.247 1.06c.072.308.136.584.207.812.077.246.178.494.35.728.254.349.599.622.996.791.267.114.532.156.789.175.238.017.521.017.837.017h.848c.316 0 .599 0 .837-.017.257-.02.522-.061.789-.175a2.4 2.4 0 00.997-.79c.171-.235.272-.483.349-.729.07-.228.135-.504.207-.811l.247-1.062c.273-.492.761-.97 1.446-1.64.85-.83 1.676-1.693 2.25-2.745a.9.9 0 00-1.581-.862c-.507.93-1.266 1.676-2.017 2.408-.499.485-1.05 1.022-1.459 1.623H12.9v-2.118l1.1-.733a.9.9 0 10-1-1.498l-1 .667-1-.667a.9.9 0 00-1 1.498l1.1.733V17.1H9.511c-.292-.43-.65-.815-.998-1.167-.175-.177-.347-.344-.516-.51-.323-.315-.637-.62-.955-.973C6.13 13.439 5.4 12.27 5.4 10.5zm4.744 8.44l-.01-.04h3.732l-.01.04c-.08.345-.13.556-.179.713a.699.699 0 01-.083.201.6.6 0 01-.25.198.69.69 0 01-.214.035c-.165.012-.381.013-.734.013h-.791c-.354 0-.57 0-.735-.013a.69.69 0 01-.215-.035.6.6 0 01-.249-.198.699.699 0 01-.083-.2c-.05-.158-.1-.37-.18-.713zm7.932-14.635a.447.447 0 01.848 0l.457 1.373c.148.444.497.793.942.941l1.372.457a.447.447 0 010 .848l-1.372.457a1.489 1.489 0 00-.942.941l-.457 1.373a.447.447 0 01-.848 0l-.457-1.373a1.489 1.489 0 00-.942-.941l-1.372-.457a.447.447 0 010-.848l1.372-.457c.445-.148.794-.497.942-.941z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24LightbulbStarOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24LightbulbStarOutline: FC<Icon24LightbulbStarOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24LightbulbStarOutline as any).mountIcon = mountIcon;

export default Icon24LightbulbStarOutline;
