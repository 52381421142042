import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'game_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="game_28"><g fill="none" fill-rule="evenodd"><path d="M0 0h28v28H0z" /><path d="M9.5 11.75v-1.248A.502.502 0 008.998 10h-.495a.502.502 0 00-.503.502v1.248H6.753a.502.502 0 00-.503.502v.495c0 .278.225.503.503.503H8v1.248c0 .277.225.502.502.502h.495a.502.502 0 00.503-.502V13.25h1.248a.502.502 0 00.502-.502v-.495a.502.502 0 00-.502-.503H9.5zM18 18h-8c-1.5 0-4.011 5-5.5 5-2 0-2.5-1-2.5-4S2.5 7 8 7h12c5.5 0 6 9 6 12s-.5 4-2.5 4-4-5-5.5-5zm-.747-6.25a.502.502 0 00-.503.502v.495c0 .278.225.503.503.503h.494a.502.502 0 00.503-.502v-.495a.502.502 0 00-.503-.503h-.494zm3.5 0a.502.502 0 00-.503.502v.495c0 .278.225.503.503.503h.494a.502.502 0 00.503-.502v-.495a.502.502 0 00-.503-.503h-.494zm-1.75 1.75a.502.502 0 00-.503.502v.495c0 .278.225.503.503.503h.494a.502.502 0 00.503-.502v-.495a.502.502 0 00-.503-.503h-.494zm0-3.5a.502.502 0 00-.503.502v.495c0 .278.225.503.503.503h.494a.502.502 0 00.503-.502v-.495a.502.502 0 00-.503-.503h-.494z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28GameProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28Game: FC<Icon28GameProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28Game as any).mountIcon = mountIcon;

export default Icon28Game;
