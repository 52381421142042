import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'thumbs_up_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="thumbs_up_outline_28"><path clip-rule="evenodd" d="M13.406 5.048A1.312 1.312 0 0116 5.323V10a1 1 0 001 1h1.5c1.817 0 3.164.509 4.053 1.373.884.86 1.447 2.2 1.447 4.127v1c0 1.928-.563 3.267-1.447 4.127-.89.865-2.236 1.373-4.053 1.373H9.494c.006-.216.006-.456.006-.72V14.72c0-.27 0-.515-.006-.734l1.709-1.368c.453-.362.767-.87.888-1.436zM8.8 11.979l1.154-.922a.515.515 0 00.181-.294l1.315-6.135a3.312 3.312 0 016.55.695V9h.5c2.183 0 4.086.617 5.447 1.94C25.313 12.266 26 14.177 26 16.5v1c0 2.322-.687 4.233-2.053 5.561-1.36 1.323-3.264 1.94-5.447 1.94H8.818a2.85 2.85 0 01-1.105.794c-.305.122-.611.166-.912.186-.284.02-.627.02-1.02.02H5.72c-.394 0-.737 0-1.021-.02-.3-.02-.607-.064-.912-.186a2.85 2.85 0 01-1.581-1.582 2.915 2.915 0 01-.187-.912C2 23.017 2 22.674 2 22.281V14.72c0-.394 0-.736.019-1.02.02-.301.064-.607.187-.912a2.85 2.85 0 011.581-1.582c.305-.123.611-.167.912-.187C4.983 11 5.326 11 5.719 11h.061c.394 0 .737 0 1.021.02.3.019.607.063.912.186.422.17.794.436 1.087.774zm-4.267 1.083c.027-.011.097-.034.298-.047.21-.014.486-.015.919-.015s.708 0 .919.015c.201.013.27.036.298.047a.85.85 0 01.472.471c.01.027.033.097.047.299.013.21.014.486.014.918v7.5c0 .433 0 .708-.014.919-.014.202-.036.271-.047.298a.85.85 0 01-.472.472c-.027.01-.097.033-.298.047-.21.014-.486.014-.919.014s-.708 0-.919-.014c-.201-.014-.27-.036-.298-.047a.85.85 0 01-.472-.472c-.01-.027-.033-.096-.047-.298-.013-.21-.014-.486-.014-.919v-7.5c0-.432 0-.708.014-.918.014-.202.036-.272.047-.299a.85.85 0 01.472-.471z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28ThumbsUpOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28ThumbsUpOutline: FC<Icon28ThumbsUpOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28ThumbsUpOutline as any).mountIcon = mountIcon;

export default Icon28ThumbsUpOutline;
