import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'thumbs_up_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="thumbs_up_outline_20"><path clip-rule="evenodd" d="M9.607 3.823a.956.956 0 011.893.189V7.25c0 .414.336.75.75.75h1c1.417 0 2.309.299 2.856.823.54.518.894 1.402.894 2.927v.5c0 1.526-.353 2.41-.894 2.927-.547.525-1.44.823-2.856.823H7v-5.75c0-.273 0-.473-.006-.633l.955-.666c.466-.326.79-.82.902-1.377zm-3.2 4.374l.683-.476a.712.712 0 00.29-.444l.757-3.75A2.456 2.456 0 0113 4.012V6.5h.25c1.583 0 2.941.327 3.894 1.24.96.92 1.356 2.286 1.356 4.01v.5c0 1.725-.397 3.091-1.356 4.01-.953.913-2.31 1.24-3.894 1.24H6.642a2.2 2.2 0 01-1.413.955c-.212.042-.468.042-.979.042s-.767 0-.98-.042a2.2 2.2 0 01-1.728-1.729c-.042-.212-.042-.468-.042-.98V10.25c0-.51 0-.767.042-.979a2.2 2.2 0 011.729-1.729c.212-.042.468-.042.979-.042s.767 0 .98.042c.46.092.869.326 1.177.655zM5.5 10.25v5.497c0 .266 0 .428-.004.55a1.28 1.28 0 01-.01.137.7.7 0 01-.549.55c-.005 0-.039.005-.137.008-.122.005-.284.005-.55.005s-.428 0-.55-.004a1.26 1.26 0 01-.137-.01.7.7 0 01-.55-.549c0-.005-.005-.04-.009-.137a18.13 18.13 0 01-.004-.55V10.25c0-.266 0-.427.004-.55.004-.098.009-.132.01-.137a.7.7 0 01.549-.55c.005 0 .04-.005.137-.008C3.822 9 3.984 9 4.25 9s.428 0 .55.005c.098.003.132.008.137.009a.7.7 0 01.55.55c0 .004.005.038.009.136.004.123.004.284.004.55z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20ThumbsUpOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20ThumbsUpOutline: FC<Icon20ThumbsUpOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20ThumbsUpOutline as any).mountIcon = mountIcon;

export default Icon20ThumbsUpOutline;
