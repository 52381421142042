import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'wrist_watch_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="wrist_watch_outline_28"><path clip-rule="evenodd" d="M9.498 4.412A3 3 0 0112.44 2h3.12a3 3 0 012.942 2.412l.41 2.045a9.008 9.008 0 013.902 5.716 2 2 0 010 3.654 9.008 9.008 0 01-3.903 5.716l-.409 2.045A3 3 0 0115.56 26h-3.12a3 3 0 01-2.942-2.412l-.41-2.045C6.629 19.937 5 17.16 5 14s1.627-5.937 4.089-7.543zm1.842.988c.84-.26 1.734-.4 2.66-.4s1.82.14 2.66.4l-.119-.596a1 1 0 00-.98-.804H12.44a1 1 0 00-.981.804zm0 17.2l.119.596a1 1 0 00.98.804h3.121a1 1 0 00.981-.804l.12-.596c-.842.26-1.735.4-2.661.4s-1.82-.14-2.66-.4zM21 14a7 7 0 11-14 0 7 7 0 0114 0zm-6-4a1 1 0 10-2 0v4a1 1 0 00.606.92l3.5 1.5a1 1 0 10.788-1.84L15 13.34z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28WristWatchOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28WristWatchOutline: FC<Icon28WristWatchOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28WristWatchOutline as any).mountIcon = mountIcon;

export default Icon28WristWatchOutline;
