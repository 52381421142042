import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'pause_32';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="pause_32"><g fill="currentColor"><path d="M22.782 7c.597 0 .814.062 1.032.179.219.117.39.288.507.507s.179.434.179 1.032v14.564c0 .597-.062.814-.179 1.032s-.288.39-.507.507-.435.179-1.032.179h-2.564c-.597 0-.814-.062-1.032-.179s-.39-.288-.507-.507-.179-.435-.179-1.032V8.718c0-.598.062-.814.179-1.032s.288-.39.507-.507c.218-.117.435-.179 1.032-.179zM12.815 7.179C12.596 7.062 12.38 7 11.782 7H9.218c-.598 0-.814.062-1.032.179-.219.117-.39.288-.507.507-.117.218-.179.434-.179 1.032v14.564c0 .597.062.814.179 1.032.117.219.288.39.507.507S8.62 25 9.218 25h2.564c.597 0 .814-.062 1.032-.179s.39-.288.507-.507.179-.435.179-1.032V8.718c0-.598-.062-.814-.179-1.032s-.288-.39-.507-.507z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32PauseProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32Pause: FC<Icon32PauseProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32Pause as any).mountIcon = mountIcon;

export default Icon32Pause;
