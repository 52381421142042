import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'pin_slash_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="pin_slash_outline_20"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M17.965 8.15a.75.75 0 01-.57.519l-1.567.315a.25.25 0 00-.134.075c-.224.241-.457.472-.69.705a.75.75 0 01-1.06-1.06l.65-.665c.25-.27.578-.453.938-.525l.194-.04-3.2-3.198-.036.182a1.75 1.75 0 01-.48.901l-.708.706a.75.75 0 01-1.057-1.064l.704-.702a.25.25 0 00.069-.13l.306-1.563a.75.75 0 011.266-.386l5.187 5.183a.75.75 0 01.188.747zM7.996 13.065l3.139 3.14c.607.606 1.616.23 1.719-.586.084-.835.13-1.674.194-2.51l1.672 1.671a.75.75 0 101.06-1.06l-9.5-9.5a.75.75 0 10-1.06 1.06l1.67 1.67c-.845.067-1.718.076-2.555.206-.81.157-1.116 1.132-.539 1.71l3.139 3.138-4.788 4.8a.75.75 0 001.133.977zm3.448 1.33l.208-2.682-3.369-3.369-2.67.212z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20PinSlashOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20PinSlashOutline: FC<Icon20PinSlashOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20PinSlashOutline as any).mountIcon = mountIcon;

export default Icon20PinSlashOutline;
