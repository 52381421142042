import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'home_outline_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="home_outline_36"><path clip-rule="evenodd" d="M16.594 4.597L4.979 15.317a3.821 3.821 0 00-1.229 2.809V29.07a3.179 3.179 0 003.179 3.179h9.321V24a1.75 1.75 0 113.5 0v8.25h9.321a3.179 3.179 0 003.179-3.179V18.126a3.821 3.821 0 00-1.23-2.808l-3.807-3.515c.024-.097.037-.198.037-.303V7l-.006-.128A1.25 1.25 0 0024.75 7v2.53l-5.344-4.933a2.073 2.073 0 00-2.812 0zM6.675 17.155L18 6.7l11.325 10.455c.27.25.425.602.425.97v10.946l-.007.1a.679.679 0 01-.672.579H22.25V24A4.25 4.25 0 0018 19.75l-.206.005A4.25 4.25 0 0013.75 24v5.75H6.929a.679.679 0 01-.679-.678V18.126c0-.369.154-.721.425-.971z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36HomeOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36HomeOutline: FC<Icon36HomeOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36HomeOutline as any).mountIcon = mountIcon;

export default Icon36HomeOutline;
