import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'send_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="send_36"><path d="M8.515 23.247c-.993 2.602-1.625 4.427-1.87 5.482C6 31.5 6 32.5 8.242 31.21c2.979-1.571 17.4-9.356 20.618-11.087 2.639-1.419 3.139-2.674-.225-4.473-3.408-1.824-17.661-9.407-20.393-10.895C6 3.5 6 4.5 6.644 7.234c.252 1.068.89 2.91 1.898 5.531.701 1.824 2.32 3.16 4.276 3.528l7.706 1.446.657.124c.151.03.148.245 0 .274l-.681.128-7.7 1.44c-1.962.368-3.586 1.71-4.285 3.542z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36SendProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36Send: FC<Icon36SendProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36Send as any).mountIcon = mountIcon;

export default Icon36Send;
