import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 12 12';
const id = 'explicit_12';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" id="explicit_12"><path d="M8 9H5V4h3v1H6v1h2v1H6v1h2v1z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M1 3.5A2.5 2.5 0 013.5 1h6A2.5 2.5 0 0112 3.5v6A2.5 2.5 0 019.5 12h-6A2.5 2.5 0 011 9.5v-6zM3.5 2h6A1.5 1.5 0 0111 3.5v6A1.5 1.5 0 019.5 11h-6A1.5 1.5 0 012 9.5v-6A1.5 1.5 0 013.5 2z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon12ExplicitProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon12Explicit: FC<Icon12ExplicitProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 12,
    height: !isNaN(props.height) ? +props.height : 12,
  }));
};

(Icon12Explicit as any).mountIcon = mountIcon;

export default Icon12Explicit;
