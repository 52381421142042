import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'briefcase_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="briefcase_outline_28"><path clip-rule="evenodd" d="M14 3h-.032c-.439 0-.817 0-1.13.021a3.07 3.07 0 00-.986.207 3 3 0 00-1.624 1.624 3.072 3.072 0 00-.207.986C10 6.15 10 6.529 10 6.968V7H8.4c-2.24 0-3.36 0-4.216.436a4 4 0 00-1.748 1.748C2 10.04 2 11.16 2 13.4v4.2c0 2.24 0 3.36.436 4.216a4 4 0 001.748 1.748C5.04 24 6.16 24 8.4 24h11.2c2.24 0 3.36 0 4.216-.436a4 4 0 001.748-1.748C26 20.96 26 19.84 26 17.6v-4.2c0-2.24 0-3.36-.436-4.216a4 4 0 00-1.748-1.748C22.96 7 21.84 7 19.6 7H18v-.032c0-.439 0-.817-.021-1.13a3.072 3.072 0 00-.207-.986 3 3 0 00-1.624-1.624 3.07 3.07 0 00-.986-.207C14.85 3 14.471 3 14.032 3zm2 4c0-.48 0-.79-.017-1.026-.015-.228-.042-.315-.06-.357a1 1 0 00-.54-.54c-.042-.018-.129-.045-.357-.06C14.79 5 14.48 5 14 5s-.79 0-1.026.017c-.228.015-.315.042-.357.06a1 1 0 00-.54.54c-.018.042-.045.129-.06.357C12 6.21 12 6.52 12 7zM9 22V9h10v13h.743H8.257zm13.064-.048c-.295.024-.639.036-1.064.042V9.006c.425.006.769.018 1.064.042.544.044.745.12.844.17a2 2 0 01.874.874c.05.099.126.3.17.844.046.566.048 1.31.048 2.464v4.2c0 1.153-.002 1.898-.048 2.464-.044.544-.12.745-.17.844a2 2 0 01-.874.874c-.099.05-.3.126-.844.17zM7 9.006v12.988a16.032 16.032 0 01-1.064-.042c-.544-.044-.745-.12-.844-.17a2 2 0 01-.874-.874c-.05-.099-.126-.3-.17-.844C4.002 19.498 4 18.754 4 17.6v-4.2c0-1.153.002-1.898.048-2.464.044-.544.12-.745.17-.844a2 2 0 01.874-.874c.099-.05.3-.126.844-.17.295-.024.639-.036 1.064-.042z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28BriefcaseOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28BriefcaseOutline: FC<Icon28BriefcaseOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28BriefcaseOutline as any).mountIcon = mountIcon;

export default Icon28BriefcaseOutline;
