import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'users_3_outline_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="users_3_outline_36"><path fill-rule="evenodd" clip-rule="evenodd" d="M18 6.999a5 5 0 110 10 5 5 0 010-10zm0 2.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM6.05 12.784a4.464 4.464 0 014.464-4.464 1.25 1.25 0 110 2.5l-.146.005a1.964 1.964 0 00.146 3.924l.128.006a1.25 1.25 0 01-.128 2.494 4.464 4.464 0 01-4.464-4.465zM1.964 24.036c0-2.671 2.24-4.786 5.75-4.786a1.25 1.25 0 110 2.5c-2.161 0-3.25 1.028-3.25 2.286 0 .65.427 1 1 1h.965l.127.006a1.25 1.25 0 01-.127 2.494h-.965l-.185-.005c-1.778-.086-3.315-1.413-3.315-3.495zm32.072 0c0-2.671-2.24-4.786-5.75-4.786a1.25 1.25 0 000 2.5c2.161 0 3.25 1.028 3.25 2.286 0 .65-.427 1-1 1h-.965l-.127.006a1.25 1.25 0 00.127 2.494h.965l.184-.005c1.779-.086 3.316-1.413 3.316-3.495zM9.679 25c0-3.501 3.373-5.75 8.321-5.75 4.948 0 8.321 2.249 8.321 5.75 0 2.46-1.633 3.821-3.821 3.821h-9c-2.188 0-3.821-1.36-3.821-3.821zm14.142 0c0-1.856-2.09-3.25-5.821-3.25-3.73 0-5.821 1.394-5.821 3.25 0 .968.423 1.321 1.321 1.321h9c.898 0 1.321-.353 1.321-1.321zM25.5 8.32a1.25 1.25 0 00-.128 2.493l.128.007a1.964 1.964 0 01.147 3.923l-.147.006a1.25 1.25 0 000 2.5 4.464 4.464 0 000-8.929z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36Users3OutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36Users3Outline: FC<Icon36Users3OutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36Users3Outline as any).mountIcon = mountIcon;

export default Icon36Users3Outline;
