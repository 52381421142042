import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'discussions_circle_fill_blue_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="discussions_circle_fill_blue_20"><linearGradient id="discussions_circle_fill_blue_20_a" gradientUnits="userSpaceOnUse" x1="-10" x2="10" y1="10" y2="30"><stop offset="0" stop-color="#70b2ff" /><stop offset="1" stop-color="#5c9ce6" /></linearGradient><path d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z" fill="url(#discussions_circle_fill_blue_20_a)" /><path d="M12.73 12.652a2.53 2.53 0 00.403-.559c.24-.448.367-.893.367-2.118V7h.898c.557 0 .759.058.963.167s.363.269.472.472c.109.204.167.406.167.963v6.045a.35.35 0 01-.597.248L13.5 13h-.76a.15.15 0 01-.1-.262c.034-.031.064-.06.09-.086zM6.5 12l-1.903 1.895A.35.35 0 014 13.647V7.602c0-.557.058-.759.167-.963.109-.203.269-.363.472-.472.204-.109.406-.167.963-.167h4.796c.557 0 .759.058.962.167s.364.269.473.472c.109.204.167.406.167.963v2.796c0 .557-.058.759-.167.963a1.135 1.135 0 01-.473.472c-.203.109-.405.167-.962.167z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20DiscussionsCircleFillBlueProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20DiscussionsCircleFillBlue: FC<Icon20DiscussionsCircleFillBlueProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20DiscussionsCircleFillBlue as any).mountIcon = mountIcon;

export default Icon20DiscussionsCircleFillBlue;
