import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'like_circle_fill_gray_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="like_circle_fill_gray_20"><linearGradient id="like_circle_fill_gray_20_a" gradientUnits="userSpaceOnUse" x1="-10" x2="10" y1="10" y2="30"><stop offset="0" stop-color="#b1b6bd" /><stop offset="1" stop-color="#99a2ad" /></linearGradient><path d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z" fill="url(#like_circle_fill_gray_20_a)" /><path d="M12.2 6.5c1.546 0 2.8 1.205 2.8 2.691 0 1.501-.626 2.254-3.28 4.238l-1.208.902a.86.86 0 01-1.024 0L8.28 13.43C5.627 11.445 5 10.692 5 9.19 5 7.705 6.254 6.5 7.8 6.5c.83 0 1.55.4 2.2 1.15.65-.75 1.37-1.15 2.2-1.15z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20LikeCircleFillGrayProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20LikeCircleFillGray: FC<Icon20LikeCircleFillGrayProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20LikeCircleFillGray as any).mountIcon = mountIcon;

export default Icon20LikeCircleFillGray;
