import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'story_outline_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="story_outline_24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" opacity=".1" /><path d="M9.128 3.1l6.046.002c1.56.019 2.367.191 3.204.639a4.534 4.534 0 011.881 1.88c.476.89.641 1.745.641 3.507v5.744c0 1.762-.165 2.617-.64 3.506a4.534 4.534 0 01-1.882 1.881c-.889.476-1.744.641-3.506.641H9.128c-1.762 0-2.617-.165-3.506-.64a4.534 4.534 0 01-1.881-1.882c-.476-.889-.641-1.744-.641-3.506l.002-6.046c.019-1.56.191-2.367.639-3.204A4.534 4.534 0 015.62 3.74c.89-.476 1.745-.641 3.507-.641zm5.744 1.8H9.128c-1.315 0-1.917.086-2.447.324l-.21.104A2.735 2.735 0 005.328 6.47C5.01 7.063 4.9 7.638 4.9 9.128v5.744c0 1.315.086 1.917.324 2.447l.104.21c.265.495.648.878 1.143 1.143.523.28 1.032.399 2.167.423l.49.005h5.744c1.315 0 1.917-.086 2.447-.324l.21-.104a2.735 2.735 0 001.143-1.143c.28-.523.399-1.032.423-2.167l.005-.49V9.128c0-1.315-.086-1.917-.324-2.447l-.104-.21a2.735 2.735 0 00-1.143-1.143c-.592-.317-1.167-.428-2.657-.428zM12 8.1a3.9 3.9 0 110 7.8 3.9 3.9 0 010-7.8zm0 1.8a2.1 2.1 0 100 4.2 2.1 2.1 0 000-4.2z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24StoryOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24StoryOutline: FC<Icon24StoryOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24StoryOutline as any).mountIcon = mountIcon;

export default Icon24StoryOutline;
