import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'microphone_slash_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="microphone_slash_16"><path d="M5.498 7.06v.44a2.501 2.501 0 002.909 2.467l1.2 1.198a4.005 4.005 0 01-5.521-2.822.75.75 0 00-1.47.314A5.508 5.508 0 007.25 12.95v1.301a.75.75 0 001.502 0v-1.3a5.473 5.473 0 001.972-.669l1.25 1.25a.751.751 0 001.062-1.061L3.88 3.32a.75.75 0 10-1.06 1.06l2.678 2.68zm6.356 1.529c-.089.314-.03.66.2.89.37.37.993.284 1.16-.211a5.44 5.44 0 00.17-.61.75.75 0 10-1.469-.315 3.943 3.943 0 01-.061.246zM6.303 2.662c-.332.307-.254.818.066 1.138l3.428 3.424a.4.4 0 00.683-.279l.022-2.445a2.501 2.501 0 00-4.199-1.838z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16MicrophoneSlashProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16MicrophoneSlash: FC<Icon16MicrophoneSlashProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16MicrophoneSlash as any).mountIcon = mountIcon;

export default Icon16MicrophoneSlash;
