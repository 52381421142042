import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'volume_circle_fill_gray_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="volume_circle_fill_gray_28"><linearGradient id="volume_circle_fill_gray_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#b1b6bd" /><stop offset="1" stop-color="#99a2ad" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#volume_circle_fill_gray_28_a)" /><path clip-rule="evenodd" d="M6.923 11.5H9l3.293-3.293A1 1 0 0114 8.914v10.172a1 1 0 01-1.707.707L9 16.5H6.923c-.669 0-.911-.07-1.156-.2-.244-.131-.436-.323-.567-.567s-.2-.487-.2-1.156v-1.154c0-.669.07-.911.2-1.156.131-.244.323-.436.567-.567.245-.13.487-.2 1.156-.2zm9.526-.788a.9.9 0 011.27.097A4.885 4.885 0 0118.9 14c0 1.216-.444 2.33-1.177 3.186a.9.9 0 11-1.367-1.17A3.084 3.084 0 0017.1 14c0-.772-.28-1.476-.747-2.018a.9.9 0 01.096-1.27zm4.044-2.39a.9.9 0 10-1.251 1.294A6.08 6.08 0 0121.1 14a6.08 6.08 0 01-1.859 4.384.9.9 0 001.252 1.294A7.88 7.88 0 0022.9 14a7.88 7.88 0 00-2.407-5.677z" fill="#fff" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28VolumeCircleFillGrayProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28VolumeCircleFillGray: FC<Icon28VolumeCircleFillGrayProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28VolumeCircleFillGray as any).mountIcon = mountIcon;

export default Icon28VolumeCircleFillGray;
