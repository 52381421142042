import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'sticker_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="sticker_outline_56"><path fill-rule="evenodd" clip-rule="evenodd" d="M27.045 4.015a2.193 2.193 0 011.517.515l.136.125.728.729 21.19 21.19.732.731c.434.435.667 1.032.64 1.646l-.02.416-.042.831-.004.06a2.982 2.982 0 01-.004.048C50.75 42.48 40.494 52 28.014 52 14.752 52 4 41.248 4 27.986 4 15.69 13.242 5.552 25.158 4.14l.366-.04c.13-.014.499-.036 1.107-.065l.414-.02zm-2.53 3.246C14.511 8.934 7 17.641 7 27.986 7 39.592 16.408 49 28.014 49c10.35 0 19.052-7.517 20.725-17.514a17.923 17.923 0 01-8.194 1.969c-9.94 0-18-8.06-18-18 0-2.951.71-5.736 1.97-8.194zm23.658 21.113L27.626 7.826a14.917 14.917 0 00-2.081 7.628c0 8.285 6.716 15 15 15a14.917 14.917 0 007.628-2.08z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56StickerOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56StickerOutline: FC<Icon56StickerOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56StickerOutline as any).mountIcon = mountIcon;

export default Icon56StickerOutline;
