import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'pin_dot_slash_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="pin_dot_slash_outline_28"><path d="M15.044 6.162a.5.5 0 01-.137.255l-1.925 1.925a1 1 0 101.446 1.383l1.893-1.893a2.5 2.5 0 00.684-1.278l.075-.374 4.782 4.782-.374.075a2.5 2.5 0 00-1.278.684l-1.864 1.864a1 1 0 101.353 1.47l.003.002 1.923-1.922a.5.5 0 01.255-.137l2.206-.441a1 1 0 00.511-1.688l-7.424-7.424a1 1 0 00-1.688.51l-.441 2.207zM10.5 7a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M4.293 5.293a1 1 0 011.414 0l17 17a1 1 0 11-1.414 1.414l-3.584-3.584-.184 2.384a1.375 1.375 0 01-2.343.867l-4.55-4.55-5.657 5.657c-.943.943-2.357-.472-1.414-1.414l5.657-5.657-4.55-4.55a1.375 1.375 0 01.867-2.343l2.384-.184-3.626-3.626a1 1 0 010-1.414zm5.489 6.903l-2.74.21L15.636 21l.211-2.739-6.064-6.064z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28PinDotSlashOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28PinDotSlashOutline: FC<Icon28PinDotSlashOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28PinDotSlashOutline as any).mountIcon = mountIcon;

export default Icon28PinDotSlashOutline;
