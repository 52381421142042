import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'ticket_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="ticket_outline_28"><path clip-rule="evenodd" d="M20.376 3.405c-.57-.57-1.042-1.041-1.458-1.393-.426-.36-.857-.651-1.37-.818a4 4 0 00-2.472 0c-.518.169-.953.464-1.383.83-.414.35-.884.82-1.453 1.39L3.414 12.24c-.57.57-1.04 1.04-1.39 1.453-.366.43-.661.865-.83 1.383a4 4 0 000 2.472c.167.513.458.944.818 1.37.352.416.823.887 1.393 1.458a1 1 0 001.336.07 2 2 0 012.812 2.813 1 1 0 00.071 1.336c.57.57 1.042 1.042 1.458 1.394.426.359.857.65 1.37.817a4 4 0 002.472 0c.518-.169.953-.464 1.383-.83.414-.35.884-.82 1.453-1.39l8.826-8.826c.57-.57 1.04-1.04 1.39-1.453.366-.43.661-.865.83-1.383a4 4 0 000-2.472c-.167-.513-.458-.944-.818-1.37-.352-.416-.823-.887-1.393-1.458a1 1 0 00-1.336-.07 2 2 0 01-2.812-2.813 1 1 0 00-.071-1.336zm-3.446-.309c.165.054.367.165.697.443.218.185.465.419.773.721a4 4 0 005.34 5.34c.302.308.536.555.72.774.28.329.39.53.444.696a2 2 0 010 1.236c-.055.167-.167.371-.452.707-.292.344-.703.756-1.31 1.362l-1.676 1.677-1.259-1.26a1 1 0 00-1.414 1.415l1.259 1.259-5.677 5.677c-.606.606-1.018 1.017-1.362 1.31-.336.284-.54.396-.707.45a1.993 1.993 0 01-1.236 0c-.165-.053-.367-.164-.696-.442a14.462 14.462 0 01-.773-.721 4 4 0 00-5.34-5.34 14.448 14.448 0 01-.722-.773c-.278-.33-.389-.532-.443-.697a2 2 0 010-1.236c.055-.167.167-.371.452-.706.292-.345.703-.757 1.31-1.363l5.676-5.677 1.259 1.26a1 1 0 101.414-1.415l-1.259-1.259 1.677-1.677c.606-.606 1.018-1.017 1.363-1.309.335-.285.54-.397.706-.452a2 2 0 011.236 0zm-.973 7.447a1 1 0 00-1.414 1.414l1.5 1.5a1 1 0 001.414-1.414z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28TicketOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28TicketOutline: FC<Icon28TicketOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28TicketOutline as any).mountIcon = mountIcon;

export default Icon28TicketOutline;
