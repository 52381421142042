import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'pin_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="pin_20"><path d="M12.122 3.204a.415.415 0 01.586 0l4.084 4.09a.414.414 0 01-.293.706h-.585a1 1 0 00-.707.293l-1.914 1.914a1 1 0 00-.293.707v3.344a.72.72 0 01-1.23.51l-2.568-2.569-3.987 3.986a.997.997 0 01-1.41-1.41l3.984-3.989-2.557-2.557a.72.72 0 01-.202-.396l-.009-.113a.72.72 0 01.72-.72h3.344a1 1 0 00.707-.293l1.915-1.914A1 1 0 0012 4.086v-.588c0-.11.044-.216.122-.294z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20PinProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20Pin: FC<Icon20PinProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20Pin as any).mountIcon = mountIcon;

export default Icon20Pin;
