import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'radiowaves_left_and_right_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="radiowaves_left_and_right_outline_28"><g fill="none" fill-rule="evenodd"><path d="M0 0h28v28H0z" /><path d="M7.491 5.698a1 1 0 01-.033 1.414l-.033.03-.02.02A9.467 9.467 0 004.5 14a9.468 9.468 0 002.935 6.866 1 1 0 01-1.383 1.446A11.468 11.468 0 012.5 14c0-3.163 1.284-6.124 3.516-8.276l.026-.025.035-.034a1 1 0 011.414.033zm13.018 0a1 1 0 011.414-.033l.035.034.026.025A11.467 11.467 0 0125.5 14c0 3.18-1.299 6.157-3.552 8.312a1 1 0 11-1.383-1.446A9.468 9.468 0 0023.5 14a9.467 9.467 0 00-2.904-6.837l-.021-.02-.033-.031a1 1 0 01-.033-1.414zm-9.671 3.396a1 1 0 01-.057 1.413A4.734 4.734 0 009.25 14c0 1.345.56 2.598 1.53 3.492a1 1 0 11-1.356 1.47A6.733 6.733 0 017.25 14c0-1.91.799-3.694 2.175-4.963a1 1 0 011.413.057zm6.324 0a1 1 0 011.413-.057A6.733 6.733 0 0120.75 14c0 1.91-.798 3.693-2.174 4.962a1 1 0 11-1.356-1.47A4.734 4.734 0 0018.75 14c0-1.345-.56-2.598-1.53-3.493a1 1 0 01-.058-1.413zM14 11.75a2.25 2.25 0 110 4.5 2.25 2.25 0 010-4.5z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28RadiowavesLeftAndRightOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28RadiowavesLeftAndRightOutline: FC<Icon28RadiowavesLeftAndRightOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28RadiowavesLeftAndRightOutline as any).mountIcon = mountIcon;

export default Icon28RadiowavesLeftAndRightOutline;
