import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'crop_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="crop_24"><path fill-rule="evenodd" clip-rule="evenodd" d="M7 2.1a.9.9 0 01.9.9v12A1.1 1.1 0 009 16.1h12a.9.9 0 110 1.8H9A2.9 2.9 0 016.1 15V3a.9.9 0 01.9-.9z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M9.6 7a.9.9 0 01.9-.9H15A2.9 2.9 0 0117.9 9v12a.9.9 0 11-1.8 0V9A1.1 1.1 0 0015 7.9h-4.5a.9.9 0 01-.9-.9zM2.1 7a.9.9 0 01.9-.9h3.5a.9.9 0 010 1.8H3a.9.9 0 01-.9-.9z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24CropProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Crop: FC<Icon24CropProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Crop as any).mountIcon = mountIcon;

export default Icon24Crop;
