import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'coins_stack_high_outline_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="coins_stack_high_outline_36"><path d="M26.995 26.176C26.835 29.03 22.802 31 18 31c-4.9 0-9-2.05-9-5V11l.005-.176C9.165 7.97 13.198 6 18 6c4.9 0 9 2.05 9 5v15zm-1.994-6.97C23.34 20.33 20.802 21 18 21c-2.8 0-5.339-.67-7-1.794V21c0 1.468 3.063 3 7 3s7-1.532 7-3zm0-5C23.34 15.33 20.802 16 18 16c-2.8 0-5.339-.67-7-1.794V16c0 1.468 3.063 3 7 3s7-1.532 7-3zM18 8c-3.937 0-7 1.532-7 3s3.063 3 7 3 7-1.532 7-3-3.063-3-7-3zm7.002 16.206C23.34 25.33 20.8 26 18 26s-5.339-.67-7-1.794V26l.005.107C11.15 27.543 14.16 29 18 29c3.937 0 7-1.532 7-3z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36CoinsStackHighOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36CoinsStackHighOutline: FC<Icon36CoinsStackHighOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36CoinsStackHighOutline as any).mountIcon = mountIcon;

export default Icon36CoinsStackHighOutline;
