import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'send_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="send_outline_28"><path clip-rule="evenodd" d="M5.8 11.363c-.657-1.679-1.117-2.977-1.32-3.807-.252-1.023-.675-2.156-.253-3.188A2.184 2.184 0 015.75 3.062c.95-.226 1.847.223 2.662.66 1.778.952 11.076 5.815 13.32 6.996 1.311.69 3.198 1.428 3.267 3.162.067 1.678-1.556 2.432-2.816 3.094-4.603 2.422-9.177 4.898-13.795 7.293-.839.436-1.763.91-2.735.664a2.167 2.167 0 01-1.539-1.45c-.187-.582-.107-1.18.032-1.762.415-1.727.993-3.415 1.635-5.07a5.615 5.615 0 012.15-2.654 5.615 5.615 0 01-2.13-2.632zm6.416 1.89c1.025.206 1.542.317 1.65.495a.5.5 0 01-.001.523c-.115.187-.68.296-1.807.515-.778.151-1.416.274-1.612.31-1.283.237-2.345 1.1-2.802 2.277-.546 1.408-1.03 2.816-1.416 4.276-.389 1.474-.454 1.72 1.239.843 4.673-2.424 9.307-4.927 13.964-7.382 1.036-.547 1.566-.84 1.569-1.138.003-.295-.512-.594-1.564-1.15-4.652-2.455-9.333-4.854-13.97-7.338-1.665-.892-1.588-.582-1.146 1.187.339 1.357.833 2.662 1.342 3.964.459 1.172 1.518 2.031 2.796 2.267.588.109 1.173.232 1.758.35z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28SendOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28SendOutline: FC<Icon28SendOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28SendOutline as any).mountIcon = mountIcon;

export default Icon28SendOutline;
