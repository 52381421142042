import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'notebook_check_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="notebook_check_outline_56"><g fill="currentColor"><path d="M38.56 21.44a1.5 1.5 0 010 2.12l-12 12a1.5 1.5 0 01-2.12 0l-6-6a1.5 1.5 0 012.12-2.12l4.94 4.939 10.94-10.94a1.5 1.5 0 012.12 0z" /><path clip-rule="evenodd" d="M20 5.5a1.5 1.5 0 00-3 0v1.608l-.076.006c-1.427.117-2.635.36-3.737.921a9.5 9.5 0 00-4.152 4.152c-.561 1.102-.804 2.31-.921 3.737C8 17.319 8 19.05 8 21.233v14.532c0 2.184 0 3.915.114 5.31.117 1.427.36 2.635.921 3.737a9.5 9.5 0 004.152 4.152c1.102.561 2.31.805 3.737.921 1.395.114 3.126.114 5.31.114h11.532c2.184 0 3.915 0 5.31-.114 1.427-.117 2.635-.36 3.737-.921a9.5 9.5 0 004.152-4.152c.561-1.102.805-2.31.921-3.736C48 39.68 48 37.95 48 35.766V21.234c0-2.184 0-3.915-.114-5.31-.117-1.427-.36-2.635-.921-3.737a9.5 9.5 0 00-4.152-4.152c-1.102-.561-2.31-.804-3.736-.921L39 7.108V5.5a1.5 1.5 0 00-3 0v1.506C35.313 7 34.57 7 33.766 7H29.5V5.5a1.5 1.5 0 00-3 0V7h-4.266c-.804 0-1.547 0-2.234.006zm16 4.506A286.44 286.44 0 0033.7 10h-4.2v1.5a1.5 1.5 0 01-3 0V10h-4.2c-.855 0-1.616 0-2.3.006V11.5a1.5 1.5 0 01-3 0v-1.381c-1.144.104-1.87.293-2.45.59a6.499 6.499 0 00-2.841 2.84c-.31.609-.504 1.378-.605 2.619C11.001 17.427 11 19.035 11 21.3v14.4c0 2.265.001 3.873.104 5.132.101 1.241.294 2.01.605 2.619a6.499 6.499 0 002.84 2.84c.609.31 1.378.504 2.619.605 1.259.103 2.867.104 5.132.104h11.4c2.265 0 3.873-.001 5.132-.104 1.241-.101 2.01-.294 2.619-.605a6.499 6.499 0 002.84-2.84c.31-.609.504-1.378.605-2.619.103-1.259.104-2.867.104-5.132V21.3c0-2.265-.001-3.873-.104-5.132-.101-1.241-.294-2.01-.605-2.619a6.499 6.499 0 00-2.84-2.84c-.581-.297-1.307-.486-2.451-.59V11.5a1.5 1.5 0 01-3 0z" fill-rule="evenodd" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56NotebookCheckOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56NotebookCheckOutline: FC<Icon56NotebookCheckOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56NotebookCheckOutline as any).mountIcon = mountIcon;

export default Icon56NotebookCheckOutline;
