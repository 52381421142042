import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'market_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="market_16"><path d="M8 0a4 4 0 014 4h1.398c.557 0 .759.058.963.167.203.109.363.269.472.472.109.204.167.406.167.963v5.232c0 1.448-.15 1.974-.434 2.503-.283.53-.699.946-1.229 1.229-.53.283-1.055.434-2.503.434H5.166c-1.448 0-1.974-.15-2.503-.434a2.953 2.953 0 01-1.229-1.229C1.151 12.807 1 12.282 1 10.834V5.602c0-.557.058-.759.167-.963.109-.203.269-.363.472-.472.204-.109.406-.167.963-.167H4a4 4 0 014-4zM4.771 5.5H4.73c-.165 0-.29.03-.396.087a.59.59 0 00-.246.246.816.816 0 00-.087.396v.042c0 .165.03.29.087.396a.59.59 0 00.246.246.816.816 0 00.396.087h.042c.165 0 .29-.03.396-.087a.59.59 0 00.246-.246.816.816 0 00.087-.396V6.23c0-.165-.03-.29-.087-.396a.59.59 0 00-.246-.246.816.816 0 00-.396-.087zm6.5 0h-.043c-.163 0-.29.03-.396.087a.591.591 0 00-.245.246.816.816 0 00-.087.396v.042c0 .165.03.29.087.396.056.106.14.19.245.246a.816.816 0 00.396.087h.043c.164 0 .29-.03.396-.087a.591.591 0 00.246-.246.816.816 0 00.087-.396V6.23c0-.165-.03-.29-.087-.396a.591.591 0 00-.245-.246.816.816 0 00-.396-.087zM8 1.5A2.5 2.5 0 005.5 4h5A2.5 2.5 0 008 1.5z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16MarketProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16Market: FC<Icon16MarketProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16Market as any).mountIcon = mountIcon;

export default Icon16Market;
