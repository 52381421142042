import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'calendar_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="calendar_outline_20"><path clip-rule="evenodd" d="M14 1a.75.75 0 01.75.75v.356c.384.061.735.161 1.066.33a4 4 0 011.748 1.748c.24.471.341.982.39 1.57.046.573.046 1.28.046 2.164v4.164c0 .883 0 1.591-.047 2.164-.048.588-.149 1.099-.389 1.57a4 4 0 01-1.748 1.748c-.471.24-.982.341-1.57.39-.573.046-1.281.046-2.164.046H7.918c-.883 0-1.591 0-2.164-.047-.588-.048-1.099-.149-1.57-.389a4 4 0 01-1.748-1.748c-.24-.471-.341-.982-.39-1.57C2 13.673 2 12.965 2 12.082V7.918c0-.883 0-1.591.047-2.164.048-.588.149-1.099.389-1.57a4 4 0 011.748-1.748 3.533 3.533 0 011.066-.33V1.75a.75.75 0 011.5 0v.255C7.101 2 7.489 2 7.918 2h4.164c.43 0 .817 0 1.168.005V1.75A.75.75 0 0114 1zM5.25 3.634v.116a.75.75 0 001.5 0v-.244a80.12 80.12 0 011.2-.006h4.1c.464 0 .859 0 1.2.006v.244a.75.75 0 001.5 0v-.116c.154.038.277.084.385.138a2.5 2.5 0 011.093 1.093c.114.224.19.516.23 1.011.03.366.039.807.041 1.374H3.501c.002-.567.01-1.008.04-1.374.041-.495.117-.787.231-1.011a2.5 2.5 0 011.093-1.093c.108-.054.231-.1.385-.138zM3.5 8.75h13v3.3c0 .922 0 1.57-.042 2.074-.04.495-.116.787-.23 1.011a2.5 2.5 0 01-1.093 1.093c-.224.114-.516.19-1.011.23-.505.041-1.151.042-2.074.042h-4.1c-.922 0-1.57 0-2.074-.042-.495-.04-.787-.116-1.011-.23a2.5 2.5 0 01-1.093-1.093c-.114-.224-.19-.516-.23-1.012-.041-.504-.042-1.15-.042-2.073zM13.75 15a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20CalendarOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20CalendarOutline: FC<Icon20CalendarOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20CalendarOutline as any).mountIcon = mountIcon;

export default Icon20CalendarOutline;
