import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'reply_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="reply_outline_20"><path d="M10.208 3.423c.921.074 1.645.845 1.645 1.785v1.765l.1.021a8.254 8.254 0 016.44 8.917.75.75 0 01-1.342.379l-.175-.22a7.576 7.576 0 00-5.023-2.721v2.08a1.791 1.791 0 01-2.897 1.41l-6.52-5.11a1.793 1.793 0 010-2.82l6.52-5.11a1.792 1.792 0 011.105-.382zm-.147 1.494a.292.292 0 00-.18.062l-6.52 5.11a.292.292 0 000 .459l6.52 5.11a.292.292 0 00.472-.23v-2.873a.75.75 0 01.767-.75l.215.005.305.016a9.064 9.064 0 015.162 2.056l.037.032-.004-.027a6.752 6.752 0 00-5.822-5.532.75.75 0 01-.66-.745V5.208a.292.292 0 00-.292-.291z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20ReplyOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20ReplyOutline: FC<Icon20ReplyOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20ReplyOutline as any).mountIcon = mountIcon;

export default Icon20ReplyOutline;
