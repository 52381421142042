import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'user_square_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="user_square_outline_20"><g stroke="currentColor" stroke-width="1.5"><path clip-rule="evenodd" d="M7.128 2.75c-1.57 0-2.151.137-2.728.446-.52.277-.927.685-1.204 1.204-.309.577-.446 1.157-.446 2.728v5.744c0 1.57.137 2.151.446 2.728.277.52.685.927 1.204 1.204.577.309 1.157.446 2.728.446h5.744c1.57 0 2.151-.137 2.728-.446.52-.277.927-.685 1.204-1.204.309-.577.446-1.157.446-2.728V7.128c0-1.57-.137-2.151-.446-2.728A2.895 2.895 0 0015.6 3.196c-.577-.309-1.157-.446-2.728-.446z" /><circle cx="10" cy="9" r="2.25" /><path d="M15.755 16.75a7 7 0 00-5.75-3 7 7 0 00-5.75 3" /></g><path clip-rule="evenodd" d="M10 7.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM7 9a3 3 0 116 0 3 3 0 01-6 0z" fill="currentColor" fill-rule="evenodd" /><path clip-rule="evenodd" d="M7.128 2c-1.61 0-2.34.138-3.082.534a3.645 3.645 0 00-1.512 1.512C2.138 4.788 2 5.517 2 7.128v5.744c0 1.61.138 2.34.534 3.082.348.65.862 1.164 1.512 1.512.742.396 1.471.534 3.082.534h5.744c1.61 0 2.34-.138 3.082-.534a3.644 3.644 0 001.512-1.512c.396-.742.534-1.472.534-3.082V7.128c0-1.61-.138-2.34-.534-3.082a3.644 3.644 0 00-1.512-1.512C15.212 2.138 14.482 2 12.872 2zm5.744 14.5c.78 0 1.274-.035 1.625-.1a6.239 6.239 0 00-4.492-1.9 6.24 6.24 0 00-4.495 1.902c.352.063.844.098 1.618.098zM4.754 3.857c.412-.22.843-.357 2.374-.357h5.744c1.531 0 1.962.136 2.374.357.389.208.69.508.897.897.22.412.357.843.357 2.374v5.744c0 1.531-.136 1.962-.357 2.374a2.204 2.204 0 01-.287.417A7.741 7.741 0 0010.005 13a7.741 7.741 0 00-5.856 2.668 2.202 2.202 0 01-.292-.422c-.22-.412-.357-.843-.357-2.374V7.128c0-1.531.136-1.962.357-2.374.208-.389.508-.69.897-.897zM7 9a3 3 0 116 0 3 3 0 01-6 0zm3-1.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20UserSquareOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20UserSquareOutline: FC<Icon20UserSquareOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20UserSquareOutline as any).mountIcon = mountIcon;

export default Icon20UserSquareOutline;
