import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = '3_circle_fill_bronze_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="3_circle_fill_bronze_20"><radialGradient id="3_circle_fill_bronze_20_a" cx="0" cy="0" gradientTransform="matrix(0 18.9947 -18.9947 0 10 .623)" gradientUnits="userSpaceOnUse" r="1"><stop offset="0" stop-color="#ffa726" /><stop offset="1" stop-color="#e68000" /></radialGradient><path d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z" fill="url(#3_circle_fill_bronze_20_a)" /><path d="M8.722 10.598h1.237c1.217 0 1.955.608 1.955 1.6 0 .956-.8 1.599-1.935 1.599-1.141 0-1.914-.588-1.989-1.504H6.302c.082 1.784 1.552 2.953 3.698 2.953 2.133 0 3.719-1.23 3.719-2.926 0-1.346-.841-2.214-2.18-2.42v-.116c1.045-.28 1.79-1.066 1.797-2.256.007-1.415-1.176-2.638-3.274-2.638-2.14 0-3.48 1.21-3.569 2.94h1.654c.076-.985.746-1.532 1.819-1.532 1.046 0 1.668.642 1.668 1.456 0 .895-.697 1.524-1.71 1.524H8.723z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon203CircleFillBronzeProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon203CircleFillBronze: FC<Icon203CircleFillBronzeProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon203CircleFillBronze as any).mountIcon = mountIcon;

export default Icon203CircleFillBronze;
