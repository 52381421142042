import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'picture_outline_32';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="picture_outline_32"><g fill="none" fill-rule="evenodd"><path d="M0 0h32v32H0z" /><path d="M12.974 2.9l6.42.001c2.762.023 4.128.307 5.544 1.064a7.46 7.46 0 013.097 3.097c.724 1.355 1.016 2.663 1.06 5.191l.005.72v6.053c0 3.011-.275 4.434-1.065 5.912a7.46 7.46 0 01-3.097 3.097c-1.355.724-2.663 1.016-5.191 1.06l-.72.005h-6.053c-3.011 0-4.434-.275-5.912-1.065a7.46 7.46 0 01-3.097-3.097c-.724-1.355-1.016-2.663-1.06-5.191l-.005-.72.001-6.422c.023-2.761.307-4.127 1.064-5.543a7.46 7.46 0 013.097-3.097c1.478-.79 2.9-1.065 5.912-1.065zm7.595 14.875l-4.67 5.674a1.1 1.1 0 01-1.365.273l-.111-.068-2.876-1.998L7.3 25.574c.247.194.514.368.8.521 1.022.547 2.015.768 4.236.8l.638.005h6.052c2.456 0 3.568-.175 4.595-.664l.28-.141a5.26 5.26 0 002.194-2.195c.235-.44.41-.874.536-1.403l-.007-.004zM19.026 5.1l-6.379.001c-2.23.016-3.288.196-4.268.663l-.28.141A5.26 5.26 0 005.906 8.1c-.596 1.115-.805 2.195-.805 4.874v6.052c0 2.456.175 3.568.664 4.595l.129.257 4.811-4.437a1.1 1.1 0 011.265-.161l.108.066 2.767 1.922 4.707-5.717a1.1 1.1 0 011.421-.24l.104.071 5.814 4.53.006-.248.004-.638v-6.052c0-2.456-.175-3.568-.664-4.595l-.141-.28A5.26 5.26 0 0023.9 5.906c-1.115-.596-2.195-.805-4.874-.805zM12 10a2 2 0 110 4 2 2 0 010-4z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32PictureOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32PictureOutline: FC<Icon32PictureOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32PictureOutline as any).mountIcon = mountIcon;

export default Icon32PictureOutline;
