import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'comment_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="comment_16"><path d="M11.957 3c.595 0 .81.062 1.028.178.217.116.387.287.504.504.116.217.178.433.178 1.027v4.582c0 .594-.062.81-.178 1.027a1.212 1.212 0 01-.504.504c-.218.116-.433.178-1.028.178H9l-2.48 2.48a.5.5 0 01-.853-.354V11h-.958c-.594 0-.81-.062-1.027-.178a1.211 1.211 0 01-.504-.504C3.062 10.1 3 9.885 3 9.29V4.709c0-.594.062-.81.178-1.027.116-.217.287-.388.504-.504C3.9 3.062 4.115 3 4.71 3h7.248z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16CommentProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16Comment: FC<Icon16CommentProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16Comment as any).mountIcon = mountIcon;

export default Icon16Comment;
