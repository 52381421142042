import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'users_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="users_28"><g fill="none" fill-rule="evenodd"><path d="M0 0h28v28H0z" /><path d="M22.5 10c0-1.934-1.566-3.5-3.5-3.5a3.499 3.499 0 00-3.5 3.5c0 1.934 1.566 3.5 3.5 3.5s3.5-1.566 3.5-3.5zm-6.17 5.293A12.72 12.72 0 0119 15c2.47 0 7 .857 7 4.286 0 1.5-.412 1.714-1.235 1.714h-7.01c.163-.487.245-1.06.245-1.714 0-1.69-.627-3.009-1.67-3.993zM12.5 10c0 1.934-1.566 3.5-3.5 3.5A3.499 3.499 0 015.5 10c0-1.934 1.566-3.5 3.5-3.5s3.5 1.566 3.5 3.5zM2 19.286C2 15.857 6.53 15 9 15c2.47 0 7 .857 7 4.286 0 1.5-.412 1.714-1.235 1.714H3.235C2.412 21 2 20.786 2 19.286z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28UsersProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28Users: FC<Icon28UsersProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28Users as any).mountIcon = mountIcon;

export default Icon28Users;
