import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'text_circle_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="text_circle_outline_28"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M11.82 17.186c-.192.643-.466.896-.958.896-.506 0-.854-.308-.854-.772 0-.185.048-.397.164-.746l2.351-6.74c.294-.868.711-1.23 1.49-1.23.773 0 1.183.355 1.477 1.23l2.352 6.74c.096.294.143.52.143.711 0 .493-.348.807-.875.807-.499 0-.786-.26-.97-.896l-.527-1.585H12.34zm2.119-6.733l-1.19 3.774h2.461l-1.183-3.774z" /><path d="M14 2C7.373 2 2 7.373 2 14s5.373 12 12 12 12-5.373 12-12S20.627 2 14 2zM4 14C4 8.477 8.477 4 14 4s10 4.477 10 10-4.477 10-10 10S4 19.523 4 14z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28TextCircleOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28TextCircleOutline: FC<Icon28TextCircleOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28TextCircleOutline as any).mountIcon = mountIcon;

export default Icon28TextCircleOutline;
