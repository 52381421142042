import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 44 44';
const id = 'coins_outline_44';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" id="coins_outline_44"><g fill="none" fill-rule="evenodd"><path d="M0 0h44v44H0z" /><path d="M36.995 29.969C36.793 34.692 30.055 38 22 38c-8.178 0-15-3.411-15-8.25v-15l.005-.219C7.207 9.808 13.945 6.5 22 6.5c8.178 0 15 3.411 15 8.25v15zm-2.994-10.17C31.246 21.794 26.863 23 22 23c-4.862 0-9.245-1.206-12-3.2v2.45c0 2.617 5.266 5.25 12 5.25s12-2.633 12-5.25zM22 9.5c-6.734 0-12 2.633-12 5.25S15.266 20 22 20s12-2.633 12-5.25S28.734 9.5 22 9.5zm12 17.799c-2.754 1.995-7.137 3.201-12 3.201-4.862 0-9.245-1.206-12-3.2v2.45l.005.138C10.185 32.458 15.385 35 22 35c6.734 0 12-2.633 12-5.25z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon44CoinsOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon44CoinsOutline: FC<Icon44CoinsOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 44,
    height: !isNaN(props.height) ? +props.height : 44,
  }));
};

(Icon44CoinsOutline as any).mountIcon = mountIcon;

export default Icon44CoinsOutline;
