import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'mail_outline_56';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="mail_outline_56"><g fill="none" fill-rule="evenodd"><path d="M0 0h56v56H0z" /><path d="M40.026 11c3.12 0 4.252.325 5.393.935a6.36 6.36 0 012.646 2.646c.61 1.14.935 2.272.935 5.393v16.052c0 3.12-.325 4.252-.935 5.393a6.36 6.36 0 01-2.646 2.646c-1.14.61-2.272.935-5.393.935H15.974c-3.12 0-4.252-.325-5.393-.935a6.36 6.36 0 01-2.646-2.646C7.325 40.279 7 39.147 7 36.026V19.974c0-3.12.325-4.252.935-5.393a6.36 6.36 0 012.646-2.646c1.14-.61 2.272-.935 5.393-.935h24.052zM46 22.343l-16.114 7.439a4.5 4.5 0 01-3.772 0L10 22.344v13.682c0 2.392.151 3.176.58 3.978.33.618.798 1.085 1.416 1.416.802.429 1.586.58 3.978.58h24.052c2.392 0 3.176-.151 3.978-.58a3.362 3.362 0 001.416-1.416c.429-.802.58-1.586.58-3.978V22.343zm-5.683-8.342L15.974 14c-2.392 0-3.176.151-3.978.58a3.362 3.362 0 00-1.416 1.416c-.368.688-.531 1.362-.57 3.048l17.361 8.014a1.5 1.5 0 001.258 0l17.361-8.015c-.039-1.685-.203-2.36-.57-3.047a3.362 3.362 0 00-1.416-1.416c-.769-.41-1.52-.567-3.687-.58z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56MailOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56MailOutline: FC<Icon56MailOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56MailOutline as any).mountIcon = mountIcon;

export default Icon56MailOutline;
