import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'hide_circle_fill_black_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="hide_circle_fill_black_28"><linearGradient id="hide_circle_fill_black_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#626466" /><stop offset="1" stop-color="#444647" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#hide_circle_fill_black_28_a)" /><path clip-rule="evenodd" d="M9.636 8.364a.9.9 0 00-1.272 1.272l1.06 1.062C7.893 11.915 7 13.436 7 14c0 1 2.8 5 7 5 1.112 0 2.126-.28 3.016-.711l1.348 1.347a.9.9 0 001.272-1.272zm6.12 8.665l-1.544-1.544a1.5 1.5 0 01-1.697-1.697l-1.544-1.544a3.5 3.5 0 004.784 4.784zM21 14c0-1-2.8-5-7-5-.54 0-1.055.066-1.545.183l1.325 1.324a3.5 3.5 0 013.714 3.713l2.119 2.12C20.503 15.375 21 14.421 21 14z" fill="#fff" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28HideCircleFillBlackProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28HideCircleFillBlack: FC<Icon28HideCircleFillBlackProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28HideCircleFillBlack as any).mountIcon = mountIcon;

export default Icon28HideCircleFillBlack;
