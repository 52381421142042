import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'bookmark_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="bookmark_outline_28"><path clip-rule="evenodd" d="M12.157 3c-1.224 0-2.203 0-2.994.065-.812.066-1.514.205-2.16.534A5.5 5.5 0 004.6 6.003c-.329.646-.468 1.348-.534 2.16C4 8.954 4 9.933 4 11.157v12.322c0 2.026 2.284 3.21 3.94 2.043l5.772-4.069a.5.5 0 01.576 0l5.772 4.069c1.656 1.168 3.94-.017 3.94-2.043V11.157c0-1.224 0-2.203-.065-2.994-.066-.812-.205-1.514-.534-2.16A5.5 5.5 0 0020.997 3.6c-.646-.329-1.348-.468-2.16-.534C18.046 3 17.067 3 15.843 3zM6 23.479a.5.5 0 00.788.408l5.772-4.069a2.5 2.5 0 012.88 0l5.772 4.07a.5.5 0 00.788-.41V11.2c0-1.277 0-2.174-.058-2.874-.056-.689-.162-1.098-.324-1.415a3.5 3.5 0 00-1.529-1.53c-.317-.161-.726-.267-1.415-.323C17.974 5.001 17.077 5 15.8 5h-3.6c-1.277 0-2.174 0-2.874.058-.689.056-1.098.162-1.415.323a3.5 3.5 0 00-1.53 1.53c-.161.317-.267.726-.323 1.415C6.001 9.026 6 9.923 6 11.2z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28BookmarkOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28BookmarkOutline: FC<Icon28BookmarkOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28BookmarkOutline as any).mountIcon = mountIcon;

export default Icon28BookmarkOutline;
