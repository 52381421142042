import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'smile_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="smile_outline_20"><path d="M10 1.5a8.5 8.5 0 110 17 8.5 8.5 0 010-17zM10 3a7 7 0 100 14 7 7 0 000-14zm3.067 9.056c.3.286.312.76.027 1.06a4.06 4.06 0 01-.855.654A4.356 4.356 0 0110 14.4a4.355 4.355 0 01-2.239-.63 4.06 4.06 0 01-.854-.653.75.75 0 011.004-1.109l.197.18c.098.083.239.186.417.292.446.263.942.42 1.475.42.533 0 1.029-.157 1.475-.42.178-.106.32-.21.417-.292l.115-.105a.75.75 0 011.06-.027zM7.625 7.25a1.125 1.125 0 110 2.25 1.125 1.125 0 010-2.25zm4.75 0a1.125 1.125 0 110 2.25 1.125 1.125 0 010-2.25z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20SmileOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20SmileOutline: FC<Icon20SmileOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20SmileOutline as any).mountIcon = mountIcon;

export default Icon20SmileOutline;
