import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'diamond_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="diamond_outline_28"><path clip-rule="evenodd" d="M10.063 4a1 1 0 00-.442.103L5.57 6.097a.995.995 0 00-.431.393l-2.985 4.975a.946.946 0 00-.11.237.994.994 0 00.118.845c.04.063.089.121.144.174l10.98 10.98.04.039a.996.996 0 00.601.257l.024.002h.002a.995.995 0 00.759-.297l10.98-10.98a.993.993 0 00.303-.808.993.993 0 00-.19-.506L22.32 6.427a.996.996 0 00-.348-.31l-3.544-1.989a1 1 0 00-.49-.128zM21.12 8.202l-3.39 1.233 1.205 3.214.003.008 4.432-1.24zM16.932 13l-1.125-3h-3.614l-1.125 3zm-7.871-.343c0-.003.002-.005.003-.008l1.19-3.177-3.81-1.27-1.912 3.188zM8.627 6.82L10.295 6h7.381l1.434.805L15.824 8h-3.662zm2.272 11.664L6.405 13.99l2.851.797zM14 20.538L11.539 15h4.922zm3.101-2.053l1.643-3.697 2.85-.797z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28DiamondOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28DiamondOutline: FC<Icon28DiamondOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28DiamondOutline as any).mountIcon = mountIcon;

export default Icon28DiamondOutline;
