import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'donate_circle_fill_yellow_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="donate_circle_fill_yellow_28"><linearGradient id="donate_circle_fill_yellow_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#ffb73d" /><stop offset="1" stop-color="#ffa000" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#donate_circle_fill_yellow_28_a)" /><path clip-rule="evenodd" d="M14 6a8 8 0 110 16 8 8 0 010-16zm.147 3.454a.333.333 0 00-.446.152l-1.268 2.57-2.837.412a.334.334 0 00-.185.569l2.053 2-.485 2.825a.333.333 0 00.484.352L14 17l2.537 1.334a.333.333 0 00.484-.352l-.485-2.825 2.053-2a.334.334 0 00-.185-.569l-2.837-.412-1.268-2.57a.333.333 0 00-.151-.152z" fill="#fff" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28DonateCircleFillYellowProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28DonateCircleFillYellow: FC<Icon28DonateCircleFillYellowProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28DonateCircleFillYellow as any).mountIcon = mountIcon;

export default Icon28DonateCircleFillYellow;
