import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'delete_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="delete_outline_28"><g fill-rule="nonzero" fill="none"><path d="M0 0h28v28H0z" /><path d="M14 1.5c2.376 0 3.925 1.234 4.422 3.5H24.5a1 1 0 010 2h-1.105l-1.612 15.314A3 3 0 0118.799 25H9.201a3 3 0 01-2.984-2.686L4.605 7H3.5a1 1 0 110-2h6.078c.497-2.266 2.046-3.5 4.422-3.5zM21.383 7H6.616l1.59 15.105a1 1 0 00.995.895h9.598a1 1 0 00.995-.895L21.383 7zM14 9a1 1 0 011 1v10a1 1 0 01-2 0V10a1 1 0 011-1zm4.05.001a1 1 0 01.949 1.049l-.5 10a1 1 0 11-1.998-.1l.5-10a1 1 0 011.049-.949zm-8.1 0a1 1 0 011.049.95l.5 10a1 1 0 11-1.998.099l-.5-10A1 1 0 019.951 9zM14 3.5c-1.267 0-2 .454-2.352 1.5h4.704C16 3.954 15.267 3.5 14 3.5z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28DeleteOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28DeleteOutline: FC<Icon28DeleteOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28DeleteOutline as any).mountIcon = mountIcon;

export default Icon28DeleteOutline;
