import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'rotate_right_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="rotate_right_20"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.2 9.5h-2.4c-.865 0-1.423.001-1.848.036-.408.033-.559.09-.633.127a1.5 1.5 0 00-.656.656c-.037.074-.094.225-.127.633-.035.425-.036.983-.036 1.848v.4c0 .865.001 1.423.036 1.848.033.408.09.559.127.633a1.5 1.5 0 00.656.656c.074.037.225.094.633.127.425.035.983.036 1.848.036h2.4c.865 0 1.423-.001 1.848-.036.408-.033.559-.09.633-.127a1.5 1.5 0 00.656-.656c.037-.074.094-.225.127-.633.035-.425.036-.983.036-1.848v-.4c0-.865-.001-1.423-.036-1.848-.033-.408-.09-.559-.127-.633a1.5 1.5 0 00-.656-.656c-.074-.037-.225-.094-.633-.127-.425-.035-.983-.036-1.848-.036zm-6.873.138C6 10.28 6 11.12 6 12.8v.4c0 1.68 0 2.52.327 3.162a3 3 0 001.311 1.311C8.28 18 9.12 18 10.8 18h2.4c1.68 0 2.52 0 3.162-.327a3 3 0 001.311-1.311C18 15.72 18 14.88 18 13.2v-.4c0-1.68 0-2.52-.327-3.162a3 3 0 00-1.311-1.311C15.72 8 14.88 8 13.2 8h-2.4c-1.68 0-2.52 0-3.162.327a3 3 0 00-1.311 1.311zM6.47 1.97a.75.75 0 011.06 0l2 2a.75.75 0 010 1.06l-2 2a.75.75 0 01-1.06-1.06l.72-.72H7A3.25 3.25 0 003.75 8.5V11a.75.75 0 01-1.5 0V8.5A4.75 4.75 0 017 3.75h.19l-.72-.72a.75.75 0 010-1.06z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20RotateRightProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20RotateRight: FC<Icon20RotateRightProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20RotateRight as any).mountIcon = mountIcon;

export default Icon20RotateRight;
