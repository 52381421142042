import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'hashtag_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="hashtag_outline_28"><path d="M20.196 3.02a1 1 0 01.8 1.06l-.015.116L20.019 9H23a1 1 0 01.117 1.993L23 11h-3.381l-1.2 6H21a1 1 0 01.117 1.993L21 19h-2.981l-1.038 5.196a1 1 0 01-1.978-.276l.016-.116L15.98 19h-5.961l-1.038 5.196a1 1 0 01-1.978-.276l.016-.116L7.98 19H5a1 1 0 01-.117-1.993L5 17h3.38l1.2-6H7a1 1 0 01-.117-1.993L7 9h2.98l1.04-5.196a1 1 0 011.977.276l-.016.116L12.019 9h5.961l1.04-5.196a1 1 0 011.176-.785zM17.58 11h-5.961l-1.2 6h5.961l1.2-6z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28HashtagOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28HashtagOutline: FC<Icon28HashtagOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28HashtagOutline as any).mountIcon = mountIcon;

export default Icon28HashtagOutline;
