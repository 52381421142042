import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'podcast_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="podcast_24"><path d="M12 5c.92 0 1.783.249 2.524.683a5.026 5.026 0 011.793 1.793C16.751 8.217 17 9.08 17 10v6c0 .92-.249 1.783-.683 2.524a5.027 5.027 0 01-1.793 1.793A4.969 4.969 0 0113 20.9v1.2a.9.9 0 01-.9.9h-.2a.9.9 0 01-.9-.9l-.001-1.2a4.967 4.967 0 01-1.523-.583 5.026 5.026 0 01-1.793-1.793A4.977 4.977 0 017 16v-6c0-.92.249-1.783.683-2.524a5.025 5.025 0 011.793-1.793A4.977 4.977 0 0112 5zm0 1.75c-.586 0-1.146.154-1.64.443A3.275 3.275 0 009.194 8.36 3.226 3.226 0 008.75 10h1.756c.21 0 .387.042.534.12a.818.818 0 01.34.34c.078.147.12.324.12.534v.012c0 .21-.042.387-.12.534a.818.818 0 01-.34.34c-.147.078-.324.12-.534.12H8.75v2h1.756c.21 0 .387.042.534.12a.818.818 0 01.34.34c.078.147.12.324.12.534v.012c0 .21-.042.387-.12.534a.818.818 0 01-.34.34c-.147.078-.324.12-.534.12H8.75l.007.219c.034.506.183.989.436 1.42.282.482.686.886 1.168 1.168A3.227 3.227 0 0012 19.25c.586 0 1.146-.154 1.64-.443a3.277 3.277 0 001.167-1.168 3.227 3.227 0 00.443-1.638L14.744 16c-.21 0-.387-.042-.534-.12a.818.818 0 01-.34-.34 1.117 1.117 0 01-.12-.534v-.012c0-.21.042-.387.12-.534a.818.818 0 01.34-.34c.147-.078.324-.12.534-.12h.506v-2.001l-.506.001c-.21 0-.387-.042-.534-.12a.818.818 0 01-.34-.34 1.117 1.117 0 01-.12-.534v-.012c0-.21.042-.387.12-.534a.818.818 0 01.34-.34c.147-.078.324-.12.534-.12l.506-.001-.007-.218a3.22 3.22 0 00-.436-1.42 3.276 3.276 0 00-1.168-1.168A3.227 3.227 0 0012 6.75zM12 .5c5.029 0 9.145 3.907 9.478 8.851.27.204.48.484.598.818.283.802.424 1.746.424 2.831s-.141 2.029-.424 2.831A1.75 1.75 0 0120.426 17H20a1.5 1.5 0 01-1.5-1.5v-5a1.5 1.5 0 011.19-1.468 7.752 7.752 0 00-15.38.002c.68.14 1.19.744 1.19 1.466v5A1.5 1.5 0 014 17h-.426a1.75 1.75 0 01-1.65-1.169C1.64 15.03 1.5 14.085 1.5 13s.141-2.029.424-2.831c.117-.333.329-.614.598-.817C2.855 4.407 6.972.5 12 .5z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24PodcastProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Podcast: FC<Icon24PodcastProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Podcast as any).mountIcon = mountIcon;

export default Icon24Podcast;
