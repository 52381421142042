import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'globe_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="globe_outline_24"><path clip-rule="evenodd" d="M3.9 12c0-.726.096-1.43.275-2.1h4.05a17.728 17.728 0 000 4.2h-4.05A8.111 8.111 0 013.9 12zm4.632 3.9H4.899a8.119 8.119 0 004.947 3.91 19.61 19.61 0 01-1.314-3.91zm5.622 3.91A8.119 8.119 0 0019.1 15.9h-3.633a19.612 19.612 0 01-1.314 3.91zm-.534-3.91A17.81 17.81 0 0112 20.098a17.81 17.81 0 01-1.62-4.198zm.34-1.8h-3.92a15.967 15.967 0 010-4.2h3.92a15.971 15.971 0 010 4.2zm1.814 0h4.051c.18-.67.275-1.374.275-2.1s-.096-1.43-.275-2.1h-4.05a17.72 17.72 0 010 4.2zm-.306-6h3.633a8.119 8.119 0 00-4.947-3.91 19.61 19.61 0 011.314 3.91zM9.846 4.19A8.119 8.119 0 004.9 8.1h3.633a19.608 19.608 0 011.314-3.91zm.534 3.91h3.24A17.811 17.811 0 0012 3.902 17.811 17.811 0 0010.38 8.1zm1.62-6c-5.468 0-9.9 4.432-9.9 9.9s4.432 9.9 9.9 9.9 9.9-4.432 9.9-9.9-4.432-9.9-9.9-9.9z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24GlobeOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24GlobeOutline: FC<Icon24GlobeOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24GlobeOutline as any).mountIcon = mountIcon;

export default Icon24GlobeOutline;
