import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'advertising_circle_fill_red_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="advertising_circle_fill_red_28"><linearGradient id="advertising_circle_fill_red_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#ff5263" /><stop offset="1" stop-color="#ff3347" /></linearGradient><path d="M14 28c7.732 0 14-6.268 14-14S21.732 0 14 0 0 6.268 0 14s6.268 14 14 14z" fill="url(#advertising_circle_fill_red_28_a)" /><g fill="#fff"><path d="M19.7 19c-.169 0-.389-.05-.529-.149L16.732 16.7s-.123-.11-.152-.146a.35.35 0 01-.062-.122.52.52 0 01-.018-.143v-5.523a.68.68 0 01.019-.205.35.35 0 01.062-.122c.029-.037.152-.149.152-.149l2.438-2.135c.141-.1.371-.152.529-.155a.8.8 0 01.8.8v9.4a.8.8 0 01-.8.8zM14.7 10.5a.3.3 0 01.3.3v5.4a.3.3 0 01-.3.3l-2.2.003c0 .606.326 1.372.583 1.697.391.493.286 1.12-.182 1.53-.47.412-1.344.345-1.734-.148-.59-.743-1.167-1.93-1.167-3.08l-.5-.002a3 3 0 110-6z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28AdvertisingCircleFillRedProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28AdvertisingCircleFillRed: FC<Icon28AdvertisingCircleFillRedProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28AdvertisingCircleFillRed as any).mountIcon = mountIcon;

export default Icon28AdvertisingCircleFillRed;
