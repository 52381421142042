import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'users_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="users_36"><path d="M28.929 12.857c0-2.486-2.014-4.5-4.5-4.5a4.499 4.499 0 00-4.5 4.5c0 2.486 2.013 4.5 4.5 4.5 2.486 0 4.5-2.014 4.5-4.5zm-4.5 6.429c3.176 0 9 1.102 9 5.51 0 1.928-.53 2.204-1.589 2.204h-9.012c.209-.626.315-1.363.315-2.204 0-2.171-.807-3.868-2.147-5.133a16.304 16.304 0 013.433-.377zm-12.858 0c3.177 0 9 1.102 9 5.51 0 1.928-.529 2.204-1.588 2.204H4.16c-1.06 0-1.589-.276-1.589-2.204 0-4.408 5.824-5.51 9-5.51zm0-10.929c2.487 0 4.5 2.014 4.5 4.5s-2.013 4.5-4.5 4.5a4.499 4.499 0 01-4.5-4.5c0-2.486 2.014-4.5 4.5-4.5z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36UsersProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36Users: FC<Icon36UsersProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36Users as any).mountIcon = mountIcon;

export default Icon36Users;
