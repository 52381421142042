import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'clip_circle_fill_violet_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="clip_circle_fill_violet_28"><linearGradient id="clip_circle_fill_violet_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#c48aff" /><stop offset="1" stop-color="#aa65f0" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#clip_circle_fill_violet_28_a)" /><g fill="#fff"><path d="M11.787 6.957l.912 3.04c.087.288.497.283.577-.008l.957-3.51a1.33 1.33 0 012.603.497l-.452 4.064a4.204 4.204 0 013.54 3.365l-.424-.246c-1.784-1.032-4 .263-4 2.31v4.362c0 .137.01.27.029.4a6.3 6.3 0 01-1.829.269h-.2c-3.5 0-6-2.35-6-5.25v-.178A3.073 3.073 0 0110.573 13h.694a.998.998 0 01.242 1.967l-.252.063a1 1 0 00.485 1.94l.252-.063A2.998 2.998 0 0011.267 11h-.194c-.52 0-1.02.078-1.493.223l-.414-3.732a1.343 1.343 0 012.621-.534z" /><path d="M22.271 19.228a.668.668 0 000-1.156L18.5 15.89a.666.666 0 00-.999.578v4.364c0 .513.555.834.999.578z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28ClipCircleFillVioletProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28ClipCircleFillViolet: FC<Icon28ClipCircleFillVioletProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28ClipCircleFillViolet as any).mountIcon = mountIcon;

export default Icon28ClipCircleFillViolet;
