import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'list_like_circle_fill_blue_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="list_like_circle_fill_blue_20"><linearGradient id="list_like_circle_fill_blue_20_a" gradientUnits="userSpaceOnUse" x1="-10" x2="10" y1="10" y2="30"><stop offset="0" stop-color="#70b2ff" /><stop offset="1" stop-color="#5c9ce6" /></linearGradient><path d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z" fill="url(#list_like_circle_fill_blue_20_a)" /><path d="M14.58 9c1.198 0 2.17.947 2.17 2.114 0 1.18-.485 1.77-2.542 3.33l-.937.708a.66.66 0 01-.793 0l-.936-.709C9.485 12.885 9 12.293 9 11.114 9 9.947 9.972 9 11.17 9c.643 0 1.214.295 1.705.864.491-.57 1.062-.864 1.704-.864zm-6.83 4.5a.75.75 0 010 1.5h-2.5a.75.75 0 010-1.5zm-1-4a.75.75 0 010 1.5h-1.5a.75.75 0 010-1.5zm8-4a.75.75 0 010 1.5h-9.5a.75.75 0 010-1.5z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20ListLikeCircleFillBlueProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20ListLikeCircleFillBlue: FC<Icon20ListLikeCircleFillBlueProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20ListLikeCircleFillBlue as any).mountIcon = mountIcon;

export default Icon20ListLikeCircleFillBlue;
