import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'message_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="message_outline_24"><path d="M7.713 18.202a.9.9 0 011-.259A9.364 9.364 0 0012 18.53c4.502 0 8.1-3.085 8.1-6.815S16.502 4.9 12 4.9s-8.1 3.084-8.1 6.814c0 1.283.423 2.515 1.214 3.586a.9.9 0 01.176.538c-.003.893-.376 2.179-1.114 3.915 1.654-.184 2.82-.71 3.537-1.551zm-4.324 3.413c-.914.036-1.594-.943-1.209-1.795.776-1.715 1.203-2.974 1.292-3.724-.891-1.312-1.372-2.815-1.372-4.382C2.1 6.924 6.56 3.1 12 3.1s9.9 3.823 9.9 8.614-4.46 8.615-9.9 8.615c-1.157 0-2.287-.173-3.353-.506-1.21 1.116-2.975 1.703-5.258 1.792z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24MessageOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24MessageOutline: FC<Icon24MessageOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24MessageOutline as any).mountIcon = mountIcon;

export default Icon24MessageOutline;
