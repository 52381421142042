import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'image_filter_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="image_filter_outline_24"><path d="M8.983 15.8a3.8 3.8 0 000-7.599 7.166 7.166 0 00-1.083 3.8c0 1.394.396 2.696 1.083 3.798z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M8.9 4.6A7.4 7.4 0 1012 18.721a7.4 7.4 0 100-13.443A7.372 7.372 0 008.9 4.6zM3.3 12a5.6 5.6 0 1111.2 0 5.6 5.6 0 01-11.2 0zm10.586-5.468A7.381 7.381 0 0116.3 12c0 2.166-.93 4.115-2.414 5.468a5.6 5.6 0 100-10.936z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24ImageFilterOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24ImageFilterOutline: FC<Icon24ImageFilterOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24ImageFilterOutline as any).mountIcon = mountIcon;

export default Icon24ImageFilterOutline;
