import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'place_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="place_outline_24"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M12 13a3.5 3.5 0 100-7 3.5 3.5 0 000 7zm0-1.8a1.7 1.7 0 100-3.4 1.7 1.7 0 000 3.4z" /><path d="M4.243 9.77C4.243 5.48 7.714 2 12 2s7.757 3.48 7.757 7.77c0 3.133-1.974 6.853-5.565 11.098a2.871 2.871 0 01-4.384 0l-.567-.67.005-.006C6.012 16.227 4.243 12.736 4.243 9.77zm6.942 9.94a1.071 1.071 0 001.633-.005c3.545-4.191 5.14-7.483 5.14-9.935 0-3.299-2.67-5.97-5.958-5.97-3.288 0-5.957 2.671-5.957 5.97 0 2.452 1.594 5.744 5.139 9.935z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24PlaceOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24PlaceOutline: FC<Icon24PlaceOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24PlaceOutline as any).mountIcon = mountIcon;

export default Icon24PlaceOutline;
