import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'flip_portrait_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="flip_portrait_outline_56"><path d="M25 12.628c0-1.852.183-2.801.712-3.79a5.135 5.135 0 012.127-2.126C28.827 6.183 29.776 6 31.628 6h12.744c1.852 0 2.801.183 3.79.712a5.135 5.135 0 012.127 2.127c.528.988.711 1.937.711 3.789v30.744c0 1.852-.183 2.801-.712 3.79a5.136 5.136 0 01-2.127 2.127c-.988.528-1.937.711-3.789.711H31.628c-1.852 0-2.801-.183-3.79-.712a5.136 5.136 0 01-2.127-2.127c-.528-.988-.711-1.937-.711-3.789V12.628zm3 0v30.744c0 1.4.093 1.88.357 2.374.209.39.506.688.897.897.493.264.975.357 2.374.357h12.744c1.4 0 1.88-.093 2.374-.357.39-.209.688-.506.897-.897.264-.494.357-.975.357-2.374V12.628c0-1.4-.093-1.88-.357-2.374a2.135 2.135 0 00-.897-.897C46.252 9.093 45.771 9 44.372 9H31.628c-1.4 0-1.88.093-2.374.357-.39.209-.688.506-.897.897-.264.494-.357.975-.357 2.374zm-23 18c0-1.852.183-2.801.712-3.79a5.135 5.135 0 012.127-2.127c.988-.528 1.937-.711 3.789-.711H20.5a1.5 1.5 0 010 3h-8.872c-1.4 0-1.88.093-2.374.357-.39.209-.688.506-.897.897-.264.493-.357.975-.357 2.374V44.5a2.5 2.5 0 002.5 2.5h10a1.5 1.5 0 010 3h-10A5.5 5.5 0 015 44.5V30.628zM9 16.5a5.5 5.5 0 015.5-5.5h2.738l-.877-1.024a1.5 1.5 0 01.051-2.01l.112-.105a1.5 1.5 0 012.115.163l3 3.5a1.5 1.5 0 010 1.952l-3 3.5a1.5 1.5 0 01-2.278-1.952L17.24 14H14.5a2.5 2.5 0 00-2.495 2.336L12 16.5v3a1.5 1.5 0 01-3 0v-3z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56FlipPortraitOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56FlipPortraitOutline: FC<Icon56FlipPortraitOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56FlipPortraitOutline as any).mountIcon = mountIcon;

export default Icon56FlipPortraitOutline;
