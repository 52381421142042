import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'hide_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="hide_outline_20"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M9.964 5.5A.75.75 0 019.958 4a11.921 11.921 0 015.918 1.578C17.566 6.558 19 8.068 19 10c0 1.003-.393 1.901-.999 2.659a.75.75 0 11-1.171-.937c.442-.553.67-1.13.67-1.722 0-1.13-.858-2.245-2.376-3.125A10.411 10.411 0 009.964 5.5zM4.124 14.422C5.844 15.42 8.036 16 10 16c1.605 0 3.355-.388 4.873-1.066l.847.846a.75.75 0 101.06-1.06L5.28 3.22a.75.75 0 00-1.06 1.06l.823.824c-.974.445-1.87 1.02-2.57 1.712C1.606 7.672 1 8.752 1 10c0 1.932 1.433 3.443 3.124 4.422zM8.5 10a1.5 1.5 0 002.53 1.09L8.91 8.97c-.254.269-.41.631-.41 1.03zM7 10a3 3 0 005.09 2.151l1.635 1.635c-1.201.458-2.523.714-3.725.714-1.702 0-3.635-.513-5.124-1.375C3.358 12.245 2.5 11.13 2.5 10c0-.731.35-1.448 1.028-2.117.66-.653 1.593-1.217 2.66-1.635l1.66 1.661A2.99 2.99 0 007 10z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20HideOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20HideOutline: FC<Icon20HideOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20HideOutline as any).mountIcon = mountIcon;

export default Icon20HideOutline;
