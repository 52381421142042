import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'podcast_circle_fill_red_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="podcast_circle_fill_red_28"><linearGradient id="podcast_circle_fill_red_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#ff5263" /><stop offset="1" stop-color="#ff3347" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#podcast_circle_fill_red_28_a)" /><path clip-rule="evenodd" d="M14 5.5a7.503 7.503 0 017.35 6.003A1.5 1.5 0 0122.75 13v3.5a1.5 1.5 0 01-3 0V13l-.004-.22A5.75 5.75 0 008.25 13v3.5a1.5 1.5 0 01-3 0V13a1.5 1.5 0 011.4-1.497A7.503 7.503 0 0114 5.5zm-2.828 4.672C11.895 9.449 12.896 9 14 9s2.105.449 2.828 1.172A3.99 3.99 0 0118 13v4a3.99 3.99 0 01-1.172 2.828 3.995 3.995 0 01-1.978 1.081v.256a.937.937 0 01-.1.451.681.681 0 01-.284.284.935.935 0 01-.45.1h-.031a.935.935 0 01-.451-.1c-.123-.065-.218-.161-.284-.284s-.1-.269-.1-.45v-.257a3.995 3.995 0 01-1.978-1.08A3.99 3.99 0 0110 17v-4a3.99 3.99 0 011.172-2.828zM14 19.5a2.49 2.49 0 001.768-.732A2.49 2.49 0 0016.5 17h-.305a.775.775 0 01-.388-.08.546.546 0 01-.227-.227.775.775 0 01-.08-.388v-.11c0-.178.028-.29.08-.388a.546.546 0 01.227-.227.775.775 0 01.388-.08h.305v-1h-.305a.775.775 0 01-.388-.08.546.546 0 01-.227-.227.775.775 0 01-.08-.388v-.11c0-.178.028-.29.08-.388a.546.546 0 01.227-.227.775.775 0 01.388-.08h.305a2.49 2.49 0 00-.732-1.768C15.314 10.78 14.69 10.5 14 10.5s-1.315.279-1.768.732A2.49 2.49 0 0011.5 13h1.305c.178 0 .29.028.388.08.098.052.175.13.227.227.052.098.08.21.08.388v.11c0 .178-.028.29-.08.388a.546.546 0 01-.227.227.775.775 0 01-.388.08H11.5v1h1.305c.178 0 .29.028.388.08.098.052.175.13.227.227.052.098.08.21.08.388v.11c0 .178-.028.29-.08.388a.546.546 0 01-.227.227.775.775 0 01-.388.08H11.5c0 .69.279 1.315.732 1.768A2.49 2.49 0 0014 19.5z" fill="#fff" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28PodcastCircleFillRedProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28PodcastCircleFillRed: FC<Icon28PodcastCircleFillRedProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28PodcastCircleFillRed as any).mountIcon = mountIcon;

export default Icon28PodcastCircleFillRed;
