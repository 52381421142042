import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 17 16';
const id = 'clock_ourline_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" id="clock_ourline_16"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M8.02 14.5a6.5 6.5 0 100-13 6.5 6.5 0 000 13zm0 1.5a8 8 0 100-16 8 8 0 000 16z" /><path d="M10.67 11.085a.75.75 0 01-1.005.336l-2.5-1.25A.75.75 0 016.75 9.5V5.25a.75.75 0 011.5 0v3.786l2.085 1.043a.75.75 0 01.336 1.006z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16ClockOurlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16ClockOurline: FC<Icon16ClockOurlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 17,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16ClockOurline as any).mountIcon = mountIcon;

export default Icon16ClockOurline;
