import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'masks_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="masks_outline_28"><g fill="currentColor"><path d="M16.285 15.84c-.485.25-1.177.608-2.285.608s-1.8-.357-2.285-.608c-.447-.23-.719-.37-.979-.062-.544.644.544 3.22 3.264 3.22s3.808-2.576 3.264-3.22c-.26-.309-.533-.168-.979.062zm-5.474-5.832c.937.08 1.647.701 1.585 1.387-.061.686-.87 1.176-1.807 1.097-.937-.08-1.647-.701-1.585-1.387.061-.686.87-1.176 1.807-1.097zm8.185 1.097c-.061-.686-.87-1.176-1.807-1.097-.937.08-1.647.701-1.585 1.387.061.686.87 1.176 1.807 1.097.937-.08 1.647-.701 1.585-1.387z" /><path clip-rule="evenodd" d="M23.443 4.262c2.36 2.44 1.77 9.15 0 13.419S18.13 25 14 25c-4.131 0-7.672-3.05-9.443-7.32-1.77-4.269-2.36-10.978 0-13.418C6.11 2.657 7.663 2.908 9.89 3.267c1.157.187 2.496.404 4.111.404s2.954-.217 4.111-.404c2.226-.359 3.779-.61 5.332.995zm-1.437 1.391c.253.262.536.762.737 1.6.197.822.283 1.838.25 2.974-.067 2.282-.609 4.786-1.397 6.688C20.024 20.705 17.068 23 14 23s-6.024-2.295-7.596-6.085c-.788-1.902-1.33-4.406-1.397-6.688-.033-1.136.053-2.152.25-2.973.201-.839.484-1.34.737-1.601.37-.381.653-.517.893-.582.28-.076.66-.098 1.295-.03.301.031.694.094 1.157.168 1.204.193 2.882.462 4.661.462 1.78 0 3.457-.269 4.661-.462.463-.074.856-.137 1.157-.169.635-.067 1.014-.045 1.295.031.24.065.524.2.893.582z" fill-rule="evenodd" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28MasksOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28MasksOutline: FC<Icon28MasksOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28MasksOutline as any).mountIcon = mountIcon;

export default Icon28MasksOutline;
