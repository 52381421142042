import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'advertising_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="advertising_outline_20"><path clip-rule="evenodd" d="M18 5.206c0-1.767-1.944-2.844-3.442-1.908L11.035 5.5H6a4 4 0 00-.44 7.976l.28 1.961a2.111 2.111 0 004.18-.597L9.83 13.5h1.206l3.522 2.202c1.5.936 3.443-.141 3.443-1.908zM8.314 13.5H7.079l.247 1.725a.611.611 0 001.21-.173zM6 7h4.5v5H6a2.5 2.5 0 010-5zm9.352 7.43L12 12.334V6.666l3.353-2.096a.75.75 0 011.147.636v8.588a.75.75 0 01-1.147.636z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20AdvertisingOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20AdvertisingOutline: FC<Icon20AdvertisingOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20AdvertisingOutline as any).mountIcon = mountIcon;

export default Icon20AdvertisingOutline;
