import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'clip_circle_fill_violet_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="clip_circle_fill_violet_20"><linearGradient id="clip_circle_fill_violet_20_a" gradientUnits="userSpaceOnUse" x1="-10" x2="10" y1="10" y2="30"><stop offset="0" stop-color="#c48aff" /><stop offset="1" stop-color="#aa65f0" /></linearGradient><path d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10z" fill="url(#clip_circle_fill_violet_20_a)" /><path clip-rule="evenodd" d="M8.974 6.578l-.478-2.03a1.358 1.358 0 00-2.678.375l.14 2.941c.3-.074.616-.114.94-.114h.523a2.523 2.523 0 01.798 4.917l-.132.045a.75.75 0 11-.474-1.423l.132-.045a1.023 1.023 0 00-.324-1.994h-.523a2.398 2.398 0 00-2.397 2.336A4.5 4.5 0 009 16h1.205c.157 0 .313-.008.467-.022a2.304 2.304 0 01-.172-.881v-2.794c0-1.765 1.913-2.886 3.454-1.99L15 10.92a3.65 3.65 0 00-2.294-3.338.292.292 0 01-.182-.304l.313-2.809a1.324 1.324 0 00-2.597-.483l-.684 2.599c-.08.302-.51.296-.582-.008zm6.628 7.816a.803.803 0 000-1.388L13.2 11.61a.8.8 0 00-1.2.694v2.794a.8.8 0 001.2.694z" fill="#fff" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20ClipCircleFillVioletProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20ClipCircleFillViolet: FC<Icon20ClipCircleFillVioletProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20ClipCircleFillViolet as any).mountIcon = mountIcon;

export default Icon20ClipCircleFillViolet;
