import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'votest_transfer_circle_fill_turquoise_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="votest_transfer_circle_fill_turquoise_20"><linearGradient id="votest_transfer_circle_fill_turquoise_20_a" gradientUnits="userSpaceOnUse" x1="30" x2="10" y1="10" y2="-10"><stop offset="0" stop-color="#65c2c2" /><stop offset="1" stop-color="#86d1d1" /></linearGradient><path d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z" fill="url(#votest_transfer_circle_fill_turquoise_20_a)" /><path d="M14.979 12.72c.014.092.021.186.021.28 0 1.657-2.239 3-5 3s-5-1.343-5-3c0-.094.007-.188.021-.28C6.163 13.802 7.97 14.5 10 14.5c2.02 0 3.817-.69 4.96-1.761zm0-2.998c.014.092.021.185.021.28 0 1.656-2.239 3-5 3s-5-1.344-5-3c0-.095.007-.188.021-.28 1.142 1.081 2.948 1.78 4.979 1.78 2.02 0 3.817-.691 4.96-1.762zM10 4c2.761 0 5 1.343 5 3s-2.239 3-5 3-5-1.343-5-3 2.239-3 5-3z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20VotestTransferCircleFillTurquoiseProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20VotestTransferCircleFillTurquoise: FC<Icon20VotestTransferCircleFillTurquoiseProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20VotestTransferCircleFillTurquoise as any).mountIcon = mountIcon;

export default Icon20VotestTransferCircleFillTurquoise;
