import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'wheel_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="wheel_outline_20"><clipPath id="wheel_outline_20_a"><path d="M0 0h20v20H0z" /></clipPath><g clip-path="url(#wheel_outline_20_a)"><path clip-rule="evenodd" d="M17.704 6.408a8.5 8.5 0 11-15.408 7.184 8.5 8.5 0 0115.408-7.184zm-5.442 10.217l-2.625-5.628L4.01 13.62a7.003 7.003 0 008.253 3.004zm-8.887-4.363l5.628-2.625L6.38 4.01a7.003 7.003 0 00-3.004 8.253zm13.25-4.524a7.003 7.003 0 01-3.004 8.253l-2.624-5.628zm-.634-1.36l-5.628 2.625-2.625-5.628a7.003 7.003 0 018.253 3.004z" fill="currentColor" fill-rule="evenodd" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20WheelOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20WheelOutline: FC<Icon20WheelOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20WheelOutline as any).mountIcon = mountIcon;

export default Icon20WheelOutline;
