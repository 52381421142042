import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'image_rotate_32';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="image_rotate_32"><path d="M24.872 10c1.783 0 2.43.186 3.082.534.651.349 1.163.86 1.512 1.512.348.652.534 1.299.534 3.082v6.744c0 1.783-.186 2.43-.534 3.082a3.635 3.635 0 01-1.512 1.512c-.652.348-1.299.534-3.082.534H10.128c-1.783 0-2.43-.186-3.082-.534s-1.163-.86-1.512-1.512c-.33-.618-.514-1.23-.532-2.81L5 15.128c0-1.783.186-2.43.534-3.082a3.635 3.635 0 011.512-1.512c.618-.33 1.23-.514 2.81-.533zm.224 2.5H9.904l-.387.007c-.643.017-.931.069-1.167.171l-.125.06c-.216.116-.37.271-.486.487l-.061.125c-.12.279-.17.63-.177 1.554v7.192l.006.387c.017.643.069.931.171 1.167l.06.125c.116.216.271.37.487.486l.125.061c.279.12.631.17 1.554.177h15.192l.387-.006c.643-.017.931-.069 1.167-.171l.125-.06c.216-.116.37-.271.486-.487l.061-.125c.12-.279.17-.63.177-1.554v-7.192l-.006-.387c-.017-.643-.069-.931-.171-1.167l-.06-.125a1.136 1.136 0 00-.487-.486l-.125-.061c-.279-.12-.63-.17-1.554-.177zM7.366.867a1.25 1.25 0 011.768 0l2.5 2.5a1.25 1.25 0 010 1.768l-2.5 2.5a1.25 1.25 0 11-1.768-1.768l.366-.366H5.25a1.75 1.75 0 00-1.744 1.606L3.5 7.25v2.5a1.25 1.25 0 11-2.5 0v-2.5A4.25 4.25 0 015.25 3h2.483l-.367-.366A1.25 1.25 0 017.275.968z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32ImageRotateProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32ImageRotate: FC<Icon32ImageRotateProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32ImageRotate as any).mountIcon = mountIcon;

export default Icon32ImageRotate;
