import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'archive_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="archive_outline_24"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M9.1 13.4a.9.9 0 01.9-.9h4a.9.9 0 110 1.8h-4a.9.9 0 01-.9-.9z" /><path d="M5 3.1A2.9 2.9 0 002.1 6v2c0 1.113.93 1.9 2 1.9V18A2.9 2.9 0 007 20.9h10a2.9 2.9 0 002.9-2.9V9.9c1.07 0 2-.787 2-1.9V6A2.9 2.9 0 0019 3.1zm0 1.8A1.1 1.1 0 003.9 6v2a.1.1 0 00.1.1h16a.1.1 0 00.1-.1V6A1.1 1.1 0 0019 4.9zm2 14.2A1.1 1.1 0 015.9 18V9.9h12.2V18a1.1 1.1 0 01-1.1 1.1z" /><path d="M10 12.5a.9.9 0 100 1.8h4a.9.9 0 000-1.8z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24ArchiveOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24ArchiveOutline: FC<Icon24ArchiveOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24ArchiveOutline as any).mountIcon = mountIcon;

export default Icon24ArchiveOutline;
