import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'achievement_circle_fill_blue_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="achievement_circle_fill_blue_20"><linearGradient id="achievement_circle_fill_blue_20_a" gradientUnits="userSpaceOnUse" x1="-10" x2="10" y1="10" y2="30"><stop offset="0" stop-color="#70b2ff" /><stop offset="1" stop-color="#5c9ce6" /></linearGradient><path d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z" fill="url(#achievement_circle_fill_blue_20_a)" /><path clip-rule="evenodd" d="M9.191 11.695l.46-.242a.75.75 0 01.698 0l.46.242a.75.75 0 001.043-.38l.197-.48a.75.75 0 01.534-.449l.508-.11a.75.75 0 00.555-.961l-.158-.496a.75.75 0 01.121-.687l.318-.411a.75.75 0 00-.193-1.093l-.44-.278a.75.75 0 01-.348-.604l-.02-.52a.75.75 0 00-.85-.713l-.516.07a.75.75 0 01-.655-.238l-.35-.385a.75.75 0 00-1.11 0l-.35.385a.75.75 0 01-.655.238l-.515-.07a.75.75 0 00-.85.713l-.021.52a.75.75 0 01-.349.604l-.44.278a.748.748 0 00-.192 1.093l.318.411a.75.75 0 01.121.687l-.158.495a.75.75 0 00.555.961l.508.111a.75.75 0 01.534.449l.197.48a.75.75 0 001.043.38zm-1.825.83a2.101 2.101 0 001.174.582.15.15 0 01.118.097l.545 1.498a.15.15 0 010 .102L8.7 16.183a.25.25 0 01-.436.062l-.656-.893c-.052-.07-.076-.092-.106-.112s-.062-.031-.098-.035a.391.391 0 00-.152.018l-1.077.262a.25.25 0 01-.294-.328l.96-2.636.069-.19a.15.15 0 01.262-.038c.074.101.138.179.193.233zm5.73-.174l1.022 2.806a.25.25 0 01-.294.328l-1.077-.262a.39.39 0 00-.152-.018c-.036.004-.068.016-.098.035s-.054.042-.105.112l-.657.893a.25.25 0 01-.436-.062l-1.094-3.005a.15.15 0 01.203-.188 2.101 2.101 0 002.422-.689.15.15 0 01.262.037z" fill="#fff" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20AchievementCircleFillBlueProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20AchievementCircleFillBlue: FC<Icon20AchievementCircleFillBlueProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20AchievementCircleFillBlue as any).mountIcon = mountIcon;

export default Icon20AchievementCircleFillBlue;
