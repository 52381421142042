import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'podcast_circle_fill_red_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="podcast_circle_fill_red_20"><linearGradient id="podcast_circle_fill_red_20_a" gradientUnits="userSpaceOnUse" x1="-10" x2="10" y1="10" y2="30"><stop offset="0" stop-color="#ff5263" /><stop offset="1" stop-color="#ff3347" /></linearGradient><path d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z" fill="url(#podcast_circle_fill_red_20_a)" /><path clip-rule="evenodd" d="M16 9A6 6 0 004 9v.008c-.243.017-.366.06-.488.126a.909.909 0 00-.378.378c-.088.163-.134.324-.134.77v1.56c0 .297.046.483.134.646.087.163.215.291.378.378.163.088.349.134.646.134h.184c.297 0 .483-.046.646-.134a.908.908 0 00.378-.378c.088-.162.134-.349.134-.646V9l.005-.212A4.5 4.5 0 0114.5 9v2.842c0 .297.046.483.134.646.087.163.215.291.378.378.162.088.349.134.646.134h.184c.297 0 .483-.046.646-.134a.908.908 0 00.378-.378c.088-.162.134-.349.134-.646v-1.56c0-.446-.046-.607-.134-.77a.908.908 0 00-.378-.378c-.122-.066-.244-.109-.488-.134zm-6-3a2.997 2.997 0 00-3 3v3a2.998 2.998 0 002.25 2.905v.866c0 .164.03.29.087.396.056.106.14.19.246.246a.816.816 0 00.396.087h.043c.164 0 .29-.03.396-.087a.591.591 0 00.245-.246.817.817 0 00.087-.396v-.866A2.997 2.997 0 0013 12V9a2.997 2.997 0 00-3-3zm-.608 5.4H8.5v.6c0 .414.167.788.44 1.06.272.273.646.44 1.06.44.414 0 .788-.167 1.06-.44.273-.272.44-.646.44-1.06V9c0-.414-.167-.788-.44-1.06A1.493 1.493 0 0010 7.5c-.414 0-.788.167-1.06.44S8.5 8.585 8.5 9v1h.892c.183 0 .27.023.352.067s.146.107.19.189c.043.081.066.169.066.352v.184c0 .183-.023.27-.067.352-.043.082-.107.146-.189.19s-.169.066-.352.066z" fill="#fff" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20PodcastCircleFillRedProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20PodcastCircleFillRed: FC<Icon20PodcastCircleFillRedProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20PodcastCircleFillRed as any).mountIcon = mountIcon;

export default Icon20PodcastCircleFillRed;
