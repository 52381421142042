import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'cam_slash_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="cam_slash_16"><path d="M10.573 13.13a.751.751 0 001.062-1.06L2.48 2.92a.75.75 0 10-1.06 1.06l.82.82c-.153.282-.24.606-.24.95v4.5a2 2 0 002 2h5a2 2 0 00.601-.092l.972.972zm4.324-7.466A.454.454 0 0115 5.95v4.074c0 .415-.393.602-.826.382-.474-.24-1.571-.789-1.571-.789a.233.233 0 01-.103-.19v-2.88c0-.075.038-.145.103-.19l1.571-.79c.194-.094.552-.115.723.096zM11 9.425V5.75a2 2 0 00-2-2H5.32L11 9.425z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16CamSlashProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16CamSlash: FC<Icon16CamSlashProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16CamSlash as any).mountIcon = mountIcon;

export default Icon16CamSlash;
