import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'compass_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="compass_outline_56"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M36.92 21.207c.406-1.307-.82-2.533-2.127-2.127l-11.036 3.425a1.9 1.9 0 00-1.252 1.252L19.08 34.791c-.406 1.307.82 2.534 2.128 2.128l11.036-3.425a1.9 1.9 0 001.251-1.252zM25.199 25.2l8.124-2.522-2.522 8.124-8.124 2.522z" /><path d="M52 28c0 13.255-10.745 24-24 24S4 41.255 4 28 14.745 4 28 4s24 10.745 24 24zm-3 0c0 11.598-9.402 21-21 21S7 39.598 7 28 16.402 7 28 7s21 9.402 21 21z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56CompassOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56CompassOutline: FC<Icon56CompassOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56CompassOutline as any).mountIcon = mountIcon;

export default Icon56CompassOutline;
