import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'story_reply_circle_fill_violet_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="story_reply_circle_fill_violet_20"><linearGradient id="story_reply_circle_fill_violet_20_a" gradientUnits="userSpaceOnUse" x1="-10" x2="10" y1="10" y2="30"><stop offset="0" stop-color="#c48aff" /><stop offset="1" stop-color="#aa65f0" /></linearGradient><path d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z" fill="url(#story_reply_circle_fill_violet_20_a)" /><path clip-rule="evenodd" d="M8.926 6.263a1 1 0 01.061 1.413L7.773 9H10c1.965 0 3.71.946 4.803 2.403a1 1 0 01-1.6 1.2A3.993 3.993 0 0010 11H7.773l1.214 1.324a1 1 0 11-1.474 1.352l-2.75-3a1 1 0 010-1.352l2.75-3a1 1 0 011.413-.061z" fill="#fff" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20StoryReplyCircleFillVioletProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20StoryReplyCircleFillViolet: FC<Icon20StoryReplyCircleFillVioletProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20StoryReplyCircleFillViolet as any).mountIcon = mountIcon;

export default Icon20StoryReplyCircleFillViolet;
