import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'skirt_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="skirt_outline_28"><path clip-rule="evenodd" d="M9.6 3h-.032c-.252 0-.498 0-.706.017a2.022 2.022 0 00-.77.201 2 2 0 00-.874.874 2.022 2.022 0 00-.201.77C7 5.07 7 5.316 7 5.568v2.248L2.443 19.779l-.023.06c-.103.268-.238.618-.216 1.046.01.2.06.393.11.54.051.15.13.332.244.496.243.35.524.52.741.653l.038.022C4.882 23.54 8.214 25 14 25s9.118-1.46 10.663-2.404l.038-.022c.217-.132.498-.303.74-.653.115-.164.194-.347.244-.495.051-.148.1-.34.11-.54.023-.43-.112-.779-.215-1.046l-.023-.061L21 7.816V5.568c0-.252 0-.498-.017-.706a2.022 2.022 0 00-.201-.77 2 2 0 00-.874-.874 2.022 2.022 0 00-.77-.201C18.93 3 18.684 3 18.432 3zm-.91 6h2.13L8.575 22.462c-2.121-.464-3.47-1.128-4.197-1.572a7.12 7.12 0 01-.17-.108l-.002-.002.03-.09.075-.2zm1.858 13.797L12.848 9h2.332l2.747 13.733A27.365 27.365 0 0114 23c-1.286 0-2.433-.076-3.452-.203zm9.343-.443c1.854-.455 3.058-1.054 3.73-1.464a7.293 7.293 0 00.17-.108l.002-.002a10.258 10.258 0 00-.105-.289L19.31 9H17.22zM19 7V5.6c0-.297 0-.459-.01-.575l-.001-.014h-.014A8.187 8.187 0 0018.4 5H9.6c-.297 0-.459 0-.575.01l-.014.001v.014C9 5.14 9 5.303 9 5.6V7z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28SkirtOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28SkirtOutline: FC<Icon28SkirtOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28SkirtOutline as any).mountIcon = mountIcon;

export default Icon28SkirtOutline;
