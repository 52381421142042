import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'key_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="key_outline_28"><g fill="none" fill-rule="evenodd"><path d="M0 0h28v28H0z" /><path d="M25 2a1 1 0 011 1v4a1 1 0 01-1 1h-2v2a1 1 0 01-.883.993L22 11h-2v2a1 1 0 01-.883.993L19 14h-2.556l.18.3c.58 1.02.917 2.17.969 3.371L17.6 18a7.5 7.5 0 11-5.78-7.301l.054.013 8.419-8.42a1 1 0 01.576-.283L21 2zm-1 2h-2.586l-8.541 8.542a1 1 0 01-.904.274l-.12-.033a5.5 5.5 0 102.504 1.728 1 1 0 01.064-1.341l.876-.877A1 1 0 0116 12h2v-2a1 1 0 01.883-.993L19 9h2V7a1 1 0 01.883-.993L22 6h2V4zM8.5 18.2a1.3 1.3 0 110 2.6 1.3 1.3 0 010-2.6z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28KeyOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28KeyOutline: FC<Icon28KeyOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28KeyOutline as any).mountIcon = mountIcon;

export default Icon28KeyOutline;
