import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'share_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="share_outline_28"><g fill="none"><path d="M0 0h28v28H0z" /><path d="M13.862 4.007a2.588 2.588 0 00-2.393 2.589l-.001 2.556-.025.006C6.011 10.292 2 15.141 2 20.862c0 .416.021.83.063 1.24.1.98 1.337 1.332 1.93.55l.224-.286a10.866 10.866 0 016.995-3.942l.256-.031v3.011c0 .58.194 1.144.55 1.601a2.564 2.564 0 003.617.442l9.378-7.404a2.609 2.609 0 000-4.086l-9.378-7.404A2.565 2.565 0 0014.045 4zm.184 2.166c.094 0 .185.032.258.09l9.378 7.404a.425.425 0 010 .666l-9.378 7.404a.417.417 0 01-.678-.333l-.001-4.162c0-.607-.494-1.097-1.096-1.087l-.28.005-.41.02a12.986 12.986 0 00-7.334 2.856l-.209.174.029-.163c.77-4.124 4.117-7.372 8.352-7.892.541-.067.948-.53.948-1.079l.001-3.48c0-.233.188-.423.42-.423z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28ShareOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28ShareOutline: FC<Icon28ShareOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28ShareOutline as any).mountIcon = mountIcon;

export default Icon28ShareOutline;
