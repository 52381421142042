import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'radiowaves_left_and_right_circle_fill_red_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="radiowaves_left_and_right_circle_fill_red_20"><linearGradient id="radiowaves_left_and_right_circle_fill_red_20_a" gradientUnits="userSpaceOnUse" x1="-10" x2="10" y1="10" y2="30"><stop offset="0" stop-color="#ff5263" /><stop offset="1" stop-color="#ff3347" /></linearGradient><path d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z" fill="url(#radiowaves_left_and_right_circle_fill_red_20_a)" /><circle cx="10" cy="10" fill="#fff" r="1.5" /><g stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M7 7.354C6.378 8.06 6 8.986 6 10s.378 1.94 1 2.646m6-5.292c.622.705 1 1.632 1 2.646s-.378 1.94-1 2.646M15 5.101A6.977 6.977 0 0117 10a6.977 6.977 0 01-2 4.899M5 5.101A6.977 6.977 0 003 10c0 1.907.763 3.636 2 4.899" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20RadiowavesLeftAndRightCircleFillRedProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20RadiowavesLeftAndRightCircleFillRed: FC<Icon20RadiowavesLeftAndRightCircleFillRedProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20RadiowavesLeftAndRightCircleFillRed as any).mountIcon = mountIcon;

export default Icon20RadiowavesLeftAndRightCircleFillRed;
