import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 12 12';
const id = 'headphones_12';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" id="headphones_12"><path d="M5.5 1a4.5 4.5 0 014.25 5.981c.155.265.25.6.25 1.019 0 1.667-1.5 2-2.25 2S7 9.105 7 8s0-2 .75-2c.314 0 .758.058 1.17.248a3.5 3.5 0 10-6.84.001C2.492 6.059 2.936 6 3.25 6 4 6 4 6.895 4 8s0 2-.75 2S1 9.667 1 8c0-.42.095-.754.249-1.02A4.5 4.5 0 015.5 1z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon12HeadphonesProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon12Headphones: FC<Icon12HeadphonesProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 12,
    height: !isNaN(props.height) ? +props.height : 12,
  }));
};

(Icon12Headphones as any).mountIcon = mountIcon;

export default Icon12Headphones;
