import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'user_microphone_badge_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="user_microphone_badge_outline_28"><path fill-rule="evenodd" clip-rule="evenodd" d="M14 14.5a5.5 5.5 0 100-11 5.5 5.5 0 000 11zm0-2a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM19 27a6 6 0 100-12 6 6 0 000 12zm-7.018-10.431a1 1 0 01-.795 1.17c-2.272.433-3.272 1.361-3.729 2.05a2.902 2.902 0 00-.408.917 2.016 2.016 0 00-.05.306v.488a.5.5 0 00.5.5h3a1 1 0 110 2h-3A2.5 2.5 0 015 21.5V21h1c-1 0-1-.002-1-.002v-.032a2.115 2.115 0 01.015-.215c.014-.13.04-.306.091-.515a4.9 4.9 0 01.686-1.553c.793-1.195 2.293-2.389 5.02-2.909a1 1 0 011.17.795zM19 16.75a1.5 1.5 0 00-1.5 1.5v2a1.5 1.5 0 003 0v-2a1.5 1.5 0 00-1.5-1.5zm-2.42 4.327a.75.75 0 10-1.46.346c.346 1.459 1.603 2.54 3.13 2.811V25a.75.75 0 001.5 0v-.766c1.527-.27 2.784-1.352 3.13-2.811a.75.75 0 10-1.46-.346c-.222.937-1.18 1.723-2.42 1.723s-2.198-.786-2.42-1.723z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28UserMicrophoneBadgeOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28UserMicrophoneBadgeOutline: FC<Icon28UserMicrophoneBadgeOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28UserMicrophoneBadgeOutline as any).mountIcon = mountIcon;

export default Icon28UserMicrophoneBadgeOutline;
