import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'brush_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="brush_outline_28"><path clip-rule="evenodd" d="M23.651 4.099a2.107 2.107 0 00-2.895.102l-8.031 7.93a1 1 0 101.405 1.423l8.037-7.936.015-.015a.107.107 0 01.145-.005.08.08 0 01.01.115l-.014.018-7.132 8.941a1 1 0 101.563 1.247l7.126-8.934a2.08 2.08 0 00-.229-2.886zM8.128 18.89c.049-1.066.88-1.891 1.936-1.891 1.1 0 1.936.855 1.936 1.845 0 .973-.847 2.28-2.982 2.927-.936.283-1.68.267-2.24.142.635-.6 1.281-1.54 1.35-3.023zM10.064 15a4.01 4.01 0 00-4.07 4c-.046.979-.353 1.28-.769 1.617-.112.09-.223.17-.338.25l-.051.036c-.093.064-.21.145-.304.22-.06.047-.141.115-.219.202-.073.08-.191.23-.26.444-.079.247-.075.53.046.794.106.234.268.38.376.46.842.634 2.593 1.429 5.122.663 2.634-.797 4.403-2.183 4.403-4.69C14 17.141 12.52 15 10.064 15z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28BrushOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28BrushOutline: FC<Icon28BrushOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28BrushOutline as any).mountIcon = mountIcon;

export default Icon28BrushOutline;
