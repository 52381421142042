import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'ghost_simle_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="ghost_simle_outline_28"><path clip-rule="evenodd" d="M21.595 5.086C19.357 3.008 16.935 2 14 2c-2.9 0-5.409 1.056-7.595 3.086C4.229 7.106 3 9.87 3 12.76l.016 8.532c.1 2.138 1.636 3.65 3.647 3.65.683 0 1.177-.137 1.782-.433l.493-.256c.335-.171.492-.209.743-.167.12.02.26.092.48.257l.596.48C11.746 25.607 12.663 26 14 26c1.251 0 2.123-.34 3.036-1.028l.197-.152.133-.11c.137-.111.333-.271.399-.322.251-.195.408-.277.555-.302.214-.036.36-.013.608.102l.134.065.26.138c.717.378 1.247.55 2.015.55 2.016 0 3.492-1.516 3.644-3.578l.019-8.6c0-2.893-1.23-5.657-3.405-7.677zM7.765 6.552C9.595 4.854 11.625 4 14 4c2.416 0 4.356.807 6.234 2.552C22.006 8.197 23 10.432 23 12.76l-.016 8.528c-.075.986-.725 1.654-1.647 1.654-.334 0-.564-.062-.92-.238l-.443-.23c-.683-.35-1.245-.485-1.986-.36-.465.078-.85.26-1.266.557l-.18.135a34.34 34.34 0 00-.475.384l-.084.068c-.661.53-1.154.742-1.983.742-.754 0-1.241-.18-1.823-.608L12 23.256a32.002 32.002 0 00-.637-.511c-.452-.34-.859-.548-1.35-.63-.742-.126-1.304.008-1.987.359l-.282.148c-.454.24-.7.32-1.08.32-.93 0-1.6-.66-1.65-1.7L5 12.758c0-2.326.994-4.56 2.766-6.206zM12.5 13a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm6 0a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28GhostSimleOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28GhostSimleOutline: FC<Icon28GhostSimleOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28GhostSimleOutline as any).mountIcon = mountIcon;

export default Icon28GhostSimleOutline;
