import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'magic_wand_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="magic_wand_outline_28"><g fill="none" fill-rule="evenodd"><path d="M0 0h28v28H0z" /><path d="M15.358 12.637a1 1 0 010 1.414L4.75 24.658a1 1 0 11-1.414-1.414l10.607-10.607a1 1 0 011.414 0zm8.587 1.543a.3.3 0 010 .28l-1.237 2.34 1.237 2.34a.3.3 0 01-.405.405l-2.34-1.237-2.34 1.237a.3.3 0 01-.405-.405l1.237-2.34-1.237-2.34a.3.3 0 01.405-.405l2.34 1.237 2.34-1.237a.3.3 0 01.405.125zm-10-10a.3.3 0 010 .28L12.708 6.8l1.237 2.34a.3.3 0 01-.405.406L11.2 8.309 8.86 9.546a.3.3 0 01-.405-.406L9.692 6.8 8.455 4.46a.3.3 0 01.405-.405l2.34 1.237 2.34-1.237a.3.3 0 01.405.125zm10 0a.3.3 0 010 .28L22.708 6.8l1.237 2.34a.3.3 0 01-.405.405L21.2 8.308l-2.34 1.237a.3.3 0 01-.405-.405l1.237-2.34-1.237-2.34a.3.3 0 01.405-.405l2.34 1.237 2.34-1.237a.3.3 0 01.405.125z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28MagicWandOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28MagicWandOutline: FC<Icon28MagicWandOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28MagicWandOutline as any).mountIcon = mountIcon;

export default Icon28MagicWandOutline;
