import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'thumb_down_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="thumb_down_24"><path fill="currentColor" fill-rule="evenodd" d="M6 14c0 1.5-.5 2-2 2H2V4h2c1.5 0 2 .5 2 2v8zm2 .636c1.034.52 1.867 1.31 2.5 2.364 1.5 2.5 1.5 5 2.5 5.5s2-.5 2-2.5-1-3.5-.5-4 3 0 5 0 2.5-.5 2.5-1.5-.667-1.5-2-1.5c1 0 1.5-.5 1.5-1.5s-.667-1.5-2-1.5c1 0 1.5-.5 1.5-1.5S20.333 7 19 7c1 0 1.5-.5 1.5-1.5S20 4 18 4c-5.145 0-5.477.875-10 .988v9.648z" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24ThumbDownProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24ThumbDown: FC<Icon24ThumbDownProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24ThumbDown as any).mountIcon = mountIcon;

export default Icon24ThumbDown;
