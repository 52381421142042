import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'error_circle_outline_32';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="error_circle_outline_32"><g fill="currentColor"><path d="M14.9 9.6c0-.28 0-.419.034-.532a.8.8 0 01.534-.534C15.58 8.5 15.72 8.5 16 8.5s.419 0 .532.034a.8.8 0 01.533.534c.035.113.035.253.035.532v7.8c0 .28 0 .419-.035.532a.8.8 0 01-.533.533c-.113.035-.253.035-.532.035s-.419 0-.532-.035a.8.8 0 01-.534-.533c-.034-.113-.034-.253-.034-.532zM14.5 22a1.5 1.5 0 013 0v.2a1.5 1.5 0 01-3 0z" /><path clip-rule="evenodd" d="M16 2a13.96 13.96 0 00-9.9 4.1C3.569 8.633 2 12.134 2 16s1.568 7.367 4.1 9.9C8.633 28.431 12.134 30 16 30s7.367-1.568 9.9-4.1S30 19.866 30 16s-1.568-7.367-4.1-9.9A13.96 13.96 0 0016 2zM7.868 7.868C9.951 5.786 12.824 4.5 16 4.5s6.05 1.286 8.132 3.368C26.214 9.951 27.5 12.824 27.5 16s-1.286 6.05-3.368 8.132C22.049 26.214 19.176 27.5 16 27.5s-6.05-1.286-8.132-3.368C5.786 22.049 4.5 19.176 4.5 16s1.286-6.05 3.368-8.132z" fill-rule="evenodd" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32ErrorCircleOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32ErrorCircleOutline: FC<Icon32ErrorCircleOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32ErrorCircleOutline as any).mountIcon = mountIcon;

export default Icon32ErrorCircleOutline;
