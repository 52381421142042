import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'view_outline_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="view_outline_16"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M8 10.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM9 8a1 1 0 11-2 0 1 1 0 012 0z" /><path d="M8 13.25c1.86 0 3.594-.69 4.865-1.587.64-.452 1.205-.985 1.625-1.566.403-.558.76-1.286.76-2.097s-.357-1.539-.76-2.097c-.42-.58-.985-1.114-1.625-1.566C11.593 3.439 9.86 2.75 8 2.75s-3.593.69-4.865 1.587c-.64.452-1.205.985-1.624 1.566C1.107 6.461.75 7.189.75 8s.357 1.539.76 2.097c.42.58.985 1.114 1.625 1.566C4.407 12.56 6.14 13.25 8 13.25zM13.75 8c0 1.5-2.75 3.75-5.75 3.75S2.25 9.5 2.25 8 5 4.25 8 4.25 13.75 6.5 13.75 8z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16ViewOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16ViewOutline: FC<Icon16ViewOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16ViewOutline as any).mountIcon = mountIcon;

export default Icon16ViewOutline;
