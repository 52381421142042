import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'video_fill_none_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="video_fill_none_24"><path d="M18.154 4c1.337 0 1.822.14 2.311.4s.873.646 1.134 1.135.401.974.401 2.31v8.31c0 1.336-.14 1.821-.4 2.31s-.646.873-1.135 1.134-.974.401-2.31.401H5.844c-1.336 0-1.821-.14-2.31-.4s-.873-.646-1.134-1.135S2 17.491 2 16.155v-8.31c0-1.336.14-1.821.4-2.31s.646-.873 1.135-1.134S4.509 4 5.845 4zm.564 3.5H5.282c-.446 0-.607.046-.77.134a.909.909 0 00-.378.378c-.088.163-.134.324-.134.77v6.436c0 .446.046.607.134.77.087.163.215.291.378.378.163.088.324.134.77.134h13.436c.446 0 .607-.046.77-.134a.908.908 0 00.378-.378c.088-.163.134-.324.134-.77V8.782c0-.446-.046-.607-.134-.77a.908.908 0 00-.378-.378c-.163-.088-.324-.134-.77-.134z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24VideoFillNoneProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24VideoFillNone: FC<Icon24VideoFillNoneProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24VideoFillNone as any).mountIcon = mountIcon;

export default Icon24VideoFillNone;
