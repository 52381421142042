import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'podcast_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="podcast_outline_28"><g fill-rule="nonzero" fill="none"><path d="M0 0h28v28H0z" /><path d="M14 6a6 6 0 016 6v6a6.002 6.002 0 01-5 5.917V25.5a1 1 0 01-2 0v-1.583A6.002 6.002 0 018 18v-6a6 6 0 016-6zm0 2a4 4 0 00-4 4h2.5a1 1 0 010 2H10v2h2.5a1 1 0 010 2H10a4 4 0 108 0h-1a1 1 0 01-.117-1.993L17 16h1v-2h-1a1 1 0 01-.117-1.993L17 12h1a4 4 0 00-4-4zm0-6c5.19 0 9.456 3.953 9.952 9.012.87.1 1.548.84 1.548 1.738v4.5a1.75 1.75 0 01-3.5 0V12a8 8 0 10-16 0v5.25a1.75 1.75 0 01-3.5 0v-4.5a1.75 1.75 0 011.549-1.739C4.544 5.953 8.81 2 14 2z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28PodcastOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28PodcastOutline: FC<Icon28PodcastOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28PodcastOutline as any).mountIcon = mountIcon;

export default Icon28PodcastOutline;
