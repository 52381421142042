import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'search_outline_32';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="search_outline_32"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.929 7.929A9.973 9.973 0 0115 5a9.973 9.973 0 017.071 2.929A9.973 9.973 0 0125 15a9.973 9.973 0 01-2.929 7.071A9.973 9.973 0 0115 25a9.973 9.973 0 01-7.071-2.929A9.973 9.973 0 015 15a9.973 9.973 0 012.929-7.071zM15 7.5a7.473 7.473 0 00-5.303 2.197A7.473 7.473 0 007.5 15a7.47 7.47 0 002.197 5.303A7.473 7.473 0 0015 22.5a7.473 7.473 0 005.303-2.197A7.473 7.473 0 0022.5 15a7.473 7.473 0 00-2.197-5.303A7.473 7.473 0 0015 7.5z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M21.624 21.357a1.25 1.25 0 011.768 0l4.752 4.751a1.25 1.25 0 01-1.768 1.768l-4.752-4.752a1.25 1.25 0 010-1.767z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32SearchOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32SearchOutline: FC<Icon32SearchOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32SearchOutline as any).mountIcon = mountIcon;

export default Icon32SearchOutline;
