import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'phone_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="phone_16"><path d="M5.443 2.64l.575.684c.796.943.713 2.365-.19 3.268l-.49.49a.475.475 0 00-.11.499c.179.487.634 1.096 1.364 1.827.732.731 1.34 1.185 1.827 1.364.174.064.369.02.5-.11l.489-.49c.903-.902 2.324-.985 3.269-.19l.683.574c.779.656.858 1.839.175 2.64-.372.431-.896.702-1.463.756-2.182.294-4.471-.758-6.868-3.155-2.398-2.398-3.45-4.688-3.156-6.87a2.213 2.213 0 01.756-1.464c.802-.68 1.984-.602 2.64.178z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16PhoneProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16Phone: FC<Icon16PhoneProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16Phone as any).mountIcon = mountIcon;

export default Icon16Phone;
