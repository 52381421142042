import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'user_square_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="user_square_outline_24"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M12 8.8a1.95 1.95 0 100 3.9 1.95 1.95 0 000-3.9zm-3.75 1.95a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0z" /><path d="M12 7a3.75 3.75 0 100 7.5A3.75 3.75 0 0012 7zm0 1.8a1.95 1.95 0 100 3.9 1.95 1.95 0 000-3.9z" /><path d="M8.228 3.1c-1.57 0-2.32.127-3.082.534a3.647 3.647 0 00-1.512 1.512c-.407.762-.534 1.512-.534 3.082v7.544c0 1.57.127 2.32.534 3.082.348.65.863 1.164 1.512 1.512.762.407 1.512.534 3.082.534h7.544c1.57 0 2.32-.127 3.082-.534a3.647 3.647 0 001.512-1.512c.407-.762.534-1.512.534-3.082V8.228c0-1.57-.127-2.32-.534-3.082a3.647 3.647 0 00-1.512-1.512c-.763-.407-1.512-.534-3.082-.534zm0 1.8c-1.487 0-1.87.128-2.233.321-.336.18-.594.438-.773.774-.194.362-.322.746-.322 2.233v7.544c0 .865-.042 1.89.564 2.587A8.876 8.876 0 0112 15.5c2.583 0 4.91 1.1 6.536 2.859.606-.697.564-1.722.564-2.587V8.228c0-1.487-.128-1.87-.322-2.233a1.847 1.847 0 00-.773-.773c-.362-.194-.746-.322-2.233-.322zM12 7a3.75 3.75 0 100 7.5A3.75 3.75 0 0012 7zM7.302 19.077A7.073 7.073 0 0112 17.3c1.802 0 3.447.671 4.699 1.777zM12 8.8a1.95 1.95 0 100 3.9 1.95 1.95 0 000-3.9z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24UserSquareOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24UserSquareOutline: FC<Icon24UserSquareOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24UserSquareOutline as any).mountIcon = mountIcon;

export default Icon24UserSquareOutline;
