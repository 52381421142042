import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'hashtag_outline_32';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="hashtag_outline_32"><path d="M23.495 2.025a1.25 1.25 0 011 1.344l-.019.127L23.174 10h3.576a1.25 1.25 0 01.128 2.494l-.128.006h-4.076l-1.4 7h3.476a1.25 1.25 0 01.128 2.494L24.75 22h-3.976l-1.398 6.995a1.25 1.25 0 01-2.47-.364l.019-.127 1.3-6.504h-6.851l-1.398 6.995a1.25 1.25 0 01-2.47-.364l.019-.126L8.825 22H5.25a1.25 1.25 0 01-.128-2.494l.128-.006h4.075l1.4-7H7.25a1.25 1.25 0 01-.128-2.494L7.25 10h3.975l1.4-6.995a1.25 1.25 0 012.47.364l-.019.127L13.774 10h6.851l1.4-6.995a1.25 1.25 0 011.47-.98zM18.725 19.5l1.4-7h-6.851l-1.4 7z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32HashtagOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32HashtagOutline: FC<Icon32HashtagOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32HashtagOutline as any).mountIcon = mountIcon;

export default Icon32HashtagOutline;
