import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'text_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="text_outline_24"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.136 17.146c-.222.725-.537 1.004-1.101 1.004-.583 0-.98-.344-.98-.883 0-.223.055-.465.175-.818L5.7 6.59c.323-.93.776-1.339 1.618-1.339.842 0 1.295.4 1.61 1.338l3.469 9.861c.102.307.157.558.157.79 0 .558-.398.911-.999.911-.564 0-.897-.288-1.11-1.004l-.87-2.62h-4.57l-.87 2.62zm3.108-9.6l-1.74 5.362h3.572l-1.73-5.362h-.102zm12.784 9.188h-.102c-.537.91-1.519 1.411-2.732 1.411-1.907 0-3.194-1.206-3.194-2.989 0-1.81 1.315-2.915 3.5-2.924h2.444v-.872c0-1.105-.675-1.68-1.925-1.68-.778 0-1.315.278-1.88.853-.25.242-.49.335-.843.335-.453 0-.75-.307-.75-.752 0-.567.39-1.124 1.213-1.57.593-.324 1.37-.5 2.39-.5C20.62 8.045 22 9.233 22 11.368v5.616c0 .706-.361 1.114-1 1.114-.565 0-.926-.343-.972-.928v-.437zm-2.241-3.09c-1.093 0-1.722.51-1.722 1.41 0 .91.713 1.523 1.768 1.523 1.195 0 2.111-.817 2.111-1.876v-1.058h-2.157z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24TextOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24TextOutline: FC<Icon24TextOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24TextOutline as any).mountIcon = mountIcon;

export default Icon24TextOutline;
