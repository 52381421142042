import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'discussions_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="discussions_24"><path d="M20.492 7C21.325 7 22 7.675 22 8.508v13.279a.502.502 0 01-.858.355L18 19H8.508A1.507 1.507 0 017 17.492V16h8.985A3.015 3.015 0 0019 12.985V7h1.492zM14.99 3C16.1 3 17 3.9 17 5.01v7.482c0 .833-.675 1.508-1.508 1.508H6l-3.142 3.142A.503.503 0 012 16.787V4.507C2 3.676 2.675 3 3.507 3H14.99z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24DiscussionsProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Discussions: FC<Icon24DiscussionsProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Discussions as any).mountIcon = mountIcon;

export default Icon24Discussions;
