import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'document_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="document_28"><g fill="none" fill-rule="evenodd"><path d="M0 0h28v28H0z" /><path d="M5 5.5v16A3.5 3.5 0 008.5 25h12a3.5 3.5 0 003.5-3.5V10.246a2 2 0 00-.489-1.31L18.098 2.69A2 2 0 0016.587 2H8.5A3.5 3.5 0 005 5.5zm11.597-.903l4.794 4.794a.35.35 0 01-.253.597l-4.645-.064a.5.5 0 01-.493-.5V4.845a.35.35 0 01.597-.248z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28DocumentProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28Document: FC<Icon28DocumentProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28Document as any).mountIcon = mountIcon;

export default Icon28Document;
