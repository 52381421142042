import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'canister_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="canister_outline_28"><path clip-rule="evenodd" d="M6 17v-6.707l7.538-3.392c1.914-.861 3.226-1.45 4.248-1.802 1.021-.352 1.48-.366 1.737-.32a3 3 0 011.956 1.265c.147.215.323.64.42 1.715.099 1.077.101 2.515.101 4.614V17c0 1.433-.002 2.388-.061 3.121-.058.71-.161 1.035-.266 1.24a3 3 0 01-1.311 1.312c-.206.105-.53.207-1.24.266-.734.06-1.689.061-3.122.061h-4c-1.433 0-2.388-.002-3.121-.062-.71-.058-1.035-.16-1.241-.265a3 3 0 01-1.311-1.311c-.105-.206-.208-.53-.266-1.24C6.001 19.387 6 18.432 6 17zm0-8.9l3-1.35V6.1c0-.297 0-.459-.01-.575l-.001-.014h-.014A8.183 8.183 0 008.4 5.5H6.6c-.297 0-.459 0-.575.01l-.014.001v.014C6 5.64 6 5.803 6 6.1zm5-2.25l1.717-.773c3.758-1.69 5.637-2.536 7.155-2.268a5 5 0 013.26 2.109C24 6.19 24 8.252 24 12.373V17c0 2.8 0 4.2-.545 5.27a5 5 0 01-2.185 2.185C20.2 25 18.8 25 16 25h-4c-2.8 0-4.2 0-5.27-.545a5 5 0 01-2.185-2.185C4 21.2 4 19.8 4 17V6.068c0-.252 0-.498.017-.706.019-.229.063-.499.201-.77a2 2 0 01.874-.874c.271-.138.541-.182.77-.201.208-.017.454-.017.706-.017h1.864c.252 0 .498 0 .706.017.229.019.499.063.77.201a2 2 0 01.874.874c.138.271.182.541.201.77.012.147.016.314.017.488zm-1.707 5.443a1 1 0 011.414 0l.707.707h5.172l.707-.707a1 1 0 111.414 1.414l-.707.707v5.172l.707.707a1 1 0 01-1.414 1.414L16.586 20h-5.172l-.707.707a1 1 0 01-1.414-1.414l.707-.707v-5.172l-.707-.707a1 1 0 010-1.414zM12 14v4h4v-4z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28CanisterOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28CanisterOutline: FC<Icon28CanisterOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28CanisterOutline as any).mountIcon = mountIcon;

export default Icon28CanisterOutline;
