import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 48 48';
const id = 'donate_outline_48';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" id="donate_outline_48"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M24 45c11.598 0 21-9.402 21-21S35.598 3 24 3 3 12.402 3 24s9.402 21 21 21zm0-3c9.941 0 18-8.059 18-18S33.941 6 24 6 6 14.059 6 24s8.059 18 18 18z" /><path d="M24 15.406l-2.405 5.783-6.244.5 4.757 4.076-1.453 6.092L24 28.592l5.345 3.265-1.453-6.092 4.757-4.075-6.244-.5zm-2.498-1.807a2.705 2.705 0 014.996 0l1.974 4.746 5.124.411a2.705 2.705 0 011.543 4.75l-3.904 3.345 1.193 5a2.705 2.705 0 01-4.041 2.936L24 32.107l-4.387 2.68a2.705 2.705 0 01-4.041-2.936l1.193-5-3.904-3.344a2.705 2.705 0 011.543-4.75l5.124-.412z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon48DonateOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon48DonateOutline: FC<Icon48DonateOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 48,
    height: !isNaN(props.height) ? +props.height : 48,
  }));
};

(Icon48DonateOutline as any).mountIcon = mountIcon;

export default Icon48DonateOutline;
