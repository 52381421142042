import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'smile_filled_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="smile_filled_16"><path clip-rule="evenodd" d="M16 8A8 8 0 100 8a8 8 0 0016 0zM6.5 6.378a1.125 1.125 0 10-2.25 0 1.125 1.125 0 002.25 0zm4.12-1.125A1.125 1.125 0 119.5 6.378a1.12 1.12 0 011.12-1.125zm.774 4.25a.75.75 0 00-1.046.175c-1.114 1.56-3.576 1.576-4.703 0a.75.75 0 10-1.22.872c1.692 2.368 5.433 2.393 7.143 0a.75.75 0 00-.174-1.047z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16SmileFilledProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16SmileFilled: FC<Icon16SmileFilledProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16SmileFilled as any).mountIcon = mountIcon;

export default Icon16SmileFilled;
