import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'videocam_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="videocam_28"><g fill="currentColor"><path d="M2 12.4c0-2.24 0-3.36.436-4.216a4 4 0 011.748-1.748C5.04 6 6.16 6 8.4 6h5.2c2.24 0 3.36 0 4.216.436a4 4 0 011.748 1.748C20 9.04 20 10.16 20 12.4v3.2c0 2.24 0 3.36-.436 4.216a4 4 0 01-1.748 1.748C16.96 22 15.84 22 13.6 22H8.4c-2.24 0-3.36 0-4.216-.436a4 4 0 01-1.748-1.748C2 18.96 2 17.84 2 15.6zM22 11.481c0-.175 0-.262.026-.34a.5.5 0 01.111-.184c.056-.06.134-.1.288-.182l2.226-1.18c.775-.41 1.163-.616 1.479-.575a1 1 0 01.685.413c.185.26.185.699.185 1.576v5.982c0 .877 0 1.316-.185 1.576a1 1 0 01-.685.413c-.316.041-.704-.164-1.48-.575l-2.225-1.18c-.154-.082-.232-.123-.288-.182a.5.5 0 01-.11-.184C22 16.78 22 16.694 22 16.519z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28VideocamProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28Videocam: FC<Icon28VideocamProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28Videocam as any).mountIcon = mountIcon;

export default Icon28Videocam;
