import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'video_circle_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="video_circle_outline_24"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M12 15.637l3.3-1.905c1.333-.77 1.333-2.694 0-3.464L12 8.363c-1.333-.77-3 .192-3 1.732v3.81c0 1.54 1.667 2.502 3 1.732zm2.4-3.81a.2.2 0 010 .346l-3.3 1.905a.2.2 0 01-.3-.173v-3.81a.2.2 0 01.3-.173z" /><path d="M5 5a9.872 9.872 0 017-2.9c2.734 0 5.21 1.11 7 2.9a9.872 9.872 0 012.9 7c0 2.733-1.11 5.21-2.9 7a9.872 9.872 0 01-7 2.9A9.872 9.872 0 015 19a9.872 9.872 0 01-2.9-7c0-2.734 1.11-5.21 2.9-7zm7-1.1c-2.237 0-4.26.906-5.728 2.372S3.9 9.763 3.9 12c0 2.237.906 4.26 2.372 5.728S9.763 20.1 12 20.1s4.26-.906 5.728-2.373S20.1 14.237 20.1 12s-.906-4.26-2.372-5.728S14.237 3.9 12 3.9z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24VideoCircleOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24VideoCircleOutline: FC<Icon24VideoCircleOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24VideoCircleOutline as any).mountIcon = mountIcon;

export default Icon24VideoCircleOutline;
