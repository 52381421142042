import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'history_outline_56';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="history_outline_56"><g fill-rule="nonzero" fill="none"><path d="M0 0h56v56H0z" /><path d="M28 4c13.255 0 24 10.745 24 24S41.255 52 28 52a23.94 23.94 0 01-17.887-7.998A1.5 1.5 0 0112.348 42 20.94 20.94 0 0028 49c11.598 0 21-9.402 21-21S39.598 7 28 7a20.96 20.96 0 00-16.494 8H16.5c1.944 0 1.998 2.836.162 2.993L16.5 18H8a1.5 1.5 0 01-1.493-1.356L6.5 16.5V8a1.5 1.5 0 012.993-.144L9.5 8v4.71A23.956 23.956 0 0128 4zm0 12a1.5 1.5 0 011.493 1.356l.007.144v9.879l6.06 6.06a1.5 1.5 0 01.104 2.008l-.103.114a1.5 1.5 0 01-2.008.103l-.114-.103-6.5-6.5a1.5 1.5 0 01-.432-.913L26.5 28V17.5A1.5 1.5 0 0128 16z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56HistoryOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56HistoryOutline: FC<Icon56HistoryOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56HistoryOutline as any).mountIcon = mountIcon;

export default Icon56HistoryOutline;
