import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'zip_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="zip_outline_28"><path clip-rule="evenodd" d="M14 4h1.2c1.573 0 2.633.002 3.45.068.793.065 1.18.181 1.439.313a3.5 3.5 0 011.53 1.53c.132.26.248.646.313 1.44.066.816.068 1.876.068 3.449v6.4c0 1.573-.002 2.633-.068 3.45-.065.793-.181 1.18-.314 1.439a3.5 3.5 0 01-1.529 1.53c-.26.132-.646.248-1.44.313-.816.066-1.876.068-3.449.068h-2.4c-1.573 0-2.633-.002-3.45-.068-.793-.065-1.18-.181-1.439-.313a3.5 3.5 0 01-1.53-1.53c-.132-.26-.248-.646-.313-1.44C6.002 19.834 6 18.774 6 17.2v-6.4c0-1.573.002-2.633.068-3.45.065-.793.181-1.18.313-1.439a3.5 3.5 0 011.53-1.53c.26-.132.646-.248 1.44-.313.67-.054 1.503-.065 2.649-.067V5.2c0 .28 0 .42.054.527a.5.5 0 00.219.219C12.38 6 12.52 6 12.8 6H14v2h-1.2c-.28 0-.42 0-.527.055a.5.5 0 00-.218.218C12 8.38 12 8.52 12 8.8v.4c0 .28 0 .42.054.527a.5.5 0 00.219.219c.107.054.247.054.527.054H14v1.2c0 .28 0 .42.054.527a.5.5 0 00.219.219c.107.054.247.054.527.054h.4c.28 0 .42 0 .527-.054a.5.5 0 00.218-.219C16 11.62 16 11.48 16 11.2v-.4c0-.28 0-.42-.054-.527a.5.5 0 00-.219-.218C15.62 10 15.48 10 15.2 10H14V8h1.2c.28 0 .42 0 .527-.054a.5.5 0 00.218-.219C16 7.62 16 7.48 16 7.2v-.4c0-.28 0-.42-.054-.527a.5.5 0 00-.219-.218C15.62 6 15.48 6 15.2 6H14zM4 10.8c0-3.08 0-4.62.6-5.797A5.5 5.5 0 017.002 2.6C8.18 2 9.72 2 12.8 2h2.4c3.08 0 4.62 0 5.797.6A5.5 5.5 0 0123.4 5.002C24 6.18 24 7.72 24 10.8v6.4c0 3.08 0 4.62-.6 5.797a5.5 5.5 0 01-2.403 2.404C19.82 26 18.28 26 15.2 26h-2.4c-3.08 0-4.62 0-5.797-.6a5.5 5.5 0 01-2.404-2.403C4 21.82 4 20.28 4 17.2zM16 16v-1.2c0-.28 0-.42-.054-.527a.5.5 0 00-.219-.218C15.62 14 15.48 14 15.2 14h-2.4c-.28 0-.42 0-.527.054a.5.5 0 00-.218.219C12 14.38 12 14.52 12 14.8V16a2 2 0 104 0z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28ZipOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28ZipOutline: FC<Icon28ZipOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28ZipOutline as any).mountIcon = mountIcon;

export default Icon28ZipOutline;
