import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'music_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="music_36"><path d="M28.5 21.728c0 5.207-1.854 6.33-4.921 6.742-1.594.214-4.079-.621-4.079-3.648 0-1.48 1.232-2.948 2.769-3.25 1.425-.28-.463.086 2.763-.528.52-.1.475-.47.475-.87l-.002-5.152-.005.027.007-3.864c0-.355-.11-.522-.517-.42L14.047 12.76c-.457.115-.547.305-.547.741v11.228c0 5.207-1.854 6.33-4.921 6.742-1.594.214-4.079-.621-4.079-3.648 0-1.48 1.232-2.948 2.769-3.25 1.425-.28-.463.086 2.763-.528.52-.1.475-.47.475-.87l-.003-5.59v-.956l-.004-3.804s-.006-2.36 0-3.54c.007-1.412.765-1.93 3-2.39l12-2.217s3-.938 3 1.5z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36MusicProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36Music: FC<Icon36MusicProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36Music as any).mountIcon = mountIcon;

export default Icon36Music;
