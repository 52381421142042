import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'cube_box_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="cube_box_outline_24"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.155 5.502l-.1.044c-.45.2-.866.383-1.185.689a2.5 2.5 0 00-.622.957c-.15.416-.149.87-.148 1.362v6.992c-.001.457-.002.893.142 1.293a2.4 2.4 0 00.598.92c.306.293.705.47 1.123.654l.097.043 6.353 2.823c.402.18.74.33 1.104.392a2.9 2.9 0 00.966 0c.363-.061.702-.212 1.104-.392l6.353-2.823.096-.043c.419-.185.818-.361 1.124-.655a2.4 2.4 0 00.598-.92c.144-.399.143-.835.142-1.292V8.554c0-.491.002-.946-.148-1.362a2.5 2.5 0 00-.622-.957c-.32-.306-.735-.49-1.184-.689l-.1-.044-6.259-2.781c-.402-.18-.74-.33-1.104-.392a2.9 2.9 0 00-.966 0c-.363.062-.702.213-1.104.392L4.155 5.5zm8.028-1.398a1.1 1.1 0 00-.366 0c-.11.019-.23.065-.751.296l-1.35.6L16.5 8.015 18.784 7l-5.85-2.6c-.52-.231-.64-.277-.75-.296zM20.1 8.385l-3.23 1.436a.252.252 0 01-.006.003l-3.276 1.456c-.246.109-.468.208-.687.283v8.052l6.31-2.804c.578-.257.657-.306.705-.353a.599.599 0 00.15-.23c.022-.063.035-.154.035-.788V8.664l-.001-.279zM11.1 11.563c-.219-.075-.441-.174-.687-.284L3.9 8.386l-.001.279v6.776c0 .634.013.725.036.789a.599.599 0 00.149.23c.048.046.127.095.706.352l6.309 2.804v-8.052zM5.216 7l5.85 2.6c.52.231.64.277.75.296.122.02.246.02.367 0 .11-.019.23-.065.751-.296l1.35-.6L7.5 5.985 5.216 7z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24CubeBoxOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24CubeBoxOutline: FC<Icon24CubeBoxOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24CubeBoxOutline as any).mountIcon = mountIcon;

export default Icon24CubeBoxOutline;
