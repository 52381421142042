import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'mention_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="mention_16"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.591 14.204c-.546.18-1.369.296-2.358.296-3.932 0-6.733-2.437-6.733-6.461C1.5 4.092 4.222 1.5 8.146 1.5c3.711 0 6.354 2.296 6.354 5.83 0 2.351-1.155 3.986-3.03 3.986-.997 0-1.741-.444-1.9-1.23h-.102c-.333.794-1.02 1.238-1.939 1.238-1.63 0-2.745-1.331-2.745-3.293 0-1.923 1.1-3.215 2.666-3.215.87 0 1.598.397 1.915 1.098h.095v-.335c0-.483.277-.763.744-.763.474 0 .743.28.743.763v3.682c0 .537.293.849.831.849.83 0 1.393-1.09 1.393-2.655 0-3.075-2.2-4.795-5.033-4.795-3.188 0-5.309 2.203-5.309 5.418 0 3.308 2.263 5.254 5.65 5.254.712 0 1.21-.093 1.843-.233a1.74 1.74 0 01.325-.031c.364 0 .562.187.562.482 0 .288-.167.498-.618.654zm-4.185-6.15c0 1.223.538 1.962 1.456 1.962.925 0 1.519-.755 1.519-1.97 0-1.19-.586-1.938-1.512-1.938-.925 0-1.463.724-1.463 1.946z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16MentionProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16Mention: FC<Icon16MentionProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16Mention as any).mountIcon = mountIcon;

export default Icon16Mention;
