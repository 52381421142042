import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'picture_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="picture_outline_24"><path d="M9.41 2.1l5.513.002c1.944.02 2.926.228 3.944.772a5.444 5.444 0 012.259 2.26c.574 1.074.774 2.108.774 4.276v5.18c0 2.168-.2 3.202-.774 4.277a5.444 5.444 0 01-2.26 2.259c-1.074.574-2.108.774-4.276.774H9.41c-2.168 0-3.202-.2-4.277-.774a5.443 5.443 0 01-2.259-2.26C2.3 17.793 2.1 16.759 2.1 14.59l.002-5.513c.02-1.944.228-2.926.772-3.944a5.443 5.443 0 012.26-2.259C6.207 2.3 7.241 2.1 9.41 2.1zm5.68 11.192l-3.728 4.53a.9.9 0 01-1.266.124L8.054 16.27l-2.806 2.755c.22.197.465.368.734.512.696.373 1.373.528 2.866.557l.562.005h5.18l.562-.005c1.318-.025 2-.15 2.62-.434l.246-.123a3.643 3.643 0 001.52-1.52c.1-.187.185-.372.255-.572zm-.5-9.392H9.41l-.562.005c-1.318.025-2 .15-2.62.434l-.246.122a3.644 3.644 0 00-1.52 1.521l-.123.246C4.016 6.931 3.9 7.713 3.9 9.41v5.18l.005.562c.022 1.133.117 1.796.324 2.353l3.14-3.085a.9.9 0 011.202-.053l1.972 1.617 3.749-4.556a.9.9 0 011.29-.103l4.51 3.985.003-.158.005-.562V9.41l-.005-.562c-.025-1.318-.15-2-.434-2.62l-.123-.246a3.644 3.644 0 00-1.52-1.52c-.696-.373-1.373-.528-2.866-.557zM8.5 7a1.5 1.5 0 110 3 1.5 1.5 0 010-3z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24PictureOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24PictureOutline: FC<Icon24PictureOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24PictureOutline as any).mountIcon = mountIcon;

export default Icon24PictureOutline;
