import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'star_circle_fill_blue_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="star_circle_fill_blue_16"><linearGradient id="star_circle_fill_blue_16_a" gradientUnits="userSpaceOnUse" x1="-8" x2="8" y1="8" y2="24"><stop offset="0" stop-color="#70b2ff" /><stop offset="1" stop-color="#5c9ce6" /></linearGradient><path clip-rule="evenodd" d="M8 16A8 8 0 108 0a8 8 0 000 16z" fill="url(#star_circle_fill_blue_16_a)" fill-rule="evenodd" /><path d="M9.624 5.76l2.151.21c.748.074.978.822.4 1.314l-1.684 1.431.625 2.333c.206.766-.431 1.23-1.07.768l-2.045-1.478-2.046 1.479c-.635.459-1.275-.003-1.07-.77l.626-2.332-1.684-1.432c-.58-.493-.352-1.239.4-1.312l2.15-.211.947-2.235c.297-.7 1.057-.7 1.353 0z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16StarCircleFillBlueProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16StarCircleFillBlue: FC<Icon16StarCircleFillBlueProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16StarCircleFillBlue as any).mountIcon = mountIcon;

export default Icon16StarCircleFillBlue;
