import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'gift_32';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="gift_32"><g fill="none" fill-rule="evenodd"><path d="M0 0h32v32H0z" /><path d="M27.996 13.002V15.5a1.5 1.5 0 01-1.5 1.5h-1.499v9.004a3 3 0 01-3 3H9.996a3 3 0 01-3-3V17h1.333H5.5A1.5 1.5 0 014 15.5v-2.498a3 3 0 013-3h17.996a3 3 0 013 3zM16.27 10H17v7h8v2h-8v10h-2V19H7v-2h8v-7h.723C11.787 9.941 7.33 8.623 7.33 6.669c0-2 2.667-4 4.667-3.334 1.333.445 2.666 2.666 3.999 6.665h.001c1.333-3.999 2.667-6.22 4-6.665 2-.666 4.666 1.334 4.666 3.334 0 1.954-4.456 3.272-8.392 3.331z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32GiftProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32Gift: FC<Icon32GiftProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32Gift as any).mountIcon = mountIcon;

export default Icon32Gift;
