import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'keyboard_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="keyboard_outline_28"><g fill-rule="nonzero" fill="none"><path d="M0 0h28v28H0z" /><path d="M9.692 4h8.616c2.675 0 3.645.278 4.623.801a5.452 5.452 0 012.268 2.268c.523.978.801 1.948.801 4.623v4.616c0 2.675-.278 3.645-.801 4.623a5.452 5.452 0 01-2.268 2.268c-.978.523-1.948.801-4.623.801H9.692c-2.675 0-3.645-.278-4.623-.801a5.452 5.452 0 01-2.268-2.268C2.278 19.953 2 18.983 2 16.308v-4.616c0-2.675.278-3.645.801-4.623A5.452 5.452 0 015.07 4.801C6.047 4.278 7.017 4 9.692 4zm0 2c-2.137 0-2.897.147-3.68.565a3.453 3.453 0 00-1.447 1.448C4.147 8.795 4 9.555 4 11.692v4.616c0 2.137.147 2.897.565 3.68.337.629.818 1.11 1.448 1.447.782.418 1.542.565 3.679.565h8.616c2.137 0 2.897-.147 3.68-.565a3.453 3.453 0 001.447-1.448c.418-.782.565-1.542.565-3.679v-4.616c0-2.137-.147-2.897-.565-3.68a3.453 3.453 0 00-1.448-1.447C21.205 6.147 20.445 6 18.308 6H9.692zM8 11.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm4 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm4 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm4 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm0 4.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm-4 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm-4 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm-4 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm1 2h10a1 1 0 010 2H9a1 1 0 010-2z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28KeyboardOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28KeyboardOutline: FC<Icon28KeyboardOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28KeyboardOutline as any).mountIcon = mountIcon;

export default Icon28KeyboardOutline;
