import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'user_outline_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="user_outline_16"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M11.75 5a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zm-1.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zM8 9.5c-1.963 0-3.485.251-4.54.804-.54.283-.976.655-1.275 1.127A2.886 2.886 0 001.75 13v.25c0 .967.784 1.75 1.75 1.75h9a1.75 1.75 0 001.75-1.75V13c0-.56-.134-1.094-.435-1.57-.299-.471-.735-.843-1.275-1.125C11.485 9.75 9.963 9.5 8 9.5zM3.25 13c0-.315.073-.562.202-.767.132-.207.349-.414.704-.6C4.889 11.249 6.117 11 8 11s3.111.249 3.844.633c.355.186.573.393.704.6.13.205.202.452.202.767v.25a.25.25 0 01-.25.25h-9a.25.25 0 01-.25-.25z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16UserOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16UserOutline: FC<Icon16UserOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16UserOutline as any).mountIcon = mountIcon;

export default Icon16UserOutline;
