import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'tear_off_flyer_outline_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="tear_off_flyer_outline_36"><g fill="currentColor"><path clip-rule="evenodd" d="M7.946 5.03C9.35 4.28 10.733 4 13.89 4h8.222c3.156 0 4.539.28 5.942 1.03a7.026 7.026 0 012.917 2.916C31.72 9.35 32 10.733 32 13.89v8.222c0 3.156-.28 4.539-1.03 5.942a7.026 7.026 0 01-2.916 2.917C26.65 31.72 25.267 32 22.11 32h-8.222c-3.156 0-4.539-.28-5.943-1.03a7.026 7.026 0 01-2.916-2.916C4.28 26.65 4 25.267 4 22.11v-8.222c0-3.156.28-4.539 1.03-5.943A7.027 7.027 0 017.946 5.03zM13.89 6.5c-3.013 0-3.902.274-4.764.735a4.527 4.527 0 00-1.89 1.89c-.46.862-.735 1.751-.735 4.764v8.222c0 3.013.274 3.902.735 4.763a4.527 4.527 0 001.89 1.891c.862.46 1.751.735 4.764.735h8.222c3.013 0 3.902-.274 4.763-.735a4.527 4.527 0 001.891-1.89c.46-.862.735-1.751.735-4.764v-8.222c0-3.013-.274-3.902-.735-4.764a4.527 4.527 0 00-1.89-1.89c-.862-.46-1.751-.735-4.764-.735z" fill-rule="evenodd" /><path d="M12 25.25a1.25 1.25 0 112.5 0v5.5a1.25 1.25 0 11-2.5 0zM21.5 25.25a1.25 1.25 0 112.5 0v5.5a1.25 1.25 0 11-2.5 0zM9.5 18.25c0-.69.56-1.25 1.25-1.25h14.5a1.25 1.25 0 110 2.5h-14.5c-.69 0-1.25-.56-1.25-1.25zM9.5 12.25c0-.69.56-1.25 1.25-1.25h14.5a1.25 1.25 0 110 2.5h-14.5c-.69 0-1.25-.56-1.25-1.25z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36TearOffFlyerOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36TearOffFlyerOutline: FC<Icon36TearOffFlyerOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36TearOffFlyerOutline as any).mountIcon = mountIcon;

export default Icon36TearOffFlyerOutline;
