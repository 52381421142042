import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'repost_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="repost_16"><path d="M8.583 1.914l.061-.087a.6.6 0 01.758-.128l.088.063 3.272 2.805.04.043a.6.6 0 01.01.768l-.075.078L9.49 8.238a.6.6 0 01-.982-.358L8.5 7.783V5.999L5 6a1 1 0 00-.993.883L4 7v4a1 1 0 00.883.993L5 12h4a1 1 0 01.117 1.993L9 14H5a3 3 0 01-2.995-2.824L2 11V7a3 3 0 012.824-2.995L5 4h3.5V2.216a.6.6 0 01.083-.303l.061-.087z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16RepostProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16Repost: FC<Icon16RepostProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16Repost as any).mountIcon = mountIcon;

export default Icon16Repost;
