import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'document_outline_32';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="document_outline_32"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.255 4.585a3.75 3.75 0 00-3.75 3.75V23.75a3.75 3.75 0 003.75 3.75h9.995A3.75 3.75 0 0025 23.75v-9.588L24.997 14H18.75A2.75 2.75 0 0116 11.25V4.61a2.75 2.75 0 00-.366-.025h-4.379zM18.5 6.282v4.968c0 .138.112.25.25.25h5.141a3.442 3.442 0 00-.033-.033L18.5 6.282zM5.005 8.335a6.25 6.25 0 016.25-6.25h4.38a5.249 5.249 0 013.65 1.478l6.311 6.108a6.25 6.25 0 011.904 4.49v9.589A6.25 6.25 0 0121.25 30h-9.995a6.25 6.25 0 01-6.25-6.25V8.335z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32DocumentOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32DocumentOutline: FC<Icon32DocumentOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32DocumentOutline as any).mountIcon = mountIcon;

export default Icon32DocumentOutline;
