import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'microphone_slash_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="microphone_slash_24"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.016 4.474c-.263-.328-.302-.794-.025-1.11A4 4 0 0116 6v5c0 .79-.861 1.102-1.382.454l-5.602-6.98zM17.53 15.08c.373.464 1.084.463 1.384-.052a7.95 7.95 0 00.83-2.009c.139-.533-.292-1.019-.843-1.019-.45 0-.826.329-.953.76a6.198 6.198 0 01-.49 1.185c-.196.362-.187.813.072 1.135z" fill="currentColor" /><path d="M8 11c0 2.493 2.37 4.407 4.79 3.922l1.483 1.848a6.203 6.203 0 01-8.22-4.01C5.926 12.33 5.55 12 5.1 12c-.551 0-.982.486-.843 1.02a8.007 8.007 0 006.843 5.93v2.15a.9.9 0 101.8 0v-2.15c.9-.1 1.756-.351 2.54-.726l1.958 2.44a.9.9 0 001.404-1.127l-12.2-15.2a.9.9 0 00-1.404 1.126L8 8.954V11z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24MicrophoneSlashProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24MicrophoneSlash: FC<Icon24MicrophoneSlashProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24MicrophoneSlash as any).mountIcon = mountIcon;

export default Icon24MicrophoneSlash;
