import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'microphone_videocam_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="microphone_videocam_outline_28"><path fill-rule="evenodd" clip-rule="evenodd" d="M5 9.5a4.5 4.5 0 019 0v4a4.5 4.5 0 11-9 0v-4zM9.5 7A2.5 2.5 0 007 9.5v4a2.5 2.5 0 005 0v-4A2.5 2.5 0 009.5 7z" fill="currentColor" /><path d="M10.5 21.942a8.504 8.504 0 006.366-4.198 1 1 0 00-1.732-1A6.497 6.497 0 019.5 20a6.497 6.497 0 01-5.634-3.256 1 1 0 00-1.732 1A8.503 8.503 0 008.5 21.942V23a1 1 0 102 0v-1.058z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M17 12a1 1 0 110-2c1.686.009 2.317.194 2.953.534.652.349 1.164.86 1.512 1.512.188.35.328.699.418 1.223l1.057-.66c1.332-.833 3.06.124 3.06 1.695v4.392c0 1.57-1.728 2.528-3.06 1.696l-1.057-.66c-.09.523-.23.872-.418 1.222a3.636 3.636 0 01-1.512 1.512c-.632.337-1.259.522-2.92.534H17a1 1 0 110-2c1.221-.005 1.611-.085 2.01-.298a1.63 1.63 0 00.691-.692c.22-.411.298-.814.298-2.138V14.89c-.01-1.136-.091-1.515-.298-1.9a1.636 1.636 0 00-.692-.692c-.398-.213-.788-.293-2.009-.298zm5 5.446l2 1.25v-4.392l-2 1.25v1.892z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28MicrophoneVideocamOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28MicrophoneVideocamOutline: FC<Icon28MicrophoneVideocamOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28MicrophoneVideocamOutline as any).mountIcon = mountIcon;

export default Icon28MicrophoneVideocamOutline;
