import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'dollar_circle_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="dollar_circle_outline_24"><path fill-rule="evenodd" clip-rule="evenodd" d="M5 5a9.872 9.872 0 017-2.9c2.734 0 5.21 1.11 7 2.9a9.872 9.872 0 012.9 7c0 2.733-1.11 5.21-2.9 7a9.872 9.872 0 01-7 2.9A9.872 9.872 0 015 19a9.872 9.872 0 01-2.9-7c0-2.734 1.11-5.21 2.9-7zm7-1.1c-2.237 0-4.26.906-5.728 2.372A8.072 8.072 0 003.9 12c0 2.237.906 4.26 2.372 5.727A8.072 8.072 0 0012 20.1c2.237 0 4.26-.906 5.728-2.373A8.072 8.072 0 0020.1 12c0-2.237-.906-4.26-2.372-5.728A8.072 8.072 0 0012 3.9z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M9.748 7.768c.626-.463 1.418-.668 2.18-.668 1.252 0 2.61.543 3.087 1.8a.9.9 0 11-1.683.639c-.103-.273-.538-.639-1.404-.639-.463 0-.856.128-1.11.315a.838.838 0 00-.36.722c0 .196.042.308.084.38a.784.784 0 00.267.249c.323.203.792.335 1.45.51l.055.014c.573.152 1.312.347 1.898.722.321.204.628.48.851.86.226.384.337.824.337 1.309 0 1.033-.52 1.802-1.232 2.277-.68.454-1.526.642-2.301.642-1.264 0-2.432-.449-3.109-1.434a.9.9 0 011.484-1.019c.243.354.756.653 1.624.653.517 0 .989-.13 1.302-.34.282-.187.432-.428.432-.78a.753.753 0 00-.088-.395.787.787 0 00-.269-.257c-.322-.205-.79-.339-1.445-.513l-.071-.018c-.569-.15-1.298-.344-1.877-.708a2.56 2.56 0 01-.853-.85 2.5 2.5 0 01-.34-1.302c0-.943.44-1.687 1.09-2.169z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M12 5.6a.9.9 0 01.9.9v1a.9.9 0 11-1.8 0v-1a.9.9 0 01.9-.9zm0 10a.9.9 0 01.9.9v1a.9.9 0 01-1.8 0v-1a.9.9 0 01.9-.9z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24DollarCircleOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24DollarCircleOutline: FC<Icon24DollarCircleOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24DollarCircleOutline as any).mountIcon = mountIcon;

export default Icon24DollarCircleOutline;
