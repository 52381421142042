import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'newsfeed_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="newsfeed_outline_24"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.228 3.1c-1.57 0-2.32.127-3.082.534a3.647 3.647 0 00-1.512 1.512c-.407.762-.534 1.512-.534 3.082v7.544c0 1.57.127 2.32.534 3.082.348.65.863 1.164 1.512 1.512.762.407 1.512.534 3.082.534h7.544c1.57 0 2.32-.127 3.082-.534a3.647 3.647 0 001.512-1.512c.407-.762.534-1.512.534-3.082V8.228c0-1.57-.127-2.32-.534-3.082a3.647 3.647 0 00-1.512-1.512c-.762-.407-1.512-.534-3.082-.534H8.228zM5.995 5.221c.362-.193.746-.321 2.233-.321h7.544c1.487 0 1.87.128 2.233.321.336.18.594.438.774.774.187.352.314.724.32 2.105H4.9c.008-1.381.134-1.753.322-2.105.18-.336.437-.594.773-.774zM4.9 9.9v5.872c0 1.487.128 1.87.322 2.233.18.336.437.594.773.773.362.194.746.322 2.233.322h7.544c1.487 0 1.87-.128 2.233-.322.336-.18.594-.437.774-.773.193-.362.321-.746.321-2.233V9.9H4.9z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24NewsfeedOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24NewsfeedOutline: FC<Icon24NewsfeedOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24NewsfeedOutline as any).mountIcon = mountIcon;

export default Icon24NewsfeedOutline;
