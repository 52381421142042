import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'article_box_outline_20';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="article_box_outline_20"><g fill-rule="nonzero" fill="none"><path opacity=".527" d="M0 0h20v20H0z" /><path d="M6.916 2h6.168c1.434 0 2.132.135 2.857.523.66.353 1.183.876 1.536 1.536.388.725.523 1.423.523 2.857v6.168c0 1.434-.135 2.132-.523 2.857a3.703 3.703 0 01-1.536 1.536c-.725.388-1.423.523-2.857.523H6.916c-1.434 0-2.132-.135-2.857-.523a3.703 3.703 0 01-1.536-1.536C2.135 15.216 2 14.518 2 13.084V6.916c0-1.434.135-2.132.523-2.857a3.703 3.703 0 011.536-1.536C4.784 2.135 5.482 2 6.916 2zm0 1.5c-1.207 0-1.671.09-2.15.345a2.203 2.203 0 00-.92.921c-.256.479-.346.943-.346 2.15v6.168c0 1.207.09 1.671.345 2.15.214.399.522.707.921.92.479.256.943.346 2.15.346h6.168c1.207 0 1.671-.09 2.15-.345.399-.214.707-.522.92-.921.256-.479.346-.943.346-2.15V6.916c0-1.207-.09-1.671-.345-2.15a2.203 2.203 0 00-.921-.92c-.479-.256-.943-.346-2.15-.346H6.916zM6.75 6h6.5a.75.75 0 110 1.5h-6.5a.75.75 0 010-1.5zm0 3.25h6.5a.75.75 0 110 1.5h-6.5a.75.75 0 110-1.5zm0 3.25h4.5a.75.75 0 110 1.5h-4.5a.75.75 0 110-1.5z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20ArticleBoxOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20ArticleBoxOutline: FC<Icon20ArticleBoxOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20ArticleBoxOutline as any).mountIcon = mountIcon;

export default Icon20ArticleBoxOutline;
