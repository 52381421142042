import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'attach_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="attach_24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" /><path d="M18.338 14.297l-4.557 4.546a4.614 4.614 0 01-6.526-.007l-.04-.04a4.993 4.993 0 01.017-6.983l6.827-6.947a2.85 2.85 0 014.1.037c1.173 1.238 1.16 3.133-.001 4.326L11.28 16.29a1.1 1.1 0 01-1.496.074l-.074-.062a.94.94 0 01-.076-1.367l4.632-4.875a.9.9 0 00-1.305-1.24L8.33 13.696a2.74 2.74 0 00.222 3.985l.074.062a2.9 2.9 0 003.944-.196l6.879-7.063a4.9 4.9 0 00-.098-6.936 4.65 4.65 0 00-6.575.056l-6.827 6.947a6.793 6.793 0 00-.022 9.501l.055.055a6.414 6.414 0 009.07.01l4.558-4.545a.9.9 0 10-1.271-1.275z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24AttachProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Attach: FC<Icon24AttachProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Attach as any).mountIcon = mountIcon;

export default Icon24Attach;
