import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'play_rectangle_stack_outline_32';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="play_rectangle_stack_outline_32"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.2 8.5h13.673c.496 0 1.007 0 1.444.036.488.04 1.1.136 1.726.454a4.5 4.5 0 011.966 1.967c.319.625.416 1.238.456 1.726.035.437.035.948.035 1.444v7.746c0 .496 0 1.007-.035 1.444-.04.488-.137 1.1-.456 1.726a4.5 4.5 0 01-1.966 1.966c-.625.319-1.238.416-1.726.455a18.62 18.62 0 01-1.444.036H9.127c-.496 0-1.007 0-1.444-.036-.488-.04-1.1-.136-1.726-.454a4.5 4.5 0 01-1.967-1.967c-.318-.625-.415-1.238-.454-1.726-.036-.437-.036-.948-.036-1.444v-7.746c0-.496 0-1.007.036-1.444.04-.488.136-1.1.454-1.726A4.5 4.5 0 015.957 8.99c.625-.318 1.238-.415 1.726-.454A18.654 18.654 0 019.2 8.5zm-2.982 3.592C6 12.52 6 13.08 6 14.2v7.6c0 1.12 0 1.68.218 2.108a2 2 0 00.874.874C7.52 25 8.08 25 9.2 25h13.6c1.12 0 1.68 0 2.108-.218a2 2 0 00.874-.874C26 23.48 26 22.92 26 21.8v-7.6c0-1.12 0-1.68-.218-2.108a2 2 0 00-.874-.874C24.48 11 23.92 11 22.8 11H9.2c-1.12 0-1.68 0-2.108.218a2 2 0 00-.874.874z" fill="currentColor" /><path d="M9 6a2 2 0 012-2h10a2 2 0 012 2v.5H9V6zm5.599 8.174a.571.571 0 00-.885.478v5.918a.572.572 0 00.872.486l4.65-2.87a.57.57 0 00.013-.964l-4.65-3.048z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32PlayRectangleStackOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32PlayRectangleStackOutline: FC<Icon32PlayRectangleStackOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32PlayRectangleStackOutline as any).mountIcon = mountIcon;

export default Icon32PlayRectangleStackOutline;
