import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'fire_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="fire_56"><path d="M27.617 4.02l.192.035.156.04a2.25 2.25 0 011.546 2.65c-.142.63-.333 1.146-.603 1.563-4.49 6.934-3.726 11.774-.397 12.267 3.097.458 4.918-1.54 4.538-5.845l-.028-.284-.268-2.49a2.19 2.19 0 013.383-2.06c1.439.949 3.075 2.588 4.953 4.93 5.34 6.66 6.983 12.456 6.908 18.399C47.86 44.22 39.682 52 28.5 52 17.268 52 9 44.174 9 33.197c.057-9.65 5.241-20.142 14.954-27.28l.613-.443 1.408-1.031a2.25 2.25 0 011.642-.423z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56FireProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56Fire: FC<Icon56FireProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56Fire as any).mountIcon = mountIcon;

export default Icon56Fire;
