import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'school_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="school_outline_28"><path clip-rule="evenodd" d="M14.637 6.104a2 2 0 00-1.274 0c-.17.057-.38.177-.724.483-.353.314-.774.754-1.39 1.402l-3.525 3.7A1 1 0 017 12H4v7c0 .717 0 1.194.03 1.56.03.356.081.518.133.621a1.5 1.5 0 00.656.655c.103.053.265.104.62.133C5.806 22 6.283 22 7 22h3.5v-3.5a3.5 3.5 0 117 0V22H21c.717 0 1.194 0 1.56-.03.356-.03.518-.081.621-.133a1.5 1.5 0 00.655-.656c.053-.103.104-.265.133-.62C24 20.194 24 19.717 24 19v-7h-3a1 1 0 01-.724-.31l-3.524-3.7c-.617-.649-1.038-1.09-1.39-1.403-.345-.306-.555-.426-.725-.483zM15.5 18.5V22h-3v-3.5a1.5 1.5 0 013 0zM7 24h14.04c.666 0 1.226 0 1.683-.037.48-.04.934-.124 1.366-.345a3.5 3.5 0 001.53-1.529c.22-.432.304-.887.344-1.366.037-.457.037-1.017.037-1.683V10.96c0-.666 0-1.226-.037-1.683-.04-.48-.124-.934-.345-1.366a3.5 3.5 0 00-1.529-1.53c-.432-.22-.887-.305-1.366-.344C22.266 6 21.706 6 21.04 6h-3.423a17.647 17.647 0 00-.927-.908c-.438-.389-.883-.705-1.417-.884a4 4 0 00-2.546 0c-.534.18-.98.495-1.417.884-.28.25-.584.554-.927.908H6.96c-.666 0-1.226 0-1.683.037-.48.04-.934.124-1.366.344a3.5 3.5 0 00-1.53 1.53c-.22.432-.305.887-.344 1.366C2 9.734 2 10.294 2 10.96V19.04c0 .666 0 1.226.037 1.683.04.48.124.934.344 1.366a3.5 3.5 0 001.53 1.53c.432.22.887.304 1.366.344C5.734 24 6.294 24 6.96 24zm16.994-14a9.244 9.244 0 00-.025-.56c-.029-.356-.08-.518-.133-.621a1.5 1.5 0 00-.655-.656c-.103-.052-.265-.103-.62-.132C22.194 8 21.717 8 21 8h-1.476l1.905 2zM8.476 8H7c-.717 0-1.194 0-1.56.03-.356.03-.518.081-.621.133a1.5 1.5 0 00-.656.656c-.052.103-.103.265-.132.62-.013.162-.021.345-.025.561H6.57zM14 9a1.5 1.5 0 100 3 1.5 1.5 0 000-3z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28SchoolOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28SchoolOutline: FC<Icon28SchoolOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28SchoolOutline as any).mountIcon = mountIcon;

export default Icon28SchoolOutline;
