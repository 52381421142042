import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'shuffle_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="shuffle_24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" /><path d="M19 15v-1.233a.502.502 0 01.808-.4l2.92 2.234a.502.502 0 010 .798l-2.92 2.233a.502.502 0 01-.808-.399V17h-3.5c-1.702 0-2.746-.844-4.5-3.262C9.246 16.156 8.202 17 6.5 17H4a1 1 0 010-2h2.5c.893 0 1.706-.763 3.282-3C8.206 9.763 7.392 9 6.5 9H4a1 1 0 110-2h2.5c1.702 0 2.746.844 4.5 3.262C12.754 7.844 13.798 7 15.5 7H19V5.767a.502.502 0 01.808-.4l2.92 2.234a.502.502 0 010 .798l-2.92 2.233a.502.502 0 01-.808-.399V9h-3.5c-.893 0-1.706.763-3.282 3 1.576 2.237 2.39 3 3.282 3H19z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24ShuffleProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Shuffle: FC<Icon24ShuffleProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Shuffle as any).mountIcon = mountIcon;

export default Icon24Shuffle;
