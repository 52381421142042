import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'video_fill_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="video_fill_24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" /><path d="M5.846 4h12.308c1.337 0 1.822.14 2.311.4.49.262.873.646 1.134 1.135.262.489.401.974.401 2.31v8.31c0 1.336-.14 1.821-.4 2.31a2.726 2.726 0 01-1.135 1.134c-.489.262-.974.401-2.31.401H5.844c-1.336 0-1.821-.14-2.31-.4A2.726 2.726 0 012.4 18.464c-.262-.489-.401-.974-.401-2.31v-8.31c0-1.336.14-1.821.4-2.31A2.726 2.726 0 013.536 4.4C4.024 4.139 4.509 4 5.845 4zm-.564 2c-.446 0-.607.046-.77.134a.909.909 0 00-.378.378c-.088.163-.134.324-.134.77v9.436c0 .446.046.607.134.77.087.163.215.291.378.378.163.088.324.134.77.134h13.436c.446 0 .607-.046.77-.134a.909.909 0 00.378-.378c.088-.163.134-.324.134-.77V7.282c0-.446-.046-.607-.134-.77a.909.909 0 00-.378-.378c-.163-.088-.324-.134-.77-.134H5.282zM9.6 13.8a.5.5 0 01.4-.8h4a.5.5 0 01.4.8l-2 2.667a.5.5 0 01-.8 0l-2-2.667zm0-3.6l2-2.667a.5.5 0 01.8 0l2 2.667a.5.5 0 01-.4.8h-4a.5.5 0 01-.4-.8z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24VideoFillProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24VideoFill: FC<Icon24VideoFillProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24VideoFill as any).mountIcon = mountIcon;

export default Icon24VideoFill;
