import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'hide_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="hide_24"><path d="M6.213 3.797L8.19 5.775l1.695 1.693 2.032 2.034h.002l2.58 2.579v.004l2.032 2.032a.04.04 0 00.001-.003l2.284 2.285-.002.001 1.386 1.387a1 1 0 01-1.32 1.498l-.095-.084-1.639-1.638C15.688 18.409 13.95 19 12 19c-6 0-10-5.6-10-7 0-.89 1.618-3.48 4.34-5.247L4.8 5.21a1 1 0 011.414-1.414zM7 12a5 5 0 008.322 3.737l-1.774-1.773a2.5 2.5 0 01-3.51-3.512L8.263 8.678A4.981 4.981 0 007 12zm5-7c6 0 10 5.6 10 7 0 .552-.621 1.755-1.74 3.012l-3.266-3.265a5 5 0 00-4.741-4.74l-1.87-1.87A9.628 9.628 0 0112 5z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24HideProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Hide: FC<Icon24HideProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Hide as any).mountIcon = mountIcon;

export default Icon24Hide;
