import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'hand_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="hand_20"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.9 2.325a.825.825 0 011.65 0v7.284a.4.4 0 00.8-.009V3.825a.825.825 0 111.65 0v9.803a12.65 12.65 0 001.51-2.089c.285-.486.91-.688 1.404-.416.4.22.591.686.429 1.113C16.658 14.031 14.595 18.5 11 18.5c-1.86-.006-2.878-.055-3.68-.463a4.25 4.25 0 01-1.857-1.857c-.346-.68-.434-1.518-.456-2.894A.834.834 0 015 13.175v-7.85a.825.825 0 111.65 0V10.1a.4.4 0 00.8.003V3.075a.825.825 0 111.65 0v6.53a.4.4 0 00.8-.005V2.325z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20HandProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20Hand: FC<Icon20HandProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20Hand as any).mountIcon = mountIcon;

export default Icon20Hand;
