import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'story_outline_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="story_outline_36"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.946 5.03C9.35 4.28 10.733 4 13.89 4h8.222c3.156 0 4.539.28 5.942 1.03a7.026 7.026 0 012.917 2.916C31.72 9.35 32 10.733 32 13.89v8.222c0 3.156-.28 4.539-1.03 5.942a7.026 7.026 0 01-2.916 2.917C26.65 31.72 25.267 32 22.11 32h-8.222c-3.156 0-4.539-.28-5.943-1.03a7.026 7.026 0 01-2.916-2.916C4.28 26.65 4 25.267 4 22.11v-8.222c0-3.156.28-4.539 1.03-5.943A7.027 7.027 0 017.946 5.03zM13.89 6.5c-3.013 0-3.902.274-4.764.735a4.527 4.527 0 00-1.89 1.89c-.46.862-.735 1.751-.735 4.764v8.222c0 3.013.274 3.902.735 4.764a4.527 4.527 0 001.89 1.89c.862.46 1.751.735 4.764.735h8.222c3.013 0 3.902-.274 4.764-.735a4.527 4.527 0 001.89-1.89c.46-.862.735-1.751.735-4.764v-8.222c0-3.013-.274-3.902-.735-4.764a4.527 4.527 0 00-1.89-1.89c-.862-.46-1.751-.735-4.764-.735h-8.222z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M18 13.75a4.25 4.25 0 100 8.5 4.25 4.25 0 000-8.5zM11.25 18a6.75 6.75 0 1113.5 0 6.75 6.75 0 01-13.5 0z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36StoryOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36StoryOutline: FC<Icon36StoryOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36StoryOutline as any).mountIcon = mountIcon;

export default Icon36StoryOutline;
