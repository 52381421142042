import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'replay_15_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="replay_15_36"><path d="M17.444 5.025c0-.41-.45-.662-.8-.447L10.19 8.544a.525.525 0 000 .894l6.453 3.966c.35.216.8-.036.8-.447v-2.84a9.375 9.375 0 010 18.75 9.378 9.378 0 01-9.213-7.63 1.125 1.125 0 00-2.211.417 11.628 11.628 0 0011.424 9.462c6.42 0 11.625-5.205 11.625-11.625S23.864 7.866 17.444 7.866v-2.84z" fill="currentColor" /><path d="M13.5 23.841a.525.525 0 01-.525-.525V17.95l-1.301.511a.42.42 0 01-.574-.39v-.73c0-.211.126-.402.32-.484l2.768-1.17a.525.525 0 01.73.483v7.147c0 .29-.236.525-.525.525H13.5zm3.371-2.317a.904.904 0 00-.215.61c0 .322.126.625.377.912.251.287.595.518 1.03.692.436.175.916.262 1.442.262.64 0 1.202-.123 1.686-.37a2.67 2.67 0 001.121-1.044c.263-.45.395-.97.395-1.564 0-.534-.11-1.003-.327-1.409a2.344 2.344 0 00-.915-.944c-.392-.225-.846-.337-1.36-.337-.345 0-.665.057-.959.171-.294.115-.512.266-.653.455h-.1l.13-1.781h2.954c.286 0 .505-.068.656-.202.151-.134.227-.326.227-.575 0-.252-.077-.449-.23-.59-.153-.14-.37-.21-.653-.21h-3.343c-.734 0-1.123.323-1.166.969l-.194 2.924a1.01 1.01 0 00.103.53.87.87 0 00.347.364 1.02 1.02 0 00.868.06c.114-.046.242-.125.383-.238.177-.128.36-.227.55-.298.19-.07.376-.105.556-.105.244 0 .463.053.657.16.194.106.345.255.453.448.108.192.162.413.162.662 0 .26-.057.491-.17.692a1.21 1.21 0 01-.475.469c-.202.112-.43.169-.685.169a1.48 1.48 0 01-.73-.184 2.2 2.2 0 01-.636-.569 1.129 1.129 0 00-.338-.288.822.822 0 00-.386-.09.706.706 0 00-.562.249z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36Replay15Props extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36Replay15: FC<Icon36Replay15Props> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36Replay15 as any).mountIcon = mountIcon;

export default Icon36Replay15;
