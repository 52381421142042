import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'microphone_slash_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="microphone_slash_outline_24"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.237 2.953A4.9 4.9 0 0116.9 7v2.953a.9.9 0 11-1.8 0V7a3.1 3.1 0 00-4.847-2.561.9.9 0 01-1.016-1.486zM8 6.37a.9.9 0 01.9.9V11a3.1 3.1 0 005.15 2.325.9.9 0 011.191 1.35A4.9 4.9 0 017.1 11V7.271a.9.9 0 01.9-.9z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M4.576 14.119a.9.9 0 011.225.345A7.098 7.098 0 0012 18.1a7.074 7.074 0 004.925-1.986.9.9 0 111.25 1.296A8.873 8.873 0 0112 19.9a8.898 8.898 0 01-7.77-4.556.9.9 0 01.346-1.225z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M12 18.1a.9.9 0 01.9.9v3a.9.9 0 01-1.8 0v-3a.9.9 0 01.9-.9zM4.364 3.364a.9.9 0 011.272 0l15 15a.9.9 0 11-1.272 1.272l-15-15a.9.9 0 010-1.272z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24MicrophoneSlashOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24MicrophoneSlashOutline: FC<Icon24MicrophoneSlashOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24MicrophoneSlashOutline as any).mountIcon = mountIcon;

export default Icon24MicrophoneSlashOutline;
