import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 12 12';
const id = 'fire_12';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" id="fire_12"><g fill="none" fill-rule="evenodd"><path d="M0 0h12v12H0z" /><path d="M6.092.787a.188.188 0 01.295.192.85.85 0 01-.1.27c-1.416 2.185-.794 3.5.16 3.64.988.147 1.71-.515 1.556-1.943l-.067-.622a.172.172 0 01.266-.162c.321.212.706.596 1.153 1.154 1.591 1.985 1.653 3.646 1.644 4.36C10.967 10.239 9.071 12 6.5 12 3.93 12 2 10.239 2 7.677c.011-1.94.991-4.67 3.696-6.6l.396-.29z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon12FireProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon12Fire: FC<Icon12FireProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 12,
    height: !isNaN(props.height) ? +props.height : 12,
  }));
};

(Icon12Fire as any).mountIcon = mountIcon;

export default Icon12Fire;
