import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'radiowaves_around_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="radiowaves_around_outline_28"><g fill="none" fill-rule="evenodd"><path d="M0 0h28v28H0z" /><path d="M14 2c6.627 0 12 5.373 12 12 0 3.93-1.904 7.542-5.043 9.779a1 1 0 01-1.161-1.629A9.984 9.984 0 0024 14c0-5.523-4.477-10-10-10S4 8.477 4 14a9.984 9.984 0 004.198 8.146 1 1 0 01-1.162 1.627A11.984 11.984 0 012 14C2 7.373 7.373 2 14 2zm0 5a7 7 0 014.496 12.366l-.033.027a1 1 0 01-1.276-1.54l.024-.02a5 5 0 10-6.402.016 1 1 0 11-1.278 1.539A7 7 0 0114 7zm0 5a2 2 0 110 4 2 2 0 010-4z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28RadiowavesAroundOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28RadiowavesAroundOutline: FC<Icon28RadiowavesAroundOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28RadiowavesAroundOutline as any).mountIcon = mountIcon;

export default Icon28RadiowavesAroundOutline;
