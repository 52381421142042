import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'message_reply_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="message_reply_outline_24"><path clip-rule="evenodd" d="M13.397 7.598l3.148 2.464c.206.16.31.241.385.277a.75.75 0 001.051-.512C18 9.745 18 9.614 18 9.353V8.4c1.1 0 1.967.34 2.609.743.343.216.515.323.577.344a.535.535 0 00.725-.526c0-.066-.03-.183-.09-.416C21.478 7.174 20.532 5.498 18 5.1V4.047c0-.261 0-.392-.019-.474a.75.75 0 00-1.05-.512c-.077.036-.18.116-.386.278l-3.148 2.463c-.173.136-.259.203-.316.268a.95.95 0 000 1.26c.057.065.143.133.316.268zM11.374 4.92a.9.9 0 00-.116-1.796C6.26 3.446 2.1 7.06 2.1 11.714c0 1.606.504 3.104 1.373 4.375-.035.29-.123.684-.288 1.2-.217.684-.55 1.525-1.005 2.531a1.272 1.272 0 001.21 1.795c2.2-.086 4.007-.638 5.257-1.792 1.049.328 2.178.506 3.353.506 5.098 0 9.48-3.4 9.872-7.963a.9.9 0 10-1.793-.154c-.294 3.42-3.686 6.317-8.079 6.317a9.362 9.362 0 01-3.287-.586.9.9 0 00-1.002.26c-.68.8-1.81 1.358-3.536 1.55a22.31 22.31 0 00.725-1.917c.242-.759.39-1.441.39-2.01a.9.9 0 00-.178-.537C4.338 14.248 3.9 13.021 3.9 11.714c0-3.472 3.17-6.517 7.474-6.794z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24MessageReplyOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24MessageReplyOutline: FC<Icon24MessageReplyOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24MessageReplyOutline as any).mountIcon = mountIcon;

export default Icon24MessageReplyOutline;
