import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'calendar_circle_fill_red_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="calendar_circle_fill_red_20"><linearGradient id="calendar_circle_fill_red_20_a" gradientUnits="userSpaceOnUse" x1="-10" x2="10" y1="10" y2="30"><stop offset="0" stop-color="#ff5263" /><stop offset="1" stop-color="#ff3347" /></linearGradient><path d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z" fill="url(#calendar_circle_fill_red_20_a)" /><path clip-rule="evenodd" d="M7.167 4.087A.816.816 0 006.771 4H6.73c-.165 0-.29.03-.396.087a.59.59 0 00-.246.246.816.816 0 00-.087.396v.352a2.327 2.327 0 00-.721.253 2.272 2.272 0 00-.945.945C4.116 6.686 4 7.09 4 8.205v4.09c0 1.114.116 1.519.334 1.926.218.407.538.727.945.945.407.218.811.334 1.926.334h5.59c1.114 0 1.519-.116 1.926-.334.407-.218.727-.538.945-.945.218-.407.334-.812.334-1.926v-4.09c0-1.115-.116-1.519-.334-1.926a2.272 2.272 0 00-.945-.945 2.328 2.328 0 00-.72-.253L14 4.73c0-.165-.03-.29-.087-.396a.591.591 0 00-.246-.246.816.816 0 00-.395-.087h-.044c-.163 0-.29.03-.395.087a.591.591 0 00-.246.246.816.816 0 00-.087.396V5l-5-.001v-.27c0-.165-.03-.29-.087-.396a.59.59 0 00-.246-.246zM6.333 8h7.334c.29 0 .395.03.5.087.106.056.19.14.246.246.057.105.087.21.087.5v3.885l-.002.155c-.01.331-.055.473-.132.615a.908.908 0 01-.378.378c-.162.088-.324.134-.77.134H6.782l-.154-.002c-.332-.01-.474-.055-.616-.132a.908.908 0 01-.378-.378c-.088-.162-.134-.324-.134-.77V8.833l.002-.113c.007-.207.036-.297.085-.387a.59.59 0 01.246-.246c.105-.057.21-.087.5-.087zm5.774 1.5h-.214c-.252 0-.387.035-.51.1a.681.681 0 00-.283.284c-.065.122-.1.257-.1.509v.214c0 .252.035.387.1.51a.681.681 0 00.284.283c.122.065.257.1.509.1h.214c.252 0 .387-.035.51-.1a.681.681 0 00.283-.284c.065-.122.1-.257.1-.509v-.214c0-.252-.035-.387-.1-.51a.681.681 0 00-.284-.283c-.122-.065-.257-.1-.509-.1z" fill="#fff" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20CalendarCircleFillRedProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20CalendarCircleFillRed: FC<Icon20CalendarCircleFillRedProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20CalendarCircleFillRed as any).mountIcon = mountIcon;

export default Icon20CalendarCircleFillRed;
