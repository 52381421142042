import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'money_transfer_circle_fill_red_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="money_transfer_circle_fill_red_20"><linearGradient id="money_transfer_circle_fill_red_20_a" gradientUnits="userSpaceOnUse" x1="-10" x2="10" y1="10" y2="30"><stop offset="0" stop-color="#ff5263" /><stop offset="1" stop-color="#ff3347" /></linearGradient><path d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z" fill="url(#money_transfer_circle_fill_red_20_a)" /><path d="M12.52 11.497a.52.52 0 01.304.098l2.879 2.08a.71.71 0 010 1.15l-2.878 2.08a.52.52 0 01-.825-.422v-4.466a.52.52 0 01.52-.52zM12.795 5c1.114 0 1.519.116 1.926.334.407.218.727.538.945.945.218.407.334.811.334 1.926v2.045a.75.75 0 01-1.5 0v-.001.001l-.001-.75h-9l.001 2.718c0 .446.046.607.134.77.087.163.215.291.378.378.142.077.284.122.616.132l.154.002H12V15H7.205c-1.115 0-1.519-.116-1.926-.334a2.272 2.272 0 01-.945-.945C4.116 13.314 4 12.91 4 11.795v-3.59c0-1.115.116-1.519.334-1.926.218-.407.538-.727.945-.945C5.686 5.116 6.09 5 7.205 5zm.423 1.5H6.782c-.446 0-.607.046-.77.134a.909.909 0 00-.378.378c-.077.142-.122.284-.132.616l-.002.154L5.499 8h9l.001-.218c0-.446-.046-.607-.134-.77a.908.908 0 00-.378-.378c-.142-.077-.284-.122-.616-.132z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20MoneyTransferCircleFillRedProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20MoneyTransferCircleFillRed: FC<Icon20MoneyTransferCircleFillRedProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20MoneyTransferCircleFillRed as any).mountIcon = mountIcon;

export default Icon20MoneyTransferCircleFillRed;
