import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'community_name_20';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="community_name_20"><g fill-rule="nonzero" fill="none"><path d="M0 0h20v20H0z" /><path d="M13 5.5A.75.75 0 1113 4h1.045c1.13 0 1.693.109 2.28.423.538.287.965.714 1.252 1.252.314.587.423 1.15.423 2.28v5.09c0 1.13-.109 1.693-.423 2.28a3.021 3.021 0 01-1.252 1.252c-.587.314-1.15.423-2.28.423h-8.09c-1.13 0-1.693-.109-2.28-.423a3.021 3.021 0 01-1.252-1.252c-.314-.587-.423-1.15-.423-2.28v-5.09c0-1.13.109-1.693.423-2.28a3.021 3.021 0 011.252-1.252C4.262 4.109 4.825 4 5.955 4H7a.75.75 0 010 1.5H5.955c-.903 0-1.233.064-1.572.245-.277.148-.49.36-.638.638-.181.34-.245.67-.245 1.572v5.09c0 .903.064 1.233.245 1.572.148.277.36.49.638.638.34.181.67.245 1.572.245h8.09c.903 0 1.233-.064 1.572-.245.277-.148.49-.36.638-.638.181-.34.245-.67.245-1.572v-5.09c0-.903-.064-1.233-.245-1.572a1.522 1.522 0 00-.638-.638c-.34-.181-.67-.245-1.572-.245H13zm-2.25-.25a.75.75 0 11-1.5 0v-2.5a.75.75 0 011.5 0v2.5zm-4 3h6.5a.75.75 0 110 1.5h-6.5a.75.75 0 010-1.5zm0 3.25h4.5a.75.75 0 110 1.5h-4.5a.75.75 0 110-1.5z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20CommunityNameProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20CommunityName: FC<Icon20CommunityNameProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20CommunityName as any).mountIcon = mountIcon;

export default Icon20CommunityName;
