import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'game_circle_fill_blue_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="game_circle_fill_blue_28"><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#game_circle_fill_blue_28_paint0_linear)" /><path d="M17 10c3.5 0 4 4.5 4 6.5 0 1.5-.5 2.5-1.5 2.5-1.5 0-1.25-2.5-3.5-2.5h-4c-2.25 0-2.008 2.5-3.5 2.5-1 0-1.5-1-1.5-2.5 0-2 .5-6.5 4-6.5h6zm-6.47 1.75h-.06a.635.635 0 00-.314.067.455.455 0 00-.19.189.636.636 0 00-.066.313v.581h-.58a.71.71 0 00-.25.038l-.064.029a.454.454 0 00-.19.189.636.636 0 00-.066.313v.062c0 .137.023.232.067.313a.454.454 0 00.189.19.635.635 0 00.313.066H9.9v.58c0 .103.013.183.038.25l.029.064a.455.455 0 00.189.19.635.635 0 00.313.066h.062a.635.635 0 00.313-.067.454.454 0 00.19-.189.635.635 0 00.066-.313V14.1h.58a.71.71 0 00.25-.038l.064-.029a.454.454 0 00.19-.189.635.635 0 00.066-.313v-.062a.635.635 0 00-.067-.313.454.454 0 00-.189-.19.635.635 0 00-.313-.066H11.1v-.58a.71.71 0 00-.038-.25l-.029-.064a.454.454 0 00-.189-.19.635.635 0 00-.313-.066zm7.4 1.15h-1.36a.635.635 0 00-.314.067.454.454 0 00-.19.189.635.635 0 00-.066.313v.062c0 .137.023.232.067.313a.454.454 0 00.189.19.635.635 0 00.313.066h1.362a.635.635 0 00.313-.067.454.454 0 00.19-.189.635.635 0 00.066-.313v-.062a.635.635 0 00-.067-.313.454.454 0 00-.189-.19.635.635 0 00-.313-.066z" fill="#fff" /><defs><linearGradient id="game_circle_fill_blue_28_paint0_linear" x1="-14" y1="14" x2="14" y2="42" gradientUnits="userSpaceOnUse"><stop stop-color="#70B2FF" /><stop offset="1" stop-color="#5C9CE6" /></linearGradient></defs></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28GameCircleFillBlueProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28GameCircleFillBlue: FC<Icon28GameCircleFillBlueProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28GameCircleFillBlue as any).mountIcon = mountIcon;

export default Icon28GameCircleFillBlue;
