import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'arrow_uturn_left_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="arrow_uturn_left_outline_24"><path clip-rule="evenodd" d="M10.236 3.264a.9.9 0 010 1.272L7.673 7.1H12a5.9 5.9 0 110 11.8H7.9a.9.9 0 110-1.8H12a4.1 4.1 0 100-8.2H7.673l2.564 2.564a.9.9 0 11-1.273 1.272l-4.1-4.1a.9.9 0 010-1.272l4.1-4.1a.9.9 0 011.273 0z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24ArrowUturnLeftOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24ArrowUturnLeftOutline: FC<Icon24ArrowUturnLeftOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24ArrowUturnLeftOutline as any).mountIcon = mountIcon;

export default Icon24ArrowUturnLeftOutline;
