import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'delete_36';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="delete_36"><g fill="none" fill-rule="evenodd"><path d="M0 0h36v36H0z" /><path d="M13.433 4a3.75 3.75 0 013.317-2h2.5a3.75 3.75 0 013.317 2H28.5A1.5 1.5 0 0130 5.5v1.125a.375.375 0 01-.375.375H6.375A.375.375 0 016 6.625V5.5A1.5 1.5 0 017.5 4h5.933zM7.5 9h21l-1.693 20.558A3.75 3.75 0 0123.07 33H12.93a3.75 3.75 0 01-3.737-3.442L7.5 9zm16.184 3.027a1 1 0 00-1.075.918l-1.216 15.453a1 1 0 101.994.157l1.216-15.453a1 1 0 00-.919-1.075zM18 12a1 1 0 00-1 1v15.5a1 1 0 002 0V13a1 1 0 00-1-1zm-5.689.027a1 1 0 00-.918 1.075l1.216 15.453a1 1 0 001.994-.157l-1.216-15.453a1 1 0 00-1.076-.918z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36DeleteProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36Delete: FC<Icon36DeleteProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36Delete as any).mountIcon = mountIcon;

export default Icon36Delete;
