import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'cake_circle_fill_purple_32';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="cake_circle_fill_purple_32"><path d="M0 16C0 7.163 7.163 0 16 0s16 7.163 16 16-7.163 16-16 16S0 24.837 0 16z" fill="url(#cake_circle_fill_purple_32_paint0_linear)" /><path fill-rule="evenodd" clip-rule="evenodd" d="M20.79 8.072a.142.142 0 00-.247 0c-.212.388-.42.682-.603.938-.352.496-.607.855-.607 1.493a1.333 1.333 0 002.667 0c0-.638-.255-.997-.607-1.493a9.09 9.09 0 01-.603-.938zM8 15.17c0-.736.597-1.333 1.333-1.333h13.334c.736 0 1.333.597 1.333 1.333 0 1.306-.568 2.043-1.333 2.398l-.027.012c-.29.13-.608.207-.933.239a3.819 3.819 0 01-.374.018c-1.994 0-2.662-1.325-2.666-1.333-.003.006-.317.627-1.152 1.019a3.081 3.081 0 01-.464.174c-.207.06-.437.103-.691.125-.115.01-.235.015-.36.015-1.994 0-2.663-1.325-2.667-1.333-.003.006-.324.643-1.184 1.034a3.095 3.095 0 01-.431.159 3.59 3.59 0 01-.692.125c-.114.01-.234.015-.36.015C9.333 17.837 8 17.17 8 15.17zm14.667 4.508a5.547 5.547 0 01-1.334.159c-1.093 0-1.978-.275-2.666-.658-.689.383-1.574.658-2.667.658-1.093 0-1.978-.275-2.667-.658-.688.383-1.573.658-2.666.658a5.5 5.5 0 01-1.334-.16v.943c0 .747 0 1.12.146 1.406.128.25.332.455.582.582.286.146.659.146 1.406.146h9.066c.747 0 1.12 0 1.406-.146.25-.127.455-.331.582-.582.146-.286.146-.659.146-1.406v-.942zM11.21 8.072a.142.142 0 01.247 0c.213.388.42.682.603.938.352.496.607.855.607 1.493a1.333 1.333 0 01-2.667 0c0-.638.255-.997.607-1.493.182-.256.39-.55.603-.938zm4.914 0a.142.142 0 00-.248 0c-.212.388-.42.682-.603.938-.352.496-.606.855-.606 1.493a1.333 1.333 0 002.666 0c0-.638-.254-.997-.606-1.493a8.925 8.925 0 01-.603-.938z" fill="#fff" /><defs><linearGradient id="cake_circle_fill_purple_32_paint0_linear" x1="-16" y1="16" x2="16" y2="48" gradientUnits="userSpaceOnUse"><stop stop-color="#937FF5" /><stop offset="1" stop-color="#735CE6" /></linearGradient></defs></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32CakeCircleFillPurpleProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32CakeCircleFillPurple: FC<Icon32CakeCircleFillPurpleProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32CakeCircleFillPurple as any).mountIcon = mountIcon;

export default Icon32CakeCircleFillPurple;
