import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'camera_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="camera_outline_20"><path clip-rule="evenodd" d="M5.5 5.5c.574 0 1.009-.204 1.34-.521.243-.234.428-.536.554-.743l.054-.089c.15-.24.259-.382.391-.478.113-.082.297-.169.66-.169h3c.365 0 .549.087.661.169.133.096.241.238.391.478l.055.09c.126.206.31.508.554.742.33.317.765.521 1.34.521.733 0 .99.004 1.189.043a2.25 2.25 0 011.768 1.768c.04.2.043.456.043 1.189v3.45c0 .853 0 1.447-.038 1.91-.037.453-.107.714-.207.911a2.25 2.25 0 01-.984.984c-.197.1-.458.17-.912.207-.462.037-1.057.038-1.91.038h-6.9c-.853 0-1.447 0-1.91-.038-.454-.038-.714-.107-.912-.207a2.25 2.25 0 01-.983-.984c-.1-.197-.17-.458-.207-.912-.038-.462-.038-1.057-.038-1.909V8.501c0-.734.003-.991.043-1.19a2.25 2.25 0 011.767-1.768c.2-.04.457-.043 1.19-.043zm3-3.5c-.636 0-1.14.163-1.543.456-.383.279-.618.637-.78.897l-.076.12c-.136.22-.207.334-.3.423C5.74 3.954 5.675 4 5.5 4h-.087c-.614 0-1.033 0-1.397.072A3.75 3.75 0 001.07 7.017C.998 7.381.998 7.8.999 8.414v3.568c0 .813 0 1.469.043 2 .045.546.139 1.026.365 1.47a3.75 3.75 0 001.64 1.64c.443.226.923.32 1.47.365.531.043 1.187.043 2 .043H13.481c.813 0 1.468 0 2-.043.546-.045 1.026-.14 1.47-.366a3.75 3.75 0 001.64-1.639c.226-.444.32-.924.365-1.47.043-.531.043-1.187.043-2V8.413c0-.613 0-1.031-.072-1.395a3.75 3.75 0 00-2.947-2.946C15.618 4 15.2 4 14.587 4H14.5c-.176 0-.241-.046-.301-.104-.093-.09-.165-.204-.3-.422a40.468 40.468 0 00-.076-.122c-.162-.26-.397-.617-.78-.896C12.64 2.163 12.136 2 11.5 2zm3.75 8.25a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm1.5 0a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20CameraOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20CameraOutline: FC<Icon20CameraOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20CameraOutline as any).mountIcon = mountIcon;

export default Icon20CameraOutline;
