import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'comment_disable_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="comment_disable_outline_28"><path d="M24.5 18.083V8.75c0-1.75-1.167-2.917-2.917-2.917H11m-5.214.533c-.705.505-1.12 1.341-1.12 2.384v9.333c0 1.75 1.167 2.917 2.917 2.917H10.5l.001.001V24.5c0 .583.583 1.75 1.75.583l4.525-3.96a.5.5 0 01.33-.123h3.687M4 4l20 20" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28CommentDisableOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28CommentDisableOutline: FC<Icon28CommentDisableOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28CommentDisableOutline as any).mountIcon = mountIcon;

export default Icon28CommentDisableOutline;
