import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'song_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="song_outline_28"><g fill="none" fill-rule="evenodd"><path d="M0 0h28v28H0z" /><path d="M19.78 3.025A1 1 0 0121 4v2.102a3.6 3.6 0 01-2.672 3.479L15 10.468v9.926c0 4.687-4.583 7.1-7.073 4.61C5.351 22.426 7.82 18.2 13 17.942V7.93c-.027-1.922.91-3.288 2.684-3.88.641-.213 1.993-.551 4.096-1.026zM13 19.944c-3.538.196-4.845 2.459-3.659 3.645C10.484 24.732 13 23.408 13 20.394zm6-14.688c-1.348.315-2.249.548-2.684.693-.934.311-1.331.89-1.316 1.968v.481l2.812-.75A1.6 1.6 0 0019 6.102z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28SongOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28SongOutline: FC<Icon28SongOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28SongOutline as any).mountIcon = mountIcon;

export default Icon28SongOutline;
