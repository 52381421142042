import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'unlock_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="unlock_outline_20"><path d="M9.53.027c2.586-.27 4.94 1.518 5.451 4.118a1 1 0 01-1.962.386c-.314-1.596-1.736-2.676-3.281-2.515-1.55.161-2.74 1.52-2.738 3.155L6.999 8H14.5a3.5 3.5 0 013.5 3.5v5a3.5 3.5 0 01-3.5 3.5h-9A3.5 3.5 0 012 16.5v-5a3.5 3.5 0 013-3.464V5.172C4.997 2.525 6.95.296 9.53.027zM14.5 10h-9A1.5 1.5 0 004 11.5v5A1.5 1.5 0 005.5 18h9a1.5 1.5 0 001.5-1.5v-5a1.5 1.5 0 00-1.5-1.5z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20UnlockOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20UnlockOutline: FC<Icon20UnlockOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20UnlockOutline as any).mountIcon = mountIcon;

export default Icon20UnlockOutline;
