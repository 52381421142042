import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 48 48';
const id = 'logo_vk_48';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" id="logo_vk_48"><path d="M29.783 5.143c10.56 0 13.074 2.514 13.074 13.074v11.566c0 10.56-2.514 13.074-13.074 13.074H18.217c-10.56 0-13.074-2.514-13.074-13.074V18.217c0-10.56 2.514-13.074 13.074-13.074zM14.964 16.929h-2.75c-.785 0-.942.37-.942.777 0 .729.932 4.341 4.34 9.12 2.273 3.262 5.475 5.031 8.388 5.031 1.748 0 1.964-.393 1.964-1.07v-2.466c0-.785.166-.942.72-.942.407 0 1.107.204 2.738 1.777 1.865 1.865 2.172 2.701 3.22 2.701h2.75c.787 0 1.18-.393.953-1.168-.248-.773-1.138-1.894-2.32-3.223-.64-.757-1.602-1.573-1.893-1.98-.408-.525-.292-.758 0-1.224l.049-.07c.414-.589 3.326-4.765 3.65-6.253.175-.582 0-1.01-.831-1.01h-2.75c-.7 0-1.021.37-1.196.777 0 0-1.399 3.409-3.38 5.623-.64.641-.932.845-1.282.845-.175 0-.428-.204-.428-.787V17.94c0-.699-.203-1.01-.785-1.01h-4.322c-.437 0-.7.324-.7.632 0 .662.99.815 1.093 2.68v4.05c0 .888-.16 1.048-.51 1.048-.932 0-3.2-3.423-4.545-7.341-.263-.762-.528-1.07-1.23-1.07z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon48LogoVkProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon48LogoVk: FC<Icon48LogoVkProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 48,
    height: !isNaN(props.height) ? +props.height : 48,
  }));
};

(Icon48LogoVk as any).mountIcon = mountIcon;

export default Icon48LogoVk;
