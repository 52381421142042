import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 48 48';
const id = 'newsfeed_outline_48';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" id="newsfeed_outline_48"><g fill="currentColor"><path clip-rule="evenodd" d="M36 8H13a5 5 0 00-5 5v22a5 5 0 005 5h23a5 5 0 005-5V13a5 5 0 00-5-5zM13 5a8 8 0 00-8 8v22a8 8 0 008 8h23a8 8 0 008-8V13a8 8 0 00-8-8z" fill-rule="evenodd" /><path d="M8 17h33v3H8z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon48NewsfeedOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon48NewsfeedOutline: FC<Icon48NewsfeedOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 48,
    height: !isNaN(props.height) ? +props.height : 48,
  }));
};

(Icon48NewsfeedOutline as any).mountIcon = mountIcon;

export default Icon48NewsfeedOutline;
