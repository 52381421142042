import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'play_gesture_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="play_gesture_56"><path fill-rule="evenodd" clip-rule="evenodd" d="M30.896 8.084a2.777 2.777 0 00-3.384 2.092l-2.048 9.214a1.5 1.5 0 01-2.96-.22l-1.753-8.073a2.658 2.658 0 00-5.237.86l1.346 12.136a9.673 9.673 0 013.345-.593h.993a5.635 5.635 0 011.62 11.033l-1.346.404a1.5 1.5 0 11-.862-2.874l1.345-.403a2.635 2.635 0 00-.757-5.16h-.993a6.692 6.692 0 00-6.677 6.247C13.05 39.903 18.836 46 26.04 46h.459a1.5 1.5 0 010 3H26c-8.888-.023-16.06-7.546-15.466-16.453a9.672 9.672 0 013.492-6.822l-1.49-13.438C12.162 8.933 14.784 6 18.154 6c2.66 0 4.96 1.857 5.524 4.46l.345 1.584.56-2.519a5.777 5.777 0 0111.358 2.067l-1.25 8.676c4.687 1.137 8.319 5.015 9.118 9.832a1.5 1.5 0 11-2.958.49h-.002c-.655-3.971-3.853-7.09-7.863-7.595a1.504 1.504 0 01-1.472-1.715l1.457-10.11a2.777 2.777 0 00-2.076-3.086zm3.704 37.56a.4.4 0 01-.601-.347V34.703a.4.4 0 01.6-.346l9.108 5.297a.4.4 0 010 .692L34.6 45.643zm11.208-8.24c1.99 1.157 1.99 4.035 0 5.192L35.505 48.59C33.506 49.752 31 48.308 31 45.993V34.007c0-2.315 2.506-3.76 4.505-2.596l10.303 5.993z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56PlayGestureProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56PlayGesture: FC<Icon56PlayGestureProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56PlayGesture as any).mountIcon = mountIcon;

export default Icon56PlayGesture;
