import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'story_fill_circle_red_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="story_fill_circle_red_28"><linearGradient id="story_fill_circle_red_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#ff5263" /><stop offset="1" stop-color="#ff3347" /></linearGradient><path d="M14 28c7.732 0 14-6.268 14-14S21.732 0 14 0 0 6.268 0 14s6.268 14 14 14z" fill="url(#story_fill_circle_red_28_a)" /><path clip-rule="evenodd" d="M7 11.978c0-1.743 0-2.614.34-3.28A3.111 3.111 0 018.698 7.34C9.364 7 10.235 7 11.978 7h4.044c1.743 0 2.614 0 3.28.34a3.111 3.111 0 011.359 1.359c.339.665.339 1.536.339 3.279v4.044c0 1.743 0 2.614-.34 3.28a3.111 3.111 0 01-1.359 1.359c-.665.339-1.536.339-3.279.339h-4.044c-1.743 0-2.614 0-3.28-.34a3.111 3.111 0 01-1.359-1.359C7 18.636 7 17.765 7 16.022zM10 14c0 2.208 1.792 4 4 4s4-1.792 4-4-1.792-4-4-4-4 1.792-4 4zm4-2.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5z" fill="#fff" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28StoryFillCircleRedProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28StoryFillCircleRed: FC<Icon28StoryFillCircleRedProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28StoryFillCircleRed as any).mountIcon = mountIcon;

export default Icon28StoryFillCircleRed;
