import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'grid_of_four_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="grid_of_four_16"><path d="M5.398 9c.557 0 .759.058.963.167.203.109.363.269.472.472.109.204.167.406.167.963v2.796c0 .557-.058.759-.167.963a1.136 1.136 0 01-.472.472c-.204.109-.406.167-.963.167H2.602c-.557 0-.759-.058-.963-.167a1.136 1.136 0 01-.472-.473c-.109-.203-.167-.405-.167-.962v-2.796c0-.557.058-.759.167-.963.109-.203.269-.363.472-.472.204-.109.406-.167.963-.167h2.796zm8 0c.557 0 .759.058.963.167.203.109.363.269.472.472.109.204.167.406.167.963v2.796c0 .557-.058.759-.167.963a1.135 1.135 0 01-.473.472c-.203.109-.405.167-.962.167h-2.796c-.557 0-.759-.058-.963-.167a1.136 1.136 0 01-.472-.473c-.109-.203-.167-.405-.167-.962v-2.796c0-.557.058-.759.167-.963.109-.203.269-.363.472-.472.204-.109.406-.167.963-.167h2.796zm-8-8c.557 0 .759.058.963.167.203.109.363.269.472.472.109.204.167.406.167.963v2.796c0 .557-.058.759-.167.963a1.136 1.136 0 01-.472.472c-.204.109-.406.167-.963.167H2.602c-.557 0-.759-.058-.963-.167a1.136 1.136 0 01-.472-.472C1.058 6.157 1 5.955 1 5.398V2.602c0-.557.058-.759.167-.963.109-.203.269-.363.472-.472.204-.109.406-.167.963-.167h2.796zm8.962.167c-.203-.109-.405-.167-.962-.167h-2.796c-.557 0-.759.058-.963.167a1.136 1.136 0 00-.472.472c-.109.204-.167.406-.167.963v2.796c0 .557.058.759.167.963.109.203.269.363.472.472.204.109.406.167.963.167h2.796c.557 0 .759-.058.963-.167.203-.109.363-.269.472-.472.109-.204.167-.406.167-.963V2.602c0-.557-.058-.759-.167-.963a1.136 1.136 0 00-.473-.472z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16GridOfFourProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16GridOfFour: FC<Icon16GridOfFourProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16GridOfFour as any).mountIcon = mountIcon;

export default Icon16GridOfFour;
