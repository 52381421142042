import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'check_circle_device_outline_56';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="check_circle_device_outline_56"><g fill="none" fill-rule="evenodd"><path d="M0 0h56v56H0z" /><path d="M25.244 4l.744.005c2.981.046 4.526.385 6.127 1.24a8.765 8.765 0 013.475 3.347l.174.312.15.292c.774 1.558 1.068 3.19 1.085 6.283a1.5 1.5 0 01-3 .016l-.008-.638c-.04-2.032-.228-3.12-.627-4.035l-.126-.27-.13-.252A5.77 5.77 0 0030.7 7.891c-1.187-.635-2.339-.873-5.115-.89L19.755 7c-2.737 0-4.003.2-5.071.698l-.133.064-.251.13a5.77 5.77 0 00-2.409 2.408c-.635 1.187-.873 2.339-.89 5.115L11 40.245c0 2.737.2 4.003.698 5.071l.064.133.13.251a5.77 5.77 0 002.408 2.409c1.1.588 2.134.825 4.438.88l.323.006.695.005h1.763a1.5 1.5 0 010 3h-1.775l-.718-.005c-2.989-.047-4.526-.377-6.14-1.24a8.765 8.765 0 01-3.476-3.347l-.174-.312-.15-.292c-.731-1.472-1.036-3.016-1.08-5.803L8 40.244V15.756l.005-.744c.046-2.981.385-4.526 1.24-6.127a8.765 8.765 0 013.347-3.475l.312-.174.292-.15c1.472-.731 3.016-1.036 5.803-1.08L19.756 4zM36 20c8.837 0 16 7.163 16 16s-7.163 16-16 16-16-7.163-16-16 7.163-16 16-16zm0 3c-7.18 0-13 5.82-13 13s5.82 13 13 13 13-5.82 13-13-5.82-13-13-13zm6.86 8.34a1.5 1.5 0 01.104 2.007l-.103.114-8 8a1.5 1.5 0 01-2.008.103l-.114-.103-3.2-3.2a1.5 1.5 0 012.008-2.225l.114.103 2.139 2.14 6.94-6.94a1.5 1.5 0 012.12 0z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56CheckCircleDeviceOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56CheckCircleDeviceOutline: FC<Icon56CheckCircleDeviceOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56CheckCircleDeviceOutline as any).mountIcon = mountIcon;

export default Icon56CheckCircleDeviceOutline;
