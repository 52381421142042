import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'arrow_down_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="arrow_down_outline_28"><path d="M14 23h-.02a.957.957 0 01-.07-.004L14 23a1.07 1.07 0 01-.155-.011l-.036-.007a1.001 1.001 0 01-.62-.377l-7.896-7.898a1 1 0 011.32-1.497l.094.083L13 19.586V4.95c0-.525.448-.95 1-.95.513 0 .935.367.993.84l.007.11v14.636l6.293-6.293a1 1 0 011.32-.083l.094.083a1 1 0 01.083 1.32l-.083.094-7.895 7.898a1.008 1.008 0 01-.621.378l-.037.005A.738.738 0 0114 23z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28ArrowDownOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28ArrowDownOutline: FC<Icon28ArrowDownOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28ArrowDownOutline as any).mountIcon = mountIcon;

export default Icon28ArrowDownOutline;
