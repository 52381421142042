import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'link_outline_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="link_outline_36"><path d="M8.5 27.232L26.732 9" stroke="currentColor" stroke-linecap="round" stroke-linejoin="bevel" stroke-width="2.5" /><path d="M13 8h15v15" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" /><path clip-rule="evenodd" d="M11.75 8c0-.69.56-1.25 1.25-1.25h15c.69 0 1.25.56 1.25 1.25v15a1.25 1.25 0 11-2.5 0V10.75L9.384 28.116a1.25 1.25 0 11-1.768-1.768L24.715 9.25H13c-.69 0-1.25-.56-1.25-1.25z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36LinkOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36LinkOutline: FC<Icon36LinkOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36LinkOutline as any).mountIcon = mountIcon;

export default Icon36LinkOutline;
