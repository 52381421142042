import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 48 48';
const id = 'camera_outline_48';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" id="camera_outline_48"><path d="M28.328 5h-8.942c-1.613 0-2.767.49-3.778 1.592l-.177.201c-.03.037-1.024 1.274-1.377 1.711-.35.435-1.287.803-2.197.803H11.5c-4.41 0-7.5 3.417-7.5 8.391v14.908c0 4.498 3.82 8.391 8.714 8.391H35c4.924 0 9.004-3.933 9.004-8.39V17.697c0-4.974-3.09-8.391-7.5-8.391h-.647c-.91 0-1.846-.368-2.197-.803l-1.377-1.71C31.23 5.548 30.036 5 28.328 5zm0 3c.765 0 1.127.14 1.557.608l.109.124 1.33 1.655c.977 1.21 2.78 1.92 4.533 1.92h.647c2.682 0 4.5 2.01 4.5 5.391v14.908c0 2.776-2.712 5.391-6.004 5.391H12.714C9.49 37.997 7 35.46 7 32.607V17.697c0-3.381 1.818-5.391 4.5-5.391h.357c1.753 0 3.557-.71 4.533-1.92l1.33-1.655c.482-.569.837-.732 1.666-.732zM24 15.5a8.5 8.5 0 100 17 8.5 8.5 0 000-17zm0 3a5.5 5.5 0 110 11 5.5 5.5 0 010-11z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon48CameraOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon48CameraOutline: FC<Icon48CameraOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 48,
    height: !isNaN(props.height) ? +props.height : 48,
  }));
};

(Icon48CameraOutline as any).mountIcon = mountIcon;

export default Icon48CameraOutline;
