import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'list_like_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="list_like_outline_28"><path d="M21.46 12c2.5 0 4.54 1.99 4.54 4.46 0 2.323-.913 3.5-4.426 6.192l-1.464 1.112c-.899.682-2.123.72-3.058.114l-.162-.114-1.51-1.147-.57-.443C11.804 19.812 11 18.64 11 16.46c0-2.47 2.04-4.46 4.54-4.46.929 0 1.784.292 2.55.85l.207.158.202.172.217-.183c.667-.534 1.406-.862 2.204-.963l.268-.026.273-.008zm.063 1.999l-.224.007-.186.02a2.283 2.283 0 00-.937.375l-.173.127-1.19 1a.5.5 0 01-.646-.002l-1.126-.962c-.477-.385-.964-.564-1.502-.564C14.13 14 13 15.108 13 16.46c0 1.408.447 2.112 3.072 4.163l.518.401 1.509 1.147a.663.663 0 00.71.059l.092-.06 1.692-1.286C23.524 18.624 24 17.91 24 16.46c0-1.298-1.042-2.371-2.317-2.456l-.16-.005zM8 18a1 1 0 110 2H4.5a1 1 0 110-2H8zm.5-6a1 1 0 110 2h-4a1 1 0 110-2h4zm13-6a1 1 0 110 2h-17a1 1 0 010-2h17z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28ListLikeOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28ListLikeOutline: FC<Icon28ListLikeOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28ListLikeOutline as any).mountIcon = mountIcon;

export default Icon28ListLikeOutline;
