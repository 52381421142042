import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'camera_32';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="camera_32"><g fill="none" fill-rule="evenodd"><path d="M0 0h32v32H0z" /><path d="M6.242 8h1.273c.907 0 1.886-.59 2.38-1.26.493-.67.93-1.32.957-1.352.528-.62 1.067-.888 1.987-.888H16V27H6.848C4.171 27 2 24.877 2 22.26V17v-4.26C2 9.779 3.333 8 6.242 8zm19.516 0h-1.273c-.907 0-1.927-.657-2.38-1.26-.452-.605-.93-1.32-.957-1.352-.528-.62-1.067-.888-1.987-.888H16V27h9.152C27.829 27 30 24.877 30 22.26v-9.52C30 9.779 28.667 8 25.758 8zM9.25 16.75A6.752 6.752 0 0016 23.5a6.752 6.752 0 006.75-6.75A6.752 6.752 0 0016 10a6.752 6.752 0 00-6.75 6.75zm2 0a4.75 4.75 0 119.5 0 4.75 4.75 0 01-9.5 0z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32CameraProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32Camera: FC<Icon32CameraProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32Camera as any).mountIcon = mountIcon;

export default Icon32Camera;
