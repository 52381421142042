import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'masks_outline_32';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="masks_outline_32"><path d="M27.16 4.499c2.79 2.882 2.092 10.81 0 15.854C25.067 25.396 20.882 29 16 29s-9.067-3.603-11.16-8.648c-2.092-5.044-2.79-12.97 0-15.853S10.42 3.8 16 3.8s8.37-2.184 11.16.699zm-6.266 1.367c-1.885.297-3.26.437-4.894.437s-3.009-.14-4.894-.437c-3.437-.541-3.76-.517-4.551.309-1.582 1.65-1.389 8.302.608 13.162C8.975 23.747 12.39 26.5 16 26.5s7.025-2.753 8.837-7.163c1.997-4.86 2.19-11.512.608-13.162-.792-.826-1.114-.85-4.55-.31zM16 19c2.5 0 3.2-1.546 3.84-.788S19.2 22 16 22s-4.48-3.03-3.84-3.788S13.5 19 16 19zm3.87-7.79c1.101-.096 2.053.493 2.126 1.316.072.823-.763 1.568-1.865 1.664-1.102.096-2.054-.493-2.127-1.316-.072-.823.763-1.568 1.865-1.664zm-7.903-.01l.164.01c1.102.096 1.937.841 1.865 1.664-.073.823-1.025 1.412-2.127 1.316-1.102-.096-1.937-.841-1.865-1.664.073-.823 1.025-1.412 2.127-1.316z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32MasksOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32MasksOutline: FC<Icon32MasksOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32MasksOutline as any).mountIcon = mountIcon;

export default Icon32MasksOutline;
