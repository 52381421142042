import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'image_format_32';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="image_format_32"><path d="M22.308 4c2.675 0 3.645.278 4.622.801.978.523 1.746 1.29 2.269 2.268S30 9.017 30 11.692v8.616l-.005.61c-.041 2.22-.315 3.113-.796 4.013a5.452 5.452 0 01-2.268 2.268c-.978.523-1.948.801-4.623.801H9.692l-.61-.005c-2.22-.041-3.113-.315-4.013-.796a5.57 5.57 0 01-1.017-.697h-.001l-.026-.023a5.474 5.474 0 01-1.224-1.548c-.502-.94-.778-1.87-.8-4.31L2 11.691c0-2.674.278-3.644.801-4.622s1.29-1.745 2.268-2.268c.94-.502 1.87-.778 4.31-.8zM4.5 14.5v6.081l.009.552c.031 1.284.149 1.888.4 2.427l.097.191c.29.543.7.953 1.242 1.243l.192.096a3.386 3.386 0 00.322.13l-.036-.012.09.031-.054-.018c.041.014.084.028.127.04l-.072-.022c.03.01.06.02.09.028l-.018-.005a3.7 3.7 0 00.287.073l-.028-.006.12.025-.092-.019c.05.011.101.021.155.03l-.063-.01.153.026-.09-.015c.047.008.096.017.147.024l-.057-.009.15.022-.093-.013.18.025-.088-.012c.047.007.095.013.144.018l-.055-.006.181.02-.126-.014.153.016-.027-.002.185.016-.158-.014c.057.006.116.01.177.015l-.019-.001a14.88 14.88 0 00.984.043l.128.002.268.003H15.5V14.5zm13.5 0v10.999h3.5v-8.595l-.007-.387c-.017-.643-.069-.931-.171-1.167l-.06-.125a1.136 1.136 0 00-.487-.486l-.125-.061c-.279-.12-.63-.17-1.554-.177zm7.56 10.59l.191-.096c.543-.29.953-.7 1.243-1.243l.096-.19c.285-.61.398-1.302.41-2.966v-8.903c0-1.874-.109-2.609-.41-3.252l-.096-.192c-.29-.542-.7-.952-1.243-1.242l-.19-.096c-.612-.286-1.306-.399-2.98-.41H9.691l-.554.004c-1.473.021-2.121.137-2.697.406l-.192.096c-.542.29-.952.7-1.242 1.242l-.096.192c-.286.611-.399 1.305-.41 2.979v.579L18.872 12c1.783 0 2.43.186 3.082.534.651.349 1.163.86 1.512 1.512.348.652.534 1.299.534 3.082l.002 8.32c.722-.058 1.158-.17 1.558-.358z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32ImageFormatProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32ImageFormat: FC<Icon32ImageFormatProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32ImageFormat as any).mountIcon = mountIcon;

export default Icon32ImageFormat;
