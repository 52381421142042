import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'message_add_badge_outline_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="message_add_badge_outline_24"><path fill-rule="evenodd" clip-rule="evenodd" d="M22 8a5 5 0 10-10 0 5 5 0 0010 0zm-5.828-2.764a.833.833 0 011.661.097v1.834h1.834l.097.005a.833.833 0 01-.097 1.661h-1.834v1.834l-.005.097a.833.833 0 01-1.661-.097V8.833h-1.834l-.097-.005a.833.833 0 01.097-1.661h1.834V5.333l.005-.097z" fill="currentColor" /><path d="M10.187 4.88a.9.9 0 00-.374-1.76c-4.24.899-7.681 4.457-7.681 8.587a7.72 7.72 0 001.377 4.38c-.04.519-.187 1.07-.404 1.657a22.75 22.75 0 01-.662 1.55c-.077.17-.155.34-.232.513-.39.865.282 1.828 1.21 1.792 2.195-.086 4-.638 5.247-1.79 1.048.327 2.175.505 3.348.505 3.643 0 7.02-1.636 8.742-4.329a.9.9 0 00-1.516-.97c-1.331 2.08-4.074 3.499-7.226 3.499a9.338 9.338 0 01-3.281-.586.9.9 0 00-1.002.26c-.678.797-1.8 1.353-3.513 1.547.197-.434.4-.9.574-1.367.289-.783.525-1.653.525-2.553a.9.9 0 00-.177-.537c-.773-1.04-1.21-2.265-1.21-3.571 0-3.103 2.66-6.064 6.255-6.827z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24MessageAddBadgeOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24MessageAddBadgeOutline: FC<Icon24MessageAddBadgeOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24MessageAddBadgeOutline as any).mountIcon = mountIcon;

export default Icon24MessageAddBadgeOutline;
