import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'forward_10_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="forward_10_24"><g fill="none" fill-rule="evenodd"><path d="M24 24H0V0h24z" /><path d="M12.12 4.25V2.356a.35.35 0 01.534-.298l4.302 2.644a.35.35 0 010 .596l-4.302 2.644a.35.35 0 01-.533-.298V5.75a6.25 6.25 0 106.142 7.414.75.75 0 111.474.278A7.752 7.752 0 014.37 12a7.75 7.75 0 017.75-7.75zM9.4 14.65V11l-.866.346a.28.28 0 01-.384-.26v-.506a.35.35 0 01.211-.321l1.845-.795a.35.35 0 01.489.322v4.864a.35.35 0 01-.35.35H9.75a.35.35 0 01-.35-.35zm4.487.349c-1.208 0-2.19-.695-2.187-2.8.002-2.106.98-2.8 2.187-2.8 1.208 0 2.19.694 2.188 2.8-.003 2.105-.98 2.8-2.188 2.8zm.003-1.119c.61 0 .94-.404.94-1.629 0-1.224-.336-1.63-.94-1.63-.604 0-.94.406-.94 1.63 0 1.224.33 1.63.94 1.63z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24Forward10Props extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Forward10: FC<Icon24Forward10Props> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Forward10 as any).mountIcon = mountIcon;

export default Icon24Forward10;
