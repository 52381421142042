import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'logo_vk_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="logo_vk_outline_28"><path d="M15.077 2.571c1.872 0 2.771.174 3.706.674a4.75 4.75 0 011.972 1.972c.5.935.674 1.834.674 3.706v6.154c0 1.872-.174 2.771-.674 3.706a4.75 4.75 0 01-1.972 1.972c-.935.5-1.835.674-3.706.674H8.923c-1.872 0-2.771-.174-3.706-.674a4.75 4.75 0 01-1.972-1.972c-.5-.935-.674-1.835-.674-3.706V8.923c0-1.872.174-2.771.674-3.706a4.751 4.751 0 011.972-1.972c.935-.5 1.834-.674 3.706-.674zm0 1.715H8.923c-1.613 0-2.245.122-2.898.47-.549.294-.975.72-1.268 1.27-.35.652-.471 1.284-.471 2.897v6.154c0 1.613.122 2.245.47 2.898.294.549.72.975 1.27 1.268.652.35 1.284.471 2.897.471h6.154c1.613 0 2.245-.122 2.898-.47.549-.294.975-.72 1.268-1.27.35-.652.471-1.284.471-2.897V8.923c0-1.613-.122-2.245-.47-2.898a3.038 3.038 0 00-1.27-1.268c-.652-.35-1.284-.471-2.897-.471zM8.17 9.064c.3 0 .412.131.524.456.574 1.67 1.54 3.13 1.938 3.13.134 0 .202-.056.215-.343l.002-.105v-1.726c-.044-.795-.466-.86-.466-1.142 0-.115.086-.235.232-.264l.066-.006h1.842c.221 0 .314.105.332.338l.003.093v2.322c0 .249.108.336.183.336.149 0 .273-.087.546-.36.844-.944 1.44-2.397 1.44-2.397a.497.497 0 01.417-.326l.093-.006h1.173c.354 0 .429.183.354.431-.137.63-1.365 2.393-1.553 2.66l-.024.035c-.124.199-.174.298 0 .521.124.174.534.522.807.845.504.566.883 1.044.989 1.373.088.303-.045.47-.326.495l-.08.003h-1.172c-.447 0-.578-.356-1.373-1.15-.695-.671-.993-.758-1.167-.758-.212 0-.29.054-.304.309l-.003.092v1.051c0 .289-.092.456-.837.456-1.242 0-2.606-.754-3.575-2.144-1.453-2.037-1.85-3.577-1.85-3.887 0-.157.054-.3.309-.327l.093-.005z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28LogoVkOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28LogoVkOutline: FC<Icon28LogoVkOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon28LogoVkOutline as any).mountIcon = mountIcon;

export default Icon28LogoVkOutline;
