import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'services_circle_fill_blue_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="services_circle_fill_blue_28"><linearGradient id="services_circle_fill_blue_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#70b2ff" /><stop offset="1" stop-color="#5c9ce6" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#services_circle_fill_blue_28_a)" /><path d="M11.398 15c.557 0 .759.058.963.167.203.109.363.269.472.472.109.204.167.406.167.963v1.796c0 .557-.058.759-.167.963a1.135 1.135 0 01-.472.472c-.204.109-.406.167-.963.167H9.602c-.557 0-.759-.058-.963-.167-.203-.109-.363-.269-.472-.472S8 18.955 8 18.398v-1.796c0-.557.058-.759.167-.963s.269-.363.472-.472c.204-.109.406-.167.963-.167zm7 0c.557 0 .759.058.963.167.203.109.363.269.472.472.109.204.167.406.167.963v1.796c0 .557-.058.759-.167.963a1.135 1.135 0 01-.472.472c-.204.109-.406.167-.963.167h-1.796c-.557 0-.759-.058-.963-.167a1.135 1.135 0 01-.472-.472c-.109-.204-.167-.406-.167-.963v-1.796c0-.557.058-.759.167-.963.109-.203.269-.363.472-.472.204-.109.406-.167.963-.167zm-.564-7.465c.221.067.405.169.8.563l1.268 1.269c.394.394.496.578.563.799.067.22.067.447 0 .668s-.169.405-.563.8l-1.269 1.268c-.394.394-.578.496-.799.563s-.447.067-.668 0-.405-.169-.8-.563l-1.268-1.269c-.394-.394-.496-.578-.563-.799s-.067-.447 0-.668c.067-.221.169-.405.563-.8l1.269-1.268c.394-.394.578-.496.799-.563s.447-.067.668 0zM11.398 8c.557 0 .759.058.963.167s.363.269.472.472c.109.204.167.406.167.963v1.796c0 .557-.058.759-.167.963a1.135 1.135 0 01-.472.472c-.204.109-.406.167-.963.167H9.602c-.557 0-.759-.058-.963-.167-.203-.109-.363-.269-.472-.472S8 11.954 8 11.398V9.602c0-.557.058-.759.167-.963.109-.203.269-.363.472-.472S9.045 8 9.602 8z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28ServicesCircleFillBlueProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28ServicesCircleFillBlue: FC<Icon28ServicesCircleFillBlueProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28ServicesCircleFillBlue as any).mountIcon = mountIcon;

export default Icon28ServicesCircleFillBlue;
