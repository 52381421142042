import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'user_square_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="user_square_outline_56"><g clip-rule="evenodd" fill="currentColor" fill-rule="evenodd"><path d="M37.5 24.5c0 5.248-4.252 9.5-9.5 9.5s-9.5-4.252-9.5-9.5S22.752 15 28 15s9.5 4.252 9.5 9.5zm-3 0c0-3.592-2.908-6.5-6.5-6.5s-6.5 2.908-6.5 6.5S24.408 31 28 31s6.5-2.908 6.5-6.5z" /><path d="M21.383 6c-5.019 0-7.117.465-9.244 1.603a10.924 10.924 0 00-4.536 4.536C6.465 14.266 6 16.364 6 21.383v13.234c0 5.019.465 7.117 1.603 9.244a10.924 10.924 0 004.536 4.536C14.266 49.535 16.364 50 21.383 50h13.234c5.019 0 7.117-.465 9.244-1.603a10.924 10.924 0 004.536-4.536C49.535 41.734 50 39.636 50 34.617V21.383c0-5.019-.465-7.117-1.603-9.244a10.924 10.924 0 00-4.536-4.536C41.734 6.465 39.636 6 34.617 6zm-7.83 4.248c1.486-.794 3-1.248 7.83-1.248h13.234c4.83 0 6.344.454 7.83 1.248a7.925 7.925 0 013.305 3.306c.794 1.485 1.248 3 1.248 7.83v13.233c0 4.83-.454 6.344-1.248 7.83-.193.36-.408.7-.645 1.02C40.41 39.733 34.464 37.502 28 37.502s-12.412 2.232-17.107 5.967a8.278 8.278 0 01-.645-1.022c-.794-1.485-1.248-3-1.248-7.83V21.384c0-4.83.454-6.344 1.248-7.83a7.925 7.925 0 013.306-3.305zm-.41 35.27c.134.08.27.159.41.234 1.486.794 3 1.248 7.83 1.248h13.234c4.83 0 6.344-.454 7.83-1.248.14-.075.276-.153.41-.235-4.122-3.147-9.27-5.016-14.857-5.016s-10.735 1.869-14.857 5.016z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56UserSquareOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56UserSquareOutline: FC<Icon56UserSquareOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56UserSquareOutline as any).mountIcon = mountIcon;

export default Icon56UserSquareOutline;
