import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'reply_outline_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="reply_outline_24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" /><path d="M12.227 3.725a2.15 2.15 0 011.997 2.145v2.117l.021.005a9.904 9.904 0 017.826 10.721c-.083.811-1.115 1.103-1.61.455l-.187-.237a9.082 9.082 0 00-5.836-3.265l-.213-.026-.001 2.494a2.15 2.15 0 01-3.477 1.692l-7.823-6.132a2.15 2.15 0 010-3.384l7.823-6.132a2.15 2.15 0 011.327-.458zm-.153 1.795a.35.35 0 00-.216.075l-7.824 6.132a.35.35 0 000 .55l7.824 6.133a.35.35 0 00.566-.276v-3.447a.9.9 0 01.916-.9l.233.004.342.017a10.88 10.88 0 016.118 2.365l.175.144-.024-.135a8.103 8.103 0 00-6.968-6.537.9.9 0 01-.791-.893l-.001-2.882a.35.35 0 00-.35-.35z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24ReplyOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24ReplyOutline: FC<Icon24ReplyOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24ReplyOutline as any).mountIcon = mountIcon;

export default Icon24ReplyOutline;
