import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 48 48';
const id = 'mention_outline_48';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" id="mention_outline_48"><path clip-rule="evenodd" d="M5 23.94C5 36.148 13.31 43.4 24.835 43.4c2.934 0 5.54-.376 7.276-.962 1.409-.47 1.902-1.104 1.902-1.925 0-.822-.54-1.338-1.503-1.338-.234 0-.54.023-.915.094-2.113.516-3.732.845-6.033.845-10.093 0-16.9-5.869-16.9-16.032 0-9.836 6.314-16.62 15.915-16.62 8.38 0 15.163 5.141 15.163 14.601 0 4.93-1.784 8.38-4.39 8.38-1.69 0-2.605-.916-2.605-2.512V16.29c0-1.315-.751-2.113-2.018-2.113-1.245 0-2.02.798-2.02 2.113v1.173h-.234c-.962-2.089-3.145-3.286-5.727-3.286-4.671 0-7.981 3.943-7.981 9.741 0 5.892 3.31 9.93 8.145 9.93 2.723 0 4.835-1.362 5.868-3.756h.235c.422 2.37 2.629 3.755 5.54 3.755 5.469 0 8.849-5.046 8.849-12.065 0-10.703-7.864-17.581-18.779-17.581C13.028 4.2 5 11.97 5 23.94zm18.732 6.245c-2.911 0-4.625-2.348-4.625-6.244 0-3.873 1.737-6.174 4.625-6.174 2.957 0 4.835 2.395 4.835 6.15 0 3.85-1.901 6.268-4.835 6.268z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon48MentionOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon48MentionOutline: FC<Icon48MentionOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 48,
    height: !isNaN(props.height) ? +props.height : 48,
  }));
};

(Icon48MentionOutline as any).mountIcon = mountIcon;

export default Icon48MentionOutline;
