import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'notification_circle_fill_gray_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="notification_circle_fill_gray_28"><linearGradient id="notification_circle_fill_gray_28_a" gradientUnits="userSpaceOnUse" x1="-14" x2="14" y1="14" y2="42"><stop offset="0" stop-color="#b1b6bd" /><stop offset="1" stop-color="#99a2ad" /></linearGradient><path d="M0 14C0 6.268 6.268 0 14 0s14 6.268 14 14-6.268 14-14 14S0 21.732 0 14z" fill="url(#notification_circle_fill_gray_28_a)" /><path d="M15.817 19.5a1.85 1.85 0 01-3.634 0zM9.5 18c-1 0-1.5-.333-1.5-1 0-1 1.5-1 1.5-2.5v-3a4.501 4.501 0 013.7-4.43V7a.8.8 0 011.6 0v.071a4.501 4.501 0 013.7 4.429v3C18.5 16 20 16 20 17c0 .667-.5 1-1.5 1z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28NotificationCircleFillGrayProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28NotificationCircleFillGray: FC<Icon28NotificationCircleFillGrayProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28NotificationCircleFillGray as any).mountIcon = mountIcon;

export default Icon28NotificationCircleFillGray;
