import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'attachments_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="attachments_28"><g fill="none" fill-rule="evenodd"><path d="M0 0h28v28H0z" /><path d="M23 21.95V9.487c0-1.56-.162-2.126-.467-2.696a3.18 3.18 0 00-1.324-1.324C20.64 5.162 20.073 5 18.513 5H7.05A2.5 2.5 0 019.5 3H21a4 4 0 014 4v12.5a2.5 2.5 0 01-2 2.45zM18.5 7h-14A2.5 2.5 0 002 9.5v14A2.5 2.5 0 004.5 26h14a2.5 2.5 0 002.5-2.5v-14A2.5 2.5 0 0018.5 7zM8 17.5l2.5 3.01L14 16l3.897 5.196a.502.502 0 01-.402.804H5.527a.502.502 0 01-.396-.811L8 17.5z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28AttachmentsProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28Attachments: FC<Icon28AttachmentsProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28Attachments as any).mountIcon = mountIcon;

export default Icon28Attachments;
