import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'text_live_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="text_live_outline_28"><path d="M22.181 5.221C24.531 7.411 26 10.534 26 14s-1.47 6.588-3.819 8.779m-16.362 0C3.469 20.589 2 17.466 2 14s1.47-6.588 3.819-8.779M19.577 8.264C21.07 9.718 22 11.75 22 14s-.928 4.282-2.423 5.736m-11.154 0C6.93 18.282 6 16.25 6 14s.929-4.282 2.423-5.736M14 18v-7M11 11h6" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" /><g fill="currentColor"><path d="M22.863 4.49A1 1 0 1021.5 5.953C23.654 7.962 25 10.823 25 14s-1.346 6.038-3.5 8.047a1 1 0 101.363 1.463C25.407 21.138 27 17.754 27 14s-1.593-7.138-4.137-9.51zM6.5 5.953A1 1 0 005.138 4.49C2.593 6.862 1 10.245 1 14s1.593 7.138 4.137 9.51A1 1 0 106.5 22.047C4.345 20.038 3 17.177 3 14s1.345-6.038 3.5-8.047z" /><path d="M9.12 8.981a1 1 0 00-1.394-1.434C6.046 9.181 5 11.47 5 14s1.046 4.819 2.726 6.453a1 1 0 101.394-1.434A6.976 6.976 0 017 14c0-1.969.811-3.746 2.12-5.019zM20.274 7.547a1 1 0 00-1.394 1.434A6.976 6.976 0 0121 14a6.976 6.976 0 01-2.12 5.019 1 1 0 101.394 1.434C21.954 18.819 23 16.53 23 14s-1.046-4.819-2.726-6.453zM13 12v6a1 1 0 102 0v-6h2a1 1 0 100-2h-6a1 1 0 100 2z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28TextLiveOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28TextLiveOutline: FC<Icon28TextLiveOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28TextLiveOutline as any).mountIcon = mountIcon;

export default Icon28TextLiveOutline;
