import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'phone_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="phone_36"><path d="M21.168 22.889c2.03-2.032 5.229-2.22 7.355-.431l1.536 1.294c1.754 1.476 1.932 4.136.396 5.94a4.978 4.978 0 01-3.293 1.7c-4.91.66-10.06-1.705-15.454-7.1-5.394-5.394-7.761-10.546-7.1-15.454.035-.384.114-.763.238-1.128a4.978 4.978 0 011.462-2.165c1.806-1.535 4.464-1.358 5.94.397l1.292 1.536c1.79 2.124 1.605 5.323-.426 7.355l-1.103 1.1c-.294.295-.39.734-.246 1.124.4 1.097 1.424 2.467 3.068 4.11 1.645 1.646 3.015 2.668 4.11 3.07.39.144.83.047 1.123-.247z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36PhoneProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36Phone: FC<Icon36PhoneProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36Phone as any).mountIcon = mountIcon;

export default Icon36Phone;
