import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'paperplane_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="paperplane_outline_28"><path d="M5.547 3.925c-.735.98-.672 1.482-.152 3.56.255 1.022.674 2.268 1.257 3.752l.158.398.09.212c.382.837.979 1.54 1.714 2.048l.159.104-.16.105a5 5 0 00-1.8 2.255c-.668 1.664-1.14 3.043-1.418 4.156l-.117.475c-.375 1.557-.384 2.056.159 2.923.593.95 1.57 1.213 2.538 1.038.4-.073.81-.218 1.308-.448l.436-.209.683-.354c2.706-1.435 9.83-5.444 11.962-6.725l.904-.55c.294-.183.476-.307.673-.455.789-.592 1.268-1.203 1.269-2.208 0-1.007-.479-1.619-1.268-2.211l-.147-.108c-.272-.195-.574-.384-1.24-.784l-.566-.338C19.183 8.903 11.531 4.612 9.73 3.71c-.645-.322-1.137-.524-1.597-.63-.958-.218-1.914-.05-2.585.844zM7.335 7l-.117-.481c-.357-1.544-.22-1.91 1.45-1.101l.167.082c2 1 10.375 5.723 12.5 6.999l.803.487c1.43.892 1.43 1.135 0 2.027l-.803.488c-1.96 1.176-10.09 5.767-12.342 6.92l-.325.161-.313.146C6.84 23.4 6.88 22.825 7.335 21c.253-1.011.697-2.31 1.334-3.897a3 3 0 012.135-1.812l.195-.036.973-.154c2.909-.47 4.363-.838 4.363-1.101 0-.291-1.779-.71-5.337-1.255a3 3 0 01-2.25-1.667l-.08-.181-.154-.391C7.96 9.096 7.567 7.927 7.335 7z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28PaperplaneOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28PaperplaneOutline: FC<Icon28PaperplaneOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28PaperplaneOutline as any).mountIcon = mountIcon;

export default Icon28PaperplaneOutline;
