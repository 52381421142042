import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'logo_vk_32';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="logo_vk_32"><path d="M19.987 3C27.267 3 29 4.733 29 12.013v7.974C29 27.267 27.267 29 19.987 29h-7.974C4.733 29 3 27.267 3 19.987v-7.974C3 4.733 4.733 3 12.013 3h7.974zM9.77 11.125H7.875c-.542 0-.65.255-.65.536 0 .502.643 2.993 2.993 6.287 1.566 2.25 3.774 3.469 5.782 3.469 1.205 0 1.354-.271 1.354-.738v-1.7c0-.541.114-.65.496-.65.281 0 .763.141 1.888 1.226 1.285 1.285 1.497 1.862 2.22 1.862h1.896c.542 0 .813-.271.657-.806-.171-.532-.785-1.305-1.6-2.221-.442-.523-1.104-1.085-1.305-1.366-.281-.362-.201-.522 0-.844l.034-.047c.285-.407 2.293-3.285 2.517-4.311.12-.402 0-.697-.574-.697h-1.895c-.482 0-.705.255-.825.536 0 0-.964 2.35-2.33 3.877-.442.441-.643.582-.884.582-.12 0-.295-.14-.295-.542v-3.756c0-.482-.14-.697-.541-.697h-2.98c-.3 0-.482.224-.482.436 0 .457.683.562.753 1.848V16.2c0 .613-.11.724-.351.724-.643 0-2.206-2.361-3.134-5.062-.181-.525-.364-.737-.848-.737z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32LogoVkProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32LogoVk: FC<Icon32LogoVkProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32LogoVk as any).mountIcon = mountIcon;

export default Icon32LogoVk;
