import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'services_24';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="services_24"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z" /><path d="M14.5 21h5a1.5 1.5 0 001.5-1.5v-5a1.5 1.5 0 00-1.5-1.5h-5a1.5 1.5 0 00-1.5 1.5v5a1.5 1.5 0 001.5 1.5zM11 19.5v-5A1.5 1.5 0 009.5 13h-5A1.5 1.5 0 003 14.5v5A1.5 1.5 0 004.5 21h5a1.5 1.5 0 001.5-1.5zM4.5 11h5A1.5 1.5 0 0011 9.5v-5A1.5 1.5 0 009.5 3h-5A1.5 1.5 0 003 4.5v5A1.5 1.5 0 004.5 11zm7.56-2.6l3.54 3.54a1.5 1.5 0 002.12 0l3.54-3.538a1.5 1.5 0 000-2.123l-3.54-3.531a1.5 1.5 0 00-2.12 0l-3.54 3.531a1.5 1.5 0 000 2.123z" fill="currentColor" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24ServicesProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Services: FC<Icon24ServicesProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Services as any).mountIcon = mountIcon;

export default Icon24Services;
