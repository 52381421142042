import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'sync_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="sync_20"><clipPath id="sync_20_a"><path d="M0 0h20v20H0z" /></clipPath><g clip-path="url(#sync_20_a)" fill="currentColor"><path d="M16.25 1.5a.75.75 0 01.75.75v3a.75.75 0 01-.75.75h-3a.75.75 0 010-1.5h1.081a6.977 6.977 0 00-4.33-1.5 6.976 6.976 0 00-4.95 2.05 6.976 6.976 0 00-1.973 5.996c.073.483-.278.954-.766.954a.69.69 0 01-.69-.563A8.54 8.54 0 011.5 10c0-2.174.83-4.35 2.49-6.01A8.476 8.476 0 0110 1.5c1.958 0 3.918.673 5.5 2.019V2.25a.75.75 0 01.75-.75zM3 14.75a.75.75 0 01.75-.75h3a.75.75 0 010 1.5H5.669C6.935 16.5 8.467 17 10 17a6.976 6.976 0 004.95-2.05 6.976 6.976 0 001.972-5.996C16.85 8.47 17.2 8 17.688 8a.69.69 0 01.69.563 8.476 8.476 0 01-2.368 7.448A8.476 8.476 0 0110 18.5a8.476 8.476 0 01-5.5-2.019v1.269a.75.75 0 01-1.5 0z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20SyncProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20Sync: FC<Icon20SyncProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20Sync as any).mountIcon = mountIcon;

export default Icon20Sync;
