import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'folder_add_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="folder_add_24"><path clip-rule="evenodd" d="M2.327 5.638C2 6.28 2 7.12 2 8.8v6.4c0 1.68 0 2.52.327 3.162a3 3 0 001.311 1.311C4.28 20 5.12 20 6.8 20h10.4c1.68 0 2.52 0 3.162-.327a3 3 0 001.311-1.311C22 17.72 22 16.88 22 15.2v-4.4c0-1.68 0-2.52-.327-3.162a3 3 0 00-1.311-1.311C19.72 6 18.88 6 17.2 6H12l-1.531-1.531c-.173-.173-.26-.26-.36-.322a1 1 0 00-.29-.12C9.704 4 9.582 4 9.337 4H6.8c-1.68 0-2.52 0-3.162.327a3 3 0 00-1.311 1.311zM15 8.5a1 1 0 00-1 1V12h-2.5a1 1 0 100 2H14v2.5a1 1 0 102 0V14h2.5a1 1 0 100-2H16V9.5a1 1 0 00-1-1z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24FolderAddProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24FolderAdd: FC<Icon24FolderAddProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24FolderAdd as any).mountIcon = mountIcon;

export default Icon24FolderAdd;
