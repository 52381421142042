import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 32 32';
const id = 'graffiti_32';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="graffiti_32"><path d="M10.13 17.464c2.656 0 4.8 2.144 4.8 4.8 0 3.536-2.864 6.4-6.4 6.4-2.416 0-4.928-1.248-6.4-3.2 1.344 0 3.2-1.104 3.2-3.2 0-2.656 2.144-4.8 4.8-4.8zM27.162 4.615l.22.22c.838.838.852 2.193.03 3.047L16.014 19.738a6.406 6.406 0 00-3.941-3.574L24.115 4.585a2.176 2.176 0 013.047.03z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon32GraffitiProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon32Graffiti: FC<Icon32GraffitiProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 32,
    height: !isNaN(props.height) ? +props.height : 32,
  }));
};

(Icon32Graffiti as any).mountIcon = mountIcon;

export default Icon32Graffiti;
