import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'forward_15_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="forward_15_24"><g fill="currentColor"><path d="M11.75 5.244V3.35a.35.35 0 01.533-.298l4.302 2.644a.35.35 0 010 .596l-4.302 2.644a.35.35 0 01-.533-.298V6.744a6.25 6.25 0 106.142 7.414.75.75 0 111.474.278 7.75 7.75 0 11-7.616-9.192z" /><path d="M9.379 15.894a.35.35 0 01-.35-.35v-3.578l-.868.34a.28.28 0 01-.382-.26v-.486a.35.35 0 01.214-.323l1.845-.78a.35.35 0 01.486.322v4.765a.35.35 0 01-.35.35zM11.626 14.35a.603.603 0 00-.143.406c0 .214.084.417.251.608s.397.345.687.461c.29.117.61.175.961.175.427 0 .801-.082 1.124-.247a1.78 1.78 0 00.748-.696c.175-.3.263-.647.263-1.043 0-.355-.073-.668-.218-.938a1.563 1.563 0 00-.61-.63 1.793 1.793 0 00-.906-.225c-.23 0-.444.038-.64.114a.962.962 0 00-.436.303h-.066l.086-1.187h1.97c.19 0 .337-.045.437-.134.101-.09.151-.218.151-.384 0-.168-.05-.299-.153-.393s-.247-.14-.435-.14h-2.229c-.49 0-.748.215-.777.646l-.13 1.95a.67.67 0 00.07.352c.053.105.13.186.23.243a.683.683 0 00.58.04c.075-.03.16-.083.255-.158.117-.086.24-.152.367-.199s.25-.07.37-.07c.163 0 .308.035.438.106s.23.17.302.3a.886.886 0 01.108.44.918.918 0 01-.114.462.806.806 0 01-.316.313.925.925 0 01-.457.112c-.178 0-.34-.04-.486-.122s-.288-.208-.424-.38a.752.752 0 00-.226-.192.549.549 0 00-.257-.06.47.47 0 00-.375.166z" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24Forward15Props extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24Forward15: FC<Icon24Forward15Props> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24Forward15 as any).mountIcon = mountIcon;

export default Icon24Forward15;
