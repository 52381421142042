import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'user_outline_20';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="user_outline_20"><g fill="none" fill-rule="evenodd"><path opacity=".1" d="M0 0h20v20H0z" /><path d="M12.5 5a2.5 2.5 0 10-5 0 2.5 2.5 0 105 0zM14 5a4 4 0 01-4 4 4 4 0 01-4-4 4 4 0 014-4 4 4 0 014 4zM4 15.5c0 .715.358 1 1 1h10c.642 0 1-.285 1-1 0-2.333-2.567-3.5-6-3.5s-6 1.167-6 3.5zm-1.5 0c0-3.485 3.333-5 7.5-5s7.5 1.515 7.5 5c0 1.628-1.095 2.5-2.5 2.5H5c-1.405 0-2.5-.872-2.5-2.5z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20UserOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20UserOutline: FC<Icon20UserOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20UserOutline as any).mountIcon = mountIcon;

export default Icon20UserOutline;
