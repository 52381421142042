import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'music_outline_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="music_outline_36"><path clip-rule="evenodd" d="M28.902 8.043a3.821 3.821 0 00-4.52-2.962L15.09 7.017l-.263.056c-1.01.226-1.553.448-2.101.844-.561.406-.99.935-1.274 1.567-.299.668-.4 1.29-.4 2.497v10.34h-.63c-5.998 0-9.08 5.854-5.902 9.032s9.033.096 9.033-5.901v-9.776l12.928-2.693v6.123h-.631c-5.997 0-9.079 5.855-5.901 9.033 3.177 3.178 9.032.096 9.032-5.901V8.822l-.009-.261a3.833 3.833 0 00-.071-.518zm-2.42 2.386V8.822a1.32 1.32 0 00-1.457-1.314l-.134.02-9.29 1.936c-.867.18-1.142.286-1.409.479-.205.148-.353.33-.457.562l-.056.14c-.083.24-.119.529-.124 1.142v1.335zM11.054 25.452v-.631h-.632c-3.905 0-5.627 3.27-4.133 4.764 1.493 1.494 4.765-.228 4.765-4.133zm15.428-3.845v.63c0 3.906-3.271 5.628-4.765 4.134-1.493-1.494.229-4.765 4.134-4.765z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36MusicOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36MusicOutline: FC<Icon36MusicOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36MusicOutline as any).mountIcon = mountIcon;

export default Icon36MusicOutline;
