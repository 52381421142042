import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'play_circle_fill_azure_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="play_circle_fill_azure_16"><circle cx="8" cy="8" r="8" fill="#2787F5" /><path d="M6.15 4.555l5.633 3.088a.4.4 0 010 .714L6.15 11.445a.445.445 0 01-.591-.151.396.396 0 01-.058-.206V4.912c0-.228.194-.412.433-.412a.45.45 0 01.216.055z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16PlayCircleFillAzureProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16PlayCircleFillAzure: FC<Icon16PlayCircleFillAzureProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16PlayCircleFillAzure as any).mountIcon = mountIcon;

export default Icon16PlayCircleFillAzure;
