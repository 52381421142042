import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'hashtag_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="hashtag_16"><path clip-rule="evenodd" d="M7.157 2.017a.75.75 0 00-.89.576L5.75 5H3.75l-.102.007A.75.75 0 003.75 6.5h1.68l-.643 3H2.75l-.102.007A.75.75 0 002.75 11h1.715l-.448 2.093-.015.1a.75.75 0 001.481.214L6 11h2.716l-.448 2.093-.015.1a.75.75 0 001.481.214L10.25 11h2.001l.102-.007A.75.75 0 0012.25 9.5h-1.68l.643-3h2.037l.102-.007A.75.75 0 0013.25 5h-1.715l.448-2.093.015-.1a.75.75 0 00-1.481-.214L10 5H7.285l.448-2.093.015-.1a.75.75 0 00-.59-.79zM9.037 9.5l.642-3H6.963l-.642 3z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16HashtagProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16Hashtag: FC<Icon16HashtagProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16Hashtag as any).mountIcon = mountIcon;

export default Icon16Hashtag;
