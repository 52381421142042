import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'newsfeed_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="newsfeed_36"><path d="M25 15.435c0-.714-.45-1.292-1.005-1.292h-11.99c-.555 0-1.005.578-1.005 1.292v10.273c0 .714.45 1.292 1.005 1.292h11.99C24.55 27 25 26.422 25 25.708V15.435zM12.25 9a1.25 1.25 0 100 2.5h8.5a1.25 1.25 0 100-2.5h-8.5z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M8.946 4.03C10.348 3.28 11.736 3 14.844 3h6.312c3.107 0 4.496.28 5.898 1.03a7.026 7.026 0 012.916 2.916c.75 1.402 1.03 2.79 1.03 5.898v10.312c0 3.107-.28 4.496-1.03 5.898a7.026 7.026 0 01-2.916 2.916c-1.402.75-2.79 1.03-5.898 1.03h-6.312c-3.107 0-4.496-.28-5.898-1.03a7.026 7.026 0 01-2.916-2.916C5.28 27.651 5 26.264 5 23.155V12.844c0-3.107.28-4.496 1.03-5.898A7.027 7.027 0 018.946 4.03zm5.898 1.47c-2.96 0-3.857.274-4.719.735a4.528 4.528 0 00-1.89 1.89c-.46.862-.735 1.76-.735 4.719v10.312c0 2.96.274 3.857.735 4.719a4.527 4.527 0 001.89 1.89c.862.46 1.76.735 4.719.735h6.312c2.96 0 3.857-.274 4.719-.735a4.527 4.527 0 001.89-1.89c.46-.862.735-1.76.735-4.719V12.844c0-2.96-.274-3.857-.735-4.719a4.527 4.527 0 00-1.89-1.89c-.862-.46-1.76-.735-4.719-.735h-6.312z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36NewsfeedProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36Newsfeed: FC<Icon36NewsfeedProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36Newsfeed as any).mountIcon = mountIcon;

export default Icon36Newsfeed;
