import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'videocam_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="videocam_36"><path d="M19.731 7.5c2.006 0 2.734.209 3.467.601s1.309.968 1.701 1.701c.392.733.601 1.46.601 3.467v9.462c0 2.006-.209 2.734-.601 3.467a4.09 4.09 0 01-1.701 1.701c-.733.392-1.46.601-3.467.601H8.77c-2.006 0-2.734-.209-3.467-.601-.733-.392-1.309-.968-1.7-1.701S3 24.738 3 22.73V13.27c0-2.006.209-2.734.601-3.467a4.089 4.089 0 011.701-1.7c.733-.393 1.46-.602 3.467-.602zM34.2 11.845c.195.26.301.576.301.901v10.508a1.5 1.5 0 01-2.4 1.2l-3.3-2.479a.75.75 0 01-.3-.6v-6.75a.75.75 0 01.3-.6l3.3-2.478a1.5 1.5 0 012.1.298z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36VideocamProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36Videocam: FC<Icon36VideocamProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36Videocam as any).mountIcon = mountIcon;

export default Icon36Videocam;
