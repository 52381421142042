import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'gift_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="gift_36"><path d="M19.61 10.95c7.008-.571 8.716-5.74 6.45-8.007-2.375-2.374-7.938-.402-8.058 7.472C17.88 2.541 12.318.57 9.943 2.943c-2.323 2.324-.485 7.7 6.973 8.044l-.64.013H9a4 4 0 00-4 4v2.25a2 2 0 002 2h22a2 2 0 002-2V15a4 4 0 00-4-4h-8.445c.21-.003.417-.01.62-.02 1.918-.038 1.488-.04.434-.03zM16.75 21.75H8V29a3 3 0 003 3h5.75V21.75zM19.25 21.75H28V29a3 3 0 01-3 3h-5.75V21.75z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36GiftProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36Gift: FC<Icon36GiftProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36Gift as any).mountIcon = mountIcon;

export default Icon36Gift;
