import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'drill_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="drill_outline_28"><path clip-rule="evenodd" d="M3 9a5 5 0 015-5h8.5c1.309 0 2.422.838 2.832 2.007.055-.005.111-.007.168-.007h.5c1.306 0 2.417.835 2.83 2H25a1 1 0 110 2h-2.17A3.001 3.001 0 0120 12h-.5c-.057 0-.113-.002-.168-.007A3.001 3.001 0 0116.5 14h-2.842l-1.54 9H17a1 1 0 110 2H4a1 1 0 110-2h1.407l1.844-9.056A5.001 5.001 0 013 9zm4.448 14h2.641l1.54-9H9.28zM16.5 12H8a3 3 0 110-6h8.5a1 1 0 011 1v4a1 1 0 01-1 1zM21 9a1 1 0 00-1-1h-.5v2h.5a1 1 0 001-1z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28DrillOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28DrillOutline: FC<Icon28DrillOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28DrillOutline as any).mountIcon = mountIcon;

export default Icon28DrillOutline;
