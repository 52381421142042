import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'box_circle_fill_blue_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="box_circle_fill_blue_20"><linearGradient id="box_circle_fill_blue_20_a" gradientUnits="userSpaceOnUse" x1="-10" x2="10" y1="10" y2="30"><stop offset="0" stop-color="#70b2ff" /><stop offset="1" stop-color="#5c9ce6" /></linearGradient><path d="M0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10z" fill="url(#box_circle_fill_blue_20_a)" /><path d="M5.2 8.595a.2.2 0 01.074.015l4.577 1.83a.4.4 0 00.298 0l4.577-1.83a.2.2 0 01.274.185v4.434a.4.4 0 01-.251.372l-4.6 1.84a.4.4 0 01-.298 0l-4.6-1.84A.4.4 0 015 13.229V8.795c0-.11.09-.2.2-.2zM9.851 4.56a.4.4 0 01.298 0l4.387 1.755a.2.2 0 010 .372L10.149 8.44a.4.4 0 01-.298 0L5.464 6.686a.2.2 0 010-.372z" fill="#fff" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20BoxCircleFillBlueProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20BoxCircleFillBlue: FC<Icon20BoxCircleFillBlueProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20BoxCircleFillBlue as any).mountIcon = mountIcon;

export default Icon20BoxCircleFillBlue;
