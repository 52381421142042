import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'market_outline_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="market_outline_36"><g fill="currentColor"><path d="M10 10.5a8 8 0 1116 0h-2.5a5.5 5.5 0 10-11 0zM24.5 16a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM10 17.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z" /><path clip-rule="evenodd" d="M8.856 10.502l18.016-.002c1.783 0 2.43.186 3.082.534.651.349 1.163.86 1.512 1.512.348.652.534 1.299.534 3.082v8.028c0 3.072-.371 4.448-1.069 5.751a7.27 7.27 0 01-3.024 3.025c-1.303.697-2.679 1.068-5.75 1.068h-8.313c-3.072 0-4.448-.371-5.751-1.069-1.304-.697-2.327-1.72-3.024-3.024S4 26.728 4 23.657v-8.03c0-1.782.186-2.429.534-3.08a3.635 3.635 0 011.512-1.513c.618-.33 1.23-.514 2.81-.533zM8.904 13l-.387.006c-.76.02-1.024.089-1.292.232-.216.115-.37.27-.486.486l-.061.125c-.12.279-.17.63-.177 1.554L6.5 23.656c0 2.46.216 3.531.773 4.572a4.77 4.77 0 001.999 1.999c.95.508 1.926.733 3.96.768l.612.005h8.312l.612-.005c2.034-.035 3.01-.26 3.96-.768a4.77 4.77 0 001.999-1.999c.508-.95.733-1.926.768-3.96l.005-.612v-8.028c0-1.249-.063-1.574-.239-1.903a1.136 1.136 0 00-.486-.486l-.125-.061c-.279-.12-.63-.17-1.554-.177z" fill-rule="evenodd" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36MarketOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36MarketOutline: FC<Icon36MarketOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36MarketOutline as any).mountIcon = mountIcon;

export default Icon36MarketOutline;
