import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 24 24';
const id = 'data_saver_circle_fill_green_24';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="data_saver_circle_fill_green_24"><path d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z" fill="url(#data_saver_circle_fill_green_24_paint0_linear)" /><path d="M8 11a1 1 0 011 1v4a1 1 0 11-2 0v-4a1 1 0 011-1zm8-5a1 1 0 011 1v9a1 1 0 11-2 0V7a1 1 0 011-1zm-4 2.5a1 1 0 011 1V16a1 1 0 11-2 0V9.5a1 1 0 011-1z" fill="#fff" /><defs><linearGradient id="data_saver_circle_fill_green_24_paint0_linear" x1="-12" y1="12" x2="12" y2="36" gradientUnits="userSpaceOnUse"><stop stop-color="#50C750" /><stop offset="1" stop-color="#32B332" /></linearGradient></defs></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon24DataSaverCircleFillGreenProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon24DataSaverCircleFillGreen: FC<Icon24DataSaverCircleFillGreenProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 24,
    height: !isNaN(props.height) ? +props.height : 24,
  }));
};

(Icon24DataSaverCircleFillGreen as any).mountIcon = mountIcon;

export default Icon24DataSaverCircleFillGreen;
