import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 36 36';
const id = 'coins_stacks_2_outline_36';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" id="coins_stacks_2_outline_36"><path d="M23 6c4.802 0 8.836 1.969 8.995 4.824L32 11v15l-.005.176C31.835 29.03 27.802 31 23 31c-4.347 0-8.065-1.614-8.848-4.038-.377.025-.762.038-1.152.038-4.9 0-9-2.05-9-5V12l.005-.176C4.165 8.97 8.198 7 13 7c1.29 0 2.525.142 3.644.405C18.282 6.517 20.54 6 23 6zm7.002 18.206C28.34 25.33 25.8 26 23 26s-5.339-.67-7-1.794V26l.005.107C16.15 27.543 19.16 29 23 29s6.85-1.458 6.995-2.893L30 26zM6 20.206V22l.005.108C6.15 23.542 9.16 25 13 25c.34 0 .675-.011 1-.033l.001-2.996c-.33.02-.663.029-1.001.029-2.8 0-5.339-.67-7-1.794zm24.002-1C28.34 20.33 25.8 21 23 21s-5.339-.67-7-1.794V21c0 1.468 3.063 3 7 3 3.84 0 6.85-1.458 6.995-2.892L30 21zM6 15.206V17c0 1.468 3.063 3 7 3 .34 0 .675-.011 1-.033l.001-2.996c-.33.02-.663.029-1.001.029-2.8 0-5.339-.67-7-1.794zm24.002-1C28.34 15.33 25.8 16 23 16s-5.339-.67-7-1.794V16c0 1.468 3.063 3 7 3 3.84 0 6.85-1.458 6.995-2.892L30 16zM13 9c-3.937 0-7 1.532-7 3s3.063 3 7 3c.34 0 .675-.011 1-.033V11l.005-.176c.035-.624.255-1.206.627-1.734C14.112 9.032 13.565 9 13 9zm10-1c-3.937 0-7 1.532-7 3s3.063 3 7 3 7-1.532 7-3-3.063-3-7-3z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon36CoinsStacks2OutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon36CoinsStacks2Outline: FC<Icon36CoinsStacks2OutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 36,
    height: !isNaN(props.height) ? +props.height : 36,
  }));
};

(Icon36CoinsStacks2Outline as any).mountIcon = mountIcon;

export default Icon36CoinsStacks2Outline;
