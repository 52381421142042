import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 48 48';
const id = 'game_48';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" id="game_48"><path d="M34.492 12c9.625 0 10.5 15.75 10.5 21s-.875 7-4.375 7C37 40 33.625 31 31 31H16.992c-2.625 0-5.992 9-9.626 9-3.5 0-4.375-1.75-4.375-7s.875-21 10.5-21zM15 16.5h-.5a1 1 0 00-1 1V20H11a1 1 0 00-.984.82L10 21v.5a1 1 0 001 1h2.5V25a1 1 0 00.82.984l.18.016h.5a1 1 0 001-1v-2.5h2.5a1 1 0 00.984-.82l.016-.18V21a1 1 0 00-1-1H16v-2.5a1 1 0 00-.82-.984zm18.5 7H33a1 1 0 00-1 1v.5a1 1 0 001 1h.5a1 1 0 001-1v-.5a1 1 0 00-1-1zM30 20h-.5a1 1 0 00-1 1v.5a1 1 0 001 1h.5a1 1 0 001-1V21a1 1 0 00-1-1zm7 0h-.5a1 1 0 00-1 1v.5a1 1 0 001 1h.5a1 1 0 001-1V21a1 1 0 00-1-1zm-3.5-3.5H33a1 1 0 00-1 1v.5a1 1 0 001 1h.5a1 1 0 001-1v-.5a1 1 0 00-1-1z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon48GameProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon48Game: FC<Icon48GameProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 48,
    height: !isNaN(props.height) ? +props.height : 48,
  }));
};

(Icon48Game as any).mountIcon = mountIcon;

export default Icon48Game;
