import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 16 16';
const id = 'mask_16';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="mask_16"><path clip-rule="evenodd" d="M8 14c-2.063 0-4.014-1.573-5.05-4.093C1.81 7.13 1.7 3.329 2.603 2.386c.452-.472.637-.486 2.6-.177 1.078.17 1.863.25 2.797.25s1.72-.08 2.797-.25c1.963-.31 2.148-.295 2.6.177.904.943.794 4.744-.347 7.521C12.015 12.427 10.063 14 8 14zm0-2.5c-2.08 0-2.912-1.894-2.496-2.367.198-.226.397-.129.73.034.366.18.895.44 1.766.44s1.4-.26 1.766-.44c.333-.163.532-.26.73-.034.416.473-.416 2.367-2.496 2.367zM5.802 7.292c.826.074 1.54-.378 1.595-1.009s-.572-1.202-1.399-1.275c-.826-.074-1.54.378-1.595 1.009s.572 1.202 1.399 1.275zm2.801-1.009c.054.631.767 1.083 1.593 1.01.825-.074 1.45-.645 1.396-1.276-.054-.631-.767-1.083-1.592-1.01-.826.074-1.45.645-1.397 1.276z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon16MaskProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon16Mask: FC<Icon16MaskProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 16,
    height: !isNaN(props.height) ? +props.height : 16,
  }));
};

(Icon16Mask as any).mountIcon = mountIcon;

export default Icon16Mask;
