import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'shuffle_outline_28';
const content = '<symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="shuffle_outline_28"><g fill="none" fill-rule="evenodd"><path d="M0 0h28v28H0z" /><path d="M21.586 6.3c.122 0 .24.038.34.108l3.689 2.616a.586.586 0 010 .957l-3.69 2.616a.586.586 0 01-.925-.479V10.5h-4.2c-1.192 0-2.194.882-4.155 3.5 1.896 2.53 2.895 3.439 4.036 3.497l.119.003H21v-1.618a.586.586 0 01.925-.479l3.69 2.616a.586.586 0 010 .957l-3.69 2.616a.586.586 0 01-.925-.478V19.5h-4.2c-1.988 0-3.231-.99-5.4-3.828C9.232 18.51 7.989 19.5 6 19.5H3a1 1 0 010-2h3c1.192 0 2.193-.882 4.154-3.499-1.895-2.531-2.894-3.44-4.035-3.498L6 10.5H3a1 1 0 01-.117-1.993L3 8.5h3c1.988 0 3.231.99 5.4 3.828C13.568 9.49 14.811 8.5 16.8 8.5H21V6.886c0-.324.262-.586.586-.586z" fill="currentColor" fill-rule="nonzero" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28ShuffleOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28ShuffleOutline: FC<Icon28ShuffleOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28ShuffleOutline as any).mountIcon = mountIcon;

export default Icon28ShuffleOutline;
