import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'favorite_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="favorite_outline_20"><clipPath id="favorite_outline_20_a"><path d="M0 0h20v20H0z" /></clipPath><g clip-path="url(#favorite_outline_20_a)"><path clip-rule="evenodd" d="M10.827 3.084c-.312-.727-1.343-.727-1.654 0L7.745 6.416a1 1 0 01-.778.596l-3.115.445a.9.9 0 00-.44 1.59l2.194 1.776a1 1 0 01.354.962l-.622 3.318c-.156.83.816 1.398 1.463.855l2.556-2.147a1 1 0 011.286 0l2.556 2.147c.647.543 1.62-.025 1.463-.855l-.622-3.318a1 1 0 01.354-.962l2.193-1.775a.9.9 0 00-.439-1.59l-3.115-.446a1 1 0 01-.778-.596zm-3.033-.59c.831-1.94 3.58-1.94 4.412 0l1.317 3.073 2.837.405c2.075.297 2.8 2.923 1.171 4.242L15.57 11.8l.567 3.025c.415 2.216-2.177 3.73-3.903 2.28L10 15.23l-2.234 1.877c-1.726 1.45-4.318-.064-3.903-2.28l.567-3.024-1.961-1.588C.84 8.895 1.565 6.269 3.639 5.972l2.838-.405z" fill="currentColor" fill-rule="evenodd" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20FavoriteOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20FavoriteOutline: FC<Icon20FavoriteOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20FavoriteOutline as any).mountIcon = mountIcon;

export default Icon20FavoriteOutline;
