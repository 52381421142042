import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'computer_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="computer_outline_28"><path clip-rule="evenodd" d="M9.4 20h1.235l-.381 3H7a1 1 0 100 2h14a1 1 0 100-2h-3.254l-.38-3H18.6c2.24 0 3.36 0 4.216-.436a4 4 0 001.748-1.748C25 16.96 25 15.84 25 13.6v-3.2c0-2.24 0-3.36-.436-4.216a4 4 0 00-1.748-1.748C21.96 4 20.84 4 18.6 4H9.4c-2.24 0-3.36 0-4.216.436a4 4 0 00-1.748 1.748C3 7.04 3 8.16 3 10.4v3.2c0 2.24 0 3.36.436 4.216a4 4 0 001.748 1.748C6.04 20 7.16 20 9.4 20zM23 13.6v-3.2c0-1.153-.002-1.898-.048-2.464-.044-.544-.12-.745-.17-.844a2 2 0 00-.874-.874c-.099-.05-.3-.126-.844-.17C20.498 6.002 19.754 6 18.6 6H9.4c-1.153 0-1.898.002-2.464.048-.544.044-.745.12-.844.17a2 2 0 00-.874.874c-.05.099-.126.3-.17.844C5.002 8.502 5 9.246 5 10.4v3.2c0 1.153.002 1.898.048 2.464.044.544.12.745.17.844a2 2 0 00.874.874c.099.05.3.126.844.17.566.046 1.31.048 2.464.048h9.2c1.153 0 1.898-.002 2.464-.048.544-.044.745-.12.844-.17a2 2 0 00.874-.874c.05-.099.126-.3.17-.844.046-.566.048-1.31.048-2.464zM15.73 23l-.381-3h-2.698l-.381 3z" fill="currentColor" fill-rule="evenodd" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28ComputerOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28ComputerOutline: FC<Icon28ComputerOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28ComputerOutline as any).mountIcon = mountIcon;

export default Icon28ComputerOutline;
